import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';

const init = () => ({
  type: User.SEND_INVOICES_INIT,
});

const success = (value) => ({
  type: User.SEND_INVOICES_SUCCESS,
  payload: {
    value,
  },
});

const failure = () => ({
  type: User.SEND_INVOICES_FAILURE,
});

const sendInvoices = (uuid, value) => async (dispatch) => {
  dispatch(init());
  try {
    await Connector.sendInvoices(uuid, value);
    dispatch(success(value));
    return true;
  } catch (err) {
    console.log(err);
    dispatch(failure());
    return false;
  }
};

export default sendInvoices;
