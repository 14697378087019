import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';
import { history } from '../../../Store/Reducers/RootReducer';
import { ROUTES } from 'Shared/enums';



const success = (data) => ({
  type: Website.GET_SINGLE_WEBSITE_SETTINGS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_SINGLE_WEBSITE_SETTINGS_FAILURE,
});

const getSingleWebsiteSettings = (uuid) => async (dispatch) => {

  try {
    dispatch(Actions.showLoader());
    const { data } = await Connector.getSingleWebsiteSettings(uuid);
    dispatch(success(data));
    dispatch(Actions.hideLoader());
    return data;
  } catch (err) {
    history.push(ROUTES.PAGE_404);
    dispatch(Actions.hideLoader());
    dispatch(failure());
  }
};

export default getSingleWebsiteSettings;
