import FadeInOnScroll from 'Components/FadeInOnScroll/FadeInOnScroll';
import React from 'react';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

interface ContactSocialsProps {
  formatMessage: any;
}

const ContactSocials: React.FC<ContactSocialsProps> = ({ formatMessage }) => {
  return (
    <FadeInOnScroll className="contact__socials">
      <h2>{formatMessage({ id: 'global.follow_our_socials' })}</h2>
      <ul className="contact__socials-list">
        <a href="https://www.facebook.com/www.corgi.pro/" target="_blank" className="contact__socials-icon" rel="noreferrer noopener">
          <SVG type={SVG_TYPE.FACEBOOK} />
        </a>
        <a href="https://www.instagram.com/corgi_pro/" target="_blank" className="contact__socials-icon" rel="noreferrer noopener">
          <SVG type={SVG_TYPE.INSTAGRAM} />
        </a>
        <a href="https://linkedin.com/company/www-corgi-pro" target="_blank" className="contact__socials-icon" rel="noreferrer noopener">
          <SVG type={SVG_TYPE.LINKEDIN} />
        </a>
        <a href="https://www.youtube.com/@corgi_pro" target="_blank" className="contact__socials-icon" rel="noreferrer noopener">
          <SVG type={SVG_TYPE.YOUTUBE} />
        </a>
      </ul>
      </FadeInOnScroll>

  );
};

export default ContactSocials;
