import { Auth } from '../EnumTypes/ActionEnumTypes';
import { Connector as AuthConnector } from 'Modules/AuthModule';
// import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: Auth.SET_COMPANY_VAT_INIT,
});

export const success = () => ({
  type: Auth.SET_COMPANY_VAT_SUCCESS,
});

const failure = () => ({
  type: Auth.SET_COMPANY_VAT_FAILURE,
});

const setCompanyVat = (data: any) => {
  return async (dispatch) => {
    dispatch(init());
    try {
      //   dispatch(Actions.showLoader());
      await AuthConnector.setCompanyVat(data);
      dispatch(success());
      //   dispatch(Actions.hideLoader());
    } catch (err:any) {
      dispatch(failure());
      //   dispatch(Actions.hideLoader());
      return err.response.status;
    }
  };
};

export default setCompanyVat;
