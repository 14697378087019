import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';

const success = (data) => ({
  type: Website.GET_DASHBOARD_USER_WEBSITES_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_DASHBOARD_USER_WEBSITES_FAILURE,
});

const getDashboardUserWebsites = () => async (dispatch) => {
  try {
    const { data } = await Connector.getDashboardUserWebsites();
    dispatch(success(data));
    dispatch(Actions.HideDashboardWebsitesInfoLoader());
  } catch (err) {
    dispatch(Actions.HideDashboardWebsitesInfoLoader());
    dispatch(failure());
  }
};

export default getDashboardUserWebsites;
