import React, { useState } from 'react';
import SVG from 'Shared/components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import ReactTooltip from 'react-tooltip';

type P = {
  className?: string;
  toolTip?: string;
  header?: string;
  first?: string;
  second?: string;
  method?: any;
};

const Choicer = ({ className, toolTip, header, first, second, method }: P) => {
  const [itemActive, setItemActive] = useState(method);

  return (
    <div className={`choicer choicer__${className}`}>
      <ReactTooltip place="top" type="light" effect="solid" className="tooltip" />
      <p className="choicer__header">
        <p className="choicer__heading">{header}</p>
        <p className="choicer__toolTip" data-tip={toolTip}>
          <SVG type={SVG_TYPE.INFORMATION} />
        </p>
      </p>

      <div className="choicer__items">
        <div onClick={() => setItemActive(!itemActive)} className={`choicer__item }`}>
          {first}
          <SVG type={SVG_TYPE.ACCEPT_GREEN} />
        </div>
        <div onClick={() => setItemActive(!itemActive)} className={`choicer__item }`}>
          {second} <SVG type={SVG_TYPE.ACCEPT_GREEN} />
        </div>
      </div>
    </div>
  );
};

export default Choicer;
