import { Locale } from 'Shared/components';

const getVatPrefixFromTranslation = () => {
  switch (Locale()) {
    case 'de':
      return { vat_type: 'eu_vat', vat_country: 'DE' };
    case 'en':
      return { vat_type: 'us_ein', vat_country: 'US' };
    case 'pl':
      return { vat_type: 'eu_vat', vat_country: 'PL' };
    default:
      return { vat_type: 'eu_vat', vat_country: 'PL' };
  }
};

export default getVatPrefixFromTranslation;
