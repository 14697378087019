import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

// const getUserStore = (state: RootState) => state.userStore;

const getPaymentStore = (state: RootState) => state.paymentStore;
const getPayment = createSelector(getPaymentStore, ({ payment: { data } }) => data);
const getStripePrice = createSelector(getPaymentStore, ({ stripePrices: { data } }) => data);

export { getPayment, getStripePrice };
