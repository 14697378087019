import React from 'react';
import Cookie from 'Assets/Image/cookie.svg';
import Button from 'Shared/components/button';
import { injectIntl } from 'react-intl';

function CookiesAgreement({ intl: { formatMessage }, setCookieAgreement }: any) {
  return (
    <div className="cookiesAgreement">
      <div className="cookiesAgreement__icon">
        <img src={Cookie} alt="Cookie" />
      </div>
      <div className="cookiesAgreement__text">
        <p>
          {formatMessage({ id: 'global.cookies_agreement.text_before' })}
          <a href={'cookies_policy.pdf'} rel="noopener noreferrer" target="_blank">
            {formatMessage({ id: 'global.cookies_agreement.policy_privacy' })}
          </a>
          {formatMessage({ id: 'global.cookies_agreement.text_after' })}.
        </p>
      </div>
      <div className="cookiesAgreement__button">
        <Button
          onClick={setCookieAgreement}
          label={formatMessage({ id: 'global.cookies_agreement.button' })}
          classNames="btn-black btn--white-border btn--transparent"
        />
      </div>
    </div>
  );
}

export default injectIntl(CookiesAgreement);
