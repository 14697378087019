import { getStripePrice } from 'Store/Selectors/PaymentSelector';
import { getLoggedUser } from 'Store/Selectors/UserSelector';
import { store } from 'Store/Store';
import { PRICES } from 'Shared/enums';
import { getMyWebsites as myWebsites } from 'Store/Selectors/UserSelector';

const stripePricing = () => {
  const state = store.getState();
  const price = getStripePrice(state);
  const user = getLoggedUser(state);
  const myWebsitesList = myWebsites(state);
  const loggedUserCurrency = user?.company?.currency || localStorage.getItem('loggedUserCurrency');
  const hasAtLeastOneSubscription = !!myWebsitesList?.filter((website) => !!website?.payment_method_uid).length;
  const isPartner = user?.company?.type === 'partner' && hasAtLeastOneSubscription;

  return {
    currencies: loggedUserCurrency ? loggedUserCurrency.toUpperCase() : price?.pricing_table_items[0].currency.toUpperCase(),
    basic: loggedUserCurrency
      ? isPartner
        ? PRICES[loggedUserCurrency].basic_partner
        : PRICES[loggedUserCurrency].basic
      : price?.pricing_table_items[0].amount.slice(0, -2),
    basic_old: loggedUserCurrency ? PRICES[loggedUserCurrency].basic_old : price?.pricing_table_items[1].amount.slice(0, -2),
    premium: loggedUserCurrency
      ? isPartner
        ? PRICES[loggedUserCurrency].premium_partner
        : PRICES[loggedUserCurrency].premium
      : price?.pricing_table_items[2].amount.slice(0, -2),
    premium_old: loggedUserCurrency ? PRICES[loggedUserCurrency].premium_old : price?.pricing_table_items[3].amount.slice(0, -2),
    pro: loggedUserCurrency
      ? isPartner
        ? PRICES[loggedUserCurrency].pro_partner
        : PRICES[loggedUserCurrency].pro
      : price?.pricing_table_items[4].amount.slice(0, -2),
    pro_old: loggedUserCurrency ? PRICES[loggedUserCurrency].pro_old : price?.pricing_table_items[5].amount.slice(0, -2),
  };
};

export default stripePricing;
