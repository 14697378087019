import React from 'react';
import { AddWebsite } from '../EnumTypes/ActionEnumTypes';
import { Connector } from '../../../Modules/AddWebsiteModule';
import { CreateUserType } from 'Shared/models/AddWebsiteModel';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import zxcvbn from 'zxcvbn';
import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';

const success = (data) => ({
  type: AddWebsite.USER_REGISTRATION_SUCCESS,
  payload: {
    data,
  },
});
const failure = (data) => ({
  type: AddWebsite.USER_REGISTRATION_FAILURE,
  payload: {
    data,
  },
});

const createUser = (data: CreateUserType) => async (dispatch) => {
  const id = toast.loading(<FormattedMessage id="global.apex_no_data" />);

  const getPasswordStrength = (password) => {
    const passwordStrength = zxcvbn(password).score;
    if (passwordStrength <= 1) {
      return 'weak';
    }
    if (passwordStrength > 1 && passwordStrength <= 3) {
      return 'medium';
    }
    if (passwordStrength > 3) {
      return 'strong';
    }
  };

  try {
    await Connector.userRegistration({ ...data, password_strength: getPasswordStrength(data.password) });
    dispatch(success(data));
    toast.update(id, {
      render:
      <div>
      <h2 className="toast-title toast-title--success"> <FormattedMessage id="global.toast_succes" /></h2>{' '}
      <p>
      <FormattedMessage id="global.toast.create_account_success" />
      </p>
    </div>,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      // icon: <SVG type={SVG_TYPE.PASSED} />,
    });
    return true;
  } catch (err) {
    dispatch(failure(data));
    toast.update(id, {
      render:
      <div>
      <h2 className="toast-title toast-title--failure"><FormattedMessage id="global.toast_failure" /></h2>{' '}
      <p>
      <FormattedMessage id="global.toast.create_account_failure" />
      </p>
    </div>,
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG type={SVG_TYPE.CLOSE_CIRCLE_FILL} variant={SVG_TYPE.CLOSE_CIRCLE_FILL} />,
    });
    console.log(err);
    return false;
  }
};

export default createUser;
