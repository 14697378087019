import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import { Toast } from 'Shared/components';

const init = () => ({
  type: User.UPDATE_THEME_COLOR_INIT,
});

const success = (data) => ({
  type: User.UPDATE_THEME_COLOR_SUCCESS,
  payload: data,
});

const failure = () => ({
  type: User.UPDATE_THEME_COLOR_FAILURE,
});

const setThemeColor = (theme_color) => async (dispatch) => {
  dispatch(init());
  Toast({ id: 'themeColor', type: 'loading', message: 'global.apex_no_data' });
  try {
    await Connector.setThemeColor(theme_color);
    dispatch(success(theme_color));

    Toast({ id: 'themeColor', type: 'success', message: 'global.toast.theme_color_change_success' });
    return true;
  } catch (err) {
    console.log(err);
    dispatch(failure());
    Toast({ id: 'themeColor', type: 'error', message: 'global.toast.theme_color_change_failure' });
    return false;
  }
};

export default setThemeColor;
