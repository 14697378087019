import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

// const getUserStore = (state: RootState) => state.userStore;

const getUserStore = (state: RootState) => state.userStore;
const getLoggedUser = createSelector(getUserStore, ({ loggedUser: { data } }) => data);
const getSubscribersLists = createSelector(getUserStore, ({ subscribersLists: { data } }) => data);
const getClientsLists = createSelector(getUserStore, ({ clientsLists: { data } }) => data);
const getClients = createSelector(getUserStore, ({ clients: { data } }) => data);
const getPreOrderLists = createSelector(getUserStore, ({ preOrderLists: { data } }) => data);
const getPaymentMethods = createSelector(getUserStore, ({ paymentMethods: { data = [] } }) => data);
const getPartnerClients = createSelector(getUserStore, ({ partnerClients: { data } }) => data);
const getAllUsers = createSelector(getUserStore, ({ allUsers: { data } }) => data);
const getMyWebsites = createSelector(getUserStore, ({ myWebsites: { data } }) => data);
const getMyWebsitesStatus = createSelector(getUserStore, ({ myWebsites: { status } }) => status);
const getUserWebsites = createSelector(getUserStore, ({ userWebsites: { data } }) => data);
const getActiveWebsite = createSelector(getUserStore, ({ activeWebsite }) => activeWebsite);
const getPartnerBalance = createSelector(getUserStore, ({ balance: { data } }) => data);
const getFile = createSelector(getUserStore, ({ file: { data } }) => data);
const getServices = createSelector(getUserStore, ({ services: { data } }) => data);

export {
  getLoggedUser,
  getPaymentMethods,
  getSubscribersLists,
  getPreOrderLists,
  getMyWebsites,
  getClientsLists,
  getActiveWebsite,
  getPartnerClients,
  getClients,
  getAllUsers,
  getPartnerBalance,
  getFile,
  getUserWebsites,
  getServices,
  getMyWebsitesStatus,
};
