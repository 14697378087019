import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import * as UserModule from 'Modules/UserModule';
// import { Auth } from '../EnumTypes/ActionEnumTypes';
import * as UserActionModel from '../Models/UserActionModel';

const init = () => ({
  type: User.ADD_WEBSITES_INIT,
});

export const success = (uuid: UserModule.Types.WebsiteUuid): UserActionModel.WebsiteUuid => ({
  type: User.ADD_WEBSITES_SUCCESS,
  payload: {
    uuid,
  },
});

const failure = () => ({
  type: User.ADD_WEBSITES_FAILURE,
});

const addWebsites =
  ({ url, plan, company_uuid }) =>
  async (dispatch) => {
    dispatch(init());

    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      // const link = dataForm.url;
      // let newLink = '';
      // if (link.search(/^http[s]?\:\/\//) == -1) {
      //   newLink = 'http://' + link;
      // }
      const modifiedObject = {
        url,
        plan,
        company_uuid,
      };
      const {
        data: { uuid },
      } = await UserModule.Connector.addWebsites(modifiedObject);
      dispatch(success(uuid));

      dispatch(ViewManagementModule.Actions.hideLoader());
      return uuid;
    } catch (err) {
      dispatch(ViewManagementModule.Actions.hideLoader());
      dispatch(failure());
      return false;
    }
  };

export default addWebsites;
