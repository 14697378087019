import API from 'Shared/connectors/Config';
import { CreateUserType, FillCompanyDataType, FtpType, WebsiteType } from 'Shared/models/AddWebsiteModel';
import { AxiosPromise } from 'axios';

export function userRegistration(data: CreateUserType): AxiosPromise<any> {
  return API.post('/users', data);
}

export function fillCompanyData(data: FillCompanyDataType, id: string): AxiosPromise<any> {
  return API.put(`/users/${id}`, data);
}

export function addWebsite(data: WebsiteType): AxiosPromise<any> {
  return API.post(`/websites`, data);
}

export function updateWebsite(data: WebsiteType, id: string): AxiosPromise<any> {
  return API.put(`/websites/${id}`, data);
}

export function addFtp(data: FtpType): AxiosPromise<any> {
  return API.post(`/connections/files`, data);
}
