import { SSL_STATUSES } from 'Shared/enums';

const handleSslColor = (sslStatus: 'ACTIVE' | 'INACTIVE' | 'NOT_INSTALLED') => {
  switch (sslStatus) {
    case SSL_STATUSES.ACTIVE:
      return 'green';

    case SSL_STATUSES.INACTIVE:
      return 'red';

    case SSL_STATUSES.NOT_INSTALLED:
      return 'orange';

    default:
      return 'gray';
  }
};

export default handleSslColor;
