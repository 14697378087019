import { connect } from 'react-redux';
import * as AuthModule from 'Modules/AuthModule';
import { RootState } from 'Store/Reducers/RootReducer';
import ResetPasswordPage from './Main';
import { Actions as VActions } from 'Modules/ViewManagementModule';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';

type ConnectedP = {
  authorizedUser: AuthModule.Types.AuthorizedUserData;
  history?: any;
};

type DispatchedP = {
  actions?: {
    setAppTranslation: (lang: string) => void;
    openModal: () => void;
    closeModal: () => void;
    loginUser: (email: string, password: string) => any;
    recoverPassword: (email: string) => any;
    checkRecoveryToken: (token: string) => any;
    setNewPassword: (token, password) => void;
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      openModal: () => VActions.openModal(),
      closeModal: () => VActions.closeModal(),
      setAppTranslation: VActions.setAppTranslation,
      loginUser: (email: string, password: string) => AuthModule.Actions.login(email, password),
      recoverPassword: AuthModule.Actions.recoverPassword,
      checkRecoveryToken: AuthModule.Actions.checkRecoveryToken,
      setNewPassword: AuthModule.Actions.setNewPassword,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  authorizedUser: AuthModule.Selectors.authorizedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ResetPasswordPage));
