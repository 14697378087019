import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';

const init = () => ({
    type: User.GET_SERVICES_INIT,
});

const success = (data) => ({
    type: User.GET_SERVICES_SUCCESS,
    payload: {
        data,
    },
});

const failure = () => ({
    type: User.GET_SERVICES_FAILURE,
});

const getServices = () => async (dispatch) => {
    dispatch(init());
    try {
        const { data } = await Connector.getServices();
        dispatch(success(data));
        return data;
    } catch (err) {
        console.log(err);
        dispatch(failure());
        return false;
    }
};

export default getServices;
