import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';

import { Toast } from 'Shared/components';

const init = () => ({
  type: User.SAVE_FILE_INIT,
});

const success = (data) => ({
  type: User.SAVE_FILE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.SAVE_FILE_FAILURE,
});

const saveFile = (values, form) => async (dispatch) => {
  dispatch(init());
  Toast({ id: 'saveFile', type: 'loading', message: 'global.apex_no_data' })
  try {  
    const file = new FormData();
    file.append('file', form);
    const { data } =  await Connector.saveFile(values, file);
    dispatch(success(data));

    Toast({ id: 'saveFile', type: 'success', message: 'global.toast.add_file_success' })
    return true;
  } catch (err) {
    dispatch(failure());

    Toast({ id: 'saveFile', type: 'error', message: 'global.toast.add_file_failure' })
    return false;
  }
};

export default saveFile;
