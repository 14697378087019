import React from 'react';
import { AddWebsite } from '../EnumTypes/ActionEnumTypes';
import { Connector } from '../../../Modules/AddWebsiteModule';
import { WebsiteType } from 'Shared/models/AddWebsiteModel';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';

const success = (data) => ({
  type: AddWebsite.ADD_WEBSITE_SUCCESS,
  payload: {
    data,
  },
});
const failure = (data) => ({
  type: AddWebsite.ADD_WEBSITE_FAILURE,
  payload: {
    data,
  },
});

const addWebsite = (data: WebsiteType) => async (dispatch) => {
  const id = toast.loading(<FormattedMessage id="global.apex_no_data" />);
  try {
    const response = await Connector.addWebsite(data);
    dispatch(success(response));
    toast.update(id, {
      render: (
        <div>
          <h2 className="toast-title toast-title--success">
            {' '}
            <FormattedMessage id="global.toast_succes" />
          </h2>{' '}
          <p>
            <FormattedMessage id="global.toast.add_website_success" />
          </p>
        </div>
      ),
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      // icon: <SVG type={SVG_TYPE.PASSED} />,
    });
    sessionStorage.setItem('website_uuid', response.data.uuid);
    return true;
  } catch (err: any) {
    dispatch(failure(data));
    console.log(err);
    toast.update(id, {
      render: (
        <div>
          <h2 className="toast-title toast-title--failure">
            <FormattedMessage id="global.toast_failure" />
          </h2>{' '}
          <p>
            <FormattedMessage id="global.toast.add_website_failure" />
          </p>
        </div>
      ),
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG type={SVG_TYPE.CLOSE_CIRCLE_FILL} variant={SVG_TYPE.CLOSE_CIRCLE_FILL} />,
    });
    return false;
  }
};

export default addWebsite;
