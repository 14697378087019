import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'Modules/ViewManagementModule';
import HomePage from './Main';
import { isMobile } from 'Shared/utils';
import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';

import { Selectors as VSelectors } from 'Modules/ViewManagementModule';
import { RootState } from 'Store/Reducers/RootReducer';
import { getMyWebsites } from 'Store/Actions/User';
import { getLoggedUser } from 'Store/Selectors/UserSelector';
import { getMyWebsites as myWebsitesSelector } from 'Store/Selectors/UserSelector';

type ConnectedP = {
  isMobile: boolean;
  translation: string;
  history?: any;
  loggedUser: any;
  myWebsites: any;
};

type DispatchedP = {
  actions: {
    openModal: () => void;
    closeModal: () => void;
    getMyWebsites: (onlyRefresh) => void;
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      openModal: () => Actions.openModal(),
      closeModal: () => Actions.closeModal(),
      getMyWebsites: () => getMyWebsites(),
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  isMobile: isMobile(),
  translation: VSelectors.getTranslation(state),
  loggedUser: getLoggedUser(state),
  myWebsites: myWebsitesSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HomePage));
