import { Connector } from 'Modules/WebsiteModule';

import { Toast } from 'Shared/components';

const storeAdditionalUrl =
  ({ uuid, url }) =>
  async () => {
    console.log(url);
    Toast({ id: 'storeAdditionalUrlSettings', type: 'loading', message: 'global.apex_no_data' });

    if (!url.includes('http://') && !url.includes('https://')) {
      url = `http://${url}`;
    }

    if (url.includes('https://')) {
      url = url.replace('https://', 'http://');
    }

    if (url.charAt(url.length - 1) === '/') {
      url = url.slice(0, -1);
    }

    if (url.includes('/')) {
      const urlParts = url.split('/');
      if (urlParts.length <= 3) {
        Toast({ id: 'storeAdditionalUrlSettings', type: 'error', message: 'global.toast.store_additional_url_failure' });
        return false;
      }
    }

    try {
      await Connector.storeAdditionalUrl({ uuid, url });

      Toast({ id: 'storeAdditionalUrlSettings', type: 'success', message: 'global.toast.store_additional_url_success' });
      return true;
    } catch (err: any) {
      if (err.response.status === 409) {
        Toast({ id: 'storeAdditionalUrlSettings', type: 'error', message: 'global.toast.store_additional_url_failure_uniq' });
        return false;
      }

      Toast({ id: 'storeAdditionalUrlSettings', type: 'error', message: 'global.toast.store_additional_url_failure' });
      return false;
    }
  };

export default storeAdditionalUrl;
