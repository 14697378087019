import React from 'react';
import { MainType } from './Container';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
// import Button from 'Shared/Components/button/Button';
// import ChevronWhite from '../../Assets/Image/Icon/icon_chevron_white.svg';
import { Link } from 'react-router-dom';
// import { history } from 'Store/Reducers/RootReducer';
import { ROUTES } from 'Shared/enums';
// import { FormattedMessage } from 'react-intl';
import Background from '../../Assets/Image/background.svg';
import { FormattedMessage } from 'react-intl';
import { Formik, Field } from 'formik';
import Button from 'Shared/components/button/Button';
import RegularField from '../../Shared/form/regularField/regularField';
import ChevronWhite from '../../Assets/Image/Icon/icon_chevron_white.svg';
import * as Yup from 'yup';

type P = {
  history: any;
};

const RemindPassword = ({ intl: { formatMessage }, actions: { recoverPassword }, history }: MainType & P) => {
  const [isAlert, setIsAlert] = React.useState(false);
  const validation = () => {
    return Yup.object().shape({
      email: Yup.string()
        .required(formatMessage({ id: 'global.validation.required' }))
        .email(formatMessage({ id: 'global.validation.wrong_email' })),
    });
  };

  const handleSubmit = () => {
    setIsAlert(false);
    history.push(ROUTES.LOGIN);
  };

  if (!isAlert) {
    return (
      <>
        <div className="login__wrapper">
          <div className="section--background">
            <img src={Background} alt="background image" />
          </div>
          <div className="remindPassword">
            <div className="remindPassword__text"><FormattedMessage id="global.register_email" /></div>
            <div className="remindPassword__mail">
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={validation()}
                onSubmit={async ({ email }) => {
                  if (await recoverPassword(email)) {
                    setIsAlert(true);
                  }
                }}>
                {({ handleSubmit, errors }) => (
                  <>
                    <FormattedMessage id="global.home_page.newsletter_mail">
                      {(msg) => <Field type="email" placeholder={msg} name="email" component={RegularField} errors={errors} />}
                    </FormattedMessage>
                    <Button
                      type="submit"
                      onClick={() => handleSubmit()}
                      iconImg={ChevronWhite}
                      label={<FormattedMessage id="global.send" />}
                      classNames="btn--blue"
                      iconAlt="icon product"
                    />
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="formSignUp remindPassword--alert">
        <div className="formSignUp__header">
          <div className="formSignUp__logo">
            <Link to={ROUTES.HOME_PAGE}>
              <SVG className="svg--logo-white" type={SVG_TYPE.LOGO} />
            </Link>
          </div>
        </div>
        <div className="formSignUp__content--wrapper">
          <div className="formSignUp__content">
            <SVG className="svg--logo-white" type={SVG_TYPE.BUST_WOOF} />
            <h1 className="formSignUp__heading">
              <FormattedMessage id="global.remind_password.title" />
            </h1>
            {/* <p className="formSignUp__email">{location.mail}</p> */}
            <p className="formSignUp__email">{''}</p>
            <p className="formSignUp__descript">
              <FormattedMessage id="global.remind_password.description" />
            </p>
            <Button
              type="submit"
              onClick={handleSubmit}
              label={<FormattedMessage id="global.cookies_agreement.button" />}
              classNames="btn--blue"
              iconImg={ChevronWhite}
              iconAlt="icon product"
            />
          </div>
        </div>
      </div>
    );
  }
};

export default RemindPassword;
