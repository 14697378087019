import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ROUTES } from 'Shared/enums';
import { getLocalStorage } from 'Shared/utils';
import Button from '../button';

const Navigation = ({ hideMenu }: P) => {
  const isUserLogged = getLocalStorage('isUserLogged');
  return (
    <>
      <NavLink onClick={hideMenu} to={ROUTES.HOW_IT_WORKS} activeClassName="navi__link--active" className="navi__link">
        <FormattedMessage id="global.how_it_works" />
      </NavLink>

      <NavLink onClick={hideMenu} to={ROUTES.PRICING} activeClassName="navi__link--active" className="navi__link">
        <FormattedMessage id="global.pricing.pricing" />
      </NavLink>

      <NavLink onClick={hideMenu} to={ROUTES.CONTACT} activeClassName="navi__link--active" className="navi__link">
        <FormattedMessage id="global.what_we_are.contact" />
      </NavLink>

      <NavLink onClick={hideMenu} to={ROUTES.LOGIN} activeClassName="navi__link--active" className="navi__link navi__link--login">
        {/* <span>
          <FormattedMessage id={isUserLogged ? 'Dashboard' : 'global.menu_login'} />
        </span> */}
        <Button
          onClick={() => null}
          label={<FormattedMessage id={isUserLogged ? 'Dashboard' : 'global.menu_login'} />}
          classNames="transparent-with-black-border"
        />
      </NavLink>
    </>
  );
};

type P = {
  hideMenu?: () => void;
};

export default Navigation;
