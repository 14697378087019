const archiwumSize = (size) => {
  if (size / (1024 * 1024) < 1) {
    return (size / 1024).toFixed(0) + ' KB';
  } else if (size / (1024 * 1024) > 1024) {
    return (size / (1024 * 1024 * 1024)).toFixed(0) + ' GB';
  } else {
    return (size / (1024 * 1024)).toFixed(0) + ' MB';
  }
  // return size;
};

export default archiwumSize;
