import { Locale } from "Shared/components";

const getCountryFromTranslation = () => {
    switch (Locale()) {
      case 'de':
        return 'DE';
      case 'en':
        return 'US';
      case 'pl':
        return 'PL';
      default:
        return 'PL';
    }
};
  
export default getCountryFromTranslation;