import { connect } from 'react-redux';
import WhyCorgi from './Main';
import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';

type ConnectedP = {};

type DispatchedP = {};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WhyCorgi));
