import { connect } from 'react-redux';
import { RootState } from 'Store/Reducers/RootReducer';
import { Selectors as VSelectors } from 'Modules/ViewManagementModule';
import { Actions } from 'Modules/WebsiteModule';
import { Actions as ViewManageActions } from 'Modules/ViewManagementModule';
import { bindActionCreators } from 'redux';
import WebsiteChecker from './Main';
import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';
import { Selectors as WebsitesSelectors } from 'Modules/WebsiteModule';
import { Actions as UserActions, Selectors as UserSelectors } from 'Modules/UserModule';

type ConnectedP = {
  translation: string;
  checkWebsite: any;
  loggedUser: any;
};

type DispatchedP = {
  actions?: {
    getAuthorizedUser: () => void;
    getCheckWebsite: any;
    checkerRegistration: any;
    openModal: () => void;
    closeModal: () => void;
    getPaymentMethods: () => void;
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getAuthorizedUser: UserActions.getAuthorizedUser,
      getCheckWebsite: Actions.getCheckWebsite,
      checkerRegistration: (data) => UserActions.checkerRegistration(data),
      openModal: () => ViewManageActions.openModal(),
      closeModal: () => ViewManageActions.closeModal(),
      getPaymentMethods: () => UserActions.getPaymentMethods(),
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  translation: VSelectors.getTranslation(state),
  checkWebsite: WebsitesSelectors.checkWebsite(state),
  loggedUser: UserSelectors.getLoggedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(WebsiteChecker));
