/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { SVG } from 'Shared/components';
import Background from '../../Assets/Image/background.svg';
import { MainType } from './Container';
import { isTokenSaved } from 'Shared/utils';
import { FormattedMessage } from 'react-intl';
import ErrorsBox from 'Shared/components/errorsBox';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { CreateNewPassword, EnterEmailForm, InvalidToken, LinkWasSend, SuccessfullPasswordChange } from './components';

const ResetPasswordPage = ({ intl: { formatMessage }, actions: { recoverPassword, checkRecoveryToken, setNewPassword } }: MainType) => {
  const [apiError, setApiError] = React.useState(false);
  const [email, setEmail] = useState('');
  const [successfullPasswordChange, setSuccessfullPasswordChange] = useState(false);

  const [isTokenValid, setIsTokenValid] = useState(true);
  const history = useHistory();
  const location = history.location;
  const token = location.search.split('=')[1];

  useEffect(() => {
    const checkTokenValidity = async () => {
      if (await checkRecoveryToken(token)) {
      } else {
        setIsTokenValid(false);
      }
    };
    checkTokenValidity();
  }, []);

  useEffect(() => {
    const addDarkClass = () => {
      const naviHeader = document.querySelector('.navi__header');
      if (naviHeader) {
        naviHeader.classList.add('navi__header--sticky');
      }
    };
    const removeDarkClass = () => {
      const naviHeader = document.querySelector('.navi__header');
      if (naviHeader) {
        naviHeader.classList.remove('navi__header--sticky');
      }
    };
    addDarkClass();
    return () => {
      removeDarkClass();
    };
  }, []);
  if (!isTokenSaved()) {
    return (
      <>
        <div className="login-page__header-overlay">
          <div className="login-page__header-wrapper">
            <Link to={ROUTES.HOME_PAGE}>
              <SVG type={SVG_TYPE.LOGO} />
            </Link>
            <p className="login-page__register">
              <FormattedMessage id="global.didnt_have_account" />{' '}
              <p onClick={() => history.push(ROUTES.REGISTER_PAGE)}>
                <FormattedMessage id="global.register" />
              </p>
            </p>
          </div>
        </div>
        <div className="login-page__wrapper">
          <div className="section--background">
            <img src={Background} alt="background image" />
          </div>
          {apiError ? <ErrorsBox text={formatMessage({ id: 'errors.form.incorrect_data' })} /> : null}
          {token ? (
            isTokenValid ? (
              successfullPasswordChange ? (
                <SuccessfullPasswordChange history={history} formatMessage={formatMessage} />
              ) : (
                <CreateNewPassword
                  token={token}
                  formatMessage={formatMessage}
                  setNewPassword={setNewPassword}
                  setApiError={setApiError}
                  history={history}
                  setSuccessfullPasswordChange={setSuccessfullPasswordChange}
                />
              )
            ) : (
              <InvalidToken formatMessage={formatMessage} history={history} />
            )
          ) : email ? (
            <LinkWasSend
              setEmail={setEmail}
              formatMessage={formatMessage}
              recoverPassword={recoverPassword}
              setApiError={setApiError}
              email={email}
              history={history}
            />
          ) : (
            <EnterEmailForm
              history={history}
              formatMessage={formatMessage}
              recoverPassword={recoverPassword}
              setApiError={setApiError}
              setEmail={setEmail}
            />
          )}
        </div>
      </>
    );
  } else {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }
};

export default ResetPasswordPage;
