import React, { useEffect, useState } from 'react';

const getPricing = () => {
  const [pricingData, setPricingData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://merchant-ui-api.stripe.com/pricing-table/prctbl_1N7upsAvMHklk0lWLHJw7a6y?key=pk_test_g46ZsHJKhSDiznoRimW3PTfn00tWCXrBVS',
        );
        const data = await response.json();
        console.log(data);

        setPricingData(data);
      } catch (error) {
        console.error('Error fetching pricing data:', error);
      }
    };

    fetchData();
  }, []);

  if (!pricingData) {
    return <div>Loading...</div>;
  }

  return <div>{/* Wyświetl tutaj potrzebne informacje z obiektu pricingData */}</div>;
};

export default getPricing;
