import { ApexOptions } from 'apexcharts';

const chartOptions = (categories, formatMessage, openModal): ApexOptions => ({
  legend: {
    show: true,
    showForNullSeries: false,
    horizontalAlign: 'left',
    customLegendItems: [
      formatMessage({ id: 'global.website_was_working' }),
      formatMessage({ id: 'global.short_unavailability' }),
      formatMessage({ id: 'global.long_failure' }),
      formatMessage({ id: 'global.no_data' }),
    ],
    fontFamily: 'Poppins, Arial, sans-serif',
    offsetX: 1,
    labels: {
      colors: '#0000005e',
      useSeriesColors: false,
    },
    markers: {
      fillColors: ['#5CDDA0', '#FF9618', '#ff0000', '#BFBFBF'],
      offsetY: 1.5,
    },
  },
  chart: {
    toolbar: {
      show: false,
    },
    height: 80,

    events: {
      dataPointSelection: (event, chartContext, config) => openModal(event, chartContext, config),
    },
    animations: {
      enabled: false,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
        enabled: false,
        delay: 150,
      },
      dynamicAnimation: {
        enabled: false,
        speed: 350,
      },
    },
  },
  grid: {
    show: false,
    padding: {
      left: 0,
      right: 0,
    },
  },
  theme: {
    mode: 'light',
    monochrome: {
      enabled: true,
      color: '#255aee',
      shadeIntensity: 0,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '98%',
      barHeight: 80,
      borderRadius: categories.length > 10 ? 2 : 4,
    },
  },
  stroke: {
    colors: ['transparent'],
    width: 2,
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories,
    type: 'category',
    labels: {
      rotate: 0,
      style: {
        // colors: ['#0000005e', '#0000005e', '#0000005e', '#0000005e', '#0000005e', '#0000005e', '#0000005e'],
        fontFamily: 'Poppins, Arial, sans-serif',
        cssClass: 'userProduct__apex--xaxis',
      },
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    labels: {
      show: false,
    },
  },
  fill: {
    opacity: 1,
  },

  tooltip: {
    enabled: false,
    enabledOnSeries: undefined,
    shared: false,
    followCursor: false,
    intersect: false,
    inverseOrder: false,
    custom: undefined,
    fillSeriesColor: false,
    // theme: false,
  },
  noData: {
    text: 'Ładuję...',
  },
});

const PING_HOUR_RANGES = {
  FROM_0_TO_4: '0-4',
  FROM_4_TO_8: '4-8',
  FROM_8_TO_12: '8-12',
  FROM_12_TO_16: '12-16',
  FROM_16_TO_20: '16-20',
  FROM_20_TO_24: '20-24',
};

const PING_HOUR_RANGES_ARRAY = [
  PING_HOUR_RANGES.FROM_0_TO_4,
  PING_HOUR_RANGES.FROM_4_TO_8,
  PING_HOUR_RANGES.FROM_8_TO_12,
  PING_HOUR_RANGES.FROM_12_TO_16,
  PING_HOUR_RANGES.FROM_16_TO_20,
  PING_HOUR_RANGES.FROM_20_TO_24,
];

export { chartOptions, PING_HOUR_RANGES, PING_HOUR_RANGES_ARRAY };
