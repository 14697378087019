import { ROLES } from "Shared/enums/routes";

const getPartnerRefcode = (loggedUser, onlyRef = false) => {
  if(loggedUser?.company?.type === ROLES.PARTNER) {
    if(onlyRef) {
      return loggedUser?.company?.ref
    } else {
      return `?refCode=${loggedUser?.company?.ref}`
    }
  } else {
    return ''
  }
}

export default getPartnerRefcode;
