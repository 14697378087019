import { Connector } from '../../../Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import getMyWebsites from './getMyWebsites';
import addConnection from './addConnection';
import { store } from 'Store/Store';
import getClientsLists from './getClientsLists';
import zxcvbn from 'zxcvbn';
import { Toast } from 'Shared/components';
import { ROUTES } from 'Shared/enums';

const init = () => ({
  type: User.UPDATE_CONNECTION_INIT,
});

const success = (data) => ({
  type: User.UPDATE_CONNECTION_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.UPDATE_CONNECTION_FAILURE,
});

const updateConnection = (dataForm) => async (dispatch) => {
  const getPasswordStrength = (password) => {
    const passwordStrength = zxcvbn(password).score;
    if (passwordStrength <= 1) {
      return 'weak';
    }
    if (passwordStrength > 1 && passwordStrength <= 3) {
      return 'medium';
    }
    if (passwordStrength > 3) {
      return 'strong';
    }
  };

  dispatch(init());
  try {
    const modifiedObject = {
      website_uuid: dataForm?.uuid,
      protocol: 'ftp',
      host: dataForm.host,
      port: '21',
      username: dataForm.username,
      password: dataForm.password,
      password_strength: getPasswordStrength(dataForm.password),
    };
    if (dataForm?.files_connection === null) {
      return dispatch(addConnection(modifiedObject));
    }
    Toast({ message: 'global.toast.add_ftp_loading', type: 'loading', id: 'updateConnection' });
    sessionStorage.setItem('ftp_connection_uuid', dataForm.uuid);
    sessionStorage.setItem('ftp_expire_date', new Date(new Date().getTime() + 2 * 60000).toISOString());
    const data = await Connector.updateConnection(modifiedObject, dataForm.files_connection.uuid);
    await dispatch(getMyWebsites(true));
    Toast({ message: 'global.toast.update_ftp_success', type: 'success', id: 'updateConnection' });
    const path = store.getState().router.location.pathname;
    path === ROUTES.PARTNER || (path === ROUTES.PARTNERS_COPY && (await dispatch(getClientsLists())));
    dispatch(success(data));
    sessionStorage.removeItem('ftp_connection_uuid');
    sessionStorage.removeItem('ftp_expire_date');

    return true;
  } catch (err: any) {
    sessionStorage.removeItem('ftp_connection_uuid');
    sessionStorage.removeItem('ftp_expire_date');
    dispatch(failure());
    Toast({ message: 'global.toast.update_ftp_failure', type: 'error', id: 'updateConnection' });
    console.log(err);
    return false;
  }
};

export default updateConnection;
