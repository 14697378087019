import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from 'Store/Reducers/RootReducer';
import { Actions } from 'Modules/ViewManagementModule';
import { Actions as WebsiteActions, Selectors } from 'Modules/WebsiteModule';
import { isMobile } from 'Shared/utils';
import CorgiProBono from './Main';
import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';

type ConnectedP = {
  isMobile: boolean;
  webinar: any;
  history? : any;
};

type DispatchedP = {
  actions: {
    openModal: () => void;
    closeModal: () => void;
    getWebinarDetails: (id: string) => void;
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      openModal: () => Actions.openModal(),
      closeModal: () => Actions.closeModal(),
      getWebinarDetails: id => WebsiteActions.getWebinarDetails(id),
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  isMobile: isMobile(),
  webinar: Selectors.getWebinarDetails(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CorgiProBono));
