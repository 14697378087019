import React, { useEffect, useRef, useState } from 'react';
import { MainType } from './Container';
import { Field, Form, Formik } from 'formik';
import { Step } from 'Screens/AddWebsite/components';
import { FormattedMessage } from 'react-intl';
import RegularField from 'Shared/form/regularField/regularField';
import { Button, Locale } from 'Shared/components';
import PasswordStrengthChecker from 'Components/PasswordStrengthChecker/PasswordStrengthChecker';
import PasswordValidation from 'Components/PasswordValidation/PasswordValidation';
import ADD_WEB_SITE_ROUTES from 'Screens/AddWebsite/AddWebsiteRoutes';
import * as Yup from 'yup';
import { VALIDATION_FIELD } from 'Shared/enums';
import Checkbox from 'Components/Checkbox';
import { getLocalStorage } from 'Shared/utils';

function Website(props: MainType) {
  const formRef = useRef(null);
  const [showValidation, setShowValidation] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const ref = getLocalStorage('refCode');
  const {
    history,
    formData,
    authorizedUser,
    getMaxStep,
    stripePrice,
    actions: { addWebsiteFormChange, createUser, addWebsite, hiddenLogin, getAuthorizedUser, getPaymentMethods },
    intl: { formatMessage },
  } = props;
  const withoutWebsiteForm = history.location.withoutWebsiteStep;
  const url = history.location.url;
  const currency = stripePrice?.pricing_table_items[0].currency.toUpperCase();
  const handleSubmit = async () => {
    try {
      await addWebsiteFormChange(formRef.current.values);
      if (!(await createUser({ ...formRef.current.values, lang: Locale(), acceptance: undefined, currency: currency }))) {
        return;
      }
      await hiddenLogin(formRef.current.values.email, formRef.current.values.password);
      await getAuthorizedUser();
      getPaymentMethods();
      if (await addWebsite({ url: formData.url || url, plan: formData.plan })) {
        history.push({ ...history.location, pathname: ADD_WEB_SITE_ROUTES.COMPANY, withoutWebsiteStep: withoutWebsiteForm });
      }
    } catch (e) {
    }
  };
  const Validation = () => {
    return Yup.object().shape({
      name: Yup.string()
        .matches(
          /^(?![\s])[^\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/? ]*$/,
          VALIDATION_FIELD(formatMessage).EMPTY_SPACE,
        )
        .required(formatMessage({ id: 'global.validation.required' }))
        .min(3, formatMessage({ id: 'global.validation.min_3' }))
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      email: Yup.string()
        .email(formatMessage({ id: 'global.validation.wrong_email' }))
        .required(formatMessage({ id: 'global.validation.required' }))
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254),
      password: Yup.string()
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .required(formatMessage({ id: 'global.validation.required' }))
        .min(8, formatMessage({ id: 'global.validation.required' }))
        .max(254, VALIDATION_FIELD(formatMessage).MAX_254)
        .test('Capital Letter', ' ', function (value) {
          return /[A-Z]/.test(value);
        })
        .test('Lowercase Letter', ' ', function (value) {
          return /[a-z]/.test(value);
        })
        .test('Number', ' ', function (value) {
          return /[0-9]/.test(value);
        }),
      acceptance: Yup.bool().oneOf([true], formatMessage({ id: 'global.validation.required' })),
    });
  };

  useEffect(() => {
    if (authorizedUser) {
      history.push({ ...history.location, pathname: ADD_WEB_SITE_ROUTES.COMPANY, withoutWebsiteStep: withoutWebsiteForm, withoutAccountForm: true });
    }
  }, []);
  const translation = localStorage.getItem('translation');

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      validationSchema={Validation()}
      initialValues={{ password: '', email: '', name: '', acceptance: false, ref: ref ? ref : undefined }}
      onSubmit={async () => {
        if (sessionStorage.getItem('submitting')) return;
        
        sessionStorage.setItem('submitting', 'true'); 
        await handleSubmit();
        sessionStorage.removeItem('submitting');
      }}>
      {({ errors, values }) => (
        <Form>
          <Step currentStep={withoutWebsiteForm ? 1 : 2} lastStep={getMaxStep} formatMessage={formatMessage} />
          <h2 className="add-website-form__title">{formatMessage({ id: 'global.create_account' })}</h2>
          <h4 className="add-website-form__subtitle">{formatMessage({ id: 'global.create_account_subtitle' })}</h4>
          <Field
            type="text"
            label={<FormattedMessage id="global.message.name" />}
            name="name"
            wrapperClassName="white"
            component={RegularField}
            errors={errors}
            errorBelow
          />
          <Field
            type="text"
            label={<FormattedMessage id="global.packet_form.email" />}
            wrapperClassName="white"
            name="email"
            component={RegularField}
            errorBelow
            errors={errors}
          />
          <Field
            label={<FormattedMessage id="global.login.password" />}
            name="password"
            wrapperClassName={`white ${showValidation ? 'form__field--password-checker-open' : ''}`}
            type="password"
            errorBelow
            component={RegularField}
            inputOnFocus={() => setShowValidation(true)}
            onBlur={() => isPasswordValid && setShowValidation(false)}
            errors={errors}
          />
          <PasswordStrengthChecker password={values.password} formatMessage={formatMessage} isHidden={!showValidation} />
          <PasswordValidation
            className={`add-website-form__password-validation ${showValidation ? 'add-website-form__password-validation--show' : ''}`}
            password={values.password}
            formatMessage={formatMessage}
            setIsValid={(value: boolean) => setIsPasswordValid(value)}
          />
          <Field
            label={
              <p>
                <FormattedMessage id="global.i_confirm" />{' '}
                <a
                  href={translation === 'pl' ? require('../../../../Assets/file/regulations_corgi.pdf') : require('../../../../Assets/file/terms_of_service.pdf')}
                  target="_blank"
                  rel="noopener noreferrer">
                  <FormattedMessage id="global.the_terms" />
                </a>{' '}
                <FormattedMessage id="global.and_i_agree" />{' '}
                <a
                  href={translation === 'pl' ? require('../../../../Assets/file/privacy_policy.pdf') : require('../../../../Assets/file/privacy_policy en.pdf')}
                  target="_blank"
                  rel="noopener noreferrer">
                  <FormattedMessage id="global.privacy_policy" />
                </a>{' '}
              </p>
            }
            name="acceptance"
            component={Checkbox}
            errors={errors}
          />

          <Button type="submit" classNames={'btn__black add-website-form__below-acceptance'} label={formatMessage({ id: 'global.next' })} />
        </Form>
      )}
    </Formik>
  );
}

export default Website;
