import React from 'react';
import { MainType } from './Container';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import Button from 'Shared/components/button/Button';
import ChevronWhite from '../../Assets/Image/Icon/icon_chevron_white.svg';
import TriangleDogs from 'Assets/Image/triangle_dogs.png';
import { Link } from 'react-router-dom';
import { history } from 'Store/Reducers/RootReducer';
import { ROUTES } from 'Shared/enums';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const MessageSend = ({ actions: { setAppTranslation }, translation }: MainType) => {
  const location = useLocation();
  return (
    <>
      <div className="formSignUp">
        <div className="formSignUp__header">
          <div className="formSignUp__logo">
            <Link to={ROUTES.HOME_PAGE}>
              <SVG className="svg--logo-white" type={SVG_TYPE.LOGO} />
            </Link>
          </div>
          <div className="language">
            <div className="navi__header--language">
              <select className="language-select" value={translation} onChange={({ currentTarget: { value } }) => setAppTranslation(value)}>
                <option value="pl">PL</option>
                <option value="en">EN</option>
                <option value="de">DE</option>
              </select>
            </div>
          </div>
        </div>
        <div className="formSignUp__content--wrapper">
          <div className="formSignUp__content">
            <SVG className="svg--logo-white" type={SVG_TYPE.BUST_WOOF} />
            <h1 className="formSignUp__heading">
              <FormattedMessage id="global.message.messageSend" />
            </h1>
            <p className="formSignUp__email">{location.mail}</p>
            <p className="formSignUp__descript formSignUp__descript--small">
              <FormattedMessage id="global.message.messageSend_description" />
            </p>
          </div>
          <div className="formSignUp__content formSignUp__content--left">
            <div className="formSignUp__content--triangle">
              <img src={TriangleDogs} />
            </div>
            <p className="formSignUp__bold formSignUp__bold--top">
              <FormattedMessage id="global.newsletter.descript_2" />
            </p>
            <p className="formSignUp__descript formSignUp__descript--left">
              <FormattedMessage id="global.newsletter.descript_2" />
            </p>
            <Link to="/#pakiety">
              <Button
                type="submit"
                onClick={() => history.push(ROUTES.LOGIN)}
                label={<FormattedMessage id="global.newsletter.btn" />}
                classNames="btn--blue"
                iconImg={ChevronWhite}
                iconAlt="icon product"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageSend;
