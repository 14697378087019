import { createSelector } from 'reselect';
// import { RootState } from 'Store/Reducers/RootReducer';

const getViewManagementStore = (state) => state.viewManagementStore;
const dashboardUserPaymentsLoader = createSelector(getViewManagementStore, (state) => state.dashboardUserPaymentsLoader);
const dashboardWebsitesInfoLoader = createSelector(getViewManagementStore, (state) => state.dashboardWebsitesInfoLoader);
const dashboardWebsitesActionsLoader = createSelector(getViewManagementStore, (state) => state.dashboardWebsitesActionsLoader);
const loaderVisible = createSelector(getViewManagementStore, (state) => state.loaderVisible);
const getIsMenuExpanded = createSelector(getViewManagementStore, (state) => state.isMenuExpanded);
const getIsModalOpened = createSelector(getViewManagementStore, (state) => state.isModalOpened);
const getTranslation = createSelector(getViewManagementStore, (state) => state.translation);

export {
  loaderVisible,
  getIsMenuExpanded,
  getIsModalOpened,
  getTranslation,
  dashboardUserPaymentsLoader,
  dashboardWebsitesInfoLoader,
  dashboardWebsitesActionsLoader,
};
