import React from 'react';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Button } from 'Shared/components';
import { ROUTES } from 'Shared/enums';

type P = {
  history: any;
  email: string;
  formatMessage: any;
};

const SuccessfullRegistration = ({ formatMessage, history, email }: P) => {
  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={() => {
        history.push(ROUTES.LOGIN);
      }}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="login-page__form">
          <h1 className="login-page__title">{formatMessage({ id: 'global.successfull_password_change' })}🥳</h1>
          <h3 className="login-page__sub-title">{formatMessage({ id: 'global.your_account_is_created' })}</h3>
          <h3 className="login-page__sub-title login-page__sub-title--email">{email}</h3>
          <h3 className="login-page__sub-title">{formatMessage({ id: 'global.click_to_login' })}</h3>

          <Button
            type="submit"
            label={<FormattedMessage id="global.login.sign_in" />}
            classNames="btn--blue login-page__submit"
            iconAlt="icon product"
          />
        </form>
      )}
    </Formik>
  );
};
export default SuccessfullRegistration;
