import React from 'react';
// import GabrielaBajorekPng from 'Assets/Image/reviews/gabriela-bajorek.png';
import MaryCzekajPng from 'Assets/Image/reviews/mary-czekaj.webp';
import PrzemyslawNitka from 'Assets/Image/reviews/przemyslaw-nitka.webp';
import NataliaJagus from 'Assets/Image/reviews/natalia-jagus.webp';
import { SVG_TYPE } from 'Shared/enums';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { SVG } from 'Shared/components';
import FadeInOnScroll from 'Components/FadeInOnScroll/FadeInOnScroll';
import AgnieszkaDiakow from 'Assets/Image/reviews/agnieszka-diakow.webp';
import ReviewsBackground from 'Assets/Image/reviews/review-background.webp';
import LinkSVG from 'Assets/Image/svg/LinkSVG';
import RobertSemrau from 'Assets/Image/reviews/robert-semrau.webp';
import TomaszKusiak from 'Assets/Image/reviews/tomasz-kusiak.webp';

interface ReviewsSliderProps {
  formatMessage: any;
}

const ReviewsSlider: React.FC<ReviewsSliderProps> = ({ formatMessage }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, align: 'start' }, [Autoplay({ delay: 8000 })]);

  const reviews = [
    {
      id: 1,
      title: formatMessage({ id: 'global.partners_reviews.website_kreacje.title' }),
      description: formatMessage({ id: 'global.partners_reviews.website_kreacje.description' }),
      name: 'Mary Czekaj',
      position: formatMessage({ id: 'global.partners_reviews.website_kreacje.position' }),
      image: MaryCzekajPng,
      logo: SVG_TYPE.WEBSITE_KREACJE_LOGO,
      url: 'websitekreacje.pl',
    },
    {
      id: 2,
      title: formatMessage({ id: 'global.partners_reviews.reddogdesign.title' }),
      description: formatMessage({ id: 'global.partners_reviews.reddogdesign.description' }),
      name: 'Przemysław Nitka',
      position: formatMessage({ id: 'global.partners_reviews.reddogdesign.position' }),
      image: PrzemyslawNitka,
      logo: SVG_TYPE.RED_DOG_LOGO,
      url: 'reddogdesign.pl',
    },
    {
      id: 3,
      title: formatMessage({ id: 'global.partners_reviews.nataliajagus.title' }),
      description: formatMessage({ id: 'global.partners_reviews.nataliajagus.description' }),
      name: 'Natalia Jaguś',
      position: formatMessage({ id: 'global.partners_reviews.nataliajagus.position' }),
      image: NataliaJagus,
      logo: SVG_TYPE.NATALIA_JAGUS_LOGO,
      url: 'nataliajagus.com',
    },
    {
      id: 4,
      title: formatMessage({ id: 'global.partners_reviews.red_wolf.title' }),
      description: formatMessage({ id: 'global.partners_reviews.red_wolf.description' }),
      name: 'Agnieszka Diaków',
      position: formatMessage({ id: 'global.partners_reviews.red_wolf.position' }),
      image: AgnieszkaDiakow,
      logo: SVG_TYPE.RED_WOLF_LOGO,
      url: 'red-wolf.pl',
    },
    {
      id: 5,
      title: formatMessage({ id: 'global.partners_reviews.PRofitto.title' }),
      description: formatMessage({ id: 'global.partners_reviews.PRofitto.descirption' }),
      name: 'Robert Semrau',
      position: formatMessage({ id: 'global.partners_reviews.PRofitto.position' }),
      image: RobertSemrau,
      logo: SVG_TYPE.PROFITTO_LOGO,
      url: 'profitto.pro',
    },
    {
      id: 6,
      title: formatMessage({ id: 'global.partners_reviews.SoBright.title' }),
      description: formatMessage({ id: 'global.partners_reviews.SoBright.descirption' }),
      name: 'Tomasz Kusiak',
      position: formatMessage({ id: 'global.partners_reviews.SoBright.position' }),
      image: TomaszKusiak,
      logo: SVG_TYPE.SO_BRIGHT_LOGO,
      url: 'sobright.pl',
    },
  ];

  const handleNext = () => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  };

  const handlePrev = () => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  };

  return (
    <FadeInOnScroll delay={600} className="reviews-slider">
      <img src={ReviewsBackground} alt="reviews background" className="reviews-slider__background" />
      <div className="reviews-slider__viewport " ref={emblaRef}>
        <div className="reviews-slider__container ">
          {reviews.map((review) => (
            <div key={`review-${review.id}`} className="review">
              <div className="review__left">
                <SVG type={review.logo} className="review__logo review__logo--desktop" />
                <div className="review__person">
                  <img src={review.image} alt={review.name} />
                  <div className="review__person-details">
                    <h3>{review.name}</h3>
                    <p>{review.position}</p>
                    <a href={`https://${review.url}`} rel="noopener noreferrer" target="_blank" className="review__button">
                      <LinkSVG />
                      {review.url}
                    </a>
                  </div>
                </div>
              </div>
              <div className="review__right">
                <SVG type={review.logo} className="review__logo review__logo--mobile" />
                <h2>{review.title}</h2>
                <p>{review.description}</p>
                <SVG type={SVG_TYPE.APOSTROPHE} className="review__apostrophe" />
              </div>
              <SVG type={SVG_TYPE.REVIEW_BACKGROUND} className="review__background" />
            </div>
          ))}
        </div>
        <div className="review__buttons">
          <span className="review__button review__button--prev" onClick={() => handlePrev()}>
            <SVG type={SVG_TYPE.ARROW_LEFT} />
          </span>
          <span className="review__button review__button--next" onClick={() => handleNext()}>
            <SVG type={SVG_TYPE.ARROW_LEFT} />
          </span>
        </div>
      </div>
    </FadeInOnScroll>
  );
};

export default ReviewsSlider;
