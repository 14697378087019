import * as Types from 'Shared/models/PaymentModel';
import { Payment } from '../Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Shared/enums';

export type State = Types.PaymentStore;

export const initialState: State = {
  payment: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  stripePrices: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  }
};

export function paymentReducer(state: any = initialState, action) {
  switch (action.type) {
    case Payment.GET_PAYMENT_INIT: {
      return {
        ...state,
        payment: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Payment.GET_PAYMENT_SUCCESS: {
      return {
        ...state,
        payment: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Payment.GET_PAYMENT_FAILURE: {
      return {
        ...state,
        payment: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Payment.GET_STRIPE_PRICE_INIT: {
      return {
        ...state,
        stripePrices: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Payment.GET_STRIPE_PRICE_SUCCESS: {
      return {
        ...state,
        stripePrices: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Payment.GET_STRIPE_PRICE_FAILURE: {
      return {
        ...state,
        stripePrices: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }

    default: {
      return state;
    }
  }
}
