import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';
import { store } from 'Store/Store';
import { getDiagnosticData } from 'Store/Selectors/WebsiteSelector';

const init = () => ({
  type: Website.GET_DIAGNOSTIC_DATA_INIT,
});

const success = (data) => ({
  type: Website.GET_DIAGNOSTIC_DATA_SUCCESS,
  payload: {
    data,
  },
});

// const failure = () => ({
//   type: Website.GET_DIAGNOSTIC_DATA_FAILURE,
// });

const runDiagnostic = (websiteUuid, diagnosticMethods) => async (dispatch) => {
  try {
    if (diagnosticMethods.length === 0) return false;
    let param = '';
    diagnosticMethods.forEach((method, index) => {
      param = `${index !== 0 ? param + ',' + method : method}`;
    });
    await Connector.runDiagnostic(websiteUuid, param);
    // Diagnostic running properly
    dispatch(init());
    const { data } = await Connector.getDiagnosticData(websiteUuid, null);
    dispatch(success(data));
  } catch (err) {
    const state = store.getState();
    const diagnosticData = getDiagnosticData(state);
    const data = {
      ...diagnosticData,
      statusInProgress: true,
    };
    dispatch(success(data));
  }
};

export default runDiagnostic;
