import React from 'react';
import { FormattedMessage } from 'react-intl';
import semver from 'semver';

const phpVersion = (phpVersion) => {
  if (semver.satisfies(phpVersion, '>=7.4.0')) {
    return (
      <p className={`product__tile--green`}>
        <FormattedMessage id="global.correct" /> &nbsp; {phpVersion}
      </p>
    );
  } else if (semver.satisfies(phpVersion, '>=7.0 || <=7.3.0')) {
    return (
      <p className={`product__tile--orange`}>
        <FormattedMessage id="global.out_of_date" /> &nbsp; {phpVersion}
      </p>
    );
  } else if (semver.satisfies(phpVersion, '<7.0')) {
    return (
      <p className={`product__tile--red`}>
        <FormattedMessage id="global.out_of_date" /> &nbsp; {phpVersion}
      </p>
    );
  }
};

export default phpVersion;
