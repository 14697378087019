import { Connector } from 'Modules/UserModule';

import { Toast } from 'Shared/components';

const editSettings = (data) => async () => {
  const toastId = Math.random().toString(36).substr(2, 9);
  Toast({ id: toastId, type: 'loading', message: 'global.apex_no_data' });
  try {
    await Connector.editSettings(data);

    Toast({ id: toastId, type: 'success', message: 'global.toast.update_website_success' });
  } catch (err: any) {
    console.log(err.response);

    Toast({ id: toastId, type: 'error', message: 'global.toast.update_website_failure' });
    return false;
  }
};

export default editSettings;
