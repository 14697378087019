/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { SVG } from 'Shared/components';

import Background from '../../Assets/Image/background_dark.svg';
import { MainType } from './Container';
import { isTokenSaved } from 'Shared/utils';
import { FormattedMessage } from 'react-intl';
import ErrorsBox from 'Shared/components/errorsBox';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import lonelyLetters from 'Shared/utils/lonelyLetters';
import { Link } from 'react-router-dom';
import { RegisterForm, SuccessfullRegistration } from './Components';

const RegisterPage = ({ intl: { formatMessage }, actions: { registerPartnerForm }, stripePrice, translation, history }: MainType) => {
  const [apiError, setApiError] = useState(false);
  const [email, setEmail] = useState('');

  setTimeout(function () {
    const el = document.querySelectorAll('.popUp--form-contact--description-gray');
    lonelyLetters(el);
  }, 100);

  useEffect(() => {
    const addDarkClass = () => {
      const naviHeader = document.querySelector('.navi__header');
      if (naviHeader) {
        naviHeader.classList.add('navi__header--dark');
        naviHeader.classList.add('navi__header--sticky');
      }
    };
    const removeDarkClass = () => {
      const naviHeader = document.querySelector('.navi__header');
      if (naviHeader) {
        naviHeader.classList.remove('navi__header--dark');
        naviHeader.classList.remove('navi__header--sticky');
      }
    };
    addDarkClass();
    return () => {
      removeDarkClass();
    };
  }, []);

  if (!isTokenSaved()) {
    return (
      <>
        <div className="login-page__header-overlay login-page__header-overlay--dark">
          <div className="login-page__header-wrapper">
            <Link to={ROUTES.HOME_PAGE}>
              <SVG type={SVG_TYPE.LOGO} />
            </Link>
            <p className="login-page__register">
              <FormattedMessage id="global.do_you_have_account" />{' '}
              <p onClick={() => history.push(ROUTES.LOGIN)}>
                <FormattedMessage id="global.login.sign_in" />
              </p>
            </p>
          </div>
        </div>
        <div className="login-page__wrapper">
          <div className="section--background section--background-dark">
            <img src={Background} alt="background image" />
          </div>
          {apiError ? <ErrorsBox text={formatMessage({ id: 'errors.form.incorrect_data' })} /> : null}
          {email ? (
            <SuccessfullRegistration history={history} formatMessage={formatMessage} email={email} />
          ) : (
            <RegisterForm
              history={history}
              stripePrice={stripePrice}
              formatMessage={formatMessage}
              registerPartnerForm={registerPartnerForm}
              setApiError={setApiError}
              translation={translation}
              setEmail={setEmail}
            />
          )}
        </div>
      </>
    );
  } else {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }
};

export default RegisterPage;
