import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';

const init = () => ({
  type: User.REGULATIONS_PARTNER_INIT,
});

const success = () => ({
  type: User.REGULATIONS_PARTNER_SUCCESS,
});

const failure = () => ({
  type: User.REGULATIONS_PARTNER_FAILURE,
});

const regulationsPartner = () => async (dispatch) => {
  dispatch(init());
  try {
    await Connector.regulationsPartner();
    dispatch(success());
  } catch (err: any) {
    dispatch(failure());
    return err.response.data;
  }
};

export default regulationsPartner;
