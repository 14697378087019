import { AddWebsite } from '../EnumTypes/ActionEnumTypes';

const success = (data) => ({
  type: AddWebsite.ADD_WEBISTE_FORM_CHANGE,
  payload: {
    data,
  },
});

const addWebsiteFormChange = (data) => async (dispatch) => {
  try {
    dispatch(success(data));
  
  } catch (err: any) {
    console.log(err);
  }
};

export default addWebsiteFormChange;
