import React from 'react';
import * as AuthModule from 'Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import * as AuthActionModel from '../Models/AuthActionModel';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { history } from '../../Reducers/RootReducer';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { SVG } from 'Shared/components';
// import { SVG } from 'Shared/Components';
// import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Auth.LOG_IN_INIT,
});

export const success = (authData: AuthModule.Types.LogInResponse): AuthActionModel.LoginSuccess => ({
  type: Auth.LOG_IN_SUCCESS,
  payload: {
    authData,
  },
});

const failure = (): AuthActionModel.LoginFailure => ({
  type: Auth.LOG_IN_FAILURE,
});

const login = (email: string, password: string) => async (dispatch) => {
  dispatch(init());
  const id = toast.loading(<FormattedMessage id="global.apex_no_data" />);
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const response = await AuthModule.Connector.login(email, password);
    // Cookies.set('auth_token', response.data.token, { domain: process.env.REACT_APP_DOMAIN });
    dispatch(success(response.data));
    // await dispatch(AuthModule.Actions.getAuthorizedUser());
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.update(id, {
      render: (
        <div>
          <h2 className="toast-title toast-title--success">
            {' '}
            <FormattedMessage id="global.toast_succes" />
          </h2>{' '}
          <p>
            <FormattedMessage id="global.login.successful_login" />
          </p>
        </div>
      ),
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
    });

    // history.push(ROUTES.SOON);
    history.push(ROUTES.DASHBOARD);
  } catch (err: any) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    toast.update(id, {
      render: (
        <div>
          <h2 className="toast-title toast-title--failure">
            <FormattedMessage id="global.toast_failure" />
          </h2>{' '}
          <p>
            <FormattedMessage id="global.login.failure_login" />
          </p>
        </div>
      ),
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG type={SVG_TYPE.CLOSE_CIRCLE_FILL} variant={SVG_TYPE.CLOSE_CIRCLE_FILL} />,
    });

    return err.response;
  }
};

export default login;
