import React from 'react';
import animationData from 'Assets/file/loader-animation.json';
import Lottie from 'react-lottie';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function Loader({ width, height }: any) {
  const style = {
    width: width || 24,
    height: height || 24,
  };
  return (
    <div className="loader m-auto">
      <Lottie style={style} options={defaultOptions} />
    </div>
  );
}
