import React from 'react';
import { SVG, Loader } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

const DiagnosticPoint = ({
  point,
  optionValue,
  isDetailsActiveValue,
  checkedOptions,
  setCheckedOptions,
  failedPointsDetailsActive,
  setFailedPointsDetailsActive,
  changeState,
  diagnosticStatus,
  setPointsChoosed,
}) => {
  const { id, name, label, isFailed, errorMessage, isTested, methodName } = point;
  return (
    <>
      <div className="diagnosticModal__dashboard--row">
        <div className="checkBox">
          <div
            className={`checkBox__btn ${optionValue && 'checkBox__btn--checked'}`}
            onClick={() => {
              changeState(checkedOptions, setCheckedOptions, optionValue, name);
              setPointsChoosed(methodName);
            }}>
            {optionValue && <SVG type={SVG_TYPE.CHECKED} />}
          </div>
        </div>
        <div className={`subRow ${!isTested && 'missed'}`}>
          {diagnosticStatus === 'pending' ? (
            <>
              {isTested ? (
                <>
                  <div className="subRow__number">{id}.</div>
                  <div className="subRow__name">{label}</div>
                  <div className="subRow__status">
                    <Loader />
                  </div>
                </>
              ) : (
                <>
                  <div className="subRow__number">{id}.</div>
                  <div className="subRow__name">{label}</div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="subRow__number">{id}.</div>
              <div className="subRow__name">{label}</div>
              {isFailed ? (
                <>
                  <div className="subRow__status">{isTested && <SVG type={SVG_TYPE.FAILED} />}</div>
                  <div
                    className="subRow__dropDown"
                    onClick={() => changeState(failedPointsDetailsActive, setFailedPointsDetailsActive, isDetailsActiveValue, name)}>
                    {isTested && <SVG type={SVG_TYPE.DROP_DOWN} />}
                  </div>
                </>
              ) : (
                <div className="subRow__status">{isTested && <SVG type={SVG_TYPE.PASSED} />}</div>
              )}
            </>
          )}
        </div>
      </div>
      {isDetailsActiveValue && (
        <div className="diagnosticModal__dashboard--details">
          <p className="errorMessage">{errorMessage}</p>
        </div>
      )}
    </>
  );
};

export default DiagnosticPoint;
