function lonelyLetters(el) {
  let result;
  el.forEach((element) => {
    if (!element.innerHTML.match(/[{}]|<script|^\n$/gi)) {
      result = element.innerHTML.replace(/ (.) /gi, ' ' + '$1' + '&nbsp;');
    }
    element.innerHTML = result;
  });
}
export default lonelyLetters;
