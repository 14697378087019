import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';

const success = (data) => ({
  type: Website.UPDATE_CHECKER_SUCCESS,
  payload: {
    data,
  },
});
const checkerUpdate = (form) => async (dispatch) => {
  const { id, phone_number } = form;
  try {
    const { data } = await Connector.checkerUpdate(id, phone_number);
    dispatch(success(form));
    return data;
  } catch (err) {
    console.log(err);
  }
};

export default checkerUpdate;
