import { ViewManagement } from './EnumTypes/ActionEnumTypes';
import * as ViewManagementActionModel from './Models/ViewManagementActionModel';

function showLoader(): ViewManagementActionModel.ShowLoader {
  return {
    type: ViewManagement.SHOW_LOADER,
  };
}

function hideLoader(): ViewManagementActionModel.HideLoader {
  return {
    type: ViewManagement.HIDE_LOADER,
  };
}

function ShowDashboardUserPaymentsLoader(): ViewManagementActionModel.ShowDashboardUserPaymentsLoader {
  return {
    type: ViewManagement.SHOW_DASHBOARD_USER_PAYMENTS_LOADER,
  };
}

function HideDashboardUserPaymentsLoader(): ViewManagementActionModel.HideDashboardUserPaymentsLoader {
  return {
    type: ViewManagement.HIDE_DASHBOARD_USER_PAYMENTS_LOADER,
  };
}

function ShowDashboardWebsitesInfoLoader(): ViewManagementActionModel.ShowDashboardWebsitesInfoLoader {
  return {
    type: ViewManagement.SHOW_DASHBOARD_WEBSITES_INFO_LOADER,
  };
}

function HideDashboardWebsitesInfoLoader(): ViewManagementActionModel.HideDashboardWebsitesInfoLoader {
  return {
    type: ViewManagement.HIDE_DASHBOARD_WEBSITES_INFO_LOADER,
  };
}

function ShowDashboardWebsitesActionsLoader(): ViewManagementActionModel.ShowDashboardWebsitesActionsLoader {
  return {
    type: ViewManagement.SHOW_DASHBOARD_WEBSITES_ACTIONS_LOADER,
  };
}

function HideDashboardWebsitesActionsLoader(): ViewManagementActionModel.HideDashboardWebsitesActionsLoader {
  return {
    type: ViewManagement.HIDE_DASHBOARD_WEBSITES_ACTIONS_LOADER,
  };
}

function setPrevPath(prevPath: string): ViewManagementActionModel.SetPrevPath {
  return {
    type: ViewManagement.SET_PREV_PATH,
    payload: {
      prevPath,
    },
  };
}
function expandMenu(): ViewManagementActionModel.ExpandMenu {
  return {
    type: ViewManagement.EXPAND_MENU,
  };
}
function expandAddReminderView(): ViewManagementActionModel.ExpandAddReminderView {
  return {
    type: ViewManagement.EXPAND_ADD_REMINDER_VIEW,
  };
}
// const openCompendiumPreview = () => ({
//   type: ViewManagement.OPEN_COMPENDIUM_PREVIEW_MOBILE,
// });
// const closeCompendiumPreview = () => ({
//   type: ViewManagement.CLOSE_COMPENDIUM_PREVIEW_MOBILE,
// });
const openModal = () => ({
  type: ViewManagement.OPEN_MODAL,
});
const closeModal = () => ({
  type: ViewManagement.CLOSE_MODAL,
});
function setMenuExpanded(value: boolean) {
  return {
    type: ViewManagement.SET_MENU_EXPANDED,
    payload: {
      value,
    },
  };
}

const success = (translation) => ({
  type: ViewManagement.SET_APP_TRANSLATION_SUCCESS,
  payload: {
    translation,
  },
});

const setAppTranslation = (lang) => async (dispatch) => {
  try {
    const script = document.createElement('script');
    script.src = `/translations/${lang}.js`;
    script.onload = () => {
      localStorage.setItem('translation', lang);
      dispatch(success(lang));
    };
    document.head.appendChild(script);
  } catch (err:any) {
    console.log(err.response);
  }
};
export {
  showLoader,
  hideLoader,
  setPrevPath,
  expandMenu,
  expandAddReminderView,
  setMenuExpanded,
  openModal,
  closeModal,
  setAppTranslation,
  ShowDashboardUserPaymentsLoader,
  HideDashboardUserPaymentsLoader,
  ShowDashboardWebsitesInfoLoader,
  HideDashboardWebsitesInfoLoader,
  ShowDashboardWebsitesActionsLoader,
  HideDashboardWebsitesActionsLoader,
};
