import React from 'react';
import { Loader } from 'Shared/components';

function Badge({ type, label, load }: any) {
  return (
    <div className={`badge ${type ? `badge--${type}` : ''}`}>
      {load && <Loader />} {label}
    </div>
  );
}

export default Badge;
