import { ViewManagementStore } from 'Shared/models/ViewManagementModel';
import { ViewManagement } from '../Actions/EnumTypes/ActionEnumTypes';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
// import { ViewsManagemenetAction } from '../Actions/Models/ViewManagementActionModel';

export type State = ViewManagementStore;

export const initialState: ViewManagementStore = {
  dashboardUserPaymentsLoader: false,
  dashboardWebsitesInfoLoader: true,
  dashboardWebsitesActionsLoader: true,
  loaderVisible: false,
  isMenuExpanded: false,
  isModalOpened: false,
  translation: '',
};

export function viewManagementReducer(state: State = initialState, action) {
  switch (action.type) {
    case ViewManagement.SHOW_LOADER: {
      return {
        ...state,
        loaderVisible: true,
      };
    }
    case ViewManagement.HIDE_LOADER: {
      return {
        ...state,
        loaderVisible: false,
      };
    }
    case ViewManagement.SHOW_DASHBOARD_USER_PAYMENTS_LOADER: {
      return {
        ...state,
        dashboardUserPaymentsLoader: true,
      };
    }
    case ViewManagement.HIDE_DASHBOARD_USER_PAYMENTS_LOADER: {
      return {
        ...state,
        dashboardUserPaymentsLoader: false,
      };
    }
    case ViewManagement.SHOW_DASHBOARD_WEBSITES_INFO_LOADER: {
      return {
        ...state,
        dashboardWebsitesInfoLoader: true,
      };
    }
    case ViewManagement.HIDE_DASHBOARD_WEBSITES_INFO_LOADER: {
      return {
        ...state,
        dashboardWebsitesInfoLoader: false,
      };
    }
    case ViewManagement.SHOW_DASHBOARD_WEBSITES_ACTIONS_LOADER: {
      return {
        ...state,
        dashboardWebsitesActionsLoader: true,
      };
    }
    case ViewManagement.HIDE_DASHBOARD_WEBSITES_ACTIONS_LOADER: {
      return {
        ...state,
        dashboardWebsitesActionsLoader: false,
      };
    }
    case ViewManagement.OPEN_MODAL: {
      return {
        ...state,
        isModalOpened: true,
      };
    }
    case ViewManagement.CLOSE_MODAL: {
      return {
        ...state,
        isModalOpened: false,
      };
    }
    case ActionTypes.ViewManagement.SET_MENU_EXPANDED: {
      return {
        ...state,
        isMenuExpanded: action.payload.value,
      };
    }
    case ViewManagement.SET_APP_TRANSLATION_SUCCESS: {
      if (action.payload.translation !== state.translation && state.translation !== '') {
        localStorage.setItem('translation', action.payload.translation);
        window.location.reload();
      }
      return {
        ...state,
        translation: action.payload.translation,
      };
    }
    default: {
      return state;
    }
  }
}
