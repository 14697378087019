import React, { FC, useEffect, useRef } from 'react';

interface RangeInputProps {
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (value: number) => void;
  onClick?: () => void;
}
const InputRange: FC<RangeInputProps> = ({ min, max, step, value, onChange, onClick }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const steps = [];
  for (let i = min; i <= max; i += step) {
    steps.push(i);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(event.target.value));
  };
  const updateTrackBackground = (value: number) => {
    const percentage = ((value - min) / (max - min)) * 100;
    inputRef.current?.style.setProperty('--value', `${percentage}%`);
  };

  useEffect(() => {
    updateTrackBackground(value);
  }, [value, min, max]);

  return (
    <div className="input-range" onTouchEnd={() => onClick()}>
      <input type="range" min={min} max={max} step={step} value={value} onChange={handleChange} className="input-range__input" ref={inputRef} />
      <div className="input-range__steps">
        {steps.map((stepValue) => (
          <div
            key={stepValue}
            className={`input-range__step ${stepValue <= value ? 'input-range__step--active' : ''} ${
              stepValue === value ? 'input-range__step--selected' : ''
            }`}>
            <div className="input-range__step__dot" onClick={() => onChange(stepValue)} />
            <div className="input-range__step__label">{stepValue}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InputRange;
