import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';
// import { Actions } from 'Modules/ViewManagementModule';

// const init = () => ({
//   type: Website.GET_PARTNERS_WEBSITES_INIT,
// });

const success = (data) => ({
  type: Website.GET_PARTNERS_WEBSITES_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_PARTNERS_WEBSITES_FAILURE,
});

const getPartnersWebsites = () => async (dispatch) => {
  // dispatch(init());
  try {
    // dispatch(Actions.ShowDashboardWebsitesInfoLoader());
    const { data } = await Connector.getPartnersWebsites();
    dispatch(success(data));
    // dispatch(Actions.HideDashboardWebsitesInfoLoader());
  } catch (err) {
    console.log(err);
    // dispatch(Actions.HideDashboardWebsitesInfoLoader());
    dispatch(failure());
  }
};

export default getPartnersWebsites;
