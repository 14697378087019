import React from 'react';
import * as AuthModule from '../../../Modules/AuthModule';
import { Auth, User, Website } from '../EnumTypes/ActionEnumTypes';
import * as AuthActionModel from '../Models/AuthActionModel';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { history } from '../../Reducers/RootReducer';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { SVG } from 'Shared/components';

const init = () => ({
  type: Auth.LOG_OUT_INIT,
});

const initWebsiteInfo = () => ({
  type: Website.GET_DASHBOARD_USER_WEBSITES_INIT,
});
const initWebsitePartner = () => ({
  type: Website.GET_DASHBOARD_PARTNER_WEBSITES_INIT,
});
const initWebsiteInfoMessages = () => ({
  type: Website.GET_DASHBOARD_WEBSITES_ACTIONS_INIT,
});
const initWebsiteAmounts = () => ({
  type: Website.GET_DASHBOARD_WEBSITES_AMOUNTS_INIT,
});

const successUserClear = (): any => ({
  type: User.LOGOUT_SUCCESS,
});

export const success = (): AuthActionModel.LogoutSuccess => ({
  type: Auth.LOG_OUT_SUCCESS,
});

const failure = (): AuthActionModel.LogoutFailure => ({
  type: Auth.LOG_OUT_FAILURE,
});

const logout = () => {
  return async (dispatch) => {
    dispatch(init());
    const id = toast.loading(<FormattedMessage id="global.apex_no_data" />);
    try {
      // dispatch(ViewManagementModule.Actions.showLoader());
      await AuthModule.Connector.logout();
      dispatch(success());
      dispatch(successUserClear());
      dispatch(initWebsiteInfo());
      dispatch(initWebsitePartner());
      dispatch(initWebsiteInfoMessages());
      dispatch(initWebsiteAmounts());
      dispatch(ViewManagementModule.Actions.ShowDashboardWebsitesInfoLoader());
      dispatch(ViewManagementModule.Actions.ShowDashboardWebsitesActionsLoader());
      sessionStorage.removeItem('paymentMethods');
      history.push(ROUTES.LOGIN);
      toast.update(id, {
        render: (
          <div>
            <h2 className="toast-title toast-title--success">
              <FormattedMessage id="global.toast_succes" />
            </h2>
            <p>
              <FormattedMessage id="global.login.successful_logout" />
            </p>
          </div>
        ),

        type: toast.TYPE.SUCCESS,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        // icon: <SVG type={SVG_TYPE.PASSED} />,
      });
      dispatch(ViewManagementModule.Actions.hideLoader());
      history.push(ROUTES.LOGIN);
    } catch (err) {
      dispatch(failure());
      // dispatch(ViewManagementModule.Actions.hideLoader());
      toast.update(id, {
        render: (
          <div>
            <h2 className="toast-title toast-title--failure">
              <FormattedMessage id="global.toast_failure" />
            </h2>{' '}
            <p>
              <FormattedMessage id="global.login.failure_logout" />
            </p>
          </div>
        ),
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG type={SVG_TYPE.CLOSE_CIRCLE_FILL} variant={SVG_TYPE.CLOSE_CIRCLE_FILL} />,
      });
    }
  };
};

export default logout;
