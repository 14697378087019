import * as React from 'react';
import SVG from 'Shared/components/SVG';

type ButtonP = {
  classNames?: string;
  iconPlacement?: 'left' | 'right';
  label?: any;
  iconAlt?: string;
  onClick?: () => void;
  link?: string;
  type?: 'button' | 'submit' | 'reset';
  iconImg?: string;
  iconImgSvg?: any;
  iconClass?: string;
  openModalFn?: () => void;
  packet?: string;
  disabled?: boolean;
  isLoading?: boolean;
  isLink?: boolean;
};
const Button = ({
  classNames,
  iconImg,
  iconImgSvg,
  iconPlacement = 'right',
  label,
  iconAlt,
  onClick,
  type,
  disabled = false,
  isLoading,
  isLink,
  link,
  iconClass,
}: ButtonP) => {
  const icon = () => (
    <span className="btn__icon">
      <i className="btn--icon">
        <img src={iconImg} alt={iconAlt} />
      </i>
    </span>
  );
  const iconSvg = () => (
    <span className="btn__icon">
      <i className="btn--icon">
        <SVG className={iconClass} type={iconImgSvg} />
      </i>
    </span>
  );
  const buttonInner = () =>
    iconImg ? (
      iconPlacement === 'left' ? (
        <>
          {icon()} {label}
        </>
      ) : (
        <>
          {label} {icon()}
        </>
      )
    ) : iconImgSvg ? (
      iconPlacement === 'left' ? (
        <>
          {iconSvg()} {label}
        </>
      ) : (
        <>
          {label} {iconSvg()}
        </>
      )
    ) : (
      label
    );

  return (
    <>
    {!isLink ? (
    <div className="btn--wrapper">
      <button
        onClick={onClick}
        type={type}
        disabled={isLoading || disabled}
        className={`btn ${!label ? 'btn__no-label' : ''} ${classNames} ${iconImg ? `btn--icon btn__icon-placement--${iconPlacement} ` : ''} ${
          iconImgSvg ? `btn--icon btn__icon-placement--${iconPlacement} ` : ''
        } ${disabled || isLoading ? 'btn--disabled' : ''} ${isLoading ? 'is-loading' : ''}`}>
        {/* {buttonInner()} */}
        {isLoading ? (
          <div className="btn--loader">
            <div className="btn--loader__inner" />
          </div>
        ) : (
          buttonInner()
        )}
      </button>
    </div>
    ) : (
      <div className="btn--wrapper">
      <a
        href={link}
        target="_blank"
        rel='noopener noreferrer'
        className={`btn ${!label ? 'btn__no-label' : ''} ${classNames} ${iconImg ? `btn--icon btn__icon-placement--${iconPlacement} ` : ''} ${
          iconImgSvg ? `btn--icon btn__icon-placement--${iconPlacement} ` : ''
        } ${disabled || isLoading ? 'btn--disabled' : ''} ${isLoading ? 'is-loading' : ''}`}>
        {/* {buttonInner()} */}
        {label}
      </a>
    </div>
    )}
    </>
  );
};

export default Button;
