import React from 'react';
import ContentLoader from 'react-content-loader';

const DiagnosticDialogLoader = () => (
  <>
    <ContentLoader viewBox="0 0 780 650" backgroundColor="#e3e3e3">
      <rect x="40" y="18" rx="5" ry="5" width="25" height="25" />
      <rect x="80" y="8" rx="4" ry="4" width="639" height="45" />
      <rect x="40" y="78" rx="5" ry="5" width="25" height="25" />
      <rect x="80" y="68" rx="4" ry="4" width="639" height="45" />
      <rect x="40" y="138" rx="5" ry="5" width="25" height="25" />
      <rect x="80" y="128" rx="4" ry="4" width="639" height="45" />
      <rect x="40" y="198" rx="5" ry="5" width="25" height="25" />
      <rect x="80" y="188" rx="4" ry="4" width="639" height="45" />
      <rect x="40" y="258" rx="5" ry="5" width="25" height="25" />
      <rect x="80" y="248" rx="4" ry="4" width="639" height="45" />
      <rect x="40" y="319" rx="5" ry="5" width="25" height="25" />
      <rect x="80" y="308" rx="4" ry="4" width="639" height="45" />
      <rect x="40" y="378" rx="5" ry="5" width="25" height="25" />
      <rect x="80" y="368" rx="4" ry="4" width="639" height="45" />
      <rect x="40" y="438" rx="5" ry="5" width="25" height="25" />
      <rect x="80" y="428" rx="4" ry="4" width="639" height="45" />
      <rect x="40" y="498" rx="5" ry="5" width="25" height="25" />
      <rect x="80" y="488" rx="4" ry="4" width="639" height="45" />
      <rect x="40" y="558" rx="5" ry="5" width="25" height="25" />
      <rect x="80" y="548" rx="4" ry="4" width="639" height="45" />
    </ContentLoader>
  </>
);

export default DiagnosticDialogLoader;
