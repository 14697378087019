import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: User.CHECK_IS_WORDPRESS_INIT,
});

const success = (url) => ({
  type: User.CHECK_IS_WORDPRESS_SUCCESS,
  payload: {
    url,
  },
});

const failure = () => ({
  type: User.CHECK_IS_WORDPRESS_FAILURE,
});

const checkIsWordpress = (URL) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(Actions.showLoader());
    const { data } = await Connector.checkIsWordpress({ url: URL });
    dispatch(success(data));
    dispatch(Actions.hideLoader());
    return data;
  } catch (err) {
    dispatch(Actions.hideLoader());
    dispatch(failure());
  }
};

export default checkIsWordpress;
