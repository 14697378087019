import { Auth } from '../EnumTypes/ActionEnumTypes';
import { Connector as AuthConnector } from 'Modules/AuthModule';
// import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: Auth.CHECK_RECOVERY_TOKEN_INIT,
});

export const success = () => ({
  type: Auth.CHECK_RECOVERY_TOKEN_SUCCESS,
});

const failure = () => ({
  type: Auth.CHECK_RECOVERY_TOKEN_FAILURE,
});

const checkRecoveryToken = (token: string) => {
  return async (dispatch) => {
    dispatch(init());
    try {
      //   dispatch(Actions.showLoader());
      await AuthConnector.checkRecoveryToken(token);
      dispatch(success());
      return true;
      //   dispatch(Actions.hideLoader());
    } catch (err: any) {
      dispatch(failure());
      //   dispatch(Actions.hideLoader());
      return false;
    }
  };
};

export default checkRecoveryToken;
