import * as Types from 'Shared/models/BackupsModel';
import { Backup } from '../Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Shared/enums';

export type State = Types.BackupsStore;

export const initialState: State = {
  myBackups: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  myBackupsPartners: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  myBackupsWebsite: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
};

export function backupsReducer(state: any = initialState, action) {
  switch (action.type) {
    case Backup.GET_BACKUPS_PARTNERS_INIT: {
      return {
        ...state,
        myBackupsPartners: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Backup.GET_BACKUPS_PARTNERS_SUCCESS: {
      return {
        ...state,
        myBackupsPartners: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Backup.GET_BACKUPS_PARTNERS_FAILURE: {
      return {
        ...state,
        myBackupsPartners: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Backup.GET_BACKUPS_INIT: {
      return {
        ...state,
        myBackups: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Backup.GET_BACKUPS_SUCCESS: {
      return {
        ...state,
        myBackups: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Backup.GET_BACKUPS_FAILURE: {
      return {
        ...state,
        myBackups: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Backup.GET_BACKUPS_WEBSITE_INIT: {
      return {
        ...state,
        myBackupsWebsite: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Backup.GET_BACKUPS_WEBSITE_SUCCESS: {
      return {
        ...state,
        myBackupsWebsite: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Backup.GET_BACKUPS_WEBSITE_FAILURE: {
      return {
        ...state,
        myBackupsWebsite: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }

    default: {
      return state;
    }
  }
}
