const getDeviceType = () => {
  const { innerWidth } = window;
  if (innerWidth > 768) {
    return 'DESKTOP';
  }
  if (innerWidth <= 768 && innerWidth > 375) {
    return 'TABLET';
  }
  if (innerWidth <= 375) {
    return 'SMARTPHONE';
  }
};
export default getDeviceType;
