import React, { lazy, Suspense, useState } from 'react';
import { getDailyStatusBasedOnPingsCount, getHourlyStatusBasedOnPingsCount, loadingData, translatedWeekOrderFromToday } from './utils';
import { chartOptions, PING_HOUR_RANGES_ARRAY } from './consts';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import Loader from '../loader';
import { isMobile, isTablet } from 'Shared/utils';
const ReactApexChart = lazy(() => import('react-apexcharts'));
const BarGraph = ({ intl: { formatMessage }, openModal, pings, setSeries, series, selectedFilter }: any) => {
  const [pingsData, setPingsData] = useState<any>(pings);

  const transformCustomPingsToSeries = (customPings, days) => {
    const currentHour = moment().format('H');
    const currentDateRangeIndex = Math.floor(parseInt(currentHour) / 4);

    const isShorted = Object.keys(customPings).length > 7 && isTablet();
    if (isShorted) {
      const value = Object.entries(customPings).map((element) => {
        const { fillColor = '#BFBFBF' } = getDailyStatusBasedOnPingsCount(element[1]);
        return {
          x: element[0],
          y: 1,
          fillColor: fillColor,
        };
      });
      return [{ name: '0-4', data: value }];
    } else {
      return PING_HOUR_RANGES_ARRAY.map((hourRange, pingHourIndex) => ({
        name: hourRange,
        data: days.map((day, dayIndex) => {
          const { fillColor = '#BFBFBF' } = customPings[day] ? getHourlyStatusBasedOnPingsCount(customPings[day][hourRange]) : {};
          if (pingHourIndex > currentDateRangeIndex) {
            const [dayNumber, month, year] = day.split('.');
            const date = moment(`${year}-${month}-${dayNumber}`);

            if (moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
              return {
                x: day,
                y: 0,
                fillColor: '#BFBFBF',
              };
            }
          }
          if (dayIndex === 6 && customPings['test7'] && pingHourIndex === 5) {
            return {
              x: '',
              y: 1,
              fillColor: '#5CDDA0',
            };
          }

          return {
            x: day,
            y: 1,
            fillColor: fillColor,
          };
        }),
      }));
    }
  };

  React.useEffect(() => {
    if (pings) {
      const keysToDays = Object.keys(pings);
      let startDate;
      const endDate = moment().format('D.M.YYYY');
      let minimumDays;

      switch (selectedFilter) {
        case 'last-week':
          startDate = moment().subtract(1, 'weeks');
          minimumDays = 7;
          break;
        case 'last-month':
          startDate = moment().subtract(31, 'day');
          minimumDays = 30;
          break;
        default:
          minimumDays = 0; // Handle according to your default case needs
      }

      const hasToday = keysToDays.includes(endDate);
      const emptyKeys = [];
      for (let i = hasToday ? 1 : 0; i < minimumDays; i++) {
        const newDate = moment(startDate).add(i, 'days');
        const formattedDate = newDate.format('D.M.YYYY');
        if (!keysToDays.includes(formattedDate)) {
          emptyKeys.push(formattedDate);
        }
      }
      const newKeysToDays = [...emptyKeys, ...keysToDays].sort((a, b) => {
        return moment(a, 'D.M.YYYY').valueOf() - moment(b, 'D.M.YYYY').valueOf();
      });

      const newPings = newKeysToDays.reduce((acc, day) => {
        acc[day] = pings[day] || {};
        return acc;
      }, {});

      setSeries(transformCustomPingsToSeries(newPings || {}, newKeysToDays));
      setPingsData(newPings);
    }
  }, [pings, selectedFilter]);
  return (
    <>
      <div
        className={`userProduct__apex ${
          pingsData && Object.keys(pingsData)?.length > 7 && isMobile() ? 'single-website__status-graph--shorted' : ''
        }`}>
        <Suspense fallback={<Loader />}>
          {series.length ? (
            <ReactApexChart
              options={chartOptions(translatedWeekOrderFromToday(formatMessage, pingsData, selectedFilter !== 'last-week'), formatMessage, openModal)}
              series={series}
              type="bar"
              height={isTablet() ? 120 : 175}
            />
          ) : (
            <ReactApexChart
              options={chartOptions(
                translatedWeekOrderFromToday(formatMessage, loadingData, selectedFilter !== 'last-week'),
                formatMessage,
                openModal,
              )}
              series={transformCustomPingsToSeries(loadingData, ['', '', '', '', '', '', ''])}
              type="bar"
              height={isTablet() ? 120 : 175}
            />
          )}
        </Suspense>
      </div>
    </>
  );
};

export default injectIntl(BarGraph);
