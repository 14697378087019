import * as React from 'react';
import { SVGType } from 'Shared/utils/svgType';
const TwistedArrowSVG = ({ className }: SVGType) => (
  <svg className={`svg ${className}`} xmlns="http://www.w3.org/2000/svg" width="64" height="24" viewBox="0 0 64 24">
    <path
      d="M61.6805 11.6699C40.375 29.0104 27.0787 23.5274 22.2874 20.2482C21.0737 19.4417 19.9861 18.5293 19.0774 17.574C15.1835 18.0504 8.5341 16.6516 0.0214971 5.86673L1.33406 4.87244C7.10842 12.2654 12.9018 16.0998 17.6924 16.052C14.4354 11.9144 13.3977 7.09543 14.4921 3.92252C15.3575 1.37151 17.4587 0.0374758 20.3125 0.112437C24.0929 0.268061 26.7644 2.42808 27.3975 5.86876C28.1583 9.84619 25.8081 15.3611 20.9283 17.0937C26.4317 22.2451 39.4007 27.4657 60.2549 10.611L54.2202 10.1999L54.3621 8.57793L63.5252 9.26275L62.8403 18.4258L61.2175 18.2838L61.6805 11.6699ZM25.8513 6.20034C25.5196 4.65378 24.4681 1.99334 20.287 1.74393C18.1386 1.61465 16.7102 2.59835 16.084 4.41175C15.1357 7.24741 16.3192 11.7289 19.4911 15.509L19.7024 15.7608C20.1233 15.6225 20.5438 15.4838 21.0275 15.2928C24.8549 13.5849 26.4518 9.347 25.8513 6.20034Z"
      fill="black"
    />
  </svg>
);

export default TwistedArrowSVG;
