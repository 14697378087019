import React, { useState } from 'react';
import Button from 'Shared/components/button/Button';
import { FormattedMessage } from 'react-intl';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { history } from 'Store/Reducers/RootReducer';
import { SVG, Badge } from 'Shared/components';
import { archiwumSize } from 'Shared/utils';

type P = {
  data?: any;
  closePopUp?: () => void;
  restoreBackup?: (uuid) => void;
  getBackupDownload?: (uuid) => void;
  getRestoreBackupStatus?: (uuid) => void;
  uuid?: string;
  onClick?: () => void;
  onSubmit?: (uuid) => void;
};

const EventsModal = ({
  data: { backupUuid, from, current_version, version, method, message, data, type, update, phone, email, backup, url, size, userUuid },
  restoreBackup,
  getBackupDownload,
  closePopUp,
  getRestoreBackupStatus,
  uuid,
  onClick,
  onSubmit,
}: P) => {
  const [backupStatus, setBackupStatus] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);
  const statusBackup = {
    pending: <Badge load label={<FormattedMessage id="global.pending" />} type="yellow" />,
    downloading: <Badge load label={<FormattedMessage id="global.downloading" />} type="yellow" />,
    decompressing: <Badge load label={<FormattedMessage id="global.decompressing" />} type="yellow" />,
    restoring: <Badge load label={<FormattedMessage id="global.restoring" />} type="yellow" />,
    end: <Badge label={<FormattedMessage id="global.success" />} type="success" />,
    failed_clearing: <Badge label={<FormattedMessage id="global.failed_clearing" />} type="error" />,
    failed_uploading: <Badge label={<FormattedMessage id="global.failed_uploading" />} type="error" />,
    failed_restoring: <Badge label={<FormattedMessage id="global.failed_restoring" />} type="error" />,
    clearing: <Badge load label={<FormattedMessage id="global.clearing" />} type="yellow" />,
    uploading: <Badge load label={<FormattedMessage id="global.uploading" />} type="yellow" />,
  };
  const resetStatus = (status, interval) => {
    setBackupStatus(status);
    {
      (status == 'success' || status === 'failed_restoring' || status === 'failed_clearing' || status === 'failed_uploading') &&
        clearInterval(interval);
    }
  };
  return (
    <div className={`popUpEvents--${from}`}>
      <p className="popUpEvents__head">
        {from == 'events' && <FormattedMessage id="global.events_head" />}
        {from == 'backup' && 'Backup'}
        {from == 'update' && <FormattedMessage id="global.updates" />}
        {from === 'cancelSubscription' && <FormattedMessage id="global.cancel_subscription" />}
        {from === 'deleteUser' && <FormattedMessage id="global.delete_user" />}
      </p>
      <div className="popUpEvents__content">
        <div className="popUpEvents__iconWrap">
          {method == 'sms' && <SVG type={SVG_TYPE.SMS} />}
          {method == 'email' && <SVG type={SVG_TYPE.EMAIL} />}
          {from == 'backup' && <SVG type={SVG_TYPE.BACKUP} />}
          {from == 'update' && <SVG className="svg--backup" type={SVG_TYPE.ARROW_UP} />}
        </div>
        <div className="popUpEvents__info">
          <p className="popUpEvents__info-top">
            {method == 'sms' && 'SMS'}
            {method == 'email' && 'Mail'}
            {backup == 'db' && <FormattedMessage id="global.backup_db" />}
            {backup == 'files' && <FormattedMessage id="global.backup_files" />}
            {from == 'update' && type}
            {from === 'cancelSubscription' && <FormattedMessage id="global.cancel_subscription_description" />}
            {from === 'deleteUser' && <FormattedMessage id="global.delete_user_description" />}
            <span className="popUpEvents__info--gray">
              {email} {phone}
              {url}
            </span>
          </p>
          <p className="popUpEvents__info-mid">
            {message}
            {update && update + ' ' + current_version + ' →  ' + version}
            {from === 'backup' && (
              <>
                <FormattedMessage id="global.file_size" />
                {archiwumSize(size)}
                {statusBackup[backupStatus]}
                {backupStatus === 'success' && (setBackupStatus('end'), setDisabledButton(false))}
              </>
            )}
          </p>
          <p className="popUpEvents__info-bottom"> {data}</p>
        </div>
      </div>
      {from == 'events' && (
        <div className="popUpEvents__buttons">
          <Button
            onClick={() => {
              uuid ? history.push(ROUTES.NOTIFICATIONS + '?id=' + uuid) : history.push(ROUTES.NOTIFICATIONS);
            }}
            type="button"
            label={<FormattedMessage id="global.see_all" />}
            classNames="btn--gray-border"
            iconAlt="icon product"
            iconImgSvg={SVG_TYPE.RIGHT_ARROW}
            iconClass="svg--right-arrow-dash--black"
          />
          <Button onClick={() => closePopUp()} type="button" label={'ok'} classNames="btn--black-5r" iconAlt="icon product" />
        </div>
      )}
      {from === 'cancelSubscription' && (
        <div className="popUpEvents__buttons">
          <Button
            onClick={onClick}
            type="button"
            label={<FormattedMessage id="global.cancel_subscription_agree_button" />}
            classNames="btn--gray-border"
            iconAlt="icon product"
            iconImgSvg={SVG_TYPE.RIGHT_ARROW}
            iconClass="svg--right-arrow-dash--black"
          />
          <Button
            onClick={() => closePopUp()}
            type="button"
            label={<FormattedMessage id="global.cancel_subscription_abort_button" />}
            classNames="btn--black-5r"
            iconAlt="icon product"
          />
        </div>
      )}
      {from == 'update' && (
        <div className="popUpEvents__buttons">
          <Button
            onClick={() => {
              uuid ? history.push(ROUTES.UPDATES + '?id=' + uuid) : history.push(ROUTES.UPDATES);
            }}
            type="button"
            label={<FormattedMessage id="global.see_all" />}
            classNames="btn--gray-border"
            iconAlt="icon product"
            iconImgSvg={SVG_TYPE.RIGHT_ARROW}
            iconClass="svg--right-arrow-dash--black"
          />
          <Button onClick={() => closePopUp()} type="button" label={'ok'} classNames="btn--black-5r" iconAlt="icon product" />
        </div>
      )}
      {from == 'backup' && (
        <div className="popUpEvents__buttons">
          <Button
            onClick={async () => {
              const url = await getBackupDownload(backupUuid);
              window.location.href = '' + url;
            }}
            type="button"
            label={<FormattedMessage id="global.download" />}
            classNames="btn--gray-border"
            iconAlt="icon product"
          />
          <Button
            onClick={async () => {
              setDisabledButton(true);
              const uuid = await restoreBackup(backupUuid);
              await getRestoreBackupStatus(uuid);
              setBackupStatus('pending');
              const interval = setInterval(async () => {
                const statusBackup = await getRestoreBackupStatus(uuid);
                resetStatus(statusBackup, interval);
              }, 4000);
            }}
            disabled={disabledButton}
            type="button"
            label={<FormattedMessage id="global.upload_on_site" />}
            classNames="btn--black-5r"
            iconAlt="icon product"
          />
        </div>
      )}
      {from == 'deleteUser' && (
        <div className="popUpEvents__buttons">
          <Button
            onClick={() => {
              onSubmit(userUuid);
              closePopUp();
            }}
            type="button"
            label={<FormattedMessage id="global.cancel_subscription_agree_button" />}
            classNames="btn--black-5r"
            iconAlt="icon product"
          />
          <Button
            onClick={() => closePopUp()}
            type="button"
            label={<FormattedMessage id="global.cancel_subscription_abort_button" />}
            classNames="btn--gray-border"
            iconAlt="icon product"
            iconClass="svg--right-arrow-dash--black"
          />
        </div>
      )}
    </div>
  );
};

export default EventsModal;
