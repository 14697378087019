import { SSL_STATUSES } from 'Shared/enums';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const handleSSLLabel = (sslStatus: 'ACTIVE' | 'INACTIVE' | 'NOT_INSTALLED') => {
  switch (sslStatus) {
    case SSL_STATUSES.ACTIVE:
      return <FormattedMessage id="global.itworks" />;

    case SSL_STATUSES.NOT_INSTALLED:
      return <FormattedMessage id="global.lack" />;

    case SSL_STATUSES.INACTIVE:
      return <FormattedMessage id="global.expired1" />;

    default:
      return <FormattedMessage id="global.no_data" />;
  }
};

export default handleSSLLabel;
