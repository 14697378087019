import { connect } from 'react-redux';
import { RootState } from 'Store/Reducers/RootReducer';
import Report from './Report.main';
import { bindActionCreators } from 'redux';
import { getReportData } from 'Store/Selectors/WebsiteSelector';
// import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';


type ConnectedP = {
    reportData?: any;
};

type DispatchedP = {
  actions?: {
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {},
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  reportData: getReportData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)((Report));
