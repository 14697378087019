/* eslint react/prop-types: 0 */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { SVG_TYPE } from 'Shared/enums';
import { SVG, Button, Modal } from 'Shared/components';
import { FormattedMessage } from 'react-intl';
import { Actions } from 'Modules/ViewManagementModule';

function DragDrop({ save, file, deleteLogo, uuid, openIncorrectFileModal, openModal, closeModal }) {
  const [image, setImage] = useState({ isChecked: null, file_name: null, uuid: null, format: null });
  const [imageName, setImageName] = useState('');
  const [imageFormat, setImageFormat] = useState('');
  const [fileTypeError, setFileTypeError] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const acceptedFileType = ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml'];

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFileType.find((type) => acceptedFiles[0].type === type)) {
      save(
        { file_name: acceptedFiles[0].name, file_format: acceptedFiles[0].type.slice(6) === 'svg+xml' ? 'svg' : acceptedFiles[0].type.slice(6) },
        acceptedFiles[0],
      );
      setImageName(acceptedFiles[0].name);
      setImageFormat(acceptedFiles[0].type);
    } else {
      openIncorrectFileModal();
      setFileTypeError(!fileTypeError);
      acceptedFiles.shift();
    }
  }, []);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
  });

  const inputProps = getInputProps({
    accept: acceptedFileType.join(','),
  });

  const removeFile = (file) => {
    deleteLogo(uuid ? uuid : image.uuid);
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    setImage({ isChecked: true, file_name: null, uuid: null, format: null });
  };

  useEffect(() => {
    setImage({ isChecked: true, file_name: null, uuid: null, format: null });
    if (file) {
      setImage({ isChecked: null, file_name: file.file_name, uuid: file.uuid, format: file.file_format });
      acceptedFiles.push(file);
    }
  }, []);

  useEffect(() => {
    acceptedFiles.shift();
  }, [fileTypeError]);

  const fileIcon = (format) => {
    switch (format) {
      case 'image/svg+xml':
      case 'svg+xml': {
        return <SVG type={SVG_TYPE.FILE_SVG} />;
      }
      case 'image/jpeg':
      case 'jpeg': {
        return <SVG type={SVG_TYPE.FILE_JPEG} />;
      }
      case 'image/jpg':
      case 'jpg': {
        return <SVG type={SVG_TYPE.FILE_JPG} />;
      }
      case 'image/png':
      case 'png': {
        return <SVG type={SVG_TYPE.FILE_PNG} />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <>
      <Modal
        new
        className="dragdrop--delete-modal"
        opened={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          closeModal();
        }}>
        <>
          <div className="heading">{<FormattedMessage id="global.logo_removal" />}</div>
          <div className="paragraph">{<FormattedMessage id="global.are_you_sure_to_remove_logotype" />}</div>
          <div className="buttons">
            <Button
              classNames="new-blue"
              label={<FormattedMessage id="global.delete" />}
              onClick={() => {
                removeFile(acceptedFiles[0]);
                closeModal();
                setIsDeleteModalOpen(false);
              }}
            />
            <Button
              classNames="new-blue-stroke"
              label={<FormattedMessage id="global.payments.cancel" />}
              onClick={() => {
                closeModal();
                setIsDeleteModalOpen(false);
              }}
            />
          </div>
        </>
      </Modal>
      {image.isChecked && acceptedFiles.length === 0 ? (
        <div {...getRootProps()}>
          <input {...inputProps} />
          {
            <>
              <div className="imageInput">
                <SVG type={SVG_TYPE.FILE_UPLOAD} />
                <div>
                  <FormattedMessage id="global.drop_files_here" />
                </div>
                <div>
                  <FormattedMessage id="global.or" />
                </div>
                <Button type="submit" label={<FormattedMessage id="global.choose_file" />} classNames="btn add-image-btn" />
              </div>
            </>
          }
        </div>
      ) : (
        <>
          <div className="imageAdded">
            {fileIcon(imageFormat ? imageFormat : acceptedFiles[0] ? acceptedFiles[0].type : image?.format)}
            <div className="imageAdded__name">{imageName ? imageName : acceptedFiles[0]?.name ? acceptedFiles[0]?.name : image?.file_name}</div>
          </div>
          <div className="imageAdded__buttons">
            <Button
              label={<FormattedMessage id="global.delete" />}
              onClick={() => {
                setIsDeleteModalOpen(true);
                openModal();
              }}
            />
            <div className="imageAdded__button" {...getRootProps()}>
              <input {...inputProps} />
              <Button
                onClick={() => !file?.uuid && setImage({ isChecked: false, file_name: null, uuid: null, format: null })}
                label={<FormattedMessage id="global.changee" />}
                classNames="btn--black"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

type DispatchedP = {
  openModal: () => void;
  closeModal: () => void;
};
const mapDispatchToProps: DispatchedP = {
  openModal: () => Actions.openModal(),
  closeModal: () => Actions.closeModal(),
};
type ConnectedP = {};
function mapStateToProps(): ConnectedP {
  return null;
}
export default connect(mapStateToProps, mapDispatchToProps)(DragDrop);
