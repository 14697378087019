import React from 'react';
import Packages from 'Screens/Packages/Container';
import { FullScreenModal, Locale } from 'Shared/components';
import { useLocation } from 'react-router-dom';
interface PartnerWebsitesFTPModalProps {
  isOpen: boolean;
  onClose: () => void;
  zindex?: number;
}

const DetailsModal = ({ isOpen, onClose, zindex }: PartnerWebsitesFTPModalProps) => {
  const handleBack = () => {
    onClose();
  };
  const location = useLocation();

  return (
    <FullScreenModal
      isOpen={isOpen}
      className="fullscreen-modal__warpper--overflow "
      onClose={() => {
        handleBack();
      }}
      zindex={zindex}>
      {/* <div className="fullscreen-modal__warpper fullscreen-modal__warpper--overflow"> */}
        <Packages location={location} translation={Locale()} />
      {/* </div> */}
    </FullScreenModal>
  );
};

export default DetailsModal;
