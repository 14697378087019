import { SSL_STATUSES, WEBSITE_STATUSES } from 'Shared/enums';

const filteringMyWebsites = (filter, protect, status, website) => {
  if (filter === 'working') {
    return website.status === WEBSITE_STATUSES.ACTIVE;
  } else if (filter === 'notProtected') {
    return !protect;
  } else if (filter === 'notWorking') {
    return website.status === WEBSITE_STATUSES.INACTIVE;
  } else if (filter === 'protected') {
    return protect;
  } else if (filter === 'protectedSSL') {
    return status === SSL_STATUSES.ACTIVE;
  } else if (filter === 'noSSL') {
    return status === SSL_STATUSES.NOT_INSTALLED;
  } else if (filter === 'expiredSSL') {
    return status === SSL_STATUSES.INACTIVE;
  } else {
    return website;
  }
};

export default filteringMyWebsites;
