import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';

// const init = () => ({
//   type: Website.GET_DASHBOARD_WEBSITES_ACTIONS_INIT,
// });

const success = (data) => ({
  type: Website.GET_DASHBOARD_WEBSITES_ACTIONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_DASHBOARD_WEBSITES_ACTIONS_FAILURE,
});

const getDashboardClientsWebsitesActions = () => async (dispatch) => {
  // dispatch(init());
  try {
    const { data } = await Connector.getDashboardClientsWebsitesActions();
    dispatch(success(data));
    dispatch(Actions.HideDashboardWebsitesActionsLoader());
  } catch (err) {
    dispatch(Actions.HideDashboardWebsitesActionsLoader());
    dispatch(failure());
  }
};

export default getDashboardClientsWebsitesActions;
