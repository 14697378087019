import { Connector } from 'Modules/WebsiteModule';

import { Toast } from 'Shared/components';

const deleteAdditionalUrl = (uuid, url_uuid) => async () => {
  console.log(url_uuid);
  Toast({ id: 'deleteAdditionalUrlSettings', type: 'loading', message: 'global.apex_no_data' });
  try {
    await Connector.deleteAdditionalUrl(uuid, url_uuid);

    Toast({ id: 'deleteAdditionalUrlSettings', type: 'success', message: 'global.toast.delete_additional_url_success' });
    return true;
  } catch (err: any) {
    console.log(err.response);

    Toast({ id: 'deleteAdditionalUrlSettings', type: 'error', message: 'global.toast.delete_additional_url_failure' });
    return false;
  }
};

export default deleteAdditionalUrl;
