import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import Button from 'Shared/components/button/Button';
import RegularField from 'Shared/form/regularField/regularField';
import ChevronBlack from 'Assets/Image/Icon/icon_chevron_black.svg';
import { FormattedMessage } from 'react-intl';
import { MainType } from './Container';
import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';

type P = {
  comeBack?: any;
};

const ChangePassword = ({ intl: { formatMessage }, actions: { changePassword }, comeBack }: MainType & P) => {
  const [isSend, setIsSend] = useState(false);
  const validation = () =>
    Yup.object().shape({
      current_password: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
      new_password: Yup.string()
        .required(formatMessage({ id: 'global.validation.required' }))
        .min(8, formatMessage({ id: 'global.validation.min_8' })),
      new_password_confirmation: Yup.string()
        .required(formatMessage({ id: 'global.validation.required' }))
        .oneOf([Yup.ref('new_password'), null], formatMessage({ id: 'global.validation.password' }))
        .min(8, formatMessage({ id: 'global.validation.min_8' })),
    });

  const [passwordError, setPasswordError] = useState(false);
  return (
    <div className="userMenu__content">
      {!isSend ? (
        <div className="MyData">
          <Formik
            initialValues={{
              current_password: '',
              new_password: '',
              new_password_confirmation: '',
            }}
            validationSchema={validation()}
            onSubmit={async ({ current_password, new_password }) => {
              const err = await changePassword(current_password, new_password);
              if (err) {
                console.log('błędne hasło');
                setPasswordError(true);
              } else {
                setIsSend(true);
                setPasswordError(false);
              }
            }}>
            {({ handleSubmit, errors }) => (
              <div className="userMenu__form">
                <h2>
                  <FormattedMessage id="global.menu_dashboard.change_password" />
                </h2>
                {passwordError && (
                  <div className="userMenu__form--wrong-password">
                    <p>
                      * <FormattedMessage id="global.change_password.wrong_password" />
                    </p>
                  </div>
                )}
                <Field
                  type="password"
                  label={<FormattedMessage id="global.change_password.old_password" />}
                  name="current_password"
                  component={RegularField}
                  errors={errors}
                />
                <Field
                  type="password"
                  label={<FormattedMessage id="global.change_password.new_password" />}
                  name="new_password"
                  component={RegularField}
                  errors={errors}
                />
                <Field
                  type="password"
                  label={<FormattedMessage id="global.change_password.confirm_password" />}
                  name="new_password_confirmation"
                  component={RegularField}
                  errors={errors}
                />
                <div className="userMenu__form-button">
                  <Button
                    onClick={() => comeBack()}
                    label={<FormattedMessage id="global.back" />}
                    classNames="btn--gray"
                    iconAlt="icon product"
                    iconPlacement="left"
                    iconImg={ChevronBlack}
                  />
                  <Button
                    type="submit"
                    onClick={() => handleSubmit()}
                    label={<FormattedMessage id="global.payments.save_data" />}
                    classNames="btn--black"
                    iconAlt="icon product"
                  />
                </div>
              </div>
            )}
          </Formik>
        </div>
      ) : (
        <div className="accConfig">
          <div className="accConfig__top">
            <div>
              <SVG className="svg--accept" type={SVG_TYPE.ACCEPT_GREEN} />
              <p>{<FormattedMessage id="global.change_password.password_changed" />}</p>
            </div>
          </div>
          <div className="accConfig__bottom">
            <div>&nbsp;</div>
            <div className="accConfig__bottom-button">
              <Button
                onClick={() => {
                  comeBack();
                  setIsSend(false);
                }}
                type="submit"
                label="Ok"
                classNames="btn--black"
                iconAlt="icon product"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ChangePassword;
