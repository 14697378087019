import * as React from 'react';
import ReactDOM from 'react-dom';
// import { MODAL_TYPE } from '../../enums';
import CloseButton from 'Assets/Image/Icon/icon_close.svg';
// import ClickAwayListener from 'react-click-away-listener';
import OutsideClickHandler from 'react-outside-click-handler';
import SVG from '../SVG';
import { SVG_TYPE } from 'Shared/enums';

type S = {
  isOpened: boolean;
};

type P = {
  new?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onSubmit?: () => void;
  className?: any;
  opened?: boolean;
  children?: JSX.Element;
  onOpenedChange?: (opened: boolean) => boolean;
};

class Modal extends React.Component<P, S> {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: this.props.opened || false,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.opened !== this.props.opened) {
      this.setOpened(nextProps.opened);
    }
    return true;
  }

  componentDidMount() {
    this.props.onOpen && this.props.onOpen();
  }

  componentWillUnmount() {
    this.props.onClose && this.props.onClose();
  }

  setOpened = (isOpened) => this.setState({ isOpened }, () => this.props.onOpenedChange && this.props.onOpenedChange(isOpened));

  submit = () => {
    this.props.onSubmit();
    this.setOpened(false);
  };

  toggle = () => this.setOpened(!this.state.isOpened);

  close = () => {
    this.props.onClose();
    this.setOpened(false);
  };

  handleChildClick = (e: any) => e.stopPropagation();

  render() {
    const { isOpened } = this.state;
    const { children, className } = this.props;
    return (
      <>
        {isOpened && (
          <OutsideClickHandler onOutsideClick={this.close}>
            <div className={`popUp ${className}`}>
              <div className="popUp--close" onClick={this.close}>
                {this.props.new ? <SVG className="popUp--close-svg" type={SVG_TYPE.CLOSE_POPUP} /> : <img src={CloseButton} alt="close button" />}
              </div>
              {children}
            </div>
          </OutsideClickHandler>
        )}
      </>
    );
  }
}

export default (props: P) => ReactDOM.createPortal(<Modal {...props} />, document.querySelector('body'));
