import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: User.GET_PARTNER_CLIENTS_INIT,
});

const success = (data) => ({
  type: User.GET_PARTNER_CLIENTS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.GET_PARTNER_CLIENTS_FAILURE,
});

const getPartnerClients = (uuid) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(Actions.showLoader());
    const { data } = await Connector.getPartnerClients(uuid);
    dispatch(success(data));
    dispatch(Actions.hideLoader());
  } catch (err) {
    console.log(err);
    dispatch(Actions.hideLoader());
    dispatch(failure());
  }
};

export default getPartnerClients;
