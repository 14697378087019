import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'Modules/UserModule';
import ContactFormHelp from './Main';
import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';

type ConnectedP = {};

type DispatchedP = {
  actions: {
    sendContactForm: any;
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      sendContactForm: Actions.sendContactForm,
    },
    dispatch,
  ),
});

const mapStateToProps = (): ConnectedP => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ContactFormHelp));
