import semver from 'semver';

const checkPhpVersion = (phpVersion) => {
  if (semver.satisfies(phpVersion, '>=7.4.0')) {
    return 'correct';
  } else if (semver.satisfies(phpVersion, '>=7.0 || <=7.3.0')) {
    return 'out_of_date--yellow';
  } else if (semver.satisfies(phpVersion, '<7.0')) {
    return 'out_of_date--red';
  }
};

export default checkPhpVersion;
