import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';

import { Toast } from 'Shared/components';
import { generateID } from 'Shared/utils';
const init = () => ({
  type: Website.GET_REPORT_DATA_INIT,
});

const getReportData = (uuid: any, dateFrom: any, dateTo: any, isCustom: any, siteName: any, month: any) => async (dispatch) => {
  dispatch(init());
  const id = generateID();
  Toast({ id: id, type: 'loading', message: 'global.apex_no_data' });
  try {
    await Connector.getReportData(uuid, { dateFrom, dateTo, isCustom }).then(({ data }) => {
      const download_url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.download = `Raport_${siteName}_${month}.pdf`;
      link.href = download_url;
      document.body.appendChild(link);
      link.click();
    });
    Toast({ id: id, type: 'success', message: 'global.toast.download_raport_success' });
    return true;
  } catch (err) {
    Toast({ id: id, type: 'error', message: 'global.toast.download_raport_failure' });
  }
};

export default getReportData;
