import { Connector } from '../../../Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { ROUTES } from 'Shared/enums';
import { history } from 'Store/Reducers/RootReducer';
import { getLocalStorage } from 'Shared/utils';

const init = () => ({
  type: User.SEND_PREORDER_FORM_INIT,
});

const success = () => ({
  type: User.SEND_PREORDER_FORM_SUCCESS,
});

const failure = () => ({
  type: User.SEND_PREORDER_FORM_FAILURE,
});

const sendPreorderForm = (dataForm) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    let modifiedObject = {};
    const link = dataForm.url;
    let newLink = '';
    if (link.search(/^http[s]?\:\/\//) == -1) {
      newLink = 'http://' + link;
    } else {
      newLink = link;
    }
    console.log(newLink);

    const ref = getLocalStorage('refCode');

    modifiedObject = {
      currency: dataForm.currency,
      website: {
        url: newLink,
      },
      user: {
        email: dataForm.email,
        password: dataForm.password,
        first_name: dataForm.first_name,
        last_name: dataForm.last_name,
      },
      pre_order: {
        product_key: dataForm.product_key,
      },
      ...(ref && { ref }),
    };

    await Connector.sendPreorderForm(modifiedObject);
    dispatch(success());
    history.push({ pathname: ROUTES.PREORDER_SIGN_UP, fullName: `${dataForm.first_name} ${dataForm.last_name}` });
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err: any) {
    console.log(err);
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    return err.response.data;
  }
};

export default sendPreorderForm;
