import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Website.GET_WEBINAR_DETAILS_INIT,
});

const success = (data) => ({
  type: Website.GET_WEBINAR_DETAILS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_WEBINAR_DETAILS_FAILURE,
});

const getWebinarDetails = (id) => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await Connector.getWebinarDetails(id);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getWebinarDetails;
