import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';

// const init = () => ({
//     type: Website.GET_WEBSITES_ALL_COUNTS_INIT,
// });

const success = (data) => ({
    type: Website.GET_WEBSITES_ALL_COUNTS_SUCCESS,
    payload: {
        data,
    },
});

const failure = () => ({
    type: Website.GET_WEBSITES_ALL_COUNTS_FAILURE,
});

const getWebsitesAllCounts = () => async (dispatch) => {
    try {
        const { data }= await Connector.getWebsitesAllCounts();
        dispatch(success(data));
        return data;
    } catch (err) {
        console.log(err)
        dispatch(failure());
        return false;
    }
};

export default getWebsitesAllCounts;
