import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import zxcvbn from 'zxcvbn';
import { Toast } from 'Shared/components';

const init = () => ({
  type: User.CHANGE_PASSWORD_INIT,
});

const success = () => ({
  type: User.CHANGE_PASSWORD_SUCCESS,
});

const failure = () => ({
  type: User.CHANGE_PASSWORD_FAILURE,
});

const changePassword = (current_password: string, new_password: string) => async (dispatch) => {
  const getPasswordStrength = (password) => {
    const passwordStrength = zxcvbn(password).score;
    if (passwordStrength <= 1) {
      return 'weak';
    }
    if (passwordStrength > 1 && passwordStrength <= 3) {
      return 'medium';
    }
    if (passwordStrength > 3) {
      return 'strong';
    }
  };

  dispatch(init());

  Toast({ id: 'changePassword', type: 'loading', message: 'global.apex_no_data' });
  try {
    await Connector.changePassword(current_password, new_password, getPasswordStrength(new_password));
    dispatch(success());
    Toast({ id: 'changePassword', type: 'success', message: 'global.toast.change_password__success' });
    return true;
  } catch (err: any) {
    dispatch(failure());
    Toast({ id: 'changePassword', type: 'error', message: 'global.toast.change_password__failure' });
    return false;
  }
};

export default changePassword;
