import React from 'react';
import { Field, Formik } from 'formik';
import { useRef } from 'react';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import RegularField from 'Shared/form/regularField/regularField';
import { Button, SVG } from 'Shared/components';
import { ROUTES, SVG_TYPE } from 'Shared/enums';

type P = {
  history: any;
  formatMessage: any;
  recoverPassword: (email: string) => any;
  setApiError: (value: boolean) => void;
  setEmail: (value: string) => void;
};

const EnterEmailForm = ({ formatMessage, recoverPassword, setApiError, setEmail, history }: P) => {
  const formRef = useRef(null);

  const validation = () =>
    Yup.object().shape({
      email: Yup.string()
        .email(formatMessage({ id: 'global.validation.wrong_email' }))
        .required(formatMessage({ id: 'global.validation.required' })),
    });
  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validation()}
      onSubmit={async ({ email }) => {
        const err = await recoverPassword(email);
        if (err.status === 401) {
          setApiError(true);
        }
        setEmail(email);
      }}>
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="login-page__form">
          <span className="login-page__back" onClick={() => history.push(ROUTES.LOGIN)}>
            <SVG type={SVG_TYPE.ARROW_LEFT} />
          </span>
          <h1 className="login-page__title">{formatMessage({ id: 'global.dont_remember_password' })}&nbsp;🔑</h1>
          <h3 className="login-page__sub-title">{formatMessage({ id: 'global.remind_password_subtitle' })}</h3>
          <Field type="text" wrapperClassName="white" label="Email" name="email" component={RegularField} errors={errors} errorBelow />
          <Button type="submit" label={<FormattedMessage id="global.send_link" />} classNames="btn--blue login-page__submit" iconAlt="icon product" />
        </form>
      )}
    </Formik>
  );
};
export default EnterEmailForm;
