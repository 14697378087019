import React, { useRef, useState } from 'react';
import { Field, Formik } from 'formik';
import { getLocalStorage } from 'Shared/utils';
import * as Yup from 'yup';
import { ROUTES } from 'Shared/enums';
import RegularField from 'Shared/form/regularField/regularField';
import { FormattedMessage } from 'react-intl';
import PasswordStrengthChecker from 'Components/PasswordStrengthChecker/PasswordStrengthChecker';
import PasswordValidation from 'Components/PasswordValidation/PasswordValidation';
import Checkbox from 'Components/Checkbox';
import { Button } from 'Shared/components';

type P = {
  setApiError: (value: boolean) => void;
  formatMessage: any;
  registerPartnerForm: (values: any, isPartner: boolean) => any;
  history: any;
  translation: string;
  setEmail: (value: string) => void;
  stripePrice: any;
};

const RegisterForm = ({ setApiError, formatMessage, registerPartnerForm, history, translation, setEmail, stripePrice }: P) => {
  const [showValidation, setShowValidation] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const formRef = useRef(null);
  const ref = getLocalStorage('refCode');

  const validation = () =>
    Yup.object().shape({
      name: Yup.string()
        .required(formatMessage({ id: 'global.validation.required' }))
        .matches(
          /^(?![\s])[^\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/? ]*$/,
          formatMessage({ id: 'global.validation.empty_space' }),
        ),
      email: Yup.string()
        .email(formatMessage({ id: 'global.validation.wrong_email' }))
        .required(formatMessage({ id: 'global.validation.required' })),
      password: Yup.string()
        .required(formatMessage({ id: 'global.validation.required' }))
        .min(8, formatMessage({ id: 'global.validation.min_8' })),
      acceptance: Yup.boolean().oneOf([true], formatMessage({ id: 'global.validation.required' })),
    });
  const currency = stripePrice?.pricing_table_items[0].currency.toUpperCase();
  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        email: '',
        password: '',
        name: '',
        acceptance: false,
        ref: ref ? ref : undefined,
      }}
      validationSchema={validation()}
      onSubmit={async (values) => {
        const err = await registerPartnerForm({ ...values, acceptance: undefined, lang: translation, currency: currency }, true);
        if (err.status === 401) {
          setApiError(true);
        } else if (err == true) {
          setEmail(values.email);
        }
      }}>
      {({ handleSubmit, errors, values }) => (
        <form onSubmit={handleSubmit} className="login-page__form">
          <h1 className="login-page__title">{formatMessage({ id: 'global.create_account' })}</h1>
          <Field
            type="text"
            wrapperClassName="white"
            label={formatMessage({ id: 'global.message.name' })}
            name="name"
            component={RegularField}
            errors={errors}
            errorBelow
          />
          <Field
            type="text"
            wrapperClassName="white"
            inputmode="email"
            label="Email"
            name="email"
            component={RegularField}
            errors={errors}
            errorBelow
          />
          <Field
            label={<FormattedMessage id="global.login.password" />}
            name="password"
            wrapperClassName={`white ${showValidation ? 'form__field--password-checker-open' : ''}`}
            type="password"
            errorBelow
            component={RegularField}
            errors={errors}
            inputOnFocus={() => setShowValidation(true)}
            onBlur={() => isPasswordValid && setShowValidation(false)}
          />
          <PasswordStrengthChecker isHidden={!showValidation} password={values.password} formatMessage={formatMessage} />
          <PasswordValidation
            className={` ${showValidation ? 'validation-item__wrapper--show' : ''}`}
            password={values.password}
            formatMessage={formatMessage}
            setIsValid={(value: boolean) => setIsPasswordValid(value)}
          />

          <Field
            label={
              <p>
                <FormattedMessage id="global.i_confirm" />{' '}
                <a
                  href={translation === 'pl' ? require('../../../Assets/file/regulations_corgi.pdf') : require('../../../Assets/file/terms_of_service.pdf')}
                  target="_blank"
                  rel="noopener noreferrer">
                  <FormattedMessage id="global.the_terms" />
                </a>{' '}
                <FormattedMessage id="global.and_i_agree" />{' '}
                <a
                  href={translation === 'pl' ? require('../../../Assets/file/privacy_policy.pdf') : require('../../../Assets/file/privacy_policy en.pdf')}
                  target="_blank"
                  rel="noopener noreferrer">
                  <FormattedMessage id="global.privacy_policy" />
                </a>{' '}
              </p>
            }
            name="acceptance"
            component={Checkbox}
            errors={errors}
          />

          <Button type="submit" label={<FormattedMessage id="global.register" />} classNames="btn--blue login-page__submit" iconAlt="icon product" />
          <p className="login-page__register login-page__register--outside">
            <FormattedMessage id="global.do_you_have_account" />{' '}
            <p onClick={() => history.push(ROUTES.LOGIN)}>
              <FormattedMessage id="global.login.sign_in" />
            </p>
          </p>
        </form>
      )}
    </Formik>
  );
};
export default RegisterForm;
