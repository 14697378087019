import React from 'react';
import moment from 'moment';

const dateNextPayments = (trial) => {
  const nextPayment = trial
    ? moment(trial).format('DD.MM.YYYY') // One day after trial ends
    : moment().add(1, 'month').startOf('month').format('DD.MM.YYYY'); // First day of next month

  // const firstDayNextMonth = moment(startOfMonth, 'DD.MM.YYYY').add(1, 'months').format('DD.MM.YYYY');
  return (
    <div>
      <p>{nextPayment}</p>
    </div>
  );
};

export default dateNextPayments;
