import React, { useState, useEffect } from 'react';
import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';
import Button from 'Shared/components/button/Button';
import ChevronBlack from 'Assets/Image/Icon/icon_chevron_black.svg';
import { FormattedMessage } from 'react-intl';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PaymentMethodsList from '../PaymentMethodsList';

type P = {
  comeBack?: any;
  paymentMethods: any;
  addPaymentMethod: (data) => any;
  deletePaymentMethod: (id) => any;
};

const PaymentUser = ({ comeBack, paymentMethods, addPaymentMethod, deletePaymentMethod }: P) => {
  const [card, setCard] = useState('');
  useEffect(() => {
    setCard(paymentMethods.length ? 'has' : 'none');
  }, [paymentMethods]);
  const [step, setStep] = useState('');
  const [alert, setAlert] = useState('');
  const [activeCard, setActiveCard] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  return (
    <div className="userMenu__content">
      {alert === '' && (
        <>
          <div className="dashboardSidebar__head">
            <section>
              <p
                className={`dashboardSidebar__${
                  step === 'add-card'
                    ? 'headingGray'
                    : 'heading' && card === 'edit-card'
                    ? 'headingGray'
                    : 'heading' && card === 'remove-card'
                    ? 'headingGray'
                    : 'heading'
                }`}>
                <FormattedMessage id="global.menu_dashboard.payment" />
              </p>
              {step === 'add-card' ? <p className="dashboardSidebar__heading">&nbsp; {<FormattedMessage id="global.payments.add_card" />}</p> : null}
              {step === 'edit-card' ? (
                <p className="dashboardSidebar__heading">&nbsp; {<FormattedMessage id="global.payments.change_card" />}</p>
              ) : null}
              {step === 'remove-card' ? (
                <p className="dashboardSidebar__heading">&nbsp; {<FormattedMessage id="global.payments.remove_card" />}</p>
              ) : null}
            </section>
            <div className="dashboardSidebar__payments-card">
              {step === 'remove-card' ? (
                <>
                  <p className="dashboardSidebar__payments-card-width">
                    <FormattedMessage id="global.payments.realy_delete" />
                  </p>
                  <div className="accConfig__bottom--upper">
                    <span>
                      <SVG className="svg--card" type={SVG_TYPE.CARD} />
                      **** {activeCard.card.last4}
                    </span>
                  </div>
                </>
              ) : (
                <p className={`${card === 'none' && 'dashboardSidebar__payments-card-width'}`}>
                  <FormattedMessage id="global.payments.hooked_up" />
                </p>
              )}

              {card === 'none' ? (
                <>
                  <section className="dashboardSidebar__payments-card--addcard">
                    <p onClick={() => setStep('add-card')}>
                      <SVG className="svg--plus svg--plus--white" type={SVG_TYPE.PLUS} />
                      <FormattedMessage id="global.payments.add_card" />
                    </p>
                  </section>
                  <div className="dashboardSidebar__payments--warning">
                    <SVG className="svg--exclamation-circle--red" type={SVG_TYPE.EXCLAMATION_CIRCLE} />
                    <p>
                      <FormattedMessage id="global.payments.no_card" />
                    </p>
                  </div>
                </>
              ) : null}
              {card === 'has' || card === 'edit-card' ? (
                <PaymentMethodsList paymentMethods={paymentMethods} setCard={setCard} setStep={setStep} card={card} setActiveCard={setActiveCard} />
              ) : null}
            </div>
            {card === 'has' && step === '' && (
              <>
                {/* <div className="dashboardSidebar__item-wrapper">
                  <p className="dashboardSidebar__descript">
                    <FormattedMessage id="global.payments.payment_history" />
                  </p>
                  <div className="dashboardSidebar__item">
                    <p className="dashboardSidebar__item-heading">
                      <FormattedMessage id="global.payments.payment" />
                    </p>
                    <p className="dashboardSidebar__item-paragraph">
                      Corgi <b style={{ textTransform: 'capitalize' }}>{loggedUser.company.preOrder.product_key}</b>
                    </p>
                    <p className="dashboardSidebar__item-price">-00.00 zł</p>
                    <SVG className="svg--card dashboardSidebar__item-icon" type={SVG_TYPE.CARD} />
                  </div>
                </div>
                <div className="dashboardSidebar__item-wrapper">
                  <div className="dashboardSidebar__item">
                    <p className="dashboardSidebar__item-heading">
                      <FormattedMessage id="global.payments.payment" />
                    </p>
                    <p className="dashboardSidebar__item-paragraph">
                      Corgi <b style={{ textTransform: 'capitalize' }}>{loggedUser.company.preOrder.product_key}</b>
                    </p>
                    <p className="dashboardSidebar__item-price">
                      {loggedUser.company.preOrder.product_key == 'basic' && '-39.00 zł'}
                      {loggedUser.company.preOrder.product_key == 'premium' && '-89.00 zł'}
                      {loggedUser.company.preOrder.product_key == 'pro' && '-279.00zł'}
                    </p>
                    <SVG className="svg--card dashboardSidebar__item-icon" type={SVG_TYPE.CARD} />
                  </div>
                </div> */}
                <div className="userMenu__payment-back userMenu__form-button">
                  <div>
                    <Button
                      onClick={() => comeBack()}
                      label={<FormattedMessage id="global.back" />}
                      classNames="btn--gray"
                      iconAlt="icon product"
                      iconPlacement="left"
                      iconImg={ChevronBlack}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {(step === 'add-card' || step === 'edit-card') && (
            <>
              <div className="cardContent">
                <div className="userMenu__form">
                  <div className="form__field form__field--text1">
                    <label className="field-label">{<FormattedMessage id="global.home_page.packet_card_number" />}</label>
                    <CardElement options={{ hidePostalCode: true }} />
                  </div>
                </div>
              </div>
              <div className="userMenu__form-button">
                <div>
                  <Button
                    onClick={() => comeBack()}
                    label={<FormattedMessage id="global.back" />}
                    classNames="btn--gray"
                    iconAlt="icon product"
                    iconPlacement="left"
                    iconImg={ChevronBlack}
                  />
                </div>
                <Button
                  type="submit"
                  onClick={async () => {
                    if (!stripe || !elements) {
                      return;
                    }
                    const test = await elements.getElement(CardElement);
                    const { token, error } = await stripe.createToken(test);

                    if (error === undefined) {
                      const { id, last4 } = await addPaymentMethod({ stripe_pm_token: { id: token.id } });
                      setActiveCard({
                        id: id,
                        card: {
                          last4: last4,
                        },
                      });
                      setAlert('addsCard');
                    }
                  }}
                  label={<FormattedMessage id="global.payments.save_card" />}
                  classNames="btn--black"
                  iconAlt="icon product"
                />
              </div>
            </>
          )}
          {step === 'remove-card' && (
            <div className="cardContent">
              <div className="userMenu__form-button userMenu__form-button-relative">
                <div>
                  <Button
                    onClick={() => {
                      setStep('edit-card');
                      setCard('edit-card');
                    }}
                    label={<FormattedMessage id="global.payments.cancel" />}
                    classNames="btn--gray"
                  />
                </div>
                <Button
                  type="submit"
                  onClick={async () => {
                    await deletePaymentMethod(activeCard.id);
                    setAlert('removeCard');
                  }}
                  label={<FormattedMessage id="global.payments.remove_card" />}
                  classNames="btn--black"
                  iconAlt="icon product"
                />
              </div>
            </div>
          )}
        </>
      )}
      {(alert === 'addsCard' || alert === 'removeCard') && (
        <div className="accConfig">
          <div className="accConfig__top">
            <div>
              <SVG className={`${alert === 'addsCard' ? 'svg--accept' : 'svg--accept-brown'}`} type={SVG_TYPE.ACCEPT_GREEN} />
              <p className={`accConfig--heading ${alert === 'addsCard' ? '' : 'accConfig--heading-orange'}`}>
                {alert === 'addsCard' ? (
                  <FormattedMessage id="global.payments.adding_card" />
                ) : (
                  <FormattedMessage id="global.payments.deleting_card" />
                )}
              </p>
            </div>
          </div>
          <div className="accConfig__bottom">
            <div className="accConfig__bottom--upper">
              <span>
                <SVG className="svg--card" type={SVG_TYPE.CARD} />
                **** {activeCard.card.last4}
              </span>
            </div>
            <div className="accConfig__bottom-button">
              <Button
                onClick={() => {
                  setAlert('');
                  setStep('');
                }}
                type="submit"
                label="Ok"
                classNames="btn--black"
                iconAlt="icon product"
              />
            </div>
          </div>
        </div>
      )}
      {card === 'edit-card' || card === 'remove-card' || step === 'add-card' || alert === 'removeCard' ? (
        ''
      ) : (
        <div className="userMenu__form-button">
          <div>
            <Button
              onClick={() => comeBack()}
              label={<FormattedMessage id="global.back" />}
              classNames="btn--gray"
              iconAlt="icon product"
              iconPlacement="left"
              iconImg={ChevronBlack}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentUser;
