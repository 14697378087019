import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import SVG from '../SVG';
import { SVG_TYPE } from 'Shared/enums';

interface ToastProps {
  message: string;
  type: 'success' | 'error' | 'loading' | 'close';
  id: string;
}

const Toast = ({ message, type, id }: ToastProps) => {
  const handleShowToast = () => {
    switch (type) {
      case 'success':
        toast.update(id, {
          render: (
            <div>
              <h2 className="toast-title toast-title--success">
                <FormattedMessage id="global.toast_succes" />
              </h2>
              <p>
                <FormattedMessage id={message} />
              </p>
            </div>
          ),
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          closeButton: true,
          autoClose: 3000,
          // icon: <SVG type={SVG_TYPE.PASSED} />,
        });
        break;
      case 'error':
        toast.update(id, {
          render: (
            <div>
              <h2 className="toast-title toast-title--failure">
                <FormattedMessage id="global.toast_failure" />
              </h2>
              <p>
                <FormattedMessage id={message} />
              </p>
            </div>
          ),
          type: toast.TYPE.ERROR,
          isLoading: false,
          closeButton: true,
          autoClose: 3000,
          icon: <SVG type={SVG_TYPE.CLOSE_CIRCLE_FILL} variant={SVG_TYPE.CLOSE_CIRCLE_FILL} />,
        });
        break;
      case 'loading':
        toast.loading(<FormattedMessage id={message} />, { toastId: id });
        break;
      case 'close':
        toast.dismiss(id);
        break;
    }
  };

  return <>{handleShowToast()}</>;
};

export default Toast;
