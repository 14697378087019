import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ROUTES_ADMIN_PANEL } from 'Shared/enums';
import { history } from 'Store/Reducers/RootReducer';

type P = {
  //   openMenuFn: boolean;
  logout: () => void;
  close?: any;
  selectMenuFn?: any;
  selectActive?: any;
  setAppTranslation: any;
  translation: any;
  location?: any;
};
const AdminMenu = ({ selectMenuFn, selectActive, logout, location }: P) => {
  const [activeClass, setActiveClass] = useState(false);

  function closeMenu() {
    document.getElementById('menu').click();
  }

  return (
    <ul>
      <li>
        <p
          className={`${activeClass && 'active-menu'} ${location === ROUTES_ADMIN_PANEL.PREORDER && 'active-menu'} ${
            location === ROUTES_ADMIN_PANEL.MAILING && 'active-menu'
          }`}
          onClick={() => {
            closeMenu();
            setActiveClass(true);
            history.push(ROUTES_ADMIN_PANEL.PREORDER);
          }}>
          Klienci
        </p>
      </li>
      <li>
        <p
          className={`${selectActive === 'changePassword' ? 'active-menu' : ''}`}
          onClick={() => {
            selectMenuFn('changePassword');
            setActiveClass(false);
          }}>
          <FormattedMessage id="global.menu_dashboard.change_password" />
        </p>
      </li>
      <p onClick={logout} className="userMenu---logout">
        <FormattedMessage id="global.menu_dashboard.log_out" />
      </p>
    </ul>
  );
};

export default AdminMenu;
