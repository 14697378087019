import React from 'react';
import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';
import Button from 'Shared/components/button/Button';
import ChevronBlack from 'Assets/Image/Icon/icon_chevron_black.svg';
import { FormattedMessage } from 'react-intl';

type P = {
  comeBack?: any;
};

const HistoryEvents = ({ comeBack }: P) => (
  <>
    <div className="dashboardSidebar__head">
      <p className="dashboardSidebar__heading">Historia zdarzeń</p>
      <p className="dashboardSidebar__descript">3 nowe</p>
      <SVG className=" dashboardSidebar__head-img" type={SVG_TYPE.TIME_BACKUP} />
    </div>
    <div className="dashboardSidebar__item-wrapper">
      <p className="dashboardSidebar__descript">25 listopada</p>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
    </div>

    <div className="dashboardSidebar__item-wrapper">
      <p className="dashboardSidebar__descript">29 listopada</p>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
      <div className="dashboardSidebar__item">
        <p className="dashboardSidebar__item-heading">Utworzono backup</p>
        <p className="dashboardSidebar__item-paragraph">strona carsmile.pl</p>
        <p className="dashboardSidebar__item-time">12:34</p>
        <SVG className="svg--backup dashboardSidebar__item-icon" type={SVG_TYPE.BACKUP} />
      </div>
    </div>
    <div className="userMenu__form-button">
      <div className="btn--mobile">
        <Button
          type="submit"
          onClick={() => comeBack()}
          iconPlacement="left"
          iconImg={ChevronBlack}
          label={<FormattedMessage id="global.back" />}
          classNames="btn--gray"
          iconAlt="icon product"
        />
      </div>
    </div>
  </>
);

export default HistoryEvents;
