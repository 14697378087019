import * as AuthModule from 'Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import * as AuthActionModel from '../Models/AuthActionModel';
import { Auth } from '../EnumTypes/ActionEnumTypes';
// import { SVG } from 'Shared/Components';
// import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Auth.LOG_IN_INIT,
});

export const success = (authData: AuthModule.Types.LogInResponse): AuthActionModel.LoginSuccess => ({
  type: Auth.LOG_IN_SUCCESS,
  payload: {
    authData,
  },
});

const failure = (): AuthActionModel.LoginFailure => ({
  type: Auth.LOG_IN_FAILURE,
});

const hiddenLogin = (email: string, password: string) => async (dispatch) => {
  dispatch(init());
  try {
    const response = await AuthModule.Connector.login(email, password);
    dispatch(success(response.data));
  } catch (err: any) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());

    return err.response;
  }
};

export default hiddenLogin;
