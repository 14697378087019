import { User } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: User.SET_ACTIVE_WEBSITE_INIT,
});

const success = (data) => ({
  type: User.SET_ACTIVE_WEBSITE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.SET_ACTIVE_WEBSITE_FAILURE,
});

const setActiveWebsite = (data) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(success(data));
  } catch (err: any) {
    console.log(err.response);
    dispatch(failure());
  }
};

export default setActiveWebsite;
