import React from 'react';
import ReactDOM from 'react-dom';
import 'Assets/Sass/style.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// Check if the service worker can be found. If it can, we delete it
serviceWorker.remove();
