import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';
import { bindActionCreators } from 'redux';
import Account from './Main';
import { RootState } from 'Store/Reducers/RootReducer';
import { Actions, Selectors } from 'Modules/UserModule';
import { Actions as AddWebsiteActions, Selectors as AddWebsiteSelectors } from 'Modules/AddWebsiteModule';
import { Selectors as WebsitesSelectors } from 'Modules/WebsiteModule';
import { AddWebsiteDataType, CreateUserType, WebsiteType } from 'Shared/models/AddWebsiteModel';
import { Selectors as PaymentSelector } from 'Modules/PaymentModule';
import * as AuthModule from 'Modules/AuthModule';

type ConnectedP = {
  loggedUser: any;
  // loaderVisible: any;
  history?: any;
  websitesPartners: any;
  clientsLists: any;
  balance: any;
  userWebsites: any;
  formData: AddWebsiteDataType;
  authorizedUser: any;
  getMaxStep: number;
  stripePrice: any;
};

type DispatchedP = {
  actions: {
    checkIsWordpress: (url) => any;
    addWebsiteFormChange: (data) => any;
    createUser: (data: CreateUserType) => any;
    hiddenLogin: (email: string, password: string) => any;
    addWebsite: (data: WebsiteType) => any;
    getAuthorizedUser: () => any;
    getPaymentMethods: () => any;
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      checkIsWordpress: (url) => Actions.checkIsWordpress(url),
      addWebsiteFormChange: (data) => AddWebsiteActions.addWebsiteFormChange(data),
      createUser: (data: CreateUserType) => AddWebsiteActions.createUser(data),
      addWebsite: (data: WebsiteType) => AddWebsiteActions.addWebsite(data),
      hiddenLogin: (email: string, password: string) => AuthModule.Actions.hiddenLogin(email, password),
      getAuthorizedUser: () => Actions.getAuthorizedUser(),
      getPaymentMethods: () => Actions.getPaymentMethods(),
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  loggedUser: Selectors.getLoggedUser(state),
  clientsLists: Selectors.getClientsLists(state),
  websitesPartners: WebsitesSelectors.getWebsitesPartners(state),
  // loaderVisible: ViewManagementSelectors.loaderVisible(state),
  balance: Selectors.getPartnerBalance(state),
  userWebsites: Selectors.getUserWebsites(state),
  formData: AddWebsiteSelectors.getAddWebsiteFormData(state),
  getMaxStep: AddWebsiteSelectors.getMaxStep(state),
  authorizedUser: AuthModule.Selectors.authorizedUser(state),
  stripePrice: PaymentSelector.getStripePrice(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Account));
