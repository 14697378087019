import React from 'react';
import View500 from 'Assets/Image/View500.svg';
import { FormattedMessage } from 'react-intl';

type P = {
  location: any;
};
const Page500 = () => {
  return (
    <>
      <div className="page404__wrapper">
        <div className="page500__content">
          <img src={View500} alt="widok 500" />
          <div className="page500__messages">
            <p className="page500__messages--paragraph">
              <FormattedMessage id="global.page500_message1" />
            </p>
            <p className="page500__messages--paragraph">
              <FormattedMessage id="global.page500_message2" />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page500;
