import React from 'react';
import Button from 'Shared/components/button/Button';
import ChevronWhite from '../../Assets/Image/Icon/icon_chevron_white.svg';
import ButtonCorgi from 'Assets/Image/buttonCorgi.svg';
import Packets from 'Assets/Image/packets.png';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTES } from 'Shared/enums';
import { stripePricing } from 'Shared/utils';
import { MainType } from './Container';

type P = {};
const WhyCorgi = ({ intl: { formatMessage } }: MainType) => {
  return (
    <div className="page-corgi">
      <div className="container number__wrapper">
        <h1>
          <FormattedMessage id="global.why_corgi.heading_site" />
        </h1>
        <div className="number number_1">
          <div className="number__numb ">
            <p className="number__numb-1">1.</p>
          </div>
          <div className="number__content">
            <h3>
              <FormattedMessage id="global.why_corgi.box_1_head" />
            </h3>
            <p>
              <FormattedMessage id="global.why_corgi.box_1_descript_bold" />
            </p>
            <span>
              <FormattedMessage id="global.why_corgi.box_1_descript_normal" />
            </span>
            <div className="number__list">
              <ul>
                <li>
                  <FormattedMessage id="global.why_corgi.box_1_list_1" />
                </li>
                <li>
                  <FormattedMessage id="global.why_corgi.box_1_list_2" />
                </li>
              </ul>
              <ul>
                <li>
                  <FormattedMessage id="global.why_corgi.box_1_list_3" />
                </li>
                <li>
                  <FormattedMessage id="global.why_corgi.box_1_list_4" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="number number_2">
          <div className="number__numb">
            <p>2.</p>
          </div>
          <div className="number__content">
            <h3>
              <FormattedMessage id="global.why_corgi.box_2_head" />
            </h3>
            <p>
              <FormattedMessage id="global.why_corgi.box_2_descript_bold" />
            </p>
            <span>
              <FormattedMessage id="global.why_corgi.box_2_descript_normal" />
            </span>
            <div className="number__list">
              <ul>
                <li>
                  <FormattedMessage id="global.why_corgi.box_2_list_1" />
                </li>
                <li>
                  <FormattedMessage id="global.why_corgi.box_2_list_2" />
                </li>
              </ul>
              <ul>
                <li>
                  <FormattedMessage id="global.why_corgi.box_2_list_3" />
                </li>
                <li>
                  <FormattedMessage id="global.why_corgi.box_2_list_4" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="number number_3">
          <div className="number__numb">
            <p>3.</p>
          </div>
          <div className="number__content">
            <h3>
              <FormattedMessage id="global.why_corgi.box_3_head" />
            </h3>
            <p>
              <FormattedMessage id="global.why_corgi.box_3_descript_bold" />
            </p>
            <span>
              <FormattedMessage id="global.why_corgi.box_3_descript_normal" />
            </span>
            <div className="number__list">
              <ul>
                <li>
                  <FormattedMessage id="global.why_corgi.box_3_list_1" />
                </li>
                <li>
                  <FormattedMessage id="global.why_corgi.box_3_list_2" />
                </li>
              </ul>
              <ul>
                <li>
                  <FormattedMessage id="global.why_corgi.box_3_list_3" />
                </li>
                <li>
                  <FormattedMessage id="global.why_corgi.box_3_list_4" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="number number_4">
          <div className="number__numb ">
            <p className="number__numb-4">4.</p>
          </div>
          <div className="number__content">
            <h3>
              <FormattedMessage id="global.why_corgi.box_4_head" />
            </h3>
            <p>
              <FormattedMessage id="global.why_corgi.box_4_descript_bold" />
            </p>
            <span>
              <FormattedMessage id="global.why_corgi.box_4_descript_normal" />
            </span>
            <div className="number__list">
              <ul>
                <li>
                  <FormattedMessage id="global.why_corgi.box_4_list_1" />
                </li>
                <li>
                  <FormattedMessage id="global.why_corgi.box_4_list_2" />
                </li>
              </ul>
              <ul>
                <li>
                  <FormattedMessage id="global.why_corgi.box_4_list_3" />
                </li>
                <li>
                  <FormattedMessage id="global.why_corgi.box_4_list_4" />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="patrol__wrapper">
          <div className="patrol">
            <div className="patrol__packet">
              <img src={ButtonCorgi} className="patrol__packet-img" />
              <Link to={ROUTES.ABOUT_CORGI}>
                <Button
                  type="submit"
                  label={<FormattedMessage id="global.why_corgi.patrol_btn" />}
                  classNames="btn--blue"
                  iconImg={ChevronWhite}
                  iconAlt="icon product"
                />
              </Link>
            </div>
            <div className="number__content">
              <h3>
                <FormattedMessage id="global.why_corgi.patrol_head" />
              </h3>
              <p>
                <FormattedMessage id="global.why_corgi.patrol_descript_bold" />
              </p>
              <span>
                <FormattedMessage id="global.why_corgi.patrol_descript_normal" />
              </span>
              <Link to={ROUTES.ABOUT_CORGI}>
                <Button
                  type="submit"
                  label={<FormattedMessage id="global.why_corgi.patrol_btn" />}
                  classNames="btn--blue"
                  iconImg={ChevronWhite}
                  iconAlt="icon product"
                />
              </Link>
            </div>
          </div>

          <div className="patrol">
            <div className="patrol__packet patrol__packet--button patrol__packet-img-second">
              <img src={Packets} />
              <Link to="/#pakiety">
                <Button
                  type="submit"
                  onClick={() => console.log('test')}
                  label={`${formatMessage({ id: 'global.from' })} ${stripePricing().basic} ${stripePricing().currencies}`}
                  classNames="btn--blue "
                  iconImg={ChevronWhite}
                  iconAlt="icon product"
                />
              </Link>
              <section className="patrol__packet-deskt">
                <FormattedMessage id="global.why_corgi.quality_packet" />
              </section>
            </div>
            <div className="number__content">
              <h3>
                <FormattedMessage id="global.why_corgi.quality_head" />
              </h3>
              <p>
                <FormattedMessage id="global.why_corgi.quality_descript_bold" />
              </p>
              <span>
                <FormattedMessage id="global.why_corgi.quality_descript_normal" />
              </span>
              <Link to="/#pakiety">
                <Button
                  type="submit"
                  onClick={() => console.log('test')}
                  label={`${formatMessage({ id: 'global.from' })} ${stripePricing().basic} ${stripePricing().currencies}`}
                  classNames="btn--blue"
                  iconImg={ChevronWhite}
                  iconAlt="icon product"
                />
              </Link>
              <section className="patrol__packet-mobile">
                <FormattedMessage id="global.why_corgi.quality_packet" />
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyCorgi;
