import React from 'react';
import ChevronBlack from 'Assets/Image/Icon/icon_chevron_black.svg';
import { Button } from 'Shared/components';
import { ROUTES } from 'Shared/enums';
import { Link } from 'react-router-dom';
import { history } from 'Store/Reducers/RootReducer';
import { FormattedMessage } from 'react-intl';

const ComeBack = ({ partner, dashboard, uuid, clients }: any) => {
  return (
    <>
      {clients && (
        <Link to={ROUTES.CLIENTS} className="back back--desktop">
          &lt; Wszyscy klienci
        </Link>
      )}
      {partner && (
        <Link to={ROUTES.PARTNER} className="back back--desktop">
          &lt; <FormattedMessage id="global.program_partner" />
        </Link>
      )}
      {dashboard === 'single' && (
        <Link to={ROUTES.SINGLE_WEBSITE + '?id=' + uuid} className="back back--desktop">
          &lt; <FormattedMessage id="global.return_website" />
        </Link>
      )}
      {dashboard === 'all' && (
        <Link to={ROUTES.DASHBOARD} className="back back--desktop">
          &lt; <FormattedMessage id="global.return_website" />
        </Link>
      )}
      {dashboard === 'clients' && (
        <Link to={{ pathname: ROUTES.DASHBOARD, state: true }} className="back back--desktop">
          &lt; <FormattedMessage id="global.return_website" />
        </Link>
      )}
      <div className="back back--mobile">
        <Button
          onClick={() => {
            dashboard == 'single' ? history.push(ROUTES.SINGLE_WEBSITE + '?id=' + uuid) : history.push(ROUTES.DASHBOARD);
            partner && history.push(ROUTES.PARTNER);
            clients && history.push(ROUTES.CLIENTS);
          }}
          label={<FormattedMessage id="global.back" />}
          classNames="btn--gray"
          iconAlt="icon product"
          iconPlacement="left"
          iconImg={ChevronBlack}
        />
      </div>
    </>
  );
};

export default ComeBack;
