import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import * as fromUserReducer from './UserReducer';
import * as fromWebsiteReducer from './WebsiteReducer';
import * as fromNotificationReducer from './NotificationReducer';
import * as fromPaymentReducer from './PaymentReducer';
import * as fromBackupsReducer from './BackupsReducer';
import * as fromUpdatesReducer from './UpdatesReducer';
import * as fromAuthReducer from './AuthReducer';
import * as fromViewManagementReducer from './ViewManagementReducer';
import * as fromAddWebsiteReducer from './AddWebsiteReducer';
import { createBrowserHistory } from 'history';

export type RootState = {
  router: any;
  userStore: fromUserReducer.State;
  authStore: fromAuthReducer.State;
  websiteStore: fromWebsiteReducer.State;
  notificationStore: fromNotificationReducer.State;
  paymentStore: fromPaymentReducer.State;
  backupsStore: fromBackupsReducer.State;
  updatesStore: fromUpdatesReducer.State;
  viewManagementStore: fromViewManagementReducer.State;
  addWebsiteStore: fromAddWebsiteReducer.State;
};

export const history = createBrowserHistory();

const rootReducer = combineReducers<RootState>({
  router: connectRouter(history),
  userStore: fromUserReducer.userReducer,
  authStore: fromAuthReducer.authReducer,
  notificationStore: fromNotificationReducer.notificationReducer,
  paymentStore: fromPaymentReducer.paymentReducer,
  backupsStore: fromBackupsReducer.backupsReducer,
  updatesStore: fromUpdatesReducer.updatesReducer,
  viewManagementStore: fromViewManagementReducer.viewManagementReducer,
  websiteStore: fromWebsiteReducer.websiteReducer,
  addWebsiteStore: fromAddWebsiteReducer.addWebsiteReducer,
} as any);


const appReducer = (state: RootState, action) => {
  if (action.type === 'LOG_OUT_SUCCESS') {
  }
  return rootReducer(state, action);
};

export default appReducer;
