// import { Connector } from 'Modules/PaymentModule';
import { Payment } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: Payment.GET_STRIPE_PRICE_INIT,
});

const success = (data) => ({
  type: Payment.GET_STRIPE_PRICE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Payment.GET_STRIPE_PRICE_FAILURE,
});

const getPayment = () => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    dispatch(Actions.showLoader());
    const response = await fetch(
      `https://merchant-ui-api.stripe.com/pricing-table/${process.env.REACT_APP_STRIPE_PRICING_TABLE}?key=${process.env.REACT_APP_STRIPE_KEY}`,
    );
    const data = await response.json();
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideLoader());
    return response;
  } catch (err) {
    dispatch(Actions.hideLoader());
    dispatch(failure());
  }
};

export default getPayment;
