import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../button/Button';
import { Locale, Modal } from 'Shared/components/';
import ContactForm from '../../../Components/ContactForm/Container';
import { FormattedMessage } from 'react-intl';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';
import lonelyLetters from 'Shared/utils/lonelyLetters';

type P = {
  openModal: () => void;
  closeModal: () => void;
  history: any;
};

class Footer extends React.Component<P, any> {
  constructor(props) {
    super(props);
    this.state = {
      isBoxVisible: false,
      isOpened: false,
    };
  }

  toggleBox = () => {
    this.setState((prevState) => ({ isBoxVisible: !prevState.isBoxVisible }));
  };

  openModal = () => {
    this.setState({ isOpened: true });
    this.props.openModal();
  };

  onClose = () => {
    this.setState({ isOpened: false });
    this.props.closeModal();
  };

  render() {
    const { isOpened } = this.state;
    const { history }: any = this.props;
    setTimeout(function () {
      const el = document.querySelectorAll(
        '.packagesTakePreorder--text, .packagesTable--text, .corgi_hacker-text span, .corgi_complex-list li, .number__list li, .AboutUs__person--descript, .popUp--form-contact--description-gray, .faq--descript p, .section__wrapper_content p, .number__content p, .number__content span, .AboutUs__heading-descript, .number__content h3, .number__content p, .number__content span, .section__l p, .section__l h3, .section__security-upper p, .mainHeading .ll',
      );
      lonelyLetters(el);
    }, 100);
    return (
      <>
        <Modal opened={isOpened} onClose={this.onClose}>
          <ContactForm />
        </Modal>
        <div className="footer">
          <div className="footer__content">
            <div className="footer__content--navi">
              <div className="footer__logo">
                <Link to="/">
                  <SVG className="svg--logo-white" type={SVG_TYPE.LOGO} />
                </Link>
              </div>
              <div className="footer__navi">
                <ul className="footer__list">
                  <li className="footer__list-item" onClick={() => history.push(ROUTES.HOW_IT_WORKS)}>
                    <FormattedMessage id="global.how_it_works" />
                  </li>
                  <li className="footer__list-item" onClick={() => history.push(ROUTES.PRICING)}>
                    <FormattedMessage id="global.pricing.pricing" />
                  </li>
                  {/* <li className="footer__list-item" onClick={() => history.push(ROUTES.HOME_PAGE)}>
                    <FormattedMessage id="global.knowlage_base" />
                  </li> */}
                  <li className="footer__list-item" onClick={() => history.push(ROUTES.CORGI_PRO_BONO)}>
                    Corgi Pro bono
                  </li>
                </ul>
                <ul className="footer__list">
                  <a
                    href={Locale() === 'pl' ? require('Assets/file/corgipolitykaprywatności.pdf') : require('Assets/file/privacy_policy en.pdf')}
                    className="footer__list-item"
                    onClick={() => history.push(ROUTES.HOME_PAGE)}>
                    <FormattedMessage id="global.privacy" />
                  </a>
                  <a
                    className="footer__list-item"
                    href={Locale() === 'pl' ? require('Assets/file/regulations_corgi.pdf') : require('Assets/file/terms_of_service.pdf')}>
                    <FormattedMessage id="global.preorder.terms" />
                  </a>
                  <a className="footer__list-item" href={'cookies_policy.pdf'}>
                    <FormattedMessage id="global.cookies_policy" />
                  </a>
                </ul>
              </div>
            </div>
            <div className="footer__contact">
              <SVG className="svg--corgi-premium" type={SVG_TYPE.CORGI_PREMIUM} />
              <a href={`${ROUTES.CONTACT}#form`}>
                <Button onClick={() => null} label={<FormattedMessage id="global.write_us" />} classNames="btn--white-border" />
              </a>
              <div className="footer__socials">
                <a href="https://www.facebook.com/www.corgi.pro" target="_blank" rel="noopener noreferrer">
                  <SVG className="svg--social" type={SVG_TYPE.FACEBOOK} />
                </a>
                <a href="https://www.instagram.com/corgi_pro/" target="_blank" rel="noopener noreferrer">
                  <SVG className="svg--social" type={SVG_TYPE.INSTAGRAM} />
                </a>
                <a href="https://pl.linkedin.com/company/www-corgi-pro" target="_blank" rel="noopener noreferrer">
                  <SVG className="svg--social" type={SVG_TYPE.LINKEDIN} />
                </a>
                <a href="https://www.youtube.com/@corgi_pro" target="_blank" rel="noopener noreferrer">
                  <SVG className="svg--social" type={SVG_TYPE.YOUTUBE} />
                </a>
              </div>
            </div>
          </div>
          <div className="footer__text">
            <p>© 2024 Corgi.pro. All rights reserved.</p>
            <p>
              Website by{' '}
              <a href="https://korg.software/" target="_blank" rel="noopener noreferrer">
                <b>korg.software</b>
              </a>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
