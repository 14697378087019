import React from 'react';
import DashboardSidebar from 'Shared/components/dashboardSidebar/Container';
import { MainType } from './Container';
import SVG from 'Shared/components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import { FormattedMessage } from 'react-intl';

class Soon extends React.Component<MainType, any> {
  async componentDidMount() {
    await this.props.actions.getAuthorizedUser();
  }
  public render() {
    const { loggedUser, translation } = this.props;
    const {
      actions: { setAppTranslation },
    } = this.props;
    if (loggedUser) {
      return (
        <div className="soon__wrapper">
          <div className="soon">
            <div className="soon__content">
              <div className="language-dashboard">
                <select className="language-select" value={translation} onChange={({ currentTarget: { value } }) => setAppTranslation(value)}>
                  <option value="pl">PL</option>
                  <option value="en">EN</option>
                  <option value="de">DE</option>
                </select>
              </div>
              <SVG className="soon__logo" type={SVG_TYPE.LOGO} />
              <div className="soon__l">
                <SVG className="svg svg-" type={SVG_TYPE.SIT_DOWN} />
              </div>
              <div className="soon__r">
                <h1>
                  <FormattedMessage id="global.soon.head" />
                </h1>
                <span>
                  <FormattedMessage id="global.soon.description" />
                </span>
              </div>
            </div>
          </div>
          <DashboardSidebar />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Soon;
