import { store } from 'Store/Store';
import { authorizedUser as getAuthorizedUser } from 'Store/Selectors/AuthSelector';
import { getMyWebsites } from 'Store/Selectors/UserSelector';
import { PRICES } from 'Shared/enums';
import { getCurrency } from 'Shared/utils';

const regularPrice = (packet) => {
  switch (packet) {
    case 'basic': {
      return PRICES[getCurrency()].basic;
    }
    case 'premium': {
      return PRICES[getCurrency()].premium;
    }
    case 'pro': {
      return PRICES[getCurrency()].pro;
    }
  }
};

const partnerPrice = (packet) => {
  switch (packet) {
    case 'basic': {
      return PRICES[getCurrency()].basic_partner;
    }
    case 'premium': {
      return PRICES[getCurrency()].premium_partner;
    }
    case 'pro': {
      return PRICES[getCurrency()].pro_partner;
    }
  }
};

const getDashboardPrice = () => {
  const state = store.getState();
  const company = getAuthorizedUser(state)?.company;
  const websites = getMyWebsites(state);



  const websiteProtected = websites?.reduce((prevValue, currentValue) => (!!currentValue.subscription_uid ? prevValue + 1 : prevValue), 0);
  const regularCosts = websites?.reduce(
    (prevValue: number, currentValue: any) => prevValue + (currentValue.subscription_uid && regularPrice(currentValue?.plan)),
    0,
  );
  const partnerCosts = websites?.reduce(
    (prevValue: number, currentValue: any) => prevValue + (currentValue.subscription_uid && partnerPrice(currentValue?.plan)),
    0,
  );

  if (company?.type === 'partner' && websiteProtected >= 2) {
    return partnerCosts;
  } else {
    return regularCosts;
  }
};

export default getDashboardPrice;
