import React from 'react';
import Krzysiek from 'Assets/Image/person/krzysiek.gif';
import Tomek from 'Assets/Image/person/tomek.gif';
import Mateusz from 'Assets/Image/person/mateusz.gif';
import Daniel from 'Assets/Image/person/daniel.gif';
import Kacper from 'Assets/Image/person/kacper.gif';
import Albert from 'Assets/Image/person/albert.gif';
import Karol from 'Assets/Image/person/karol.gif';
import Oskar from 'Assets/Image/person/oskar.gif';
import Bartek from 'Assets/Image/person/bartek.gif';
import Patrycja from 'Assets/Image/person/patrycja.gif';
import Group from 'Assets/Image/person/group.svg';
import One from 'Assets/Image/person/one.svg';
import Button from 'Shared/components/button/Button';
import ChevronWhite from '../../Assets/Image/Icon/icon_chevron_white.svg';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Table from '../../Assets/Image/table.svg';
// import Premium from '../../Assets/Image/premium.png';
// import CorgiBottom from '../../Assets/Image/corgiline.svg';
import { ROUTES } from 'Shared/enums';
import { Modal } from 'Shared/components/';
import ContactForm from '../../Components/ContactForm/Container';
import { MainType } from './Container';

class AboutUs extends React.Component<MainType, any> {
  constructor(props) {
    super(props);
    this.state = {
      isBoxVisible: false,
      isOpened: false,
    };
  }
  toggleBox = () => {
    this.setState((prevState) => ({ isBoxVisible: !prevState.isBoxVisible }));
  };

  openModal = () => {
    this.setState({ isOpened: true });
    this.props.actions.openModal();
  };
  onClose = () => {
    this.setState({ isOpened: false });
    this.props.actions.closeModal();
  };

  render() {
    const { isOpened } = this.state;
    return (
      <>
        <Modal opened={isOpened} onClose={this.onClose}>
          <ContactForm />
        </Modal>
        <div className="page-corgi AboutUs-wrapper">
          <div className="container AboutUs">
            <h1>
              <FormattedMessage id="global.about_us.team_krk_heading" />
            </h1>
            <p className="AboutUs__heading-descript">
              <FormattedMessage id="global.about_us.team_krk_descript" />
            </p>
            <div className="AboutUs__person--wrapper">
              <div className="AboutUs__person--container">
                <div className="AboutUs__person">
                  <div className="AboutUs__left">
                    <p className="AboutUs__person--name">Krzysztof Organek</p>
                    <p className="AboutUs__person--func">CEO</p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.krzysiek_descript_1" />
                    </p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.krzysiek_descript_2" />
                    </p>
                  </div>
                  <img src={Krzysiek} alt="prezes firmy" className="AboutUs__person--img" />
                  <section className="AboutUs__person--frame">
                    <p className="AboutUs__person--team">
                      <span className="AboutUs__person--team-circle"></span>
                      <FormattedMessage id="global.about_us.team_krk_heading" />
                    </p>
                  </section>
                </div>
              </div>
              <div className="AboutUs__person--container">
                <div className="AboutUs__person">
                  <div className="AboutUs__left">
                    <p className="AboutUs__person--name">Tomasz Organek</p>
                    <p className="AboutUs__person--func">Project Manager</p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.tomek_description_1" />
                    </p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.tomek_description_2" />
                    </p>
                  </div>
                  <img src={Tomek} alt="prezes firmy" className="AboutUs__person--img" />
                  <section className="AboutUs__person--frame">
                    <p className="AboutUs__person--team">
                      <span className="AboutUs__person--team-circle"></span>
                      <FormattedMessage id="global.about_us.team_krk_heading" />
                    </p>
                  </section>
                </div>
              </div>
            </div>
            <div className="AboutUs__person--wrapper">
              <div className="AboutUs__person--container">
                <div className="AboutUs__person">
                  <div className="AboutUs__left">
                    <p className="AboutUs__person--name">Mateusz Zabój</p>
                    <p className="AboutUs__person--func">Full-Stack Developer</p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.mateusz_description_1" />
                    </p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.mateusz_description_2" />
                    </p>
                  </div>
                  <img src={Mateusz} alt="prezes firmy" className="AboutUs__person--img" />
                  <section className="AboutUs__person--frame">
                    <p className="AboutUs__person--team">
                      <span className="AboutUs__person--team-circle"></span>
                      <FormattedMessage id="global.about_us.team_krk_heading" />
                    </p>
                  </section>
                </div>
              </div>
              <div className="AboutUs__person--container">
                <div className="AboutUs__person">
                  <div className="AboutUs__left">
                    <p className="AboutUs__person--name">Daniel Miksa</p>
                    <p className="AboutUs__person--func">DevOps Engineer</p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.daniel_description_1" />
                    </p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.daniel_description_2" />
                    </p>
                  </div>
                  <img src={Daniel} alt="prezes firmy" className="AboutUs__person--img" />
                  <section className="AboutUs__person--frame">
                    <p className="AboutUs__person--team">
                      <span className="AboutUs__person--team-circle"></span>
                      <FormattedMessage id="global.about_us.team_krk_heading" />
                    </p>
                  </section>
                </div>
              </div>
            </div>
            <div className="AboutUs__person--wrapper">
              <div className="AboutUs__person--container">
                <div className="AboutUs__person">
                  <div className="AboutUs__left">
                    <p className="AboutUs__person--name">Bartek Milan</p>
                    <p className="AboutUs__person--func">Junior Frontend Developer</p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.bartek_description_1" />
                    </p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.bartek_description_2" />
                    </p>
                  </div>
                  <img src={Bartek} alt="prezes firmy" className="AboutUs__person--img" />
                  <section className="AboutUs__person--frame">
                    <p className="AboutUs__person--team">
                      <span className="AboutUs__person--team-circle"></span>
                      <FormattedMessage id="global.about_us.team_krk_heading" />
                    </p>
                  </section>
                </div>
              </div>
              <div className="AboutUs__person--container">
                <div className="AboutUs__person">
                  <div className="AboutUs__left">
                    <p className="AboutUs__person--name">Patrycja Golan</p>
                    <p className="AboutUs__person--func">Junior UI/UX Designer</p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.patrycja_description_1" />
                    </p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.patrycja_description_2" />
                    </p>
                  </div>
                  <img src={Patrycja} alt="prezes firmy" className="AboutUs__person--img" />
                  <section className="AboutUs__person--frame">
                    <p className="AboutUs__person--team">
                      <span className="AboutUs__person--team-circle"></span>
                      <FormattedMessage id="global.about_us.team_krk_heading" />
                    </p>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-corgi AboutUs-wrapper AboutUs-wrapper-elk">
          <div className="container AboutUs secondContainer">
            <h1>
              <FormattedMessage id="global.about_us.team_elk_heading" />
            </h1>
            <p className="AboutUs__heading-descript">
              <FormattedMessage id="global.about_us.team_elk_descript" />
            </p>
            <div className="AboutUs__person--wrapper">
              <div className="AboutUs__person--container AboutUs__person--container-elk">
                <div className="AboutUs__person">
                  <div className="AboutUs__left">
                    <p className="AboutUs__person--name">Kacper Wilczewski</p>
                    <p className="AboutUs__person--func">Frontend Developer</p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.kacper_description_1" />
                    </p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.kacper_description_2" />
                    </p>
                  </div>
                  <img src={Kacper} alt="prezes firmy" className="AboutUs__person--img" />
                  <section className="AboutUs__person--frame">
                    <p className="AboutUs__person--team">
                      <span className="AboutUs__person--team-circle"></span>
                      <FormattedMessage id="global.about_us.team_elk_heading" />
                    </p>
                  </section>
                </div>
              </div>
              <div className="AboutUs__person--container AboutUs__person--container-elk">
                <div className="AboutUs__person">
                  <div className="AboutUs__left">
                    <p className="AboutUs__person--name">Oskar Adasiewicz</p>
                    <p className="AboutUs__person--func">Web Developer</p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.oskar_description_1" />
                    </p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.oskar_description_2" />
                    </p>
                  </div>
                  <img src={Oskar} alt="prezes firmy" className="AboutUs__person--img" />
                  <section className="AboutUs__person--frame">
                    <p className="AboutUs__person--team">
                      <span className="AboutUs__person--team-circle"></span>
                      <FormattedMessage id="global.about_us.team_elk_heading" />
                    </p>
                  </section>
                </div>
              </div>
            </div>
            <div className="AboutUs__person--wrapper">
              <div className="AboutUs__person--container AboutUs__person--container-elk">
                <div className="AboutUs__person">
                  <div className="AboutUs__left">
                    <p className="AboutUs__person--name">Karol Sobolewski</p>
                    <p className="AboutUs__person--func">Junior Web Developer</p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.karol_description_1" />
                    </p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.karol_description_2" />
                    </p>
                  </div>
                  <img src={Karol} alt="prezes firmy" className="AboutUs__person--img" />
                  <section className="AboutUs__person--frame">
                    <p className="AboutUs__person--team">
                      <span className="AboutUs__person--team-circle"></span>
                      <FormattedMessage id="global.about_us.team_elk_heading" />
                    </p>
                  </section>
                </div>
              </div>
              <div className="AboutUs__person--container AboutUs__person--container-elk">
                <div className="AboutUs__person">
                  <div className="AboutUs__left">
                    <p className="AboutUs__person--name">Albert Żukowski</p>
                    <p className="AboutUs__person--func">Junior Backend Developer</p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.albert_description_1" />
                    </p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.albert_description_2" />
                    </p>
                  </div>
                  <img src={Albert} alt="prezes firmy" className="AboutUs__person--img" />
                  <section className="AboutUs__person--frame">
                    <p className="AboutUs__person--team">
                      <span className="AboutUs__person--team-circle"></span>
                      <FormattedMessage id="global.about_us.team_elk_heading" />
                    </p>
                  </section>
                </div>
              </div>
            </div>
            <div className="AboutUs__person--wrapper AboutUs__person--wrapper-last">
              <div className="AboutUs__person--container AboutUs__person--container-team">
                <div className="AboutUs__person">
                  <div className="AboutUs__left">
                    <p className="AboutUs__person--name">
                      <FormattedMessage id="global.about_us.team_head" />
                    </p>
                    <p className="AboutUs__person--func">&nbsp;</p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.team_descript_1" />{' '}
                    </p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.team_descript_2" />
                    </p>
                  </div>
                  <img src={Group} alt="prezes firmy" className="AboutUs__person--img" />
                </div>
              </div>

              <div className="AboutUs__person--container AboutUs__person--container-team">
                <div className="AboutUs__person">
                  <div className="AboutUs__left">
                    <p className="AboutUs__person--name">
                      <FormattedMessage id="global.about_us.join_head" />
                    </p>
                    <p className="AboutUs__person--descript">
                      <FormattedMessage id="global.about_us.join_descript_bold" />
                    </p>
                    <Button
                      type="submit"
                      // onClick={this.openModal}
                      label={<FormattedMessage id="global.about_us.join_btn" />}
                      classNames="btn--blue"
                      iconImg={ChevronWhite}
                      iconAlt="icon product"
                      onClick={this.openModal}
                    />
                  </div>
                  <img src={One} alt="prezes firmy" className="AboutUs__person--img" />
                </div>
              </div>
            </div>
          </div>
          <div className="others">
            <div className="others__4You">
              <p className="others__4You--slope"></p>
              <div>
                <div className="others__4You-heading">
                  <h3>
                    <FormattedMessage id="global.about_us.we_are_for_you" />
                  </h3>
                  <p>
                    <FormattedMessage id="global.about_us.we_are_for_you_descript" />
                  </p>
                </div>
                <div className="others__4You-ofert">
                  <div>
                    <h4>
                      <FormattedMessage id="global.about_us.one_team" />
                    </h4>
                    <p>
                      <FormattedMessage id="global.about_us.one_team_descript" />
                    </p>
                    <Link to={ROUTES.FAQ}>
                      <Button
                        type="submit"
                        label={<FormattedMessage id="global.check_faq" />}
                        classNames="btn--blue"
                        iconImg={ChevronWhite}
                        iconAlt="icon product"
                      />
                    </Link>
                  </div>
                  <img className="others__4You-img" src={Table} alt="background" />
                </div>
              </div>
            </div>
            {/* <div className="others__contact section__wrapper">
              <div className="others__contact--content">
                <h4 className="others__contact--header">
                  <FormattedMessage id="global.about_us.contact" />
                </h4>
                <p className="others__contact--descript">
                  <FormattedMessage id="global.about_us.contact_descript" />
                </p>
                <div className="others__contact--descript-btn">
                  <img src={Premium} alt="ikona premium" />
                  <Button onClick={this.openModal} label={<FormattedMessage id="global.write_us" />} classNames="others__contact--button" />
                </div>
              </div>
            </div>
            <div className="others--corgiLine">
              <img className="others__contact--corgiLine" src={CorgiBottom} alt="grafika corgi linie" />{' '}
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default AboutUs;
