import CURRENCIES from "./currencies";

const PRICES = {
  PLN: {
    currency: CURRENCIES.pl,
    basic_old: Number(process.env.REACT_APP_BASIC_PRICE_OLD_PLN),
    basic: Number(process.env.REACT_APP_BASIC_PRICE_PLN),
    basic_partner: Number(process.env.REACT_APP_PARTNER_BASIC_PRICE_PLN),
    premium_old: Number(process.env.REACT_APP_PREMIUM_PRICE_OLD_PLN),
    premium: Number(process.env.REACT_APP_PREMIUM_PRICE_PLN),
    premium_partner: Number(process.env.REACT_APP_PARTNER_PREMIUM_PRICE_PLN),
    pro_old: Number(process.env.REACT_APP_PRO_PRICE_OLD_PLN),
    pro: Number(process.env.REACT_APP_PRO_PRICE_PLN),
    pro_partner: Number(process.env.REACT_APP_PARTNER_PRO_PRICE_PLN),
  },
  USD: {
    currency: CURRENCIES.en,
    basic_old: Number(process.env.REACT_APP_BASIC_PRICE_OLD_USD),
    basic: Number(process.env.REACT_APP_BASIC_PRICE_USD),
    basic_partner: Number(process.env.REACT_APP_PARTNER_BASIC_PRICE_USD),
    premium_old: Number(process.env.REACT_APP_PREMIUM_PRICE_OLD_USD),
    premium: Number(process.env.REACT_APP_PREMIUM_PRICE_USD),
    premium_partner: Number(process.env.REACT_APP_PARTNER_PREMIUM_PRICE_USD),
    pro_old: Number(process.env.REACT_APP_PRO_PRICE_OLD_USD),
    pro: Number(process.env.REACT_APP_PRO_PRICE_USD),
    pro_partner: Number(process.env.REACT_APP_PARTNER_PRO_PRICE_USD),
  },
  EUR: {
    currency: CURRENCIES.de,
    basic_old: Number(process.env.REACT_APP_BASIC_PRICE_OLD_EUR),
    basic: Number(process.env.REACT_APP_BASIC_PRICE_EUR),
    basic_partner: Number(process.env.REACT_APP_PARTNER_BASIC_PRICE_EUR),
    premium_old: Number(process.env.REACT_APP_PREMIUM_PRICE_OLD_EUR),
    premium: Number(process.env.REACT_APP_PREMIUM_PRICE_EUR),
    premium_partner: Number(process.env.REACT_APP_PARTNER_PREMIUM_PRICE_EUR),
    pro_old: Number(process.env.REACT_APP_PRO_PRICE_OLD_EUR),
    pro: Number(process.env.REACT_APP_PRO_PRICE_EUR),
    pro_partner: Number(process.env.REACT_APP_PARTNER_PRO_PRICE_EUR),
  }
}

export default PRICES;