import React from 'react';
import { FormattedMessage } from 'react-intl';

type P = {
  //   openMenuFn: boolean;
  logout: () => void;
  close?: any;
  selectMenuFn?: any;
  selectActive?: any;
  setAppTranslation: any;
  translation: any;
  loggedUser: any;
  paymentMethods?: any;
};
const UserMenu = ({ setAppTranslation, translation, selectMenuFn, selectActive, logout, loggedUser, paymentMethods }: P) => {
  let validMyData = '';
  if (
    loggedUser.company.phone_prefix === null ||
    loggedUser.company.phone_number === null ||
    loggedUser.company.name === null ||
    loggedUser.company.vat_id === null ||
    loggedUser.company.address === null ||
    loggedUser.company.zip_code === null ||
    loggedUser.company.city == null ||
    loggedUser.company.country == null
  ) {
    validMyData = 'error';
  }
  let validPayments = '';
  if (paymentMethods.length === 0) {
    validPayments = 'error';
  }

  return (
    <ul>
      <li>
        <p
          className={`${selectActive === 'yourSite' ? 'active-menu' : ''} userMenu--inActive`}
          onClick={() => {
            selectMenuFn('yourSite');
          }}>
          <FormattedMessage id="global.menu_dashboard.your_site" />
          <span className="userMenu--inActive-soon">
            <FormattedMessage id="global.menu_dashboard.soon" />
          </span>
        </p>
      </li>
      <li>
        <p
          className={`${selectActive === 'payments' ? 'active-menu' : ''}`}
          onClick={() => {
            selectMenuFn('payments');
          }}>
          <FormattedMessage id="global.menu_dashboard.payment" />{' '}
          {validPayments === 'error' ? <p className="menuErrors">brak formy płatności</p> : ''}
        </p>
      </li>
      <li>
        <p
          className={`${selectActive === 'myData' ? 'active-menu' : ''}`}
          onClick={() => {
            selectMenuFn('myData');
          }}>
          <FormattedMessage id="global.menu_dashboard.my_data" /> {validMyData === 'error' ? <p className="menuErrors">brakujące dane</p> : ''}
        </p>
      </li>
      <li>
        <p
          className={`${selectActive === 'changePassword' ? 'active-menu' : ''}`}
          onClick={() => {
            selectMenuFn('changePassword');
          }}>
          <FormattedMessage id="global.menu_dashboard.change_password" />
        </p>
      </li>
      {selectActive === '' ? (
        <>
          <p onClick={logout} className="userMenu---logout">
            <FormattedMessage id="global.menu_dashboard.log_out" />
          </p>
        </>
      ) : (
        ''
      )}
      <li className="language-dashboard-mobile">
        <div>
          <select className="language-select" value={translation} onChange={({ currentTarget: { value } }) => setAppTranslation(value)}>
            <option value="pl">PL</option>
            <option value="en">EN</option>
            <option value="de">DE</option>
          </select>
        </div>
      </li>

      {/* <li>
            <p
              className={`${selectActive === 'partners' ? 'active-menu' : ''}`}
              onClick={() => {
                selectMenuFn('partners');
                // close();
              }}>
              Zostań partnerem
            </p>
          </li> */}
    </ul>
  );
};

export default UserMenu;
