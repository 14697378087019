import React from 'react';
import { Formik } from 'formik';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

type P = {
  history: any;
  formatMessage: any;
  recoverPassword: (email: string) => any;
  setApiError: (value: boolean) => void;
  email: string;
  setEmail: (value: string) => void;
};

const LinkWasSend = ({ formatMessage, recoverPassword, setApiError, email, setEmail }: P) => {
  const formRef = useRef(null);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        email: '',
      }}
      onSubmit={async () => {
        const err = await recoverPassword(email);
        if (err.status === 401) {
          setApiError(true);
        }
      }}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="login-page__form">
          <span className="login-page__back" onClick={() => setEmail('')}>
            <SVG type={SVG_TYPE.ARROW_LEFT} />
          </span>
          <h1 className="login-page__title">{formatMessage({ id: 'global.link_was_send' })}&nbsp;🚀</h1>
          <h3 className="login-page__sub-title">{formatMessage({ id: 'global.link_was_send_subtitle' })}</h3>
          <p className="login-page__sub-title">{formatMessage({ id: 'global.didnt_recieve_mail' })}</p>

          <Button
            type="submit"
            label={<FormattedMessage id="global.send_link_again" />}
            classNames="btn--blue login-page__submit"
            iconAlt="icon product"
          />
        </form>
      )}
    </Formik>
  );
};
export default LinkWasSend;
