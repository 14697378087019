import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';
import { Toast } from 'Shared/components';

const init = () => ({
  type: User.DELETE_WEBSITE_INIT,
});

const success = (data) => ({
  type: User.DELETE_WEBSITE_SUCCESS,
  payload: { uuid: data },
});

const failure = () => ({
  type: User.DELETE_PAYMENT_METHOD_FAILURE,
});

const deleteWebsite = (uuid) => async (dispatch) => {
  dispatch(init());
  Toast({ message: 'global.apex_no_data', type: 'loading', id: 'deleteWebsite' });
  try {
    dispatch(Actions.showLoader());
    await Connector.deleteWebsite(uuid);
    dispatch(success(uuid));
    dispatch(Actions.hideLoader());

    Toast({ message: 'global.toast.delete_website_success', type: 'success', id: 'deleteWebsite' });
    return true;
  } catch (err) {
    dispatch(Actions.hideLoader());
    dispatch(failure());
    Toast({ message: 'global.toast.delete_website_failure', type: 'error', id: 'deleteWebsite' });
  }
};

export default deleteWebsite;
