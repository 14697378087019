import React, { useEffect, useState } from 'react';
import { MainType } from './Container';
import TomaszOrganekPng from 'Assets/Image/tomasz_organek_blue.webp';
import Button from 'Shared/components/button/Button';
// import Background from '../../Assets/Image/background_hp.svg';
import { FormattedMessage } from 'react-intl';
import { PACKET_TYPE, SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';
import MetaTags from 'react-meta-tags';
import { InlineWidget } from 'react-calendly';
import FadeInOnScroll from 'Components/FadeInOnScroll/FadeInOnScroll';
import { isMobile, stripePricing } from 'Shared/utils';
import SlideOnScroll from 'Components/SlideOnScroll';
import CorgiGroupSVG from 'Assets/Image/svg/CorgiGroupSVG';
import PacketsBasicSVG from 'Assets/Image/svg/PacketsBasicSVG';
import { useHistory } from 'react-router-dom';
import ADD_WEB_SITE_ROUTES from 'Screens/AddWebsite/AddWebsiteRoutes';
import CheckSVG from 'Assets/Image/svg/CheckSVG';
import CloseMenuSVG from 'Assets/Image/svg/CloseMenuSVG';
import PacketsPremiumSVG from 'Assets/Image/svg/PacketsPremiumSVG';
import PacketsProSVG from 'Assets/Image/svg/PacketsProSVG';
import { DetailsModal } from 'Shared/modals';
import { Calculator } from './Components';
// import QuestionMarksSVG from 'Assets/Image/svg/QuestionMarksSVG';

const Pricing = ({ intl: { formatMessage } }: MainType) => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePacketClick = (packet, scrollTo) => {
    history.push({
      pathname: `${ADD_WEB_SITE_ROUTES.WEBSITE}`,
      previousPath: `${history.location.pathname}${scrollTo ? `#${scrollTo}` : ''}`,
      packages: packet.toLowerCase(),
    });
  };

  useEffect(() => {
    const url = window.location.href;
    const hash = url.split('#')[1];
    if (hash) {
      const element = document.getElementById(hash);
      console.log(element);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 300);
      }
    }
  }, []);

  return (
    <div className="home-page pricing">
      <DetailsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <MetaTags>
        <title>{formatMessage({ id: 'global.metatag.home_page_title' })}</title>
        <meta name="description" content={formatMessage({ id: 'global.metatag.home_page_description' })} />
      </MetaTags>

      <section className="section__wrapper section--gray section--gray-small-margin">
        <div className="section--background section--background--top250">
          <SVG type={SVG_TYPE.HERO_SECTION_BG} />
        </div>
        <div className="section banner mainHeading__content heroSection homepage__section-content">
          <FadeInOnScroll className="section__r">
            <h1 className="section--header mainHeading">
              <p className="paragraph">
                <span className="blue">{formatMessage({ id: 'global.free_account' })}</span> {formatMessage({ id: 'global.pay_ony_for_packets' })}
              </p>
            </h1>
            <p className="section--under-header">{formatMessage({ id: 'global.pricing_subtitle' })}</p>
          </FadeInOnScroll>
          <FadeInOnScroll delay={isMobile ? 300 : 900} className="how-it-works__main-image-wrapper pricing__main-image">
            <CorgiGroupSVG />
          </FadeInOnScroll>
        </div>
      </section>

      <section className="section__wrapper section--gray pricing__options-wrapper ">
        <div className="section homepage__section-content pricing__options-section">
          <div className="pricing__options">
          <div className='pricing__option-wrapper' id="packets--basic">
            <SlideOnScroll delay={400} direction="bottom" className="pricing__option pricing__option--basic">
              <PacketsBasicSVG className="pricing__option-top-icon" />
              <h2 className="pricing__option-title">BASIC</h2>
              <p className="pricing__option-description">{formatMessage({ id: 'global.pricing_basic' })}</p>
              <hr />
              <p className="pricing__option-price">
                {stripePricing().basic}
                <p>{stripePricing().currencies}</p>
                <span>
                  <FormattedMessage id="global.vat" />
                </span>
              </p>
              <p>{formatMessage({ id: 'global.every_month_for_page' })}</p>
              <Button
                label={formatMessage({ id: 'global.checker.check_for_free' })}
                onClick={() => {
                  handlePacketClick('BASIC', 'packets--basic');
                }}
              />
              <hr />
              <ul className="pricing__option-list">
                <li>
                  <CheckSVG />
                  {formatMessage({ id: 'global.pricing_options.monitoring' })}
                </li>
                <li>
                  <CheckSVG />
                  <p>
                    <b>{formatMessage({ id: 'global.pricing_options.monthly' })}</b> {formatMessage({ id: 'global.pricing_options.backup' })}
                  </p>
                </li>
                <li className="inactive">
                  <CloseMenuSVG className="svg--close" />
                  <p>{formatMessage({ id: 'global.pricing_options.subpages' })}</p>
                </li>
                <li className="inactive">
                  <CloseMenuSVG className="svg--close" />
                  <p>{formatMessage({ id: 'global.pricing_options.hacker_protection' })}</p>
                </li>
                <li className="inactive">
                  <CloseMenuSVG className="svg--close" />
                  <p>{formatMessage({ id: 'global.pricing_options.updates' })}</p>
                </li>
                <li className="inactive">
                  <CloseMenuSVG className="svg--close" />
                  <p>{formatMessage({ id: 'global.pricing_options.hosting' })}</p>
                </li>
              </ul>
              </SlideOnScroll>
              </div>
            <div className='pricing__option-wrapper' id="packets--premium">
            <SlideOnScroll delay={400} direction="bottom" className="pricing__option pricing__option--premium">
              <PacketsPremiumSVG className="pricing__option-top-icon" />
              <div className="pricing__most-chosen-wrapper">
                <span className="pricing__most-chosen">{formatMessage({ id: 'global.most_choosed' })}</span>
              </div>
              <h2 className="pricing__option-title">Premium</h2>
              <p className="pricing__option-description">{formatMessage({ id: 'global.pricing_premium' })}</p>
              <hr />
              <p className="pricing__option-price">
                {stripePricing().premium}
                <p>{stripePricing().currencies}</p>
                <span>
                  <FormattedMessage id="global.vat" />
                </span>
              </p>
              <p>{formatMessage({ id: 'global.every_month_for_page' })}</p>
              <Button
                label={formatMessage({ id: 'global.checker.check_for_free' })}
                onClick={() => {
                  handlePacketClick('PREMIUM', 'packets--premium');
                }}
              />
              <hr />
              <ul className="pricing__option-list">
                <li>
                  <CheckSVG />
                  {formatMessage({ id: 'global.pricing_options.monitoring' })}
                </li>
                <li>
                  <CheckSVG />
                  <p>
                    <b>{formatMessage({ id: 'global.pricing_options.weekly' })}</b> {formatMessage({ id: 'global.pricing_options.backup' })}
                  </p>
                </li>
                <li>
                  <CheckSVG />
                  <p>{formatMessage({ id: 'global.pricing_options.subpages' })}</p>
                </li>
                <li>
                  <CheckSVG />
                  <p>{formatMessage({ id: 'global.pricing_options.hacker_protection' })}</p>
                </li>
                <li>
                  <CheckSVG />
                  <p>{formatMessage({ id: 'global.pricing_options.updates' })}</p>
                </li>
                <li className="inactive">
                  <CloseMenuSVG className="svg--close" />
                  <p>{formatMessage({ id: 'global.pricing_options.hosting' })}</p>
                </li>
              </ul>
              </SlideOnScroll>
              </div>
            <div className='pricing__option-wrapper' id="packets--pro">
              <SlideOnScroll delay={400} direction="bottom" className="pricing__option pricing__option--pro">
                <PacketsProSVG className="pricing__option-top-icon" />
                <h2 className="pricing__option-title">Pro</h2>
                <p className="pricing__option-description">{formatMessage({ id: 'global.pricing_pro' })}</p>
                <hr />
                <p className="pricing__option-price">
                  {stripePricing().pro}
                  <p>{stripePricing().currencies}</p>
                  <span>
                    <FormattedMessage id="global.vat" />
                  </span>
                </p>
                <p>{formatMessage({ id: 'global.every_month_for_page' })}</p>
                <Button
                  label={formatMessage({ id: 'global.checker.check_for_free' })}
                  onClick={() => {
                    handlePacketClick(PACKET_TYPE.PRO, 'packets--pro');
                  }}
                />
                <hr />
                <ul className="pricing__option-list">
                  <li>
                    <CheckSVG />
                    {formatMessage({ id: 'global.pricing_options.monitoring' })}
                  </li>
                  <li>
                    <CheckSVG />
                    <p>
                      <b>{formatMessage({ id: 'global.pricing_options.daily' })}</b> {formatMessage({ id: 'global.pricing_options.backup' })}
                    </p>
                  </li>
                  <li>
                    <CheckSVG />
                    <p>{formatMessage({ id: 'global.pricing_options.subpages' })}</p>
                  </li>
                  <li>
                    <CheckSVG />
                    <p>{formatMessage({ id: 'global.pricing_options.hacker_protection' })}</p>
                  </li>
                  <li>
                    <CheckSVG />
                    <p>{formatMessage({ id: 'global.pricing_options.updates' })}</p>
                  </li>
                  <li>
                    <CheckSVG />
                    <p>{formatMessage({ id: 'global.pricing_options.hosting' })}</p>
                  </li>
                </ul>
              </SlideOnScroll>
            </div>
          </div>
          <div className="pricing__compare-wrapper">
            <p className="pricing__dont-know-what-to-choose">{formatMessage({ id: 'global.dont_know_what_to_choose' })}</p>
            <Button
              classNames="pricing__compare-packages-button"
              label={formatMessage({ id: 'global.compare_packages' })}
              onClick={() => {
                setIsModalOpen(true);
              }}
            />
          </div>
        </div>
      </section>

      <section className="section__wrapper pricing__calculator-section">
        <div className="section__wrapper--white"> </div>
        <div className="section homepage__section-content pricing__calculator-wrapper">
          <Calculator />
        </div>
      </section>

      <section className="section__wrapper">
        <div className="section__wrapper--blue"> </div>
        <div className="section section--center homepage__calendry-wrapper" id="calendry">
          <FadeInOnScroll delay={isMobile ? 300 : 600} className="homepage__calendry">
            <InlineWidget url="https://calendly.com/tomaszorganek/corgi_demo" />
          </FadeInOnScroll>
          <FadeInOnScroll className="homepage__demo">
            <img src={TomaszOrganekPng} alt="Tomasz Organek" />
            <h2 className="homepage__subtitle  homepage__subtitle--partner homepage__subtitle--big ">
              {formatMessage({ id: 'global.make_a_demo_partner' })}
            </h2>
            <p className="homepage__demo-description">{formatMessage({ id: 'global.make_a_demo_description' })}</p>
          </FadeInOnScroll>
        </div>
      </section>

      <section className="section__wrapper section__wrapper-margin section--who-earns">
        <div className="section__wrapper--gray"> </div>
        <FadeInOnScroll className="section homepage__section-content">
          <h2 className="homepage__subtitle homepage__subtitle--white homepage__subtitle--white homepage__subtitle--one-tool">
            {formatMessage({ id: 'global.who_earn_most' })}
          </h2>
          <FadeInOnScroll delay={isMobile ? 300 : 600} className="homepage__earn-most">
            <div className="homepage__earn-most-left">
              <SVG type={SVG_TYPE.CORGI_WITHOUT_WOOF} className="svg--corgi-shield" />
              <SVG type={SVG_TYPE.MONEY} className="svg--money" />
            </div>
            <ul className="homepage__earn-most-right">
              <FadeInOnScroll delay={isMobile ? 300 : 600} className="homepage__earn-most-list-item">
                <SVG type={SVG_TYPE.DESKTOP_ICON} />
                <h3> {formatMessage({ id: 'global.who_earn_most_options.website_creators' })}</h3>
                <p>{formatMessage({ id: 'global.who_earn_most_options.website_creators_description' })}</p>
              </FadeInOnScroll>
              <FadeInOnScroll delay={isMobile ? 300 : 800} className="homepage__earn-most-list-item">
                <SVG type={SVG_TYPE.SEARCH_ICON} className="search" />
                <h3> {formatMessage({ id: 'global.who_earn_most_options.seo' })}</h3>
                <p>{formatMessage({ id: 'global.who_earn_most_options.seo_description' })}</p>
              </FadeInOnScroll>
              <FadeInOnScroll delay={isMobile ? 300 : 1000} className="homepage__earn-most-list-item">
                <SVG type={SVG_TYPE.SMILE_ICON} />
                <h3> {formatMessage({ id: 'global.who_earn_most_options.assistant' })}</h3>
                <p>{formatMessage({ id: 'global.who_earn_most_options.assistant_description' })}</p>
              </FadeInOnScroll>
              <FadeInOnScroll delay={isMobile ? 300 : 1200} className="homepage__earn-most-list-item">
                <SVG type={SVG_TYPE.ANNOUNCEMENT_ICON} />
                <h3> {formatMessage({ id: 'global.who_earn_most_options.agency' })}</h3>
                <p>{formatMessage({ id: 'global.who_earn_most_options.agency_description' })}</p>
              </FadeInOnScroll>
            </ul>
          </FadeInOnScroll>
        </FadeInOnScroll>
      </section>

       <section className="section__wrapper pricing__faq-wrapper">
        {/* <div className="homepage__gray"></div>
        <FadeInOnScroll className="homepage__section-content section section--column ">
          <QuestionMarksSVG className="pricing__faq-background" />
          <h2 className="homepage__subtitle homepage__subtitle--black homepage__subtitle--big">{formatMessage({ id: 'global.faq_header' })}</h2>
          <Faq />
        </FadeInOnScroll> */}
      </section> 
    </div>
  );
};

export default Pricing;
