import BubbleSVG from 'Assets/Image/svg/BubbleSVG';
import CorgiSuitSVG from 'Assets/Image/svg/CorgiSuitSvg';
import Money2SVG from 'Assets/Image/svg/Money2SVG';
import TwistedArrowSVG from 'Assets/Image/svg/TwistedArrowSVG';
import FadeInOnScroll from 'Components/FadeInOnScroll';
import SlideOnScroll from 'Components/SlideOnScroll';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, InputRange } from 'Shared/components';
import { isMobile, stripePricing } from 'Shared/utils';

const Calculator: React.FC = () => {
  const intil = useIntl();
  const { formatMessage } = intil;
  const isPL = stripePricing().currencies === 'PLN';
  const [clients, setClients] = useState(isMobile() ? 30 : 25);
  const [price, setPrice] = useState(isPL ? 400 : 70);
  const [earnings, setEarnings] = useState(0);
  const [clickedInputs, setClickedInputs] = useState([false, false]);

  const calculateEarnings = (clients: number, price: number) => {
    const premiumPrice = stripePricing().premium;
    return clients * (price - premiumPrice);
  };

  useEffect(() => {
    const earnings = calculateEarnings(clients, price);
    setEarnings(earnings);
  }, [clients, price, stripePricing().premium]);

  useEffect(() => {
    if (isMobile()) {
      if (clickedInputs[0] && clickedInputs[1]) {
        const element = document.querySelector('.pricing__calculator-bubble--earnings');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  }, [clickedInputs[0], clickedInputs[1]]);

  const handleClick = (index: number) => {
    const newClickedInputs = [...clickedInputs];
    newClickedInputs[index] = true;
    setClickedInputs(newClickedInputs);
  };

  useEffect(() => {
    setPrice(isPL ? 400 : 70);
  }, [stripePricing().currencies]);

  return (
    <>
      <FadeInOnScroll className="section homepage__section-content pricing__calculator">
        <Money2SVG className="pricing__calculator-money" />
        <h2 className="homepage__subtitle ">{formatMessage({ id: 'global.how_much_can_you_earn' })}</h2>
        <p className="pricing__calculator-subtitle">
          <TwistedArrowSVG className="pricing__calculator-arrow" />
          {formatMessage({ id: 'global.how_much_can_you_earn_subtitle_1' })}{' '}
          <b>{formatMessage({ id: 'global.how_much_can_you_earn_subtitle_2' })}?</b>{' '}
          <span className="blue">{formatMessage({ id: 'global.how_much_can_you_earn_subtitle_3' })}</span>
        </p>
        <div className="pricing__calculator-inputs">
          <div className="pricing__calculator-input">
            <p className="pricing__calculator-text">{formatMessage({ id: 'global.how_many_clients' })}</p>
            <InputRange
              min={isMobile() ? 10 : 5}
              max={50}
              value={clients}
              onChange={(value) => setClients(value)}
              step={isMobile() ? 10 : 5}
              onClick={() => handleClick(0)}
            />
          </div>
          <div className="pricing__calculator-input">
            <p className="pricing__calculator-text">{formatMessage({ id: 'global.price_per_cilent' })}</p>
            <InputRange
              min={isPL ? 200 : 50}
              max={isMobile() ? (isPL ? 600 : 90) : isPL ? 650 : 95}
              value={price}
              onChange={(value) => setPrice(value)}
              step={isMobile() ? (isPL ? 100 : 10) : isPL ? 50 : 5}
              onClick={() => handleClick(1)}
            />
          </div>
        </div>
        <div className="pricing__calculator-corgi">
          <SlideOnScroll direction="left" className="pricing__calculator-bubble pricing__calculator-bubble--earnings">
            <p>
              {formatMessage({ id: 'global.by_offering_corgi1' })} <b className="blue">{clients}</b>{' '}
              {formatMessage({ id: 'global.by_offering_corgi2' })}
            </p>
            <div className="pricing__calculator-earning">
              {earnings} <p>{stripePricing().currencies}</p>
              <span>
                <FormattedMessage id="global.vat" />/
                <FormattedMessage id="global.monthly_short" />
              </span>
            </div>

            <BubbleSVG />
          </SlideOnScroll>
          <CorgiSuitSVG className="pricing__calculator-corgi-in-suit" />
          <SlideOnScroll direction="right" className="pricing__calculator-bubble pricing__calculator-bubble--earn-more">
            <p>
              {formatMessage({ id: 'global.wanna' })} <b>{formatMessage({ id: 'global.earn_more' })}</b> {formatMessage({ id: 'global.make_a_demo' })}
            </p>
            <a href="#calendry">
              <Button label={formatMessage({ id: 'global.making_demo' })} />
            </a>

            <BubbleSVG />
          </SlideOnScroll>
        </div>
      </FadeInOnScroll>
    </>
  );
};

export default Calculator;
