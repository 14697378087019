import React from 'react';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

const NumberUnsetTile = ({ formatMessage, onClick }) => (
  <>
    <div className="number-unset">
      <div className="number-unset__text">{formatMessage({ id: 'global.default_number' })}</div>
      <div className="number-unset__svg" onClick={onClick}>
        <SVG type={SVG_TYPE.PENCIL} />
      </div>
    </div>
  </>
);

export default NumberUnsetTile;
