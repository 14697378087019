import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';

// const init = () => ({
//   type: Website.CHECK_WEBSITE_INIT,
// });

const success = (data) => ({
  type: Website.CHECK_WEBSITE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.CHECK_WEBSITE_FAILURE,
});

const getCheckWebsite = (url, uuid) => async (dispatch) => {
  // dispatch(init());
  sessionStorage.removeItem('checkWebsite');
  try {
    const { data } = await Connector.getCheckWebsite(url, uuid);
    dispatch(success(data));
    sessionStorage.setItem('checkWebsite', JSON.stringify(data))
    dispatch(Actions.HideDashboardWebsitesInfoLoader());
    return data
  } catch (err) {
    dispatch(Actions.HideDashboardWebsitesInfoLoader());
    console.log(err);
    sessionStorage.setItem('checkWebsite', JSON.stringify(err))
    dispatch(failure());
  }
};

export default getCheckWebsite;
