import React from 'react';
import Menu from '../Menu/Container';
import ChangePassword from '../ChangePassword/Container';

type P = {
  openMenuFn: boolean;
  logout: () => void;
  close?: any;
  selectMenuFn?: any;
  selectActive?: any;
  loggedUser;
  comeBack: () => void;
  location?: string;
};

const AdminPanel = ({ openMenuFn, loggedUser, selectMenuFn, selectActive, comeBack, logout, location }: P) => {
  return (
    <>
      <Menu
        location={location}
        loggedUser={loggedUser}
        openMenuFn={openMenuFn}
        selectMenuFn={selectMenuFn}
        selectActive={selectActive}
        logout={logout}
      />
      <div className="userMenu__chose-wrapper">
        <div className="userMenu__chose">{selectActive === 'changePassword' && <ChangePassword comeBack={comeBack} />}</div>
      </div>
    </>
  );
};

export default AdminPanel;
