import React from 'react';
import { FormattedMessage } from 'react-intl';

const sliceWorkingDay = (workingDay, websiteWorking) => {
  const days = workingDay;
  const fields = days.split('|');
  const day = fields[0];
  const hour = fields[1];
  const minutes = fields[2];
  return (
    <>
      <div className={`product__tile--time product__tile--time--${websiteWorking ? 'green' : 'red'}`}>
        <div className="product__tile--time--hours">
          {day === '1' && day + ' '}
          {day === '1' && <FormattedMessage id="global.home_page.day" />}
          {day != '1' && day + ' '}
          {day != '1' && <FormattedMessage id="global.home_page.days" />}
        </div>
        <div className="product__tile--time--minutes">
          <div>{hour + ' h'}</div>
          <div>&nbsp; {minutes + ' min'}</div>
        </div>
      </div>
    </>
  );
};

export default sliceWorkingDay;
