import React, { useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Locale } from 'Shared/components';
import * as Yup from 'yup';
import RegularField from 'Shared/form/regularField/regularField';
import { FormattedMessage } from 'react-intl';
import Checkbox from 'Components/Checkbox';
import { MainType } from './Container';

const ContactForm: React.FC<MainType> = ({ actions: { sendContactForm }, intl: { formatMessage } }) => {
  const [isRecaptcha,] = useState(true);
  const formRef: any = useRef();
  const validation = () =>
    Yup.object().shape({
      sender_name: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
      sender_email: Yup.string()
        .email(formatMessage({ id: 'global.validation.wrong_email' }))
        .required(formatMessage({ id: 'global.validation.required' })),
      message: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
      acceptance: Yup.boolean().oneOf([true], formatMessage({ id: 'global.validation.required' })),
    });

  return (
    <Formik
      initialValues={{
        sender_name: '',
        sender_email: '',
        message: '',
        topic: 'Contact form',
        acceptance: false,
      }}
      validationSchema={validation()}
      innerRef={formRef}
      onSubmit={async (values) => {
        isRecaptcha && await sendContactForm(values);
        formRef.current.handleReset();
        formRef.current.setErrors({});
        formRef.current.setTouched({});
      }}>
      {({ handleSubmit, errors }) => (
        <Form onSubmit={handleSubmit} className="contact-form">
          <h2>{formatMessage({ id: 'global.leave_us_message' })}</h2>
          <h2>{formatMessage({ id: 'global.leave_us_message1' })}</h2>
          <div className="contact-form__group">
            <Field
              type="text"
              label={<FormattedMessage id="global.dashboard.name" />}
              name="sender_name"
              component={RegularField}
              errors={errors}
              wrapperClassName="white"
            />
            <Field
              type="text"
              label={<FormattedMessage id="global.dashboard.email_2" />}
              name="sender_email"
              component={RegularField}
              errors={errors}
              wrapperClassName="white "
            />
          </div>
          <Field
            type="textarea"
            label={<FormattedMessage id="global.your_message" />}
            name="message"
            component={RegularField}
            errors={errors}
            wrapperClassName="white"
          />
          <Field
            label={
              <p>
                <FormattedMessage id="global.i_confirm_i_read" />{' '}
                <a
                  href={Locale() === 'pl' ? require('Assets/file/regulations_corgi.pdf') : require('Assets/file/terms_of_service.pdf')}
                  target="_blank"
                  rel="noopener noreferrer">
                  <FormattedMessage id="global.terms_of_service" />
                </a>{' '}
                <FormattedMessage id="global.and" />&nbsp;
                <a
                  href={Locale() === 'pl'        ? require('Assets/file/privacy_policy.pdf')
                    : require('Assets/file/privacy_policy en.pdf')}
                  target="_blank"
                  rel="noopener noreferrer">
                  <FormattedMessage id="global.privacy_policy1" />
                </a>.{' '}
              </p>
            }
            name="acceptance"
            component={Checkbox}
            errors={errors}
          />
          <Button type="submit" onClick={() => handleSubmit()} label={<FormattedMessage id="global.send" />} classNames="new-blue " />
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
