import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import Button from 'Shared/components/button/Button';
import RegularField from 'Shared/form/regularField/regularField';
import ChevronBlack from 'Assets/Image/Icon/icon_chevron_black.svg';
import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';
import { FormattedMessage } from 'react-intl';
import countryList from 'react-select-country-list';

type P = {
  comeBack?: any;
  loggedUser?: any;
  editUser: (data) => any;
};

const validation = () => {
  return Yup.object().shape({
    first_name: Yup.string().required('Pole wymagane'),
    last_name: Yup.string().required('Pole wymagane'),
    email: Yup.string().required('Pole wymagane'),
    phone_prefix: Yup.string().required('Pole wymagane'),
    phone_number: Yup.string().required('Pole wymagane'),
    zip_code: Yup.string()
      .required('Pole wymagane')
      .test('zip-code', 'Niepoprawny format (xx-xxx)', (val) => val && !!/^[0-9]{2}-[0-9]{3}$/i.test(val)),
    name: Yup.string().required('Pole wymagane'),
    address: Yup.string().required('Pole wymagane'),
    city: Yup.string().required('Pole wymagane'),
    vat_id: Yup.string()
      .required('Pole wymagane')
      .test('vat-letters', 'Niepoprawny format (PLXXXXXX) ', (val) => val && /^[A-Z]{2}\d.*/g.test(val.toUpperCase()))
      .test('vat-id', 'Niepoprawny NIP', (val) => {
        if (val) {
          if (/^PL\d.*/g.test(val.toUpperCase())) {
            const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
            const vn = val.toUpperCase().replace('PL', '').split('');
            let sum = 0;
            vn.forEach((number, index) => {
              if (weights[index]) {
                sum += parseInt(number) * weights[index];
              }
            });

            const modulo = sum % 11;
            if (modulo !== parseInt(vn[9]) || val.toUpperCase() === 'PL0000000000') {
              return false;
            }
          } else {
            return false;
          }
          return true;
        }
      }),
    country: Yup.string().required('Pole wymagane'),
  });
};
const MyData = ({ comeBack, loggedUser, editUser }: P) => {
  const [isSend, setIsSend] = useState(false);

  const initialValues = {
    first_name: loggedUser.first_name || null,
    last_name: loggedUser.last_name || '',
    email: loggedUser.email || '',
    phone_prefix: loggedUser.company.phone_prefix || '',
    phone_number: loggedUser.company.phone_number || '',
    zip_code: loggedUser.company.zip_code || '',
    name: loggedUser.company.name || '',
    address: loggedUser.company.address || '',
    city: loggedUser.company.city || '',
    vat_id: loggedUser.company.vat_id || '',
    // country: loggedUser.company.country || '',
    country: 'PL',
  };
  return (
    <div className="wrap">
      <div className="wrap__content ">
        <div className="userMenu__content">
          {!isSend ? (
            <div className="MyData">
              <Formik
                enableReinitialize
                validationSchema={validation()}
                initialValues={initialValues}
                onSubmit={async (values) => {
                  if (JSON.stringify(initialValues) !== JSON.stringify(values)) {
                    if (await editUser(values)) {
                      setIsSend(true);
                    }
                  }
                }}>
                {({ handleSubmit, errors }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="userMenu__form">
                      <h2>
                        <FormattedMessage id="global.menu_dashboard.my_data" />
                      </h2>
                      <div className="MyData--none">
                        <Field
                          type="text"
                          label={<FormattedMessage id="global.dashboard.name" />}
                          name="first_name"
                          component={RegularField}
                          errors={errors}
                        />
                        <Field
                          class="surname"
                          type="text"
                          label={<FormattedMessage id="global.dashboard.surname" />}
                          name="last_name"
                          component={RegularField}
                          errors={errors}
                        />
                        <Field
                          type="text"
                          label={<FormattedMessage id="global.dashboard.email" />}
                          name="email"
                          component={RegularField}
                          errors={errors}
                        />
                      </div>

                      <div className="userMenu__form-half">
                        <Field
                          type="text"
                          label={<FormattedMessage id="global.dashboard.phone" />}
                          name="phone_prefix"
                          component={RegularField}
                          errors={errors}
                        />
                        <Field type="text" label="&thinsp;" name="phone_number" component={RegularField} errors={errors} />
                      </div>
                      <Field
                        type="text"
                        label={<FormattedMessage id="global.dashboard.company_name" />}
                        name="name"
                        component={RegularField}
                        errors={errors}
                      />
                      <Field
                        type="text"
                        label={<FormattedMessage id="global.dashboard.nip" />}
                        name="vat_id"
                        component={RegularField}
                        errors={errors}
                      />
                      <Field
                        type="text"
                        label={<FormattedMessage id="global.dashboard.company_adress" />}
                        name="address"
                        component={RegularField}
                        errors={errors}
                      />
                      <Field
                        type="text"
                        label={<FormattedMessage id="global.packet_form.postal_code" />}
                        name="zip_code"
                        component={RegularField}
                        errors={errors}
                      />
                      <Field
                        type="text"
                        label={<FormattedMessage id="global.packet_form.city" />}
                        name="city"
                        component={RegularField}
                        errors={errors}
                      />
                      <div className="MyData--none">
                        <Field
                          type="countryList"
                          options={countryList().getData()}
                          label={'Państwo'}
                          country={loggedUser.company.country}
                          name="country"
                          component={RegularField}
                          errors={errors}
                        />
                      </div>
                      <div className="userMenu__form-button">
                        <div>
                          <Button
                            onClick={() => comeBack()}
                            label={<FormattedMessage id="global.back" />}
                            classNames="btn--gray"
                            iconAlt="icon product"
                            iconPlacement="left"
                            iconImg={ChevronBlack}
                          />
                        </div>
                        <Button
                          onClick={() => {
                            handleSubmit();
                          }}
                          type="submit"
                          label={<FormattedMessage id="global.payments.save_data" />}
                          classNames="btn--black"
                          iconAlt="icon product"
                        />
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          ) : (
            <div className="accConfig">
              <div className="accConfig__top">
                <div>
                  <SVG className="svg--accept" type={SVG_TYPE.ACCEPT_GREEN} />
                  <p>{<FormattedMessage id="global.succed" />}</p>
                </div>
              </div>
              <div className="accConfig__bottom">
                <div>&nbsp;</div>
                <div className="accConfig__bottom-button">
                  <Button onClick={() => setIsSend(false)} type="submit" label="Ok" classNames="btn--black" iconAlt="icon product" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyData;
