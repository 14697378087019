import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: Website.EDIT_WP_USERS_INIT,
});

const success = (isNumbersCustomized) => ({
  type: Website.EDIT_WP_USERS_SUCCESS,
  payload: {
    isNumbersCustomized
  }
});

const failure = () => ({
  type: Website.EDIT_WP_USERS_SUCCESS,
});

const editWpUserCustomPhones = (data) => async (dispatch) => {
  console.log(data)
  dispatch(init());
  try {
    dispatch(Actions.showLoader());
    const { status } = await Connector.editWpUserCustomPhone(data);
    dispatch(success(data.isNumbersCustomized));
    dispatch(Actions.hideLoader());
    return status;
  } catch (err) {
    console.log(err);
    dispatch(Actions.hideLoader());
    dispatch(failure());
  }
};

export default editWpUserCustomPhones;
