import React from 'react';
import { Formik } from 'formik';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'Shared/components';
import { ROUTES } from 'Shared/enums';

type P = {
  history: any;
  formatMessage: any;
};

const InvalidToken = ({ formatMessage, history }: P) => {
  const formRef = useRef(null);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        email: '',
      }}
      onSubmit={() => {
        history.push(ROUTES.RESET_PASSWORD_PAGE);
      }}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="login-page__form">
          <h1 className="login-page__title">{formatMessage({ id: 'global.checker.dog_ankle' })}&nbsp;😰</h1>
          <h3 className="login-page__sub-title">{formatMessage({ id: 'global.link_expired' })}</h3>

          <Button
            type="submit"
            label={<FormattedMessage id="global.send_link_again" />}
            classNames="btn--blue login-page__submit"
            iconAlt="icon product"
          />
        </form>
      )}
    </Formik>
  );
};
export default InvalidToken;
