import { Button } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';

interface FullScreenModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  zindex?: number;
  blur?: boolean;
  className?: string;
}

const FullScreenModal: React.FC<FullScreenModalProps> = ({ isOpen, children, onClose, zindex, blur = false, className = '' }) => {
  React.useEffect(() => {
    if (isOpen) {
      document.querySelector('html')?.classList.add('no-scroll');
    } else {
      document.querySelector('html')?.classList.remove('no-scroll');
    }
  }, [isOpen]);

  return createPortal(
    <div
      style={{ zIndex: isOpen ? zindex : -1 }}
      className={`fullscreen-modal ${className} ${isOpen ? 'fullscreen-modal--open' : ''} ${blur ? 'fullscreen-modal--blur' : ''}`}>
      {!blur ? (
        <Button
          classNames="add-website-form__button btn__black-stroke fullscreen-modal-close"
          label={<FormattedMessage id="global.close" />}
          onClick={() => onClose()}
          iconImgSvg={SVG_TYPE.CLOSE}
        />
      ) : null}
      {children}
    </div>,
    document.querySelector('#root'),
  );
};

export default FullScreenModal;
