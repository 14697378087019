import React from 'react';
import SVG from 'Shared/components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import { FormattedMessage } from 'react-intl';

type P = {
  type?: string;
  clearFilter?: () => void;
};

const Filter = ({ type, clearFilter }: P) => {
  return (
    <div className="filter">
      <div className="filter__wrapper">
        <div className="filter__item" onClick={() => clearFilter()}>
          <p>{type === 'working' && <FormattedMessage id="global.working_pages" />}</p>
          <p>{type === 'notWorking' && <FormattedMessage id="global.not_working_pages" />}</p>
          <p>{type === 'notProtected' && <FormattedMessage id="global.unprotected" />}</p>
          <p>{type === 'protectedSSL' && <FormattedMessage id="global.protected_ssl" />}</p>
          <p>{type === 'protected' && <FormattedMessage id="global.protected" />}</p>
          <p>{type === 'noSSL' && <FormattedMessage id="global.no_certificate_ssl" /> }</p>
          <p>{type === 'expiredSSL' && <FormattedMessage id="global.expiredSSL" />}</p>
          <SVG type={SVG_TYPE.CLOSE} className="svg--close--gray" />
        </div>
      </div>
    </div>
  );
};

export default Filter;
