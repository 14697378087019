import React, { useRef, useEffect } from 'react';

type P = {
  onClick: () => void;
  children: any;
};

function OutsideClick({ onClick, children }: P) {
  const node: any = useRef();

  const handleClick = (e) => {
    if (!node.current.contains(e.target)) {
      onClick();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return <div ref={node}>{children}</div>;
}

export default OutsideClick;
