import moment from 'moment';
import { PING_HOUR_RANGES } from './consts';
import { isMobile, isTablet } from 'Shared/utils';

const curr = new Date();
const first = curr.getDate();
const firstday = new Date(curr.setDate(first)).toUTCString();
const today = moment(firstday).format('dddd').toUpperCase();

const dayOfWeek = (day, formatMessage) => {
  if (today === day) {
    day = 'TODAY';
  }
  switch (day) {
    case 'MONDAY': {
      return formatMessage({ id: 'global.monday' });
    }
    case 'TUESDAY': {
      return formatMessage({ id: 'global.tuesday' });
    }
    case 'WEDNESDAY': {
      return formatMessage({ id: 'global.wednesday' });
    }
    case 'THURSDAY': {
      return formatMessage({ id: 'global.thursday' });
    }
    case 'FRIDAY': {
      return formatMessage({ id: 'global.friday' });
    }
    case 'SATURDAY': {
      return formatMessage({ id: 'global.saturday' });
    }
    case 'SUNDAY': {
      return formatMessage({ id: 'global.sunday' });
    }
    case 'TODAY': {
      return formatMessage({ id: 'global.today' });
    }
  }
};

const getWeekOrderFromToday = () => {
  const weekDays = [
    moment().subtract(6, 'days').format('dddd').toUpperCase(),
    moment().subtract(5, 'days').format('dddd').toUpperCase(),
    moment().subtract(4, 'days').format('dddd').toUpperCase(),
    moment().subtract(3, 'days').format('dddd').toUpperCase(),
    moment().subtract(2, 'days').format('dddd').toUpperCase(),
    moment().subtract(1, 'days').format('dddd').toUpperCase(),
    moment().format('dddd').toUpperCase(),
  ];

  return weekDays;
};

function prepareDayLabels(obj) {
  if (!obj) return;
  const keys = Object.keys(obj);
  const showShortened = isMobile() && keys.length > 7;
  const resultArray = [];

  if (keys[0] === 'test1') {
    return ['', '', '', '', '', '', ''];
  }

  keys.forEach((key, index) => {
    // For mobile, when shortened, only add first and last labels
    if (showShortened && index !== 0 && index !== keys.length - 1) {
      resultArray.push('');
      return;
    }

    const [day, month, year] = key.split('.');
    const paddedMonth = month.padStart(2, '0');
    const paddedDay = day.padStart(2, '0');
    const date = moment(`${year}-${paddedMonth}-${paddedDay}`, 'YYYY-MM-DD');
    const showMonth = showShortened || index === 0 || index === keys.length - 1 || day === '1';
    const translatedMonth = index === 0 ? moment(date).format('MMMM').slice(0, 3) : moment(date).format('MMMM').slice(0, 3);
    const formattedKey = showMonth ? `${day} ${translatedMonth}` : `${day}`;
    resultArray.push(formattedKey);
  });

  return resultArray;
}

function prepareWeekDaysLabels(obj, formatMessage) {
  if (!obj) return;
  const keys = Object.keys(obj);
  const resultArray = [];
  if (keys[0] === 'test1') {
    return ['', '', '', '', '', '', ''];
  }
  keys.forEach((key) => {
    const [day, month, year] = key.split('.');
    const paddedMonth = month.padStart(2, '0');
    const paddedDay = day.padStart(2, '0');
    const date = moment(`${year}-${paddedMonth}-${paddedDay}`, 'YYYY-MM-DD');
    const dayOfWeek = date.format('dddd').toUpperCase();
    const isToday = moment().format('YYYY-MM-DD') === date.format('YYYY-MM-DD');

    const formattedKey = isToday
      ? isTablet()
        ? formatMessage({ id: 'global.today' })
        : formatMessage({ id: 'global.today' }) + `, ${paddedDay}.${paddedMonth}`
      : `${isTablet() ? dayOfWeek.toLocaleLowerCase().slice(0, 2) : dayOfWeek.toLocaleLowerCase() + `, ${paddedDay}.${paddedMonth}`}`;
    resultArray.push(formattedKey);
  });

  return resultArray;
}

const translatedWeekOrderFromToday = (formatMessage, pings, isCustom) => {
  return isCustom ? prepareDayLabels(pings) : prepareWeekDaysLabels(pings, formatMessage);
};

const getPingHourRange = (createdAt) => {
  const hour = Number(moment(createdAt).format('H'));

  if (hour >= 0 && hour < 4) {
    return PING_HOUR_RANGES.FROM_0_TO_4;
  }
  if (hour >= 4 && hour < 8) {
    return PING_HOUR_RANGES.FROM_4_TO_8;
  }
  if (hour >= 8 && hour < 12) {
    return PING_HOUR_RANGES.FROM_8_TO_12;
  }
  if (hour >= 12 && hour < 16) {
    return PING_HOUR_RANGES.FROM_12_TO_16;
  }
  if (hour >= 16 && hour < 20) {
    return PING_HOUR_RANGES.FROM_16_TO_20;
  }
  if (hour >= 20 && hour <= 24) {
    return PING_HOUR_RANGES.FROM_20_TO_24;
  }
};
const initializeHourRanges = () => ({
  [PING_HOUR_RANGES.FROM_0_TO_4]: {
    data: [],
    status: undefined,
  },
  [PING_HOUR_RANGES.FROM_4_TO_8]: {
    data: [],
    status: undefined,
  },
  [PING_HOUR_RANGES.FROM_8_TO_12]: {
    data: [],
    status: undefined,
  },
  [PING_HOUR_RANGES.FROM_12_TO_16]: {
    data: [],
    status: undefined,
  },
  [PING_HOUR_RANGES.FROM_16_TO_20]: {
    data: [],
    status: undefined,
  },
  [PING_HOUR_RANGES.FROM_20_TO_24]: {
    data: [],
    status: undefined,
  },
});

const sortPingsByDay = (pings = [], statusOnly = false) => {
  return pings.reduce((accumulatedPings: any, currentPing) => {
    const day = moment(currentPing.periodFrom).format('dddd').toUpperCase();

    if (moment().diff(moment(currentPing.periodFrom).startOf('day'), 'days') > 6) {
      return accumulatedPings;
    }

    const currentPingWithStatus = {
      ...currentPing,
    };
    const hourRange = getPingHourRange(currentPing.periodFrom);

    accumulatedPings[day] = accumulatedPings[day]
      ? {
          ...accumulatedPings[day],
          [hourRange]: {
            ...accumulatedPings[day][hourRange],
            ...(!statusOnly ? { data: [...accumulatedPings[day][hourRange].data, currentPingWithStatus] } : {}),
          },
        }
      : {
          ...initializeHourRanges(),
          [hourRange]: {
            ...(!statusOnly ? { data: [currentPingWithStatus] } : {}),
          },
        };

    return accumulatedPings;
  }, {});
};

const getHourlyStatusBasedOnPingsCount = (pings = null) => {
  if (!pings) {
    return {
      graphValue: 0,
      // fillColor: '#FFFFFF',
      fillColor: '#bfbfbf',
    };
  }
  const { pingsWithFail } = pings;
  // const { pingsWithFail } = pings?.[0] || {};

  if (pingsWithFail <= 4) {
    return {
      graphValue: 1.0,
      fillColor: '#5CDDA0',
    };
  }
  if (pingsWithFail >= 5 && pingsWithFail < 60) {
    return {
      graphValue: 0.8,
      fillColor: '#FF9618',
    };
  }
  return {
    graphValue: 0.6,
    fillColor: '#ff0000',
  };
};
const loadingData = {
  test1: {},
  test2: {},
  test4: {},
  test5: {},
  test6: {},
  test7: {
    '0-4': {
      pingsWithFail: 0,
    },
    '4-8': {
      pingsWithFail: 0,
    },
    '8-12': {
      pingsWithFail: 0,
    },
    '12-16': {
      pingsWithFail: 0,
    },
    '16-20': {
      pingsWithFail: 0,
    },
    '20-24': {
      pingsWithFail: 0,
    },
  },
};

type pingType = {
  '0-4': { pingsWithFail: number };
  '4-8': { pingsWithFail: number };
  '8-12': { pingsWithFail: number };
  '12-16': { pingsWithFail: number };
  '16-20': { pingsWithFail: number };
  '20-24': { pingsWithFail: number };
};

type pingsType = {
  [key: string]: pingType;
};

const getDailyStatusBasedOnPingsCount = (pings: any) => {
  if (!pings) {
    return {
      graphValue: 0,
      fillColor: '#bfbfbf',
    };
  }
  const pingsWithFailCount: any = Object.values(pings).reduce((acc: number, curr: any) => {
    return acc + curr.pingsWithFail;
  }, 0);

  if (pingsWithFailCount <= 4) {
    return {
      graphValue: 1.0,
      fillColor: '#5CDDA0',
    };
  }
  if (pingsWithFailCount >= 5 && pingsWithFailCount < 60) {
    return {
      graphValue: 0.8,
      fillColor: '#FF9618',
    };
  }
  return {
    graphValue: 0.6,
    fillColor: '#ff0000',
  };
};

export {
  sortPingsByDay,
  getWeekOrderFromToday,
  getHourlyStatusBasedOnPingsCount,
  dayOfWeek,
  translatedWeekOrderFromToday,
  prepareDayLabels,
  loadingData,
  getDailyStatusBasedOnPingsCount,
};
