import * as AuthModule from '../../../Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import * as AuthActionModel from '../Models/AuthActionModel';

const init = () => ({
  type: Auth.GET_REFRESH_TOKEN_INIT,
});

const success = (token: string): AuthActionModel.GetRefreshToken => ({
  type: Auth.GET_REFRESH_TOKEN_SUCCESS,
  payload: {
    token,
  },
});

const failure = () => ({
  type: Auth.GET_REFRESH_TOKEN_FAILURE,
});

const getRefreshToken =
  (refreshToken: string = null) =>
  async (dispatch) => {
    dispatch(init());
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      const {
        data: { token },
      } = await AuthModule.Connector.getRefreshToken(refreshToken);
      dispatch(success(token));
      dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err: any) {
      dispatch(ViewManagementModule.Actions.hideLoader());
      dispatch(failure());
      if (err.response && err.response.status === 404) {
        localStorage.removeItem('isUserLogged');
      }
    }
  };

export default getRefreshToken;
