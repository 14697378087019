import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { INTL_TYPE } from 'Shared/enums';
import { injectIntl } from 'react-intl';
import { Actions as UserAction } from 'Modules/UserModule';
import ChangePassword from './Main';

type ConnectedP = {};

type DispatchedP = {
  actions: {
    changePassword: (new_password: string, current_password: string) => any;
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      changePassword: UserAction.changePassword,
    },
    dispatch,
  ),
});

const mapStateToProps = (): ConnectedP => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChangePassword));
