import API from './Config';
import { AxiosPromise } from 'axios';
import * as UserModule from 'Modules/UserModule';

export function getAuthorizedUser(): AxiosPromise<any> {
  return API.get('users/me');
}

export function editUser(uuid: string, userData: any): AxiosPromise<any> {
  return API.put(`users/${uuid}`, userData);
}
export function editSettings(data): AxiosPromise<any> {
  return API.put(`user/settings`, data);
}
export function sendMethod(send_method: any): AxiosPromise<any> {
  return API.put(`user/notification`, send_method);
}

export function changePassword(current_password: string, new_password: string, password_strength: 'weak' | 'medium' | 'strong'): AxiosPromise<any> {
  return API.put(`users/change-password`, { current_password, new_password, password_strength });
}

export function getPaymentMethods(): AxiosPromise<any> {
  return API.get('payment-methods');
}

export function getMyWebsites(companyUuid): AxiosPromise<any> {
  return API.get('websites', { params: { company_uuid: companyUuid ? companyUuid : null } });
}
export function addWebsites(dataForm): AxiosPromise<UserModule.Types.WebsiteUuid> {
  return API.post('/websites', dataForm);
}
export function deleteWebsite(uuid): AxiosPromise<any> {
  return API.delete(`/websites/${uuid}`);
}

export function cancelSubscription(uuid): AxiosPromise<any> {
  return API.put(`/websites/deactivate/${uuid}`);
}

export function addWebsitePayment(dataForm, website_uuid: string): AxiosPromise<UserModule.Types.WebsiteUuid> {
  return API.put(`/websites/${website_uuid}`, dataForm);
}

export function updateWebsite(dataForm, website_uuid: string): AxiosPromise<any> {
  return API.put(`/websites/${website_uuid}`, dataForm);
}
export function getPartnerClients(uuid): AxiosPromise<any> {
  return API.get(`/websites?company_uuid=${uuid}`);
}

export function addConnection(dataForm): AxiosPromise<any> {
  return API.post('/connections/files', dataForm);
}

export function updateConnection(dataForm, connection_uuid): AxiosPromise<any> {
  return API.put(`/connections/files/${connection_uuid}`, dataForm);
}

export function AddPaymentMethod(paymentData): AxiosPromise<any> {
  return API.post('payment-methods', paymentData);
}
export function checkIsWordpress(url): AxiosPromise<any> {
  return API.post('websites/is-wordpress', url);
}

export function deletePaymentMethod(id): AxiosPromise<any> {
  return API.delete(`/payment-methods/${id}`);
}

export function sendContactForm(dataForm): AxiosPromise<any> {
  return API.post('contact', dataForm);
}

export function sendPreorderForm(dataForm): AxiosPromise<any> {
  return API.post('/registration', dataForm);
}

export function registerPartnerForm(dataForm): AxiosPromise<any> {
  return API.post('/users', dataForm);
}

export function regulationsPartner(): AxiosPromise<any> {
  return API.put('/companies/partner');
}

export function sendNewsletterForm(dataForm): AxiosPromise<any> {
  return API.post('newsletters/subscribers', dataForm);
}

export function getSubscribersLists(): AxiosPromise<any> {
  return API.get('newsletters/subscribers');
}
export function getAllUsers(): AxiosPromise<any> {
  return API.get('users/all/list');
}
export function getClientsLists(): AxiosPromise<any> {
  return API.get('/clients');
}
export function getClients(company_uuid): AxiosPromise<any> {
  return API.get(`/clients/${company_uuid}`);
}

export function getPreOrderLists(): AxiosPromise<any> {
  return API.get('/pre-orders');
}
export function getMyBalance(currency): AxiosPromise<any> {
  return API.get('/balance', { params: { currency } });
}

export function saveFile({ file_name, file_format }, file): AxiosPromise<any> {
  return API.post(`/file/save?&file_format=${file_format === 'svg xml' ? 'svg' : file_format}&file_name=${file_name}`, file);
}

export function setThemeColor(theme_color): AxiosPromise<any> {
  return API.put(`/user/choose-theme`, { theme_color });
}

export function deleteFile(uuid) {
  return API.delete(`file/delete?uuid=${uuid}`);
}

export function deleteUser(uuid) {
  return API.delete(`users/delete`, { params: { uuid } });
}

export function sendInvoices(uuid, value): AxiosPromise<any> {
  return API.get(`clients/sending-invoices`, { params: { uuid, value } });
}
export function checkerRegistration(data): AxiosPromise<any> {
  return API.post(`/checker/registration`, data);
}

export function  updateServices(body) {
  return API.put(`services/update`, body);
}

export function getServices() {
  return API.get(`services/show`)
}

export function updateDefalutPaymentMethod(body) {
  return API.put(`payment-methods`, body);
}