import { Connector } from '../../../Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { User } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: User.CHECKER_REGISTER_INIT,
});

const success = () => ({
  type: User.CHECKER_REGISTER_SUCCESS,
});

const failure = () => ({
  type: User.CHECKER_REGISTER_FAILURE,
});

const checkerRegistration = (form) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    delete form.checkbox_privacy;
    const { data } = await Connector.checkerRegistration(form);
    dispatch(success());
    dispatch(ViewManagementModule.Actions.hideLoader());
    return data;
  } catch (err: any) {
    console.log(err);
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    return err.response;
  }
};

export default checkerRegistration;
