import * as React from 'react';
import AdminImg from 'Assets/Image/admin.svg';
import { MainType } from './Container';
import SVG from 'Shared/components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import AdminPanel from './components/AdminPanel';
import ClientPanel from './components/ClientPanel';
import * as H from 'history';

type P = {
  active: boolean;
  selectActive: string;
  title: string;
};
type D = {
  location?: H.Location;
};

class DashboardSidebar extends React.Component<MainType & D, P> {
  constructor(props: MainType) {
    super(props);
    this.state = {
      active: false,
      selectActive: '',
      title: 'text',
    };
  }

  async componentDidMount() {
    await this.props.actions.getPaymentMethods();
  }

  selectMenu(someArg) {
    this.setState({ selectActive: someArg });
  }
  openMenu = () => {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };

  comeBack = () => {
    this.setState({ selectActive: '' });
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase().slice(0, 1);
  }

  public render() {
    const { active, selectActive } = this.state;
    const { openMenu, selectMenu, comeBack } = this;
    const {
      actions: { editUser, logout, addPaymentMethod, deletePaymentMethod },
      loggedUser,
      paymentMethods,
    } = this.props;
    const { pathname } = location;
    return (
      <div className={`systemUsers__r ${active ? 'systemUsers__r--active' : ''} ${active == true ? '' : 'hidden positionRelative'} `}>
        {loggedUser.role === 'admin' ? (
          <AdminPanel
            location={pathname}
            comeBack={comeBack}
            loggedUser={loggedUser}
            selectActive={selectActive}
            selectMenuFn={selectMenu.bind(this)}
            openMenuFn={active}
            logout={logout}
          />
        ) : (
          <ClientPanel
            loggedUser={loggedUser}
            editUser={editUser}
            comeBack={comeBack}
            selectActive={selectActive}
            selectMenuFn={selectMenu.bind(this)}
            openMenuFn={active}
            logout={logout}
            paymentMethods={paymentMethods}
            addPaymentMethod={addPaymentMethod}
            deletePaymentMethod={deletePaymentMethod}
          />
        )}
        <div className="systemUsers__r__wrapper">
          <div className="systemUsers__info">
            <div className="systemUsers--userLogo">
              {loggedUser.role === 'admin' && <img src={AdminImg} alt="logo użytkownika" />}
              {loggedUser.company?.preOrder?.product_key === 'basic' && <SVG className="svg" type={SVG_TYPE.PACKETS_BASIC} />}
              {loggedUser.company?.preOrder?.product_key === 'premium' && <SVG className="svg" type={SVG_TYPE.PACKETS_PREMIUM} />}
              {loggedUser.company?.preOrder?.product_key === 'pro' && <SVG className="svg" type={SVG_TYPE.PACKETS_PRO} />}
            </div>
            <div className="systemUsers--name">
              <p>
                {loggedUser.first_name} {this.Capitalize(loggedUser.last_name)}.
              </p>
              <span>{loggedUser.role === 'admin' ? 'Administrator' : loggedUser.company?.preOrder?.product_key} </span>
            </div>
            <div
              // className={`systemUsers__settings ${active ? 'systemUsers__settings--active' : ''}`}3
              id="menu"
              className={`systemUsers__settings ${active ? 'systemUsers__settings--active' : ''} ${
                loggedUser.role === 'admin' && 'systemUsers__settings--active-desktop  click-disabled-desktop'
              }`}
              onClick={() => {
                openMenu();
                this.setState({ selectActive: '' });
              }}>
              <SVG className="svg--settings" type={SVG_TYPE.SETTINGS} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardSidebar;
