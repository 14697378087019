import { Connector } from '../../../Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { ROUTES } from 'Shared/enums';
import { history } from 'Store/Reducers/RootReducer';
import zxcvbn from 'zxcvbn';
import { Toast } from 'Shared/components';

const init = () => ({
  type: User.REGISTER_PARTNER_FORM_INIT,
});

const success = () => ({
  type: User.REGISTER_PARTNER_FORM_SUCCESS,
});

const failure = () => ({
  type: User.REGISTER_PARTNER_FORM_FAILURE,
});

const registerPartnerForm = (dataForm, withoutRoute?: boolean) => async (dispatch) => {
  dispatch(init());

  const getPasswordStrength = (password) => {
    const passwordStrength = zxcvbn(password).score;
    if (passwordStrength <= 1) {
      return 'weak';
    }
    if (passwordStrength > 1 && passwordStrength <= 3) {
      return 'medium';
    }
    if (passwordStrength > 3) {
      return 'strong';
    }
  };

  Toast({ message: 'global.apex_no_data', type: 'loading', id: 'updateConnection' });
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await Connector.registerPartnerForm({ ...dataForm, password_strength: getPasswordStrength(dataForm.password) });
    dispatch(success());
    !withoutRoute && history.push({ pathname: ROUTES.PARTNER_SIGN_UP, fullName: `${dataForm.first_name} ${dataForm.last_name}` });
    dispatch(ViewManagementModule.Actions.hideLoader());

    Toast({ message: 'global.toast.create_account_success', type: 'success', id: 'updateConnection' });

    return true;
  } catch (err: any) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    Toast({ message: 'global.toast.create_account_failure', type: 'error', id: 'updateConnection' });

    return err.response.data;
  }
};

export default registerPartnerForm;
