import * as React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { store } from './Store/Store';
import { Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { history } from './Store/Reducers/RootReducer';
import Routes from './Routes';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ToastContainer } from 'react-toastify';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from 'Shared/utils';
import { Locale } from 'Shared/components';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonTheme } from 'react-loading-skeleton';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, { locale: Locale() });

const RootApp = () => {
  {
    const [isReady, setIsReady] = React.useState(false);
    const translation = Locale() || 'pl';
    const setTranslations = () => {
      const script = document.createElement('script');
      script.src = `/translations/${translation}.js`;
      script.onload = () => {
        localStorage.setItem('translation', translation);
        setIsReady(true);
      };
      document.head.appendChild(script);
    };
    setTranslations();
    console.log(isReady);
    return (
      <IntlProvider
        messages={flattenMessages(window?.translations?.[translation])}
        locale={translation}
        key={translation}
        defaultLocale={translation}>
        <Provider store={store}>
          <Elements stripe={stripePromise}>
            <ConnectedRouter history={history}>
              <SkeletonTheme baseColor="#D9D9D9" highlightColor="#BFBFBF">
                <Route path="/" component={Routes} history={history} />
              </SkeletonTheme>
            </ConnectedRouter>
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </Elements>
        </Provider>
      </IntlProvider>
    );
  }
};
// );
export default RootApp;
