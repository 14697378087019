import { Website } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Website.MARK_WP_USER_ACTIVE_INIT,
});

const success = (id) => ({
  type: Website.MARK_WP_USER_ACTIVE_SUCCESS,
  payload: {
    id
  },
});

const failure = () => ({
  type: Website.MARK_WP_USER_ACTIVE_FAILURE,
});

const markWpUserActive = (id) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(success(id));
    return true;
  } catch (err: any) {
    console.log(err.response);
    dispatch(failure());
    return false;
  }
};

export default markWpUserActive;
