import React, { useRef, useState } from 'react';
import { MainType } from './Container';
import { Field, Form, Formik } from 'formik';
import { Step } from 'Screens/AddWebsite/components';
import { FormattedMessage } from 'react-intl';
import RegularField from 'Shared/form/regularField/regularField';
import { Button, SVG } from 'Shared/components';
import * as Yup from 'yup';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import zxcvbn from 'zxcvbn';

function FTP(props: MainType) {
  const formRef = useRef(null);
  const [isStrengthShown, setIsStrengthShown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    formData,
    maxStep,
    history,
    intl: { formatMessage },
    actions: { addWebsiteFormChange, addFtp },
  } = props;

  const query = new URLSearchParams(history.location.search);
  const website_uuid = query.get('id');
  const clientId = query.get('clientId');

  const getPasswordStrength = (password) => {
    const passwordStrength = zxcvbn(password).score;
    if (passwordStrength <= 1) {
      return 'weak';
    }
    if (passwordStrength > 1 && passwordStrength <= 3) {
      return 'medium';
    }
    if (passwordStrength > 3) {
      return 'strong';
    }
  };
  const handleSubmit = async (values: any) => {
    addWebsiteFormChange({ ftp: values });
    if (
      await addFtp({
        ...values,
        website_uuid: website_uuid ? website_uuid : formData.website_uuid,
        port: '21',
        protocol: 'ftp',
        username: values.userName,
        userName: undefined,
        password_strength: getPasswordStrength(values.password),
      })
    ) {
      if (clientId) {
        history.push(ROUTES.PARTNER + '?clientId=' + clientId);
        return;
      }
      if (history?.location?.state?.from) {
        history.push(history.location.state.from);
      }
      history.push(ROUTES.MY_WEBSITES);
    }
  };

  const validation = () => {
    return Yup.object().shape({
      host: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
      userName: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
      password: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
    });
  };

  const getPasswordStrengthLabel = (passwordStrengthValue) => (
    <>
      <p
        className={`add-website-form__tooltip-strength add-website-form__tooltip-strength--weak 
        ${passwordStrengthValue === 'weak' ? 'add-website-form__tooltip-strength--weak' : ''}
        ${passwordStrengthValue === 'medium' ? 'add-website-form__tooltip-strength--medium' : ''}
        ${passwordStrengthValue === 'strong' ? 'add-website-form__tooltip-strength--strong' : ''}
        `}>
        {passwordStrengthValue === 'weak' && formatMessage({ id: 'global.password_strength_1' })}
        {passwordStrengthValue === 'medium' && formatMessage({ id: 'global.password_strength_2' })}
        {passwordStrengthValue === 'strong' && formatMessage({ id: 'global.password_strength_3' })}
      </p>
    </>
  );

  return (
    <Formik
      enableReinitialize
      validationSchema={validation}
      innerRef={formRef}
      initialValues={{ password: '', host: '', userName: '' }}
      onSubmit={async (values) => {
        if (isSubmitting) return;
        setIsSubmitting(true);    
        await handleSubmit(values);
        setIsSubmitting(false);
      }}>
      {({ errors, values }) => (
        <Form>
          <Step currentStep={maxStep} lastStep={maxStep} formatMessage={formatMessage} />
          <h2 className="add-website-form__title">{formatMessage({ id: 'global.enter_ftp_data' })}</h2>
          <h4 className="add-website-form__subtitle">
            {formatMessage({ id: 'global.enter_ftp_data_subtitle2' })}
            <br /> <br /> <p>{formatMessage({ id: 'global.enter_ftp_data_subtitle3' })}</p>
          </h4>

          <div className="add-website-form__tooltip-wrapper">
            <div className={`add-website-form__packet-tooltip add-website-form__packet-tooltip--active`}>
              <SVG type={SVG_TYPE.TWISTED_ARROW} />
              <div className="add-website-form__tooltip-content">
                <SVG className="tooltip-svg tooltip-svg--ftp" type={SVG_TYPE.SHIELD_BULE_CHECK} />
                <h4>{formatMessage({ id: 'global.you_can_sleep_well' })} </h4>
                <p>{formatMessage({ id: 'global.all_data_is_encripted' })}</p>
              </div>
            </div>
          </div>

          <Field
            type="text"
            wrapperClassName="white"
            label={<FormattedMessage id="global.server_name" />}
            name="host"
            errorBelow
            component={RegularField}
            errors={errors}
          />
          <Field
            type="text"
            label={<FormattedMessage id="global.user_name" />}
            name="userName"
            component={RegularField}
            wrapperClassName="white"
            errorBelow
            errors={errors}
          />
          <div className="add-website-form__password">
            <Field
              type="password"
              label={<FormattedMessage id="global.password" />}
              name="password"
              errorBelow
              wrapperClassName="white without-margin"
              component={RegularField}
              errors={errors}
              onKeyPress={() => setIsStrengthShown(true)}
              customOnChange={() => setIsStrengthShown(true)}
              onBlur={() => setIsStrengthShown(true)}
            />
            <div className="add-website-form__tooltip-wrapper add-website-form__tooltip-wrapper--left">
              <div
                className={`add-website-form__packet-tooltip ${
                  isStrengthShown ? 'add-website-form__packet-tooltip--active' : ''
                } add-website-form__packet-tooltip--left`}>
                <div className="add-website-form__tooltip-content add-website-form__tooltip-content--left">
                  <SVG className="tooltip-svg tooltip-svg--ftp" type={SVG_TYPE.PACKETS_PRO_NO_BG} />
                  <h4>
                    {formatMessage({ id: 'global.password_strength' })} {getPasswordStrengthLabel(getPasswordStrength(values.password))}
                  </h4>
                  {getPasswordStrength(values.password) === 'weak' && <p>{formatMessage({ id: 'global.password_strength_subtitle' })}</p>}
                  {getPasswordStrength(values.password) === 'medium' && <p>{formatMessage({ id: 'global.password_strength_subtitle_medium' })}</p>}
                  {getPasswordStrength(values.password) === 'strong' && <p>{formatMessage({ id: 'global.password_strength_subtitle_strong' })}</p>}
                </div>
                <SVG type={SVG_TYPE.TWISTED_ARROW} className="tooltip__arrow" />
              </div>
            </div>
          </div>

          <Button type="submit" classNames={'btn__black'} label={formatMessage({ id: 'global.next' })} />
        </Form>
      )}
    </Formik>
  );
}

export default FTP;
