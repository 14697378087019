import React, { useEffect } from 'react';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { Link } from 'react-router-dom';
import { MainType } from './Container';
import Navigation from '../navigation/Navigation';
import * as H from 'history';
import { ROUTES } from 'Shared/enums';
import ADD_WEB_SITE_ROUTES from 'Screens/AddWebsite/AddWebsiteRoutes';

type P = {
  location: H.Location;
};

const Header = ({ isMenuExpanded, actions: { setMenuExpanded, setAppTranslation }, translation }: MainType & P) => {
  const { pathname } = location;
  const hideMenu = () => {
    setMenuExpanded(false);
  };
  const sliceFromPath = pathname.slice(0, 8);
  const isWebinarSite = sliceFromPath === '/webinar';
  const black =
    pathname === ROUTES.ABOUT_US ||
    pathname === ROUTES.ABOUT_CORGI ||
    pathname === ROUTES.WHY_CORGI ||
    pathname === ROUTES.FAQ ||
    pathname === ROUTES.CORGI_PRO_BONO;
  let menu;

  const isAddWebsite =
    pathname === ROUTES.ADD_WEBSITE ||
    pathname === ADD_WEB_SITE_ROUTES.ACCOUNT ||
    pathname === ADD_WEB_SITE_ROUTES.COMPANY ||
    pathname === ADD_WEB_SITE_ROUTES.CREDIT_CARD ||
    pathname === ADD_WEB_SITE_ROUTES.FTP ||
    pathname === ADD_WEB_SITE_ROUTES.WEBSITE;

  useEffect(() => {
    !isAddWebsite && sessionStorage.removeItem('addWebsiteForm');
    !isAddWebsite && sessionStorage.removeItem('website_uuid');
  }, [pathname]);

  if (black) {
    if (isMenuExpanded) {
      menu = <SVG type={SVG_TYPE.LOGO} className="svg--logo-white" />;
    } else {
      menu = <SVG className="svg--logo-white" type={SVG_TYPE.LOGO} />;
    }
  } else {
    menu = <SVG type={SVG_TYPE.LOGO} />;
  }

  let lastScrollTop = 0;
  let lastThresholdExceeded = 0;

  useEffect(() => {
    const handleScroll = () => {
      const st = window.pageYOffset || document.documentElement.scrollTop;

      if (Math.abs(st - lastThresholdExceeded) > 100) {
        if (st > lastScrollTop) {
          document.querySelector('.navi__header')?.classList.add('navi__header--hidden');
        } else {
          document.querySelector('.navi__header')?.classList.remove('navi__header--hidden');
        }
        lastThresholdExceeded = st;
      }

      lastScrollTop = st <= 0 ? 0 : st;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //remove hidden class on initial render
  useEffect(() => {
    setTimeout(() => {
      document.querySelector('.navi__header')?.classList.remove('navi__header--hidden');
    }, 1000);
  }, []);

  return (
    <header
      className={`navi__header navi__header--hidden ${
        pathname === ROUTES.ABOUT_US ||
        pathname === ROUTES.ABOUT_CORGI ||
        pathname === ROUTES.WHY_CORGI ||
        pathname === ROUTES.FAQ ||
        pathname === ROUTES.CORGI_PRO_BONO
          ? 'navi__header-white'
          : ''
      }
        ${isAddWebsite ? 'navi__header-hidden' : ''}
        
      `}>
      {!isWebinarSite && (
        <div className="navi__header-content">
          <div className={`navi__header--logo  ${isMenuExpanded ? 'navi__header--logo--active' : ''}`}>
            <Link to="/" onClick={hideMenu}>
              {menu}
            </Link>
          </div>
          <button
            className={`hamburger ${isMenuExpanded ? 'hamburger--active' : ''}`}
            onClick={() => {
              setMenuExpanded(!isMenuExpanded);
            }}>
            <span className="hamburger__box">
              <span className="hamburger__inner"></span>
            </span>
          </button>
          <div className={`navi__header--menu ${isMenuExpanded ? 'navi__header--menu--active' : ''}`}>
            {isMenuExpanded && (
              <div className="navi__header__logoMenuMobile">
                <SVG className="svg--logo-white" type={SVG_TYPE.LOGO} />
              </div>
            )}
            <nav className="navi__links">
              <Navigation hideMenu={hideMenu} />
            </nav>

            <div
              className={`navi__header--language ${
                pathname === ROUTES.WHY_CORGI ||
                pathname === ROUTES.ABOUT_CORGI ||
                pathname === ROUTES.ABOUT_US ||
                pathname === ROUTES.FAQ ||
                pathname === ROUTES.CORGI_PRO_BONO
                  ? 'navi__header--language-white'
                  : ''
              }`}>
              <select
                className="language-select"
                value={translation}
                onChange={({ currentTarget: { value } }) => {
                  setAppTranslation(value);
                  sessionStorage.setItem('language', value);
                  window.location.reload();
                }}>
                <option value="pl">PL</option>
                <option value="en">EN</option>
                <option value="de">DE</option>
              </select>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
