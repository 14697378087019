import * as Types from 'Shared/models/NotificationModel';
import { Notification } from '../Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Shared/enums';

export type State = Types.NotificationStore;

export const initialState: State = {
  myNotifications: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  myNotificationsWebsite: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  myNotificationsPartners: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
};

export function notificationReducer(state: any = initialState, action) {
  switch (action.type) {
    case Notification.GET_NOTIFICATION_INIT: {
      return {
        ...state,
        myNotifications: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Notification.GET_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        myNotifications: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Notification.GET_NOTIFICATION_FAILURE: {
      return {
        ...state,
        myNotifications: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Notification.GET_NOTIFICATION_PARTNERS_INIT: {
      return {
        ...state,
        myNotificationsPartners: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Notification.GET_NOTIFICATION_PARTNERS_SUCCESS: {
      return {
        ...state,
        myNotificationsPartners: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Notification.GET_NOTIFICATION_PARTNERS_FAILURE: {
      return {
        ...state,
        myNotificationsPartners: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Notification.GET_NOTIFICATION_WEBSITE_INIT: {
      return {
        ...state,
        myNotificationsWebsite: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Notification.GET_NOTIFICATION_WEBSITE_SUCCESS: {
      return {
        ...state,
        myNotificationsWebsite: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Notification.GET_NOTIFICATION_WEBSITE_FAILURE: {
      return {
        ...state,
        myNotificationsWebsite: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }

    default: {
      return state;
    }
  }
}
