import React, { useState } from 'react';
import OutsideClick from '../outsideClick';
import { SVG_TYPE } from 'Shared/enums';
import SVG from '../SVG';
import moment from 'moment';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

interface DateRangeProps {
  startDate: Date;
  endDate: Date | null;
  onChange: (any) => void;
  label?: string;
}
const DateRange: React.FC<DateRangeProps> = ({ startDate, endDate, onChange, label }) => {
  const [isDataRangeVisible, setDataRangeVisible] = useState(true);
  return (
    <div className={`date-range`}>
      {label ? <label className="date-range__label">{label}</label> : null}
      <div className={`date-range__calendar ${isDataRangeVisible ? '' : 'date-range__calendar--hidden'}`}>
        <OutsideClick onClick={() => setDataRangeVisible(false)}>
          <span className="date-range__inputs-wrapper" onClick={() => setDataRangeVisible(true)}>
            <DateRangePicker
              onChange={(item) => onChange(item)}
              value={[startDate, endDate]}
              maxDate={new Date()}
              showLeadingZeros
              className={'date-range'}
              formatShortWeekday={(locale, date) => {
                //moment splice to 1 letter
                return moment(date).format('dd')[0];
              }}
            />
            <SVG type={SVG_TYPE.CALENDAR} />
          </span>
        </OutsideClick>
      </div>
    </div>
  );
};

export default DateRange;
