import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
// import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: User.GET_MY_WEBSITES_INIT,
});

const success = (websites, companyUuid) => ({
  type: User.GET_USER_WEBSITES_SUCCESS,
  payload: {
    websites,
    companyUuid,
  },
});

const failure = () => ({
  type: User.GET_MY_WEBSITES_FAILURE,
});

const getUserWebsites =
  (companyUuid, onlyRefresh: boolean = false) =>
  async (dispatch) => {
    // Do odświezenia tabelki nie potrzeba jej na nowo inizjalizować
    if (!onlyRefresh) {
      dispatch(init());
    }
    try {
      // dispatch(Actions.ShowDashboardWebsitesInfoLoader());
      const { data } = await Connector.getMyWebsites(companyUuid);
      dispatch(success(data, companyUuid));
      // dispatch(Actions.HideDashboardWebsitesInfoLoader());
    } catch (err) {
      dispatch(failure());
      // dispatch(Actions.HideDashboardWebsitesInfoLoader());
    }
  };

export default getUserWebsites;
