import { Connector } from '../../../Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { ROUTES } from 'Shared/enums';
import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
  type: User.SEND_NEWSLETTER_FORM_INIT,
});

const success = () => ({
  type: User.SEND_NEWSLETTER_FORM_SUCCESS,
});

const failure = () => ({
  type: User.SEND_NEWSLETTER_FORM_FAILURE,
});

const sendNewsletterForm = (dataForm) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await Connector.sendNewsletterForm(dataForm);
    dispatch(success());
    history.push({ pathname: ROUTES.NEWSLETTER_SIGN_UP, fullName: `${dataForm.first_name} ${dataForm.last_name}` });
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default sendNewsletterForm;
