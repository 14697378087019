import { Auth } from '../EnumTypes/ActionEnumTypes';
import { Connector as AuthConnector } from 'Modules/AuthModule';
import zxcvbn from 'zxcvbn';
import { Toast } from 'Shared/components';


const init = () => ({
  type: Auth.SET_NEW_PASSWORD_INIT,
});

export const success = () => ({
  type: Auth.SET_NEW_PASSWORD_SUCCESS,
});

const failure = () => ({
  type: Auth.SET_NEW_PASSWORD_FAILURE,
});

const setNewPassword = (token: string, password: any) => {
  const getPasswordStrength = (password) => {
    const passwordStrength = zxcvbn(password).score;
    if (passwordStrength <= 1) {
      return 'weak';
    }
    if (passwordStrength > 1 && passwordStrength <= 3) {
      return 'medium';
    }
    if (passwordStrength > 3) {
      return 'strong';
    }
  };

  return async (dispatch) => {
    dispatch(init());
    Toast({ id: 'setNewPassword', type: 'loading', message: 'global.apex_no_data' });
    try {
      await AuthConnector.setNewPassword(token, password, getPasswordStrength(password));
      dispatch(success());
      Toast({ id: 'setNewPassword', type: 'success', message: 'global.toast.change_password__success' });
      return true;
    } catch (err: any) {
      dispatch(failure());
      Toast({ id: 'setNewPassword', type: 'error', message: 'global.toast.change_password__failure' })
      return err.response.status;
    }
  };
};

export default setNewPassword;
