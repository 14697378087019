import React from 'react';
import { Redirect } from 'react-router-dom';
import Header from 'Shared/components/header/Container';
import View404 from 'Assets/Image/404.svg';
import { FormattedMessage } from 'react-intl';
import { ROUTES } from 'Shared/enums';

type P = {
  location: any;
};
const Page404 = ({ location: { pathname } }: P) => {
  if (pathname === ROUTES.PAGE_404) {
    return (
      <>
        <Header location={location} />
        <div className="page404__wrapper">
          <div className="page404__content">
            <img src={View404} alt="widok 404" />
            <p className="page404--paragraph">
              <FormattedMessage id="global.page404" />
            </p>
          </div>
        </div>
      </>
    );
  } else return <Redirect to={ROUTES.PAGE_404} />;
};

export default Page404;
