import detectBrowserLanguage from 'detect-browser-language';

function BrowserLanguage(setAppTranslation) {
  if (detectBrowserLanguage().slice(-2).toLowerCase() === 'pl') {
    setAppTranslation('pl');
    return 'pl';
  } else if(detectBrowserLanguage().slice(-2).toLowerCase() === 'de'){
    setAppTranslation('de');
    return 'de';
  }else{
    setAppTranslation('en');
    return 'en';
  }
  // console.log(detectBrowserLanguage().slice(-2).toLowerCase());
}

export default BrowserLanguage;
