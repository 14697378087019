import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import { useRef } from 'react';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import RegularField from 'Shared/form/regularField/regularField';
import { Button } from 'Shared/components';
import PasswordStrengthChecker from 'Components/PasswordStrengthChecker/PasswordStrengthChecker';
import PasswordValidation from 'Components/PasswordValidation/PasswordValidation';

type P = {
  history: any;
  formatMessage: any;
  setNewPassword: (token, password) => any;
  setApiError: (value: boolean) => void;
  token: string;
  setSuccessfullPasswordChange: (value: boolean) => void;
};

const CreateNewPassword = ({ formatMessage, setNewPassword, setApiError, token, setSuccessfullPasswordChange }: P) => {
  const [showValidation, setShowValidation] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const formRef = useRef(null);
  const validation = () =>
    Yup.object().shape({
      password: Yup.string()
        .required(formatMessage({ id: 'global.validation.required' }))
        .min(8, formatMessage({ id: 'global.validation.min_8' }))
        .test('Capital Letter', ' ', function (value) {
          return /[A-Z]/.test(value);
        })
        .test('Lowercase Letter', ' ', function (value) {
          return /[a-z]/.test(value);
        })
        .test('Number', ' ', function (value) {
          return /[0-9]/.test(value);
        }),

      confirmPassword: Yup.string()
        .required(formatMessage({ id: 'global.validation.required' }))
        .oneOf([Yup.ref('password'), null], formatMessage({ id: 'global.validation.password' })),
    });
  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={validation()}
      onSubmit={async ({ password }) => {
        const err = await setNewPassword(token, password);
        console.log(err);
        if (err.status === 401) {
          setApiError(true);
        } else if (err == true) {
          setSuccessfullPasswordChange(true);
        }
      }}>
      {({ handleSubmit, errors, values }) => (
        <form onSubmit={handleSubmit} className="login-page__form">
          <h1 className="login-page__title">{formatMessage({ id: 'global.create_new_password' })}&nbsp;🔐</h1>
          <Field
            label={<FormattedMessage id="global.login.password" />}
            name="password"
            wrapperClassName="white"
            type="password"
            errorBelow
            component={RegularField}
            errors={errors}
            inputOnFocus={() => setShowValidation(true)}
            onBlur={() => isPasswordValid && setShowValidation(false)}
          />
          <PasswordStrengthChecker isHidden={!showValidation} password={values.password} formatMessage={formatMessage} />
          <PasswordValidation
            className={` ${showValidation ? 'validation-item__wrapper--show' : ''}`}
            password={values.password}
            formatMessage={formatMessage}
            setIsValid={(value: boolean) => setIsPasswordValid(value)}
          />
          <Field
            label={<FormattedMessage id="global.change_password.repeat_password" />}
            name="confirmPassword"
            wrapperClassName="white"
            type="password"
            errorBelow
            component={RegularField}
            errors={errors}
          />
          <Button
            type="submit"
            label={<FormattedMessage id="global.save_new_password" />}
            classNames="btn--blue login-page__submit"
            iconAlt="icon product"
          />
        </form>
      )}
    </Formik>
  );
};
export default CreateNewPassword;
