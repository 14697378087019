import { WEBSITE_STATUSES } from 'Shared/enums';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const handleWebsiteStatusLabel = (sslStatus: 'ACTIVE' | 'INACTIVE' | 'CONFIRMING_FAILURE' | 'INACTIVE_SUBPAGE') => {
  switch (sslStatus) {
    case WEBSITE_STATUSES.ACTIVE:
      return <FormattedMessage id="global.itworks" />;

    case WEBSITE_STATUSES.INACTIVE:
      return <FormattedMessage id="global.not_working" />;

    case WEBSITE_STATUSES.CONFIRMING_FAILURE:
      return <FormattedMessage id="global.confirming_malfunction" />;

    case WEBSITE_STATUSES.INACTIVE_SUBPAGE:
      return <FormattedMessage id="global.subpage_not_working" />;

    default:
      return <FormattedMessage id="global.no_data" />;
  }
};

export default handleWebsiteStatusLabel;
