import * as Types from 'Shared/models/UpdatesModel';
import { Updates } from '../Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Shared/enums';

export type State = Types.UpdatesStore;

export const initialState: State = {
  myUpdates: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  myUpdatesPartners: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  myUpdatesWebsite: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
  myAwaitingUpdates: {
    data: null,
    status: REQUEST_STATUS.IDLE,
  },
};

export function updatesReducer(state: any = initialState, action) {
  switch (action.type) {
    case Updates.GET_UPDATES_INIT: {
      return {
        ...state,
        myUpdates: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Updates.GET_UPDATES_SUCCESS: {
      return {
        ...state,
        myUpdates: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Updates.GET_UPDATES_FAILURE: {
      return {
        ...state,
        myUpdates: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Updates.GET_UPDATES_WEBSITE_INIT: {
      return {
        ...state,
        myUpdatesWebsite: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Updates.GET_UPDATES_WEBSITE_SUCCESS: {
      return {
        ...state,
        myUpdatesWebsite: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Updates.GET_UPDATES_WEBSITE_FAILURE: {
      return {
        ...state,
        myUpdatesWebsite: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Updates.GET_UPDATES_PARTNERS_INIT: {
      return {
        ...state,
        myUpdatesPartners: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Updates.GET_UPDATES_PARTNERS_SUCCESS: {
      return {
        ...state,
        myUpdatesPartners: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Updates.GET_UPDATES_PARTNERS_FAILURE: {
      return {
        ...state,
        myUpdatesPartners: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Updates.GET_AWAITING_UPDATES_INIT: {
      return {
        ...state,
        myAwaitingUpdates: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Updates.GET_AWAITING_UPDATES_SUCCESS: {
      return {
        ...state,
        myAwaitingUpdates: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Updates.GET_AWAITING_UPDATES_FAILURE: {
      return {
        ...state,
        myAwaitingUpdates: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default: {
      return state;
    }
  }
}
