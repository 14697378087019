export const isDowngrade = (newPlan,oldPlan) => {
    if (!oldPlan) {
      return false;
    }
    if (oldPlan === 'premium' && newPlan === 'pro') {
      return false;
    }
    if (oldPlan === 'basic') {
      return false;
    }
    if (oldPlan === newPlan) {
      return false;
    }

    return true;
  };
