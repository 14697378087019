import React from 'react';
import UserMenu from './components/UserMenu/';
import AdminMenu from './components/AdminMenu/';
// import { ROUTES, ROUTES_ADMIN_PANEL } from 'Shared/enums';

type P = {
  openMenuFn: boolean;
  logout: () => void;
  close?: any;
  selectMenuFn?: any;
  selectActive?: any;
  actions: any;
  translation: any;
  loggedUser: any;
  location?: any;
  paymentMethods?: any;
};

const Menu = ({
  actions: { setAppTranslation },
  paymentMethods,
  loggedUser,
  translation,
  selectMenuFn,
  openMenuFn,
  selectActive,
  logout,
  location,
}: P) => {
  return (
    <div className="userMenu__wrapper">
      <div
        className={`userMenu ${openMenuFn ? 'userMenu--active' : ''} ${
          loggedUser.role === 'admin' && 'userMenu--active-desktop userMenu--no-shield'
        }`}>
        {loggedUser.role === 'admin' ? (
          <AdminMenu
            setAppTranslation={setAppTranslation}
            translation={translation}
            selectMenuFn={selectMenuFn}
            selectActive={selectActive}
            logout={logout}
            location={location}
          />
        ) : (
          <UserMenu
            setAppTranslation={setAppTranslation}
            translation={translation}
            selectMenuFn={selectMenuFn}
            selectActive={selectActive}
            logout={logout}
            loggedUser={loggedUser}
            paymentMethods={paymentMethods}
          />
        )}
      </div>
    </div>
  );
};

export default Menu;
