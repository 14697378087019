import { Website } from '../EnumTypes/ActionEnumTypes';

const success = () => ({
  type: Website.CLEAR_CUSTOM_PINGS_SUCCESS,
});


const clearCustomPings = () => async (dispatch) => {
  try {
    dispatch(success());
  } catch (err) {
  }
};

export default clearCustomPings;
