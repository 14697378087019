import { connect } from 'react-redux';
import * as AuthModule from 'Modules/AuthModule';
import { RootState } from 'Store/Reducers/RootReducer';
import RegisterPage from './Main';
import { Actions as VActions, Selectors as VSelectors } from 'Modules/ViewManagementModule';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';
import { Actions as UserActions } from 'Modules/UserModule';
import { Selectors as PaymentSelector } from 'Modules/PaymentModule';

type ConnectedP = {
  authorizedUser: AuthModule.Types.AuthorizedUserData;
  translation: string;
  history?: any;
  stripePrice: any;
};

type DispatchedP = {
  actions?: {
    setAppTranslation: (lang: string) => void;
    openModal: () => void;
    closeModal: () => void;
    registerPartnerForm: (dataForm, withoutRoute) => any;
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      openModal: () => VActions.openModal(),
      closeModal: () => VActions.closeModal(),
      setAppTranslation: VActions.setAppTranslation,
      registerPartnerForm: UserActions.registerPartnerForm,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  authorizedUser: AuthModule.Selectors.authorizedUser(state),
  translation: VSelectors.getTranslation(state),
  stripePrice: PaymentSelector.getStripePrice(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RegisterPage));
