import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions as WebsiteActions } from 'Modules/WebsiteModule';
import CheckSite from './Main';
import { isMobile } from 'Shared/utils';
import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';
import { Selectors as VSelectors } from 'Modules/ViewManagementModule';
import { RootState } from 'Store/Reducers/RootReducer';
import { Actions, Selectors } from 'Modules/UserModule';

type ConnectedP = {
  loggedUser: any;
  isMobile: boolean;
  translation: string;
  history?: any;
};

type DispatchedP = {
  actions: {
    getAuthorizedUser: () => void;
    getCheckWebsite: any;
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getAuthorizedUser: Actions.getAuthorizedUser,
      getCheckWebsite: WebsiteActions.getCheckWebsite,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  isMobile: isMobile(),
  translation: VSelectors.getTranslation(state),
  loggedUser: Selectors.getLoggedUser(state),

});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CheckSite));
