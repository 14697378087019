import { Connector } from 'Modules/WebsiteModule';
import { Website } from 'Store/Actions/EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';


const success = (data) => ({
  type: Website.GET_WP_USERS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_WP_USERS_FAILURE,
});

const getWpUsers = (uuid) => async (dispatch) => {
  try {
    dispatch(Actions.showLoader());
    const { data } = await Connector.getWpUsers(uuid);
    dispatch(success(data));
    dispatch(Actions.hideLoader());
    return data;
  } catch (err) {
    console.log(err);
    dispatch(Actions.hideLoader());
    dispatch(failure());
  }
};

export default getWpUsers;
