import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import RegularField from 'Shared/form/regularField/regularField';
import { Button, SVG } from 'Shared/components';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { MainType } from './Container';
import ProgressBar from '@ramonak/react-progress-bar';
import { history } from 'Store/Reducers/RootReducer';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'Shared/utils';
import { gsap } from 'gsap';

type P = {
  url?: boolean;
};

const CheckSite = ({ url, actions: { getCheckWebsite, getAuthorizedUser }, intl: { formatMessage }, loggedUser }: MainType & P) => {
  const [isLoad, setIsLoad] = useState(false);
  const [transitionDuration, setTransitionDuration] = useState('8s');
  const [progress, setProgress] = useState(3);
  const [count, setCount] = useState(0);
  const [response, setResponse] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlParam = searchParams.get('url');
  const loadingUrl = isLoad && urlParam;

  useEffect(() => {
    const unlisten = history.listen(() => {
      setResponse(null);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    const fetchData = async () => {
      await getAuthorizedUser();
    };
    !loggedUser && fetchData();
  }, []);

  useEffect(() => {
    if (progress !== 100) {
      const timer = count > 0 && setInterval(() => setCount(count - 1), 1000);
      if (count === 1) {
        setProgress(95);
        setTransitionDuration('25s');
      }
      return () => clearInterval(timer);
    }
  }, [count]);

  useEffect(() => {
    function corgiFly(element) {
      const amplitude = isMobile() ? 15 : 50; // odległość, o którą porusza się element
      const duration = isMobile() ? 1 : 2; // częstotliwość drgań

      gsap.to(element, {
        y: amplitude,
        duration: duration,
        yoyo: true,
        repeat: -1,
        ease: 'sine.inOut',
      });
    }

    const svgImage = document.getElementById('corgiFly');
    corgiFly(svgImage);
  }, []);

  const validation = () =>
    Yup.object().shape({
      url: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
    });

  const runLoader = () => {
    setIsLoad(true);
    setProgress(80);
    setTimeout(() => {
      setCount(8);
    }, 100);
  };

  const afterReturnChecker = (urlParam) => {
    setTransitionDuration('1s');
    setProgress(100);
    setTimeout(() => {
      history.push(`${ROUTES.WEBSITE_CHECKER}?url=${urlParam}`);
    }, 1500);
  };

  useEffect(() => {
    if (response) {
      afterReturnChecker(response.websiteUrl);
    }
  }, [response]);

  useEffect(() => {
    url && urlParam && runLoader();
    async function fetchData() {
      const data = await getCheckWebsite(urlParam, loggedUser?.uuid);
      data && afterReturnChecker(urlParam);
    }
    urlParam && fetchData();
  }, [urlParam]);

  return (
    <div className="checkSite">
      <SVG className="checkSite__background" type={SVG_TYPE.CHECKER_BACKGROUND} />
      <div className="checkSite__content">
        <div className="checkSite__l">
          <SVG type={SVG_TYPE.CORGI_PREMIUM_FLY} />
        </div>
        <div className="checkSite__r">
          <p className="checkSite--heading">{formatMessage({ id: 'global.checker.check_level' })}</p>
          <p className="checkSite--heading">{formatMessage({ id: 'global.checker.security_your_website' })}</p>
          <p className="checkSite--paragraph">{formatMessage({ id: 'global.checker.add_address_url' })}</p>
          <div className="checkSite--form">
            {!isLoad && !loadingUrl ? (
              <Formik
                initialValues={{
                  url: '',
                }}
                validationSchema={validation()}
                onSubmit={async ({ url }) => {
                  runLoader();
                  const data = await getCheckWebsite(url, loggedUser?.uuid);
                  setResponse(data);
                }}>
                {({ handleSubmit, errors }) => (
                  <form onSubmit={handleSubmit} onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}>
                    <div className="checkSite__form">
                      <Field
                        placeholder={formatMessage({ id: 'global.checker.write_address_site' })}
                        type="text"
                        name="url"
                        component={RegularField}
                        errors={errors}
                      />
                      <Button
                        type="submit"
                        onClick={() => handleSubmit()}
                        label={formatMessage({ id: 'global.checker.test_site' })}
                        classNames="btn--blue"
                        iconAlt="icon product"
                        iconImgSvg={SVG_TYPE.RIGHT_ARROW_BOLD}
                      />
                    </div>
                  </form>
                )}
              </Formik>
            ) : (
              <>
                <p className="progressBar--defaultState" />
                <ProgressBar
                  completed={progress}
                  bgColor="#3832EC"
                  borderRadius="30"
                  transitionDuration={transitionDuration}
                  labelClassName="progressBar__label"
                  barContainerClassName="progressBar"
                  animateOnRender
                  initCompletedOnAnimation={0}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckSite;
