import React from 'react';
import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';
import { FormattedMessage } from 'react-intl';

function PaymentMethodsList({ paymentMethods, setStep, setCard, card, setActiveCard }: any) {
  return paymentMethods.map((payment) => {
    return (
      <section className="dashboardSidebar__payments-card--card" key={payment.id}>
        <span>
          <SVG className="svg--card" type={SVG_TYPE.CARD} /> {payment.card.last4}
        </span>
        {card === 'edit-card' ? (
          <p
            className="dashboardSidebar__red"
            onClick={() => {
              setStep('remove-card');
              setCard('remove-card');
              setActiveCard(payment);
            }}>
            <FormattedMessage id="global.payments.remove_card" />
          </p>
        ) : (
          <p
            className="dashboardSidebar__white"
            onClick={() => {
              setStep('edit-card');
              setCard('edit-card');
            }}>
            <FormattedMessage id="global.payments.change_card" />
          </p>
        )}
      </section>
    );
  });
}

export default PaymentMethodsList;
