import { connect } from 'react-redux';
import { Actions as AuthActions } from 'Modules/AuthModule';
import { RootState } from 'Store/Reducers/RootReducer';
import { bindActionCreators } from 'redux';
import { Actions, Selectors } from 'Modules/UserModule';
import { Actions as UserActions } from 'Modules/UserModule';
import DashboardSidebar from './Main';

type ConnectedP = {
  loggedUser: any;
  paymentMethods: any;
};
type DispatchedP = {
  actions: {
    logout: () => void;
    getAuthorizedUser: () => void;
    addPaymentMethod: (paymentData) => void;
    editUser: (data) => any;
    deletePaymentMethod: (id) => void;
    getPaymentMethods: () => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      logout: () => AuthActions.logout(),
      getAuthorizedUser: Actions.getAuthorizedUser,
      editUser: (editedUserData) => UserActions.editUser(editedUserData),
      addPaymentMethod: (paymentData) => UserActions.addPaymentMethod(paymentData),
      deletePaymentMethod: (id) => UserActions.deletePaymentMethod(id),
      getPaymentMethods: UserActions.getPaymentMethods,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  loggedUser: Selectors.getLoggedUser(state),
  paymentMethods: Selectors.getPaymentMethods(state),
});

// export default DashboardSidebar;
export default connect(mapStateToProps, mapDispatchToProps)(DashboardSidebar);
