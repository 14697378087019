import React, { useEffect, useRef, useState } from 'react';
import { MainType } from './Container';
import { Field, Form, Formik } from 'formik';
import { Step } from 'Screens/AddWebsite/components';
import { FormattedMessage } from 'react-intl';
import RegularField from 'Shared/form/regularField/regularField';
import { Button, Locale, SVG } from 'Shared/components';
import ADD_WEB_SITE_ROUTES from 'Screens/AddWebsite/AddWebsiteRoutes';
import { AddressElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SVG_TYPE, TAX_IDS, VALIDATION_FIELD } from 'Shared/enums';
import * as Yup from 'yup';
import { getCountryFromTranslation, getVatPrefixFromTranslation } from 'Shared/utils';
import { toast } from 'react-toastify';

function Company(props: MainType) {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, { locale: Locale() });
  const formRef = useRef(null);
  const [isAddressCompleted, setIsAddressCompleted] = useState(false);
  const {
    history,
    maxStep,
    formData,
    getLoggedUser,
    authorizedUser,
    actions: { addWebsiteFormChange, fillCompanyData, addWebsite, setCompanyVat },
    intl: { formatMessage },
  } = props;
  const withoutAccountForm = history.location.withoutAccountForm;

  const query = new URLSearchParams(history.location.search);
  const website_uuid = query.get('id');

  const url = history.location.url || formData.url;

  const handleAddressElementChange = async (event, setFieldValue, values) => {
    const { city, line1, line2, postal_code, state, country } = event.value.address;
    const { zip_code, address } = values;
    setIsAddressCompleted(event.complete);
    if (city !== values.city) {
      setFieldValue('city', city);
    }
    if (`${line1} ${line2 !== null ? line2 : ''}` !== address) {
      setFieldValue('address', `${line1} ${line2 !== null ? line2 : ''}`);
    }
    if (postal_code !== zip_code) {
      setFieldValue('zip_code', postal_code);
    }
    if (country !== values.country) {
      setFieldValue('country', country);
    }
    if (state !== values.state) {
      setFieldValue('state', state);
    }
  };

  const validation = () => {
    return Yup.object().shape({
      vat_id: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
      vat_prefix: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
      companyName: Yup.string()
        .min(2, formatMessage({ id: 'global.validation.min_2' }))
        .max(50, formatMessage({ id: 'global.validation.max_50' }))
        .matches(/^[^\s].*/, VALIDATION_FIELD(formatMessage).EMPTY_SPACE)
        .required(formatMessage({ id: 'global.validation.required' })),
      address: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
      zip_code: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
      city: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
      country: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
      // state: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
    });
  };
 
  useEffect(() => {
    if (authorizedUser?.company?.address && authorizedUser?.company?.vat_id) {
      history.push({ ...history.location, pathname: ADD_WEB_SITE_ROUTES.CREDIT_CARD });
    }
  }, []);

  const handleSendTaxId = async () => {
    const { values, setFieldTouched, setFieldError } = formRef.current;
    const prefix = values.vat_prefix;
    const vat = values.vat_id;
    const vatID = vat && prefix.vat_country === 'PL' ? `${prefix.vat_country}${vat}` : vat;
    const response = await setCompanyVat({
      vat_type: prefix.vat_type,
      vat_country: prefix.vat_country,
      vat_id: vatID,
    });
    if (response === 422 || response === 401) {
      setFieldTouched('vat_id', true, false);
      setFieldError('vat_id', formatMessage({ id: 'global.validation.incorrect_nip' }));
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const toastId = toast.loading(<FormattedMessage id="global.apex_no_data" />);
    let showSuccess = true;
    try {
      await addWebsiteFormChange({ company: formRef.current.values });

      if (withoutAccountForm && !formData.website_uuid) {
        await addWebsite({ url: url, plan: formData.plan });
      }

      if (
        !(await fillCompanyData(
          {
            name: getLoggedUser.name,
            email: getLoggedUser.email,
            lang: getLoggedUser.lang || 'pl',
            company: {
              ...formRef.current.values,
              vat_prefix: undefined,
              vat_id: undefined,
              vat_country: undefined,
              companyName: undefined,
              name: formRef.current.values.companyName,
              vat_type: undefined,
            },
          },
          getLoggedUser.uuid,
        ))
      ) {
        showSuccess = false;
      }
      if (!(await handleSendTaxId())) {
        showSuccess = false;
        toast.update(toastId, {
          render: (
            <div>
              <h2 className="toast-title toast-title--failure">
                <FormattedMessage id="global.toast_failure" />
              </h2>{' '}
              <p>
                <FormattedMessage id="global.toast.add_company_data_failure" />
              </p>
            </div>
          ),
          type: toast.TYPE.ERROR,
          isLoading: false,
          closeButton: true,
          autoClose: 3000,
          icon: <SVG type={SVG_TYPE.CLOSE_CIRCLE_FILL} variant={SVG_TYPE.CLOSE_CIRCLE_FILL} />,
        });
        return;
      }
      if (showSuccess) {
        toast.update(toastId, {
          render: (
            <div>
              <h2 className="toast-title toast-title--success">
                {' '}
                <FormattedMessage id="global.toast_succes" />
              </h2>{' '}
              <p>
                <FormattedMessage id="global.toast.add_company_data_success" />
              </p>
            </div>
          ),
          type: toast.TYPE.SUCCESS,
          isLoading: false,
          closeButton: true,
          autoClose: 3000,
          // icon: <SVG type={SVG_TYPE.PASSED} />,
        });
      } else {
        toast.update(toastId, {
          render: (
            <div>
              <h2 className="toast-title toast-title--failure">
                <FormattedMessage id="global.toast_failure" />
              </h2>{' '}
              <p>
                <FormattedMessage id="global.toast.add_company_data_failure" />
              </p>
            </div>
          ),
          type: toast.TYPE.ERROR,
          isLoading: false,
          closeButton: true,
          autoClose: 3000,
          icon: <SVG type={SVG_TYPE.CLOSE_CIRCLE_FILL} variant={SVG_TYPE.CLOSE_CIRCLE_FILL} />,
        });
      }
      website_uuid
        ? history.push(ADD_WEB_SITE_ROUTES.CREDIT_CARD + '?id=' + website_uuid)
        : history.push({ ...history.location, pathname: ADD_WEB_SITE_ROUTES.CREDIT_CARD });
    } catch (e) {
      toast.update(toastId, {
        render: (
          <div>
            <h2 className="toast-title toast-title--failure">
              <FormattedMessage id="global.toast_failure" />
            </h2>{' '}
            <p>
              <FormattedMessage id="global.toast.add_company_data_failure" />
            </p>
          </div>
        ),
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG type={SVG_TYPE.CLOSE_CIRCLE_FILL} variant={SVG_TYPE.CLOSE_CIRCLE_FILL} />,
      });
    }
  };
  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={{
        vat_prefix: getVatPrefixFromTranslation(),
        vat_id: '',
        companyName: authorizedUser?.company?.name || '',
        address: authorizedUser?.company?.address || '',
        zip_code: authorizedUser?.company?.zip_code || '',
        city: authorizedUser?.company?.city || '',
        country: authorizedUser?.company?.country || '',
        state: authorizedUser?.company?.state || '',
      }}
      validationSchema={validation()}
      onSubmit={async () => {
        if (sessionStorage.getItem('submitting')) return;
        
        sessionStorage.setItem('submitting', 'true'); 
        isAddressCompleted && await handleSubmit();
        sessionStorage.removeItem('submitting');
      }}>
      {({ errors, setFieldValue, values, handleBlur }) => (
        <Form>
          <Step currentStep={maxStep - 2} lastStep={maxStep} formatMessage={formatMessage} />
          <h2 className="add-website-form__title">{formatMessage({ id: 'global.enter_company_data' })}</h2>
          <h4 className="add-website-form__subtitle">{formatMessage({ id: 'global.service_only_for_companies' })}</h4>
          <div className="add-website-form__wrapper">
            <div className="add-website-form__nip">
              <Field
                type="select"
                options={TAX_IDS}
                handleBlur={handleBlur}
                label={<FormattedMessage id="global.packet_form.nip" />}
                name="vat_prefix"
                isClearable={false}
                component={RegularField}
                wrapperClassName="white "
                // isSearchable={false}
                errorBelow
                isTax
                errors={errors}
              />
              <Field
                type="text"
                handleBlur={handleBlur}
                wrapperClassName="white"
                label="&nbsp;"
                name="vat_id"
                errorBelow
                component={RegularField}
                errors={errors}
              />
            </div>
            <Field
              type="text"
              wrapperClassName="white form__field--companyName"
              label={formatMessage({ id: 'global.dashboard.company_name' })}
              name="companyName"
              handleBlur={handleBlur}
              component={RegularField}
              errorBelow
              errors={errors}
            />
            {authorizedUser != '0' ? (
              <Elements
                stripe={stripePromise}
                options={{
                  fonts: [
                    {
                      cssSrc: 'https://fonts.googleapis.com/css?family=Poppins:400,500,700,900|Raleway:400,500,700,900',
                    },
                  ],
                  appearance: {
                    variables: {
                      colorBackground: '#F9F9F9',
                      fontFamily: 'Poppins, sans-serif',
                      spacingGridColumn: '0',
                      colorIconChevronDown: 'hsl(0,0%,80%)',
                    },
                    rules: {
                      '.Label': {
                        fontWeight: '700',
                        fontSize: '14px',
                        color: '#111',
                        lineHeight: '24px',
                      },
                      '.div': {
                        paddingLeft: '2px',
                      },
                      '.Error': {
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: '500',
                        fontSize: '12px',
                        color: '#E20D0D',
                        marginLeft: '-4px',
                        marginTop: '8px',
                      },
                      '.Input': {
                        display: 'flex',
                        outerWidth: '50%',
                        margin: '0 1px',
                        border: 'unset',
                        outline: 'unset',
                        boxShadow: 'none',
                        fontWeight: '400',
                        fontSize: '14px',
                        color: '#111',
                        lineHeight: '24px',
                        backgroundColor: '#FFF',
                        marginLeft: '-4px',
                        width: '352px',
                      },
                      '.p-HeightObserverProvider-container': {
                        marginLeft: '0',
                        paddingLeft: '0',
                      },
                      '.Input:active': {
                        borderColor: 'unset',
                        border: 'unset',
                        outline: 'unset',
                        boxShadow: 'none',
                      },
                      '.Input:focus': {
                        borderColor: 'unset',
                        border: 'unset',
                        outline: 'unset',
                        boxShadow: 'none',
                      },
                      '.Input--invalid': {
                        border: '1px solid',
                        boxShadow: 'none',
                      },
                      '.Input::-webkit-input-placeholder': {
                        color: 'transparent',
                      },
                      '.Input::placeholder': {
                        color: 'transparent',
                      },
                      '.Block': {
                        marginRight: '3px',
                      },
                    },
                  },
                }}>
                <ElementsConsumer>
                  {({ elements }) => {
                    return (
                      <>
                        <div className=" myData__stripe myData__stripe--hideName">
                          <AddressElement
                            onChange={(event) => {
                              handleAddressElementChange(event, setFieldValue, values);
                            }}
                            options={{
                              mode: 'shipping',
                              fields: {
                                phone: 'never',
                              },

                              defaultValues: {
                                name: '.',
                                address: {
                                  line1: authorizedUser?.company?.address || '',
                                  line2: '',
                                  city: authorizedUser?.company?.city || '',
                                  state: authorizedUser?.company?.state || '',
                                  postal_code: authorizedUser?.company?.zip_code || '',
                                  country: authorizedUser?.company?.country || getCountryFromTranslation(),
                                },
                              },
                            }}
                          />
                        </div>
                        <Button
                          type="submit"
                          classNames={'btn__black'}
                          onClick={() => elements.submit()}
                          label={formatMessage({ id: 'global.next' })}
                        />
                      </>
                    );
                  }}
                </ElementsConsumer>
              </Elements>
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Company;
