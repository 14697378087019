import React from 'react';
import { FormattedMessage } from 'react-intl';
type P = {
  cardNumber: string;
  onClick: () => void;
};

function ChangeCard({ cardNumber, onClick }: P) {
  return (
    <div className="change-card">
      <div className="change-card__number">{cardNumber}</div>
      <div className="change-card__button" onClick={onClick}>
        <FormattedMessage id="global.payments.change_card" />
      </div>
    </div>
  );
}

export default ChangeCard;
