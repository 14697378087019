import * as React from 'react';
import { SVGType } from 'Shared/utils/svgType';
const BubbleSVG = ({ className }: SVGType) => (
  <svg className={`svg  ${className}`} width="77" height="75" viewBox="0 0 77 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M76.2503 71.639C45.9938 87.11 5.01 32.303 0.126799 18.7228L43.9583 0.766841C31.8007 30.5732 60.4206 60.4342 76.2503 71.639Z"
      fill="#F2F2F2"
    />
  </svg>
);
export default BubbleSVG;
