import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';

const success = (data) => ({
  type: Website.CHECKER_LIST_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.CHECKER_LIST_FAILURE,
});

const getCheckerList = (page) => async (dispatch) => {
  try {
    const { data } = await Connector.getCheckerList(page);
    dispatch(success(data));
    dispatch(Actions.HideDashboardWebsitesInfoLoader());
  } catch (err) {
    dispatch(Actions.HideDashboardWebsitesInfoLoader());
    dispatch(failure());
  }
};

export default getCheckerList;
