import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';
// import { Actions } from 'Modules/ViewManagementModule';

const success = (data) => ({
  type: Website.GET_DASHBOARD_PARTNER_WEBSITES_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_DASHBOARD_CLIENTS_WEBSITES_FAILURE,
});

const getDashboardClientsWebsites = () => async (dispatch) => {
  try {
    const { data } = await Connector.getDashboardClientsWebsites();
    dispatch(success(data));
    // dispatch(Actions.HideDashboardWebsitesInfoLoader());
  } catch (err) {
    // dispatch(Actions.HideDashboardWebsitesInfoLoader());
    dispatch(failure());
  }
};

export default getDashboardClientsWebsites;
