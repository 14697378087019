import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import { Actions } from "Modules/UserModule";

const init = () => ({
    type: User.UPDATE_SERVICES_INIT,
});

const success = (data) => ({
    type: User.UPDATE_SERVICES_SUCCESS,
    payload: {
        data,
    },
});

const failure = () => ({
    type: User.UPDATE_SERVICES_FAILURE,
});

const updateServices = (body) => async (dispatch) => {
    dispatch(init());
    try {
        await Connector.updateServices(body);
        dispatch(success(Actions.getServices()));
        return true;
    } catch (err) {
        console.log(err);
        dispatch(failure());
        return false;
    }
};

export default updateServices;
