import { connect } from 'react-redux';
import Packages from './Main';
import { bindActionCreators } from 'redux';
import { Actions } from 'Modules/ViewManagementModule';
import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';

type ConnectedP = {
  translation: string;
  history?: any;
};

type DispatchedP = {
  actions: {
    openModal: () => void;
    closeModal: () => void;
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      openModal: () => Actions.openModal(),
      closeModal: () => Actions.closeModal(),
    },
    dispatch,
  ),
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Packages));
