const MONTHS = {
    "01": 'global.january',
    "02": 'global.february',
    "03": 'global.march',
    "04": 'global.april',
    "05": 'global.may',
    "06": 'global.june',
    "07": 'global.july',
    "08": 'global.august',
    "09": 'global.september',
    "10": 'global.october',
    "11": 'global.november',
    "12": 'global.december',
}

export default MONTHS;