import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: Website.GET_SINGLE_WEBSITE_ACTIONS_INIT,
});

const success = (data) => ({
  type: Website.GET_SINGLE_WEBSITE_ACTIONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_SINGLE_WEBSITE_ACTIONS_FAILURE,
});

const getSingleWebsiteActions = (uuid) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(Actions.showLoader());
    const { data } = await Connector.getSingleWebsiteActions(uuid);
    dispatch(success(data));
    dispatch(Actions.hideLoader());
    return data;
  } catch (err) {
    console.log(err);
    dispatch(Actions.hideLoader());
    dispatch(failure());
  }
};

export default getSingleWebsiteActions;
