import React from 'react';
import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';

function WebsitesStatus({ type, label, status }: any) {
  return (
    <div className="websitesStatus__wrapper">
      <p className={`websitesStatus__icon`}>
        {type == 'ssl' && <SVG type={SVG_TYPE.FEATHER_LOCK} className={status} />}
        {type == 'ftp' && <SVG type={SVG_TYPE.FEATHER_DATABASE} className={status} />}
        {type == 'php' && <SVG type={SVG_TYPE.FEATHER_SEETINGS} className={status} />}
        {type == 'indexing' && <SVG type={SVG_TYPE.FEATHER_GOOGLE} className={status} />}
      </p>
      <p className={`websitesStatus ${type ? `websitesStatus--${type}` : ''} ${WebsitesStatus ? `websitesStatus--${type}` : ''}`}>{label}</p>
    </div>
  );
}

export default WebsitesStatus;
