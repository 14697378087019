import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import { Toast } from 'Shared/components';
import { getFirstDayOfNextMonth, isDowngrade } from 'Shared/utils';

const init = () => ({
  type: User.UPDATE_WEBSITE_INIT,
});

const success = (data) => ({
  type: User.UPDATE_WEBSITE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.UPDATE_WEBSITE_FAILURE,
});

const updateWebsite =
  (data, website_uuid, withoutToast = false) =>
  async (dispatch) => {
    dispatch(init());
    if (!withoutToast) {
      Toast({ message: 'global.apex_no_data', type: 'loading', id: 'updateWebsite' });
    }
    try {
      await Connector.updateWebsite(data, website_uuid);
      // await dispatch(getMyWebsites());
      if (data?.oldPlan) {
        if (isDowngrade(data.plan, data.oldPlan)) {
          data.plan_downgraded_to = data.plan;
          data.plan = data.oldPlan;
          data.scheduled_downgrade_at = getFirstDayOfNextMonth();
        }
      } 
      dispatch(success({...data, uuid: website_uuid}));
      if (!withoutToast) {
        Toast({ message: 'global.toast.update_website_success', type: 'success', id: 'updateWebsite' });
      }

      return true;
    } catch (err: any) {
      console.log(err);
      dispatch(failure());
      if (!withoutToast) {
        Toast({ message: 'global.toast.update_website_failure', type: 'error', id: 'updateWebsite' });
      }
      return false;
    }
  };

export default updateWebsite;
