/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef } from 'react';
import { Formik, Field } from 'formik';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, SVG } from 'Shared/components';
import RegularField from '../../Shared/form/regularField/regularField';
import Background from '../../Assets/Image/background.svg';
import { MainType } from './Container';
import { isTokenSaved } from 'Shared/utils';
import { FormattedMessage } from 'react-intl';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import lonelyLetters from 'Shared/utils/lonelyLetters';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const LoginPage = ({ intl: { formatMessage }, actions: { loginUser } }: MainType) => {
  const history = useHistory();
  const validation = () =>
    Yup.object().shape({
      email: Yup.string()
        .email(formatMessage({ id: 'global.validation.wrong_email' }))
        .required(formatMessage({ id: 'global.validation.required' })),
      password: Yup.string()
        .required(formatMessage({ id: 'global.validation.required' }))
        .min(8, formatMessage({ id: 'global.validation.min_8' })),
    });

  setTimeout(function () {
    const el = document.querySelectorAll('.popUp--form-contact--description-gray');
    lonelyLetters(el);
  }, 100);
  const formRef = useRef(null);

  useEffect(() => {
    const addDarkClass = () => {
      const naviHeader = document.querySelector('.navi__header');
      if (naviHeader) {
        naviHeader.classList.add('navi__header--sticky');
      }
    };
    const removeDarkClass = () => {
      const naviHeader = document.querySelector('.navi__header');
      if (naviHeader) {
        naviHeader.classList.remove('navi__header--sticky');
      }
    };
    addDarkClass();
    return () => {
      removeDarkClass();
    };
  }, []);



  if (!isTokenSaved()) {
    return (
      <>
        <div className="login-page__header-overlay">
          <div className="login-page__header-wrapper">
            <Link to={ROUTES.HOME_PAGE}>
              <SVG type={SVG_TYPE.LOGO} />
            </Link>
            <p className="login-page__register">
              <FormattedMessage id="global.didnt_have_account" />{' '}
              <p onClick={() => history.push(ROUTES.REGISTER_PAGE)}>
                <FormattedMessage id="global.register" />
              </p>
            </p>
          </div>
        </div>
        <div className="login-page__wrapper">
          <div className="section--background">
            <img src={Background} alt="background image" />
          </div>
          <Formik
            innerRef={formRef}
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={validation()}
            onSubmit={async ({ email, password }) => {
              await loginUser(email, password);
            }}>
            {({ handleSubmit, errors }) => (
              <form onSubmit={handleSubmit} className="login-page__form">
                <h1 className="login-page__title">{formatMessage({ id: 'global.login.sign_in' })}</h1>
                <Field type="text" wrapperClassName="white" label="Email" name="email" component={RegularField} errors={errors} errorBelow />
                <Field
                  label={<FormattedMessage id="global.login.password" />}
                  name="password"
                  wrapperClassName="white"
                  type="password"
                  errorBelow
                  component={RegularField}
                  errors={errors}
                />
                <p className="login-page__remember-password" onClick={() => history.push(ROUTES.RESET_PASSWORD_PAGE)}>
                  {<FormattedMessage id="global.dont_remember_password" />}
                </p>

                <Button
                  type="submit"
                  label={<FormattedMessage id="global.login.sign_in" />}
                  classNames="btn--blue login-page__submit"
                  iconAlt="icon product"
                />
                <p className="login-page__register login-page__register--outside">
                  <FormattedMessage id="global.didnt_have_account" />{' '}
                  <p onClick={() => history.push(ROUTES.REGISTER_PAGE)}>
                    <FormattedMessage id="global.register" />
                  </p>
                </p>
              </form>
            )}
          </Formik>
        </div>
      </>
    );
  } else {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }
};

export default LoginPage;
