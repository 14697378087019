import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
// import { Actions } from 'Modules/ViewManagementModule';

// const init = () => ({
//   type: User.GET_PAYMENT_METHODS_INIT,
// });

const success = (data) => ({
  type: User.GET_PAYMENT_METHODS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.GET_PAYMENT_METHODS_FAILURE,
});

const getPaymentMethods = () => async (dispatch) => {
  // dispatch(init());
  try {
    // dispatch(Actions.showLoader());
    const { data } = await Connector.getPaymentMethods();
    dispatch(success(data));
    // dispatch(Actions.hideLoader());
    return data;
  } catch (err) {
    console.log(err);
    // dispatch(Actions.hideLoader());
    dispatch(failure());
  }
};

export default getPaymentMethods;
