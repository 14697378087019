const sslStatus = (fail, status) => {
  if (fail === 0) {
    if (status === 'no_ssl') {
      return 'orange';
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export default sslStatus;
