import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';
import { bindActionCreators } from 'redux';
import Partner from './Main';
import { RootState } from 'Store/Reducers/RootReducer';
import { Actions, Selectors } from 'Modules/UserModule';
import { Selectors as WebsitesSelectors } from 'Modules/WebsiteModule';
import { Actions as WebsiteActions } from 'Modules/WebsiteModule';
import { Selectors as ViewManagementSelectors, Actions as VActions } from 'Modules/ViewManagementModule';
import { Actions as AddWebsiteActions, Selectors as AddWebsiteSelectors } from 'Modules/AddWebsiteModule';
import { Selectors as AuthSelector } from 'Modules/AuthModule';
import { AddWebsiteDataType } from 'Shared/models/AddWebsiteModel';

type ConnectedP = {
  loggedUser: any;
  authorizedUser: any;
  history?: any;
  loaderVisible: any;
  websitesPartners: any;
  clientsLists: any;
  balance: any;
  formData: AddWebsiteDataType;
  userWebsites: any;
  paymentMethods: any;
};

type DispatchedP = {
  actions: {
    regulationsPartner: () => any;
    getClientsLists: () => void;
    getWebsitesPartners: () => void;
    getPartnerBalance: (currency) => void;
    openModal: () => void;
    closeModal: () => void;
    getUserWebsites: (uuid) => void;
    updateConnection: (data) => void;
    updateWebsite: (dataForm, websiteUuid) => void;
    setActiveWebsite: (activeWebsite) => void;
    changeMaxStep: (maxStep) => void;
    getAuthorizedUser: () => void;
    addWebsiteFormChange: (data) => any;
    getPaymentMethods: () => any;
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      regulationsPartner: Actions.regulationsPartner,
      getClientsLists: Actions.getClientsLists,
      getWebsitesPartners: WebsiteActions.getWebsitesPartners,
      getPartnerBalance: Actions.getPartnerBalance,
      openModal: VActions.openModal,
      closeModal: VActions.closeModal,
      getUserWebsites: Actions.getUserWebsites,
      updateWebsite: Actions.updateWebsite,
      updateConnection: Actions.updateConnection,
      setActiveWebsite: Actions.setActiveWebsite,
      changeMaxStep: AddWebsiteActions.changeMaxStep,
      getAuthorizedUser: Actions.getAuthorizedUser,
      addWebsiteFormChange: AddWebsiteActions.addWebsiteFormChange,
      getPaymentMethods: Actions.getPaymentMethods,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  loggedUser: Selectors.getLoggedUser(state),
  clientsLists: Selectors.getClientsLists(state),
  websitesPartners: WebsitesSelectors.getWebsitesPartners(state),
  loaderVisible: ViewManagementSelectors.loaderVisible(state),
  balance: Selectors.getPartnerBalance(state),
  userWebsites: Selectors.getUserWebsites(state),
  authorizedUser: AuthSelector.authorizedUser(state),
  formData: AddWebsiteSelectors.getAddWebsiteFormData(state),
  paymentMethods: Selectors.getPaymentMethods(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Partner));
