import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: User.GET_CLIENTS_LISTS_INIT,
});

const success = (data) => ({
  type: User.GET_CLIENTS_LISTS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.GET_CLIENTS_LISTS_FAILURE,
});

const getClientsLists = () => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(Actions.showLoader());
    const { data } = await Connector.getClientsLists();
    dispatch(success(data));
    dispatch(Actions.hideLoader());
  } catch (err) {
    dispatch(Actions.hideLoader());
    dispatch(failure());
  }
};

export default getClientsLists;
