import SVG_TYPE from './svg_types';

const TAX_IDS = [
  {
    value: {
      vat_type: 'au_abn',
      vat_country: 'AU',
    },
    label: 'Australian Business Number (AU ABN)',
    searchableLabel: 'Australian Business Number (AU ABN)',
    svgType: SVG_TYPE.FLAG_AU,
  },
  {
    value: {
      vat_type: 'eu_vat',
      vat_country: 'ATU',
    },
    label: 'Austria European VAT number',
    searchableLabel: 'Austria European VAT number',
    svgType: SVG_TYPE.FLAG_AT,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'BE' },
    label: 'Belgium European VAT number',
    searchableLabel: 'Belgium European VAT number',
    svgType: SVG_TYPE.FLAG_BE,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'BG' },
    label: 'Bulgaria European VAT number',
    searchableLabel: 'Bulgaria European VAT number',
    svgType: SVG_TYPE.FLAG_BG,
  },
  {
    value: { vat_type: 'ca_bn', vat_country: 'CA' },
    label: 'Canadian BN',
    searchableLabel: 'Canadian BN',
    svgType: SVG_TYPE.FLAG_CA,
  },
  {
    value: { vat_type: 'cl_tin', vat_country: 'CL' },
    label: 'Chilean TIN',
    searchableLabel: 'Chilean TIN',
    svgType: SVG_TYPE.FLAG_CL,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'HR' },
    label: 'Croatia European VAT number',
    searchableLabel: 'Croatia European VAT number',
    svgType: SVG_TYPE.FLAG_HR,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'CY' },
    label: 'Cyprus European VAT number',
    searchableLabel: 'Cyprus European VAT number',
    svgType: SVG_TYPE.FLAG_CY,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'CZ' },
    label: 'Czech Republic European VAT number',
    searchableLabel: 'Czech Republic European VAT number',
    svgType: SVG_TYPE.FLAG_CZ,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'DK' },
    label: 'Denmark European VAT number',
    searchableLabel: 'Denmark European VAT number',
    svgType: SVG_TYPE.FLAG_DK,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'EE' },
    label: 'Estonia European VAT number',
    searchableLabel: 'Estonia European VAT number',
    svgType: SVG_TYPE.FLAG_EE,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'FI' },
    label: 'Finland European VAT number',
    searchableLabel: 'Finland European VAT number',
    svgType: SVG_TYPE.FLAG_FI,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'FRAB' },
    label: 'France European VAT number',
    searchableLabel: 'France European VAT number',
    svgType: SVG_TYPE.FLAG_FRAB,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'DE' },
    label: 'Germany European VAT number',
    searchableLabel: 'Germany European VAT number',
    svgType: SVG_TYPE.FLAG_DE,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'EL' },
    label: 'Greece European VAT number',
    searchableLabel: 'Greece European VAT number',
    svgType: SVG_TYPE.FLAG_EL,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'HU' },
    label: 'Hungary European VAT number',
    searchableLabel: 'Hungary European VAT number',
    svgType: SVG_TYPE.FLAG_HU,
  },
  {
    value: { vat_type: 'is_vat', vat_country: 'IS' },
    searchableLabel: 'Icelandic VAT ',
    label: 'Icelandic VAT ',
    svgType: SVG_TYPE.FLAG_IS,
  },
  {
    value: { vat_type: 'in_gst', vat_country: 'IO' },
    label: 'Indian GST number',
    searchableLabel: 'Indian GST number',
    svgType: SVG_TYPE.FLAG_IO,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'IE' },
    label: 'Ireland European VAT number',
    searchableLabel: 'Ireland European VAT number',
    svgType: SVG_TYPE.FLAG_IE,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'IT' },
    label: 'Italy European VAT number',
    searchableLabel: 'Italy European VAT number',
    svgType: SVG_TYPE.FLAG_IT,
  },
  {
    value: { vat_type: 'jp_cn', vat_country: 'JP' },
    label: 'Japanese Corporate Number (*Hōjin Bangō*)',
    searchableLabel: 'Japanese Corporate Number (*Hōjin Bangō*)',
    svgType: SVG_TYPE.FLAG_JP,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'LV' },
    label: 'Latvia European VAT number',
    searchableLabel: 'Latvia European VAT number',
    svgType: SVG_TYPE.FLAG_LV,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'LT' },
    label: 'Lithuania European VAT number',
    searchableLabel: 'Lithuania European VAT number',
    svgType: SVG_TYPE.FLAG_LT,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'LU' },
    label: 'Luxembourg European VAT number',
    searchableLabel: 'Luxembourg European VAT number',
    svgType: SVG_TYPE.FLAG_LU,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'MT' },
    label: 'Malta European VAT number',
    searchableLabel: 'Malta European VAT number',
    svgType: SVG_TYPE.FLAG_MT,
  },
  {
    value: { vat_type: 'mx_rfc', vat_country: 'MX' },
    label: 'Mexican RFC number',
    searchableLabel: 'Mexican RFC number',
    svgType: SVG_TYPE.FLAG_MX,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'NL' },
    label: 'Netherlands European VAT number',
    searchableLabel: 'Netherlands European VAT number',
    svgType: SVG_TYPE.FLAG_NL,
  },
  {
    value: { vat_type: 'nz_gst', vat_country: 'NZ' },
    label: 'New Zealand GST number',
    searchableLabel: 'New Zealand GST number',
    svgType: SVG_TYPE.FLAG_NZ,
  },
  {
    value: { vat_type: 'no_vat', vat_country: 'NO' },
    label: 'Norwegian VAT number',
    searchableLabel: 'Norwegian VAT number',
    svgType: SVG_TYPE.FLAG_NO,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'PL' },
    label: 'Poland European VAT number',
    searchableLabel: 'Poland European VAT number',
    svgType: 'FLAG_PL',
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'PT' },
    label: 'Portugal European VAT number',
    searchableLabel: 'Portugal European VAT number',
    svgType: SVG_TYPE.FLAG_PT,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'RO' },
    label: 'Romania European VAT number',
    searchableLabel: 'Romania European VAT number',
    svgType: SVG_TYPE.FLAG_RO,
  },
  {
    value: { vat_type: 'ru_inn', vat_country: 'RU' },
    label: 'Russian INN',
    searchableLabel: 'Russian INN',
    svgType: SVG_TYPE.FLAG_RU,
  },
  {
    value: { vat_type: 'sa_vat', vat_country: 'SA' },
    label: 'Saudi Arabia VAT',
    searchableLabel: 'Saudi Arabia VAT',
    svgType: SVG_TYPE.FLAG_SA,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'SK' },
    label: 'Slovakia European VAT number',
    searchableLabel: 'Slovakia European VAT number',
    svgType: SVG_TYPE.FLAG_SK,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'SL' },
    label: 'Slovenia European VAT number',
    searchableLabel: 'Slovenia European VAT number',
    svgType: SVG_TYPE.FLAG_SL,
  },
  {
    value: { vat_type: 'za_vat', vat_country: 'PZ' },
    label: 'South Africa VAT number',
    searchableLabel: 'South Africa VAT number',
    svgType: SVG_TYPE.FLAG_PZ,
  },
  {
    value: { vat_type: 'kr_brn', vat_country: 'KR' },
    label: 'Korean BRN',
    searchableLabel: 'Korean BRN',
    svgType: SVG_TYPE.FLAG_KR,
  },
  {
    value: { vat_type: 'es_cif', vat_country: 'ESA' },
    label: 'Spanish NIF number',
    searchableLabel: 'Spanish NIF number',
    svgType: SVG_TYPE.FLAG_ES,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'ES' },
    label: 'Spain European VAT number',
    searchableLabel: 'Spain European VAT number',
    svgType: SVG_TYPE.FLAG_ES,
  },
  {
    value: { vat_type: 'eu_vat', vat_country: 'SE' },
    label: 'Sweden European VAT number',
    searchableLabel: 'Sweden European VAT number',
    svgType: SVG_TYPE.FLAG_SE,
  },
  {
    value: { vat_type: 'ch_vat', vat_country: 'CH' },
    label: 'Switzerland VAT number',
    searchableLabel: 'Switzerland VAT number',
    svgType: SVG_TYPE.FLAG_CH,
  },
  {
    value: { vat_type: 'tw_vat', vat_country: 'TW' },
    label: 'Taiwanese VAT',
    searchableLabel: 'Taiwanese VAT',
    svgType: SVG_TYPE.FLAG_TW,
  },
  {
    value: { vat_type: 'th_vat', vat_country: 'TH' },
    label: 'Thai VAT',
    searchableLabel: 'Thai VAT',
    svgType: SVG_TYPE.FLAG_TH,
  },
  {
    value: { vat_type: 'tr_tin', vat_country: 'TR' },
    label: 'Turkish Tax Identification Number',
    searchableLabel: 'Turkish Tax Identification Number',
    svgType: SVG_TYPE.FLAG_TR,
  },
  {
    value: { vat_type: 'ua_vat', vat_country: 'UA' },
    label: 'Ukrainian VAT',
    searchableLabel: 'Ukrainian VAT',
    svgType: SVG_TYPE.FLAG_UA,
  },
  {
    value: { vat_type: 'ae_trn', vat_country: 'AE' },
    label: 'United Arab Emirates TRN',
    searchableLabel: 'United Arab Emirates TRN',
    svgType: SVG_TYPE.FLAG_AE,
  },
  {
    value: { vat_type: 'ae_trn', vat_country: 'IRL' },
    label: 'Northern Ireland VAT number',
    searchableLabel: 'Northern Ireland VAT number',
    svgType: SVG_TYPE.FLAG_IRL,
  },
  {
    value: { vat_type: 'gb_vat', vat_country: 'XI' },
    label: 'United Kingdom VAT number',
    searchableLabel: 'United Kingdom VAT number',
    svgType: SVG_TYPE.FLAG_XI,
  },
  {
    value: { vat_type: 'us_ein', vat_country: 'US' },
    label: 'United States EIN',
    searchableLabel: 'United States EIN',
    svgType: 'FLAG_USA',
  },
];

export default TAX_IDS;
