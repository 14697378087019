import WEBSITE_STATUSES from 'Shared/enums/websiteStatuses';

const handleWebsiteStatusColor = (sslStatus: 'ACTIVE' | 'INACTIVE' | 'CONFIRMING_FAILURE' | 'INACTIVE_SUBPAGE') => {
  switch (sslStatus) {
    case WEBSITE_STATUSES.ACTIVE:
      return 'green';

    case WEBSITE_STATUSES.INACTIVE:
      return 'red';

    case WEBSITE_STATUSES.CONFIRMING_FAILURE:
      return 'yellow';

    case WEBSITE_STATUSES.INACTIVE_SUBPAGE:
      return 'yellow';

    default:
      return 'gray';
  }
};

export default handleWebsiteStatusColor;
