import { Website } from '../EnumTypes/ActionEnumTypes';

const success = () => ({
  type: Website.CLEAR_SINGLE_WEBSITE_STATE_SUCCESS,
});

const clearSingleWebsiteState = () => async (dispatch) => {
  try {
    dispatch(success());
    return true;
  } catch (err) {
    console.log(err);
  }
};

export default clearSingleWebsiteState;
