import React from 'react';

type P = {
  currentStep: number;
  lastStep: number;
  formatMessage: any;
};

function Step({ currentStep, lastStep, formatMessage }: P) {
  return (
    <h3 className="step">
      {formatMessage({ id: 'global.packet_form.step' })} {currentStep} {formatMessage({ id: 'global.packet_form.step_of' })}{' '}
      <span className="step__last">{lastStep}</span>
    </h3>
  );
}

export default Step;
