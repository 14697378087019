import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';



const success = (data) => ({
  type: User.GET_MY_WEBSITES_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.GET_MY_WEBSITES_FAILURE,
});

const getMyWebsites =
  (onlyRefresh: boolean = false, companyUuid = null) =>
  async (dispatch) => {
    if (!onlyRefresh) {
      // dispatch(init());
    }

    try {
      dispatch(Actions.showLoader());
      const { data } = await Connector.getMyWebsites(companyUuid);
      dispatch(success(data));
      dispatch(Actions.hideLoader());
    } catch (err) {
      dispatch(Actions.hideLoader());
      dispatch(failure());
    }
  };

export default getMyWebsites;
