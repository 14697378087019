import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import { Actions } from 'Modules/ViewManagementModule';
import { Toast } from 'Shared/components';

const init = () => ({
  type: User.CANCEL_SUBSCRIPTION_INIT,
});

const success = () => ({
  type: User.CANCEL_SUBSCRIPTION_SUCCESS,
});

const failure = () => ({
  type: User.CANCEL_SUBSCRIPTION_FAILURE,
});

const cancelSubscription = (uuid: string) => async (dispatch) => {
  dispatch(init());
  Toast({ message: 'global.apex_no_data', type: 'loading', id: 'deleteWebsite' });
  try {
    dispatch(Actions.showLoader());
    await Connector.cancelSubscription(uuid);
    dispatch(success());
    dispatch(Actions.hideLoader());
    Toast({ message: 'global.toast.subscription_cancel_success', type: 'success', id: 'deleteWebsite' });
    return true;
  } catch (err) {
    dispatch(failure());
    dispatch(Actions.hideLoader());
    console.log(err);
    Toast({ message: 'global.toast.subscription_cancel_failrue', type: 'error', id: 'deleteWebsite' });
    return false;
  }
};

export default cancelSubscription;
