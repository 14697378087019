// import { store } from 'Store/Store';
// import { getWebsitesPartners } from 'Store/Selectors/WebsiteSelector';

const provision = (packet, currency) => {
  const key = `${packet}_${currency.toLowerCase()}`;
  switch (key) {
    case 'basic_pln':
      return 10;
    case 'premium_pln':
      return 20;
    case 'pro_pln':
      return 50;
    case 'basic_eur':
      return 3;
    case 'premium_eur':
      return 6;
    case 'pro_eur':
      return 10;
    case 'basic_usd':
      return 3;
    case 'premium_usd':
      return 6;
    case 'pro_usd':
      return 10;
  }
};

const getPartnerProvision = (websites, currency) => {
  // const state = store.getState();
  // const websites = getWebsitesPartners(state);

  const websiteProtected = websites?.reduce(
    (prevValue, currentValue) => prevValue + (currentValue.subscription_uid && provision(currentValue.plan, currency)),
    0,
  );
  return websiteProtected || 0;
};

export default getPartnerProvision;
