import { store } from 'Store/Store';
import { authorizedUser as getAuthorizedUser } from 'Store/Selectors/AuthSelector';
import { getMyWebsites } from 'Store/Selectors/UserSelector';
import { PRICES } from 'Shared/enums';
import { getCurrency } from 'Shared/utils';

const regularPrice = (packet, currency = false) => {
  switch (packet) {
    case 'basic': {
      return PRICES[getCurrency(currency)].basic;
    }
    case 'premium': {
      return PRICES[getCurrency(currency)].premium;
    }
    case 'pro': {
      return PRICES[getCurrency(currency)].pro;
    }
  }
};

const partnerPrice = (packet, currency = false) => {
  switch (packet) {
    case 'basic': {
      return PRICES[getCurrency(currency)].basic_partner;
    }
    case 'premium': {
      return PRICES[getCurrency(currency)].premium_partner;
    }
    case 'pro': {
      return PRICES[getCurrency(currency)].pro_partner;
    }
  }
};

const getDashboardPrice = (packet, isHiddenForPartner, websitesAmount = 2, currency = false) => {
  const state = store.getState();
  const auth = getAuthorizedUser(state);
  const company = auth?.company;
  const websites = getMyWebsites(state);
  const websiteProtected = websites?.reduce((prevValue, currentValue) => (!!currentValue.payment_method_uid ? prevValue + 1 : prevValue), 0);

  if (company?.type === 'partner' && websiteProtected >= websitesAmount && !isHiddenForPartner) {
    return partnerPrice(packet, currency);
  } else {
    return regularPrice(packet, currency);
  }
};

export default getDashboardPrice;
