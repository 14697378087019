import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';
import moment from 'moment';

const init = () => ({
  type: Website.GET_CUSTOM_PINGS_INIT,
});

const success = (data) => ({
  type: Website.GET_CUSTOM_PINGS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_CUSTOM_PINGS_FAILURE,
});

const getCustomPings = (uuid, dateTo, dateFrom, urlId) => async (dispatch) => {
  dispatch(init());
  try {
    //check if dateFrom is greater than dateTo. Use moment.js
    let data;
    if (moment(dateFrom).isBefore(dateTo)) {
      //change timezones to UTC
      data = await Connector.getCustomPings(uuid, dateTo + ' 23:59:59', dateFrom + ' 00:00:00', urlId);
    } else {
      data = await Connector.getCustomPings(uuid, dateFrom + ' 23:59:59', dateTo + ' 00:00:00', urlId);
    }
    dispatch(success(data.data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getCustomPings;
