import { Connector } from 'Modules/PaymentModule';
import { Payment } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

// const init = () => ({
//   type: Payment.GET_PAYMENT_INIT,
// });

const success = (data) => ({
  type: Payment.GET_PAYMENT_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Payment.GET_PAYMENT_FAILURE,
});

const getPayment = () => async (dispatch) => {
  // dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    dispatch(Actions.showLoader());
    const { data } = await Connector.getPayment();
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    console.log(err);
    dispatch(Actions.hideLoader());
    dispatch(failure());
  }
};

export default getPayment;
