enum ROUTES {
  HOME_PAGE = `/`,
  ABOUT_CORGI = '/what-is-corgi/',
  ABOUT_US = '/about-us/',
  CONTACT = '/contact',
  PRICE = '/pricing/',
  LOGIN = '/login/',
  WORKERS = '/workers/',
  RULES = '/rules/',
  SOON = '/soon/',
  WHY_CORGI = '/why-corgi/',
  PREORDER_SIGN_UP = '/preorder/',
  NEWSLETTER_SIGN_UP = '/newsletter/',
  PARTNER_SIGN_UP = '/partner/',
  FAQ = '/faq/',
  MESSAGE_SEND = '/message-confirmation/',
  REMIND_PASSWORD = '/reset-password/',
  RESET_PASSWORD = '/recover/',
  PAGE_404 = '/404/',
  PAGE_500 = '/500/',
  PACKAGES = '/details/',
  MY_WEBSITES = '/dashboard/my-websites/',
  DASHBOARD = `/dashboard/`,
  MY_DATA = '/dashboard/my-data/',
  CHANGE_PASSWORD = '/dashboard/change-password/',
  CLIENTS = '/dashboard/clients/',
  ALL_WEBISTE = '/dashboard/all-website/',
  PAYMENTS = '/dashboard/payments/',
  PAYMENTS_LISTS = '/dashboard/payments-lists/',
  PARTNER = '/dashboard/partner/',
  PARTNER_CLIENT = '/dashboard/partner-client/',
  BACKUPS = '/dashboard/backups/',
  NOTIFICATIONS = '/dashboard/notifications/',
  UPDATES = '/dashboard/updates/',
  PARTNERS_WEBSITES = '/dashboard/partners-websites/',
  FILES_CHANGES = '/dashboard/files-changes/',
  CORGI_WEBINAR = '/webinar/:date',
  CORGI_PRO_BONO = '/pro-bono/',
  REPORT = '/report/',
  WEBSITE_CHECKER = '/website-checker',
  CHECKER_LISTS = '/dashboard/checker-list',
  CHECK_YOUR_WEBSITE = '/check-your-website',
  CHECK_WEBSITE = '/check-website',
  HOME_PAGE_COPY = '/home-page-copy',
  ADD_WEBSITE = '/add-website',
  WEBSITE_FORM = '/add-website/:website',
  SVG_TEST = '/svg-test',
  WEBSITE_CHECKER_COPY = '/website-checker-copy',
  REGISTER_PAGE = '/register-page',
  RESET_PASSWORD_PAGE = '/reset-password-page/',
  GUIDE = '/guide',
  MY_WEBSITES_COPY = '/dashboard/my-websites-old',
  PARTNERS_COPY = '/dashboard/partners-copy/',
  MY_ACCOUNT = '/dashboard/my-account/',
  SINGLE_WEBSITE = '/dashboard/single-website',
  HOW_IT_WORKS = '/how-it-works',
  PRICING = '/pricing',
}

export enum ROLES {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
  CLIENT = 'client',
  PARTNER = 'partner',
}

export enum ROUTES_ADMIN_PANEL {
  PREORDER = '/dashboard/preorder',
  MAILING = '/dashboard/newsletter',
}

export enum ROUTES_USER_PANEL {
  CARSMILE = '/dashboard/carsmile',
}

export enum ROUTES_LABELS {
  PREORDER = 'Preorder',
  MAILING = 'Lista Mailingowa',
  CARSMILE = 'carsmile.pl',
  CLIENTS = 'Preorder',
}

export const ROUTES_MAP_ADMIN = new Map<ROUTES_ADMIN_PANEL, { label: string; className: string }>([
  [ROUTES_ADMIN_PANEL.PREORDER, { label: ROUTES_LABELS.PREORDER, className: 'blue' }],
  [ROUTES_ADMIN_PANEL.MAILING, { label: ROUTES_LABELS.MAILING, className: 'green' }],
]);

export const ROUTES_MAP_USER = new Map<ROUTES_USER_PANEL, { label: string; className: string }>([
  [ROUTES_USER_PANEL.CARSMILE, { label: ROUTES_LABELS.CARSMILE, className: 'blue' }],
]);

export const PROTECTED_ROUTES_MAP = new Map<string, Array<ROLES>>([
  [ROUTES.DASHBOARD, [ROLES.SUPER_ADMIN]],
  [ROUTES.WORKERS, [ROLES.SUPER_ADMIN]],
  [ROUTES.REPORT, [ROLES.SUPER_ADMIN]],
]);

export default ROUTES;
