import { SVG_TYPE } from 'Shared/enums';
import React, { useState, useEffect } from 'react';
import SVG from '../SVG';
import { FormattedMessage } from 'react-intl';
import Button from '../button';
import { toast } from 'react-toastify';

interface FileInputDropdownProps {
  label?: string;
  onFileDrop: (file: File | null) => void;
  className?: string;
  accept?: string;
  file: any | null;
  onFileDelete?: (file: any) => void;
}

const FileInputDropdown: React.FC<FileInputDropdownProps> = ({ label, onFileDrop, className = '', accept, file, onFileDelete }) => {
  const [dragging, setDragging] = useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleDrag = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragExit = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const currentFileExtension = e.dataTransfer.files[0].name.split('.').pop()?.toLocaleUpperCase();
    if (
      accept &&
      !accept
        .toUpperCase()
        .replaceAll('.', '')
        .split('/')
        .map((item) => item.split(','))
        .toString()
        .includes(currentFileExtension)
    ) {
      toast.error(<FormattedMessage id="global.wrong_format_file" />, {
        toastId: 'fileExtension',
        autoClose: 3000,
      });
      return;
    }
    onFileDrop(e.dataTransfer.files[0]);
  };

  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentFileExtension = e.target.files[0].name.split('.').pop()?.toLocaleUpperCase();

    if (
      accept &&
      !accept
        .toUpperCase()
        .replaceAll('.', '')
        .split('/')
        .map((item) => item.split(','))
        .toString()
        .includes(currentFileExtension)
    ) {
      toast.error(<FormattedMessage id="global.wrong_format_file" />, {
        toastId: 'fileExtension',
        autoClose: 3000,
      });
      return;
    }
    onFileDrop(e.target.files[0]);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleRemoveFile = () => {
    onFileDrop(null);
    fileInputRef.current.value = '';
    onFileDelete && onFileDelete(file);
  };

  useEffect(() => {
    document.body.addEventListener('dragover', handleDrag);
    document.body.addEventListener('dragleave', handleDragExit);
    document.body.addEventListener('dragenter', handleDrag);
    document.body.addEventListener('drop', handleDrop);
    return () => {
      document.body.removeEventListener('dragover', handleDrag);
      document.body.removeEventListener('dragleave', handleDragExit);
      document.body.removeEventListener('dragenter', handleDrag);
      document.body.removeEventListener('drop', handleDrop);
    };
  }, []);
  return (
    <div className={`file-input-dropdown ${className}`}>
      <input
        type="file"
        accept={accept} // Specify accepted file types
        onChange={handleFileSelection}
        ref={fileInputRef}
        className="file-input-dropdown__input"
      />
      {label ? <label className="file-input-dropdown__label">{label}</label> : null}
      <div
        className={`file-input-dropdown__wrapper ${dragging ? 'file-input-dropdown__wrapper--dragging' : ''} ${
          file ? 'file-input-dropdown__wrapper--file' : ''
        }`}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDragExit}
        onDrop={handleDrop}>
        {file ? (
          <div className="file-input-dropdown__file">
            <p>{file.name || file.file_name}</p>
            <button className="file-input-dropdown__clear-button" onClick={handleRemoveFile}>
              <SVG type={SVG_TYPE.CLOSE} />
            </button>
          </div>
        ) : (
          <>
            <SVG className="upload" type={SVG_TYPE.FILE_UPLOAD} />
            <p>
              <FormattedMessage id="global.drag_and_drop" />
            </p>
            <Button
              label={<FormattedMessage id="global.download_file" />}
              onClick={handleButtonClick}
              classNames="new-blue-stroke file-input-dropdown__button"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default FileInputDropdown;
