import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import RegularField from 'Shared/form/regularField/regularField';
import Button from 'Shared/components/button/Button';
import { FormattedMessage } from 'react-intl';
import { MainType } from './Container';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

type P = {
  user?: any;
  myData?: any;
};

const ContactFormHelp = ({ intl: { formatMessage }, actions: { sendContactForm }, user, myData }: MainType & P) => {
  const translation = localStorage.getItem('translation');
  const validation = () => {
    return Yup.object().shape({
      message: Yup.string().required(formatMessage({ id: 'global.validation.required' })),
    });
  };
  const [expand, setExpand] = useState(false);
  const [successSend, setSuccessSend] = useState(false);
  return (
    <div className="contactFormHelp__wrapper">
      <div className="contactFormHelp__header">
        <div className="contactFormHelp__heading">
          <FormattedMessage id="global.helper" />
          <SVG type={SVG_TYPE.HELP} />
        </div>
        {successSend && (
          <div className="contactFormHelp__successInfo">
            <SVG type={SVG_TYPE.ACCEPT_GREEN} />
            <p>
              <FormattedMessage id="global.success_send_message" />
            </p>
          </div>
        )}
      </div>
      <p className="contactFormHelp__paragraph">
        <FormattedMessage id="global.need_help_text" />
      </p>
      <div className={`expand-text expand-text-login ${expand ? 'expand-text-active' : ''}`}>
        <span className="popUp--form-contact--description-gray">
          <FormattedMessage id="global.message.law_1" />
        </span>
        <span className="popUp--form-contact--description-gray">
          {/* <FormattedMessage id="global.message.law_2" /> */}
          <FormattedMessage id="global.message.law_2" />
          <a
            href={
              translation === 'pl' ? require('../../../Assets/file/privacy_policy.pdf') : require('../../../Assets/file/privacy_policy en.pdf')
            }
            target="_blank"
            rel="noopener noreferrer">
            <FormattedMessage id="global.message.law_2_policy_privacy" />
          </a>
        </span>
        <div className="expand-text--button expand-text--button-gray">
          <div
            className="expand-text--button-txt"
            onClick={() => {
              setExpand(!expand);
            }}>
            {expand ? (
              <>
                <FormattedMessage id="global.collapse" /> <SVG className="svg--chevron-black-up" type={SVG_TYPE.CHEVRON_DOWN} />
              </>
            ) : (
              <>
                <FormattedMessage id="global.expand" /> <SVG className="svg--chevron-black" type={SVG_TYPE.CHEVRON_DOWN} />
              </>
            )}
          </div>
        </div>
      </div>
      <Formik
        initialValues={{
          topic: 'Pomoc Corgi',
          sender_name: user.first_name + ' ' + user.last_name,
          sender_email: user.email,
          message: '',
          myData: myData,
        }}
        validationSchema={validation()}
        onSubmit={async (values) => {
          const myDataSend = await sendContactForm(values);
          {
            myDataSend && setSuccessSend(true);
          }
        }}>
        {({ handleSubmit, errors }) => (
          <>
            {/* <Field type="text" label={<FormattedMessage id="global.message.title" />} name="topic" component={RegularField} errors={errors} /> */}
            <Field type="textarea" label={<FormattedMessage id="global.message.content" />} name="message" component={RegularField} errors={errors} />
            <Button
              type="submit"
              onClick={() => handleSubmit()}
              label={<FormattedMessage id="global.message.send" />}
              classNames="btn--black"
              iconAlt="icon product"
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default ContactFormHelp;
