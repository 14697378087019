import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';
import * as AuthModule from 'Modules/AuthModule';

export function login(email: string, password: string): AxiosPromise<AuthModule.Types.LogInResponse> {
  return API.post('auth/login', { email, password });
}
export function logout(): AxiosPromise<any> {
  return API.put('auth/logout');
  // config(null, { Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}` })
}
// }
export function getAuthorizedUser(): AxiosPromise<AuthModule.Types.AuthorizedUserData> {
  return API.get('auth/me');
}

export function getRefreshToken(refreshToken: string): AxiosPromise<any> {
  return API.post('auth/refresh', { refreshToken: refreshToken });
}

export function recoverPassword(email: string): AxiosPromise<any> {
  return API.post('auth/recover', { email });
}

export function setNewPassword(token, password, password_strength: 'weak' | 'medium' | 'strong'): AxiosPromise<any> {
  return API.put(`auth/recover/${token}`, { password, password_strength });
}

export function setCompanyVat(data): AxiosPromise<any> {
  return API.put(`vat`, data);
}

export function checkRecoveryToken(token: string): AxiosPromise<any> {
  return API.get(`auth/recover/${token}`);
}

export function getUserLocation(): AxiosPromise<any> {
  return API.get(`https://ipgeolocationapi.com/json/`);
}
