import { Toast } from 'Shared/components';
import { Connector } from '../../../Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: User.SEND_CONTACT_FORM_INIT,
});

const success = () => ({
  type: User.SEND_CONTACT_FORM_SUCCESS,
});

const failure = () => ({
  type: User.SEND_CONTACT_FORM_FAILURE,
});

const sendContactForm = (dataForm) => async (dispatch) => {
  dispatch(init());
  Toast({ id: 'sendContactForm', type: 'loading', message: 'global.apex_no_data' })
  try {
    await Connector.sendContactForm(dataForm);
    Toast({ id: 'sendContactForm', type: 'success', message: 'global.toast.send_contact_form_success' })
    dispatch(success());
    return true;
  } catch (err) {
    Toast({ id: 'sendContactForm', type: 'error', message: 'global.toast.send_contact_form_failure' })
    dispatch(failure());
    return false;
  }
};

export default sendContactForm;
