import React, { useState } from 'react'; 
import GraphDateFilters from './filters/GraphDateFilters';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const BarGraphRange = ({ getCustomPings, uuid, websiteAdded, setSeries, setCustomBarGraph }) => {
    interface Date {
        dateTo: any;
        dateFrom: any;
    }    
    const [error, errorDetected] = useState(null);
    const today = moment(new Date()).format('yyyy-MM-dd');
    const sixMonthsAgo = moment(new Date()).subtract(6, 'months').format('yyyy-MM-dd');
    const [date, setDate] = useState<Date>({dateTo: '',  dateFrom: '', });
    const handleSetFilters = async () => {
        if(date.dateFrom < moment(websiteAdded).format('yyyy-MM-dd')){
            return errorDetected(true);
        }else if(date.dateFrom < sixMonthsAgo){
            return errorDetected(true);
        }else if(date.dateFrom > today){
            return errorDetected(true);
        }else if(date.dateTo < date.dateFrom){
            return errorDetected(true);
        }else if(moment(date.dateTo).format('yyyy-MM-dd') === moment().format('yyyy-MM-dd') && moment(date.dateFrom).format('yyyy-MM-dd') === moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('yyyy-MM-dd')){
            return;
        }
        else{
            errorDetected(null);   
            setSeries([]);
            setCustomBarGraph(true);
            await getCustomPings(uuid, moment(date.dateTo).format('YYYY-MM-DD'), moment(date.dateFrom).format('YYYY-MM-DD'));
        }
    }

    return(
        <>
        <div className="table-filters">
           <GraphDateFilters setDate={setDate} />
           <button onClick={handleSetFilters} className='table-filters__filter-btn'><FormattedMessage id="global.filter" /> </button>
           {error && (<div className='table-filters__errors'>Data nieprawidłowa</div>)}
        </div>
        </>
    )
}

export default BarGraphRange;

BarGraphRange.propTypes = {
    currentFilters: PropTypes.object.isRequired,
    getCustomPings: PropTypes.func.isRequired,
    pings: PropTypes.array.isRequired,
    setFilters: PropTypes.func.isRequired,
    setCustomBarGraph: PropTypes.func.isRequired,
    setSeries: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    websiteAdded: PropTypes.string.isRequired,
}