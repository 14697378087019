import { store } from 'Store/Store';
import { BrowserLanguage } from 'Shared/components';
import { getTranslation } from 'Store/Selectors/ViewManagementSelector';
import { setAppTranslation } from 'Store/Actions/ViewManagementActions';

const Locale = () => {
  const state = store.getState();
  const translation = getTranslation(state);
  if (localStorage.getItem('translation') === 'undefined') {
    localStorage.removeItem('translation');
  }
  const newLocale =
    translation || sessionStorage.getItem('language') || localStorage.getItem('translation') || BrowserLanguage(setAppTranslation) || 'pl';
  return newLocale;
};

export default Locale;
