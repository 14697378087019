import { Connector } from 'Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import { Toast } from 'Shared/components';

const init = () => ({
  type: Auth.RECOVER_PASSWORD_INIT,
});

export const success = () => ({
  type: Auth.RECOVER_PASSWORD_SUCCESS,
});

const failure = () => ({
  type: Auth.RECOVER_PASSWORD_FAILURE,
});

const recoverPassword = (email: string) => async (dispatch) => {
  dispatch(init());

  Toast({ id: 'recoverPassword', type: 'loading', message: 'global.apex_no_data' });
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await Connector.recoverPassword(email);
    dispatch(success());
    dispatch(ViewManagementModule.Actions.hideLoader());
    Toast({ id: 'recoverPassword', type: 'success', message: 'global.toast.remind_password__success' });
    return true;
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());

    Toast({ id: 'recoverPassword', type: 'error', message: 'global.toast.remind_password__failure' });
    return false;
  }
};

export default recoverPassword;
