import { store } from 'Store/Store';
import { authorizedUser as getAuthorizedUser } from 'Store/Selectors/AuthSelector';
import { getTranslation } from 'Store/Selectors/ViewManagementSelector';
import { CURRENCIES } from 'Shared/enums';

type p = {
  currency?: string;
}
const getCurrency = (currency = null) => {
  const state = store.getState();
  const user = getAuthorizedUser(state);
  const langApp = getTranslation(state)
  const currencyApp = CURRENCIES[langApp]
  const loggedUserCurrency = localStorage.getItem('loggedUserCurrency')
  const userCurrency = user?.company?.currency || loggedUserCurrency
  
  if(currency) {
    return currency
  } else if(userCurrency) {
    return userCurrency
  } else {
    return currencyApp
  }
};

export default getCurrency;