import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import getMyWebsites from './getMyWebsites';

const init = () => ({
  type: User.ADD_WEBSITE_PAYMENT_INIT,
});

const success = (data) => ({
  type: User.ADD_WEBSITE_PAYMENT_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.ADD_WEBSITE_PAYMENT_FAILURE,
});

const addWebsitePayment = (dataForm, website_uuid) => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await Connector.addWebsitePayment(dataForm, website_uuid);
    dispatch(success(data));
    await dispatch(getMyWebsites());
    return true;
  } catch (err) {
    console.log(err);
    dispatch(failure());
    return false;
  }
};

export default addWebsitePayment;
