import React, { useState, useEffect } from 'react';
import { Button, Loader } from 'Shared/components/';
import RegularField from 'Shared/form/regularField/regularField';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import copy from 'copy-to-clipboard';
import { getCurrency, getPartnerProvision } from 'Shared/utils';
import { Formik, Field } from 'formik';
import { SVG_TYPE } from '../../enums';

type P = {
  heading?: any;
  descript?: any;
  buttonClick?: any;
  buttonIcon?: any;
  buttonClass?: any;
  buttonClassMobile?: any;
  buttonMobileIconPlacement?: any;
  buttonText?: any;
  beltClass?: any;
  buttonDisabled?: any;
  refLink?: any;
  loggedUser?: any;
  clientName?: any;
  clientSurname?: any;
  websitesPartners?: any;
  loaderVisible?: any;
  accessAddWebsite?: any;
  balance?: any;
  hideBtn?: any;
  getPartnerBalance?: any;
};
const Belt = ({
  heading,
  descript,
  buttonClick,
  buttonIcon,
  buttonClass,
  buttonText,
  beltClass,
  buttonDisabled,
  refLink,
  loggedUser,
  clientName,
  clientSurname,
  websitesPartners,
  loaderVisible,
  balance,
  hideBtn,
  getPartnerBalance,
}: P) => {
  const [tooltipText, setTooltipText] = useState(false);
  const [provisionLang, setProvisionLang] = useState(getCurrency());
  const [langOptions, setLangOptions] = useState([]);
  const isMultiCurrenciesClients = langOptions.length > 1;
  const flagByCurrency = (currency) => {
    switch (currency) {
      case 'PLN':
        return SVG_TYPE.FLAG_PL;
      case 'EUR':
        return SVG_TYPE.FLAG_DE;
      case 'USD':
        return SVG_TYPE.FLAG_USA;
    }
  };
  useEffect(() => {
    const currencies = [
      {
        value: loggedUser?.company?.currency,
        svgType: flagByCurrency(loggedUser?.company?.currency),
        label: loggedUser?.company?.currency,
      },
    ];
    websitesPartners?.forEach((partner) => {
      if (partner.company.currency === 'PLN' || partner.company.currency === 'EUR' || partner.company.currency === 'USD') {
        const index = currencies.findIndex((c) => c.value === partner.company.currency);

        if (index === -1) {
          currencies.push({
            value: partner.company.currency,
            svgType: flagByCurrency(partner.company.currency),
            label: partner.company.currency,
          });
        }
      }
    });
    setLangOptions(currencies);
  }, [websitesPartners]);


  const calculateCommission = (websitesPartners) => {
    const websiteByLang = websitesPartners?.filter((item) => item?.company?.currency === provisionLang);
    return `${getPartnerProvision(websiteByLang, provisionLang)} ${provisionLang}`;
  };

  return (
    <div className={`belt ${beltClass}`}>
      <ReactTooltip
        id="copy"
        place="bottom"
        type="light"
        effect="solid"
        className="tooltip"
        getContent={() => (tooltipText ? <FormattedMessage id="global.coppied" /> : <FormattedMessage id="global.click_coppy" />)}
        afterHide={() => setTooltipText(false)}
      />
      <ReactTooltip
        place="top"
        type="light"
        effect="solid"
        className="tooltip"
        id="noData"
        getContent={() =>
          beltClass == 'addWebsiteBelt' ? <FormattedMessage id="global.enter_data" /> : <FormattedMessage id="global.enter_data_clients" />
        }
      />
      <div className="belt__head">
        <h1 className="belt__head__header">
          {heading}
          {clientName} {clientSurname}
        </h1>
        <p className="belt__head__paragraph">{descript}</p>
        {loggedUser && (
          <p className="belt__head__userName">
            {loggedUser.first_name} {loggedUser.last_name}
          </p>
        )}
        {refLink && (
          <p
            className="belt__head__paragraph partnerBelt__paragraph"
            data-tip
            data-for={'copy'}
            onClick={() => {
              copy(process.env.REACT_APP_FRONT_DOMAIN + '/?refCode=' + refLink);
              setTooltipText(true);
            }}>
            <p>
              <FormattedMessage id="global.your_unique_link" />:{' '}
            </p>
            {process.env.REACT_APP_FRONT_DOMAIN}/?refCode={refLink}
          </p>
        )}
      </div>
      <div className="belt__button">
        {beltClass == 'partnerBelt partnerBeltt' && (
          <div className="belt__price__wrapper">
            <div className="belt__price">
              <p className="belt__price--head">
                {/* {loaderVisible ? <Loader /> : getPartnerProvision(websitesPartners, 'PLN')} */}
                {loaderVisible ? <Loader /> : `+ ${calculateCommission(websitesPartners)}`}
              </p>
              <p className="belt__price--paragraph">
                <FormattedMessage id="global.comission" />
              </p>
            </div>
            <div className="belt__price">
              <p className="belt__price--head">
                {balance?.selected_balance?.balance ? balance?.selected_balance?.balance : 0} {provisionLang}
              </p>
              <p className="belt__price--paragraph">
                <FormattedMessage id="global.your_balance" />
              </p>
            </div>
            {!loaderVisible ? (
              isMultiCurrenciesClients && (
                <div className="belt__price">
                  <Formik
                    initialValues={{
                      currency: loggedUser?.company?.currency,
                    }}
                    enableReinitialize
                    onSubmit={() => console.log()}>
                    {({ errors }) => (
                      <Field
                        type="select"
                        component={RegularField}
                        name="currency"
                        options={langOptions}
                        errors={errors}
                        isClearable={false}
                        handleOnChange={(e) => {
                          getPartnerBalance(e.value);
                          setProvisionLang(e.value);
                        }}
                      />
                    )}
                  </Formik>
                </div>
              )
            ) : (
              <div className="belt__price">
                <Loader />
              </div>
            )}
          </div>
        )}
        {!hideBtn ? (
          <Button
            iconImg={buttonIcon && buttonIcon}
            onClick={buttonClick}
            iconAlt="icon add"
            classNames={buttonClass}
            label={buttonText}
            disabled={buttonDisabled}
          />
        ) : (
          ''
        )}
      </div>
      {beltClass == 'addWebsiteBelt' && (
        <div className="belt__buttonMobile">
          <Button
            iconImg={buttonIcon && buttonIcon}
            onClick={buttonClick}
            iconAlt="icon add"
            classNames={buttonClass}
            label={buttonText}
            disabled={buttonDisabled}
          />
        </div>
      )}
    </div>
  );
};

export default Belt;
