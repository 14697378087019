import React, { useState, useEffect } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import { SVG, Button, Modal } from 'Shared/components';
import RegularField from 'Shared/form/regularField/regularField';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { MainType } from './Container';
import 'react-circular-progressbar/dist/styles.css';
import { AnimatedProgressProvider, RegisterModal } from './components';
import { easeQuadInOut } from 'd3-ease';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { history } from 'Store/Reducers/RootReducer';
import { isMobile, setLocalStorage, getLocalStorage, getPartnerRefcode } from 'Shared/utils';
import { gsap } from 'gsap';
import { useLocation } from 'react-router-dom';
import ADD_WEB_SITE_ROUTES from 'Screens/AddWebsite/AddWebsiteRoutes';
  
type P = {
  history: any;
};

const WebsiteCheckerCopy = ({
  intl: { formatMessage },
  actions: { getCheckWebsite, getAuthorizedUser, closeModal, checkerRegistration, getPaymentMethods },
  checkWebsite,
  translation,
  loggedUser,
}: MainType & P) => {
  const [fromUrl, setFromUrl] = useState(true);
  const [count, setCount] = useState(0);
  const [isLoad, setIsLoad] = useState(false);
  const [isEditUrlActive, setIsEditUrlActive] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isUserRegister, setIsUserRegister] = useState(false);
  const [registerEmail, setEmailRegister] = useState(null);
  const [progress, setProgress] = useState(3);
  const [transitionDuration, setTransitionDuration] = useState('8s');
  const isUserLogged = getLocalStorage('isUserLogged');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fullUrl = searchParams.get('url');
  const [url, refCode] = fullUrl.split('?refCode=');
  const urlParam = url;
  const loadingUrl = isLoad && urlParam;

  useEffect(() => {
    console.log(refCode);

    if (refCode && !isUserLogged) {
      setLocalStorage('refCode', refCode);
      history.push(`${ROUTES.WEBSITE_CHECKER}?url=${url}`);
    }
  }, [refCode]);

  useEffect(() => {
    const fetchData = async () => {
      await getAuthorizedUser();
    };
    !loggedUser && fetchData();
    loggedUser && getPaymentMethods();
  }, []);

  useEffect(() => {
    if (progress !== 100) {
      const timer = count > 0 && setInterval(() => setCount(count - 1), 1000);
      if (count === 1) {
        setProgress(95);
        setTransitionDuration('25s');
      }
      return () => clearInterval(timer);
    }
  }, [count]);

  let data;
  if (checkWebsite) {
    data = checkWebsite;
  } else if (sessionStorage?.checkWebsite) {
    data = JSON.parse(sessionStorage?.checkWebsite);
  }

  if (!checkWebsite && !sessionStorage?.checkWebsite && !urlParam) {
    history.push(ROUTES.CHECK_YOUR_WEBSITE);
  }

  const percentage = data?.websiteProtectionScore;
  const isWordpress = data?.isWordpress;
  // let isSSL;
  // if (data?.websiteUrl?.includes('https://')) {
  //   isSSL = true;
  // } else {
  //   isSSL = false;
  // }

  const scrollToTiles = () => {
    const section = document.querySelector('#tiles') as HTMLElement;
    const windowHeight = window.innerHeight;
    const sectionHeight = section?.offsetHeight;
    const sectionTop = section?.offsetTop;

    const scrollOffset = sectionTop - (windowHeight - sectionHeight) / 2;
    window.scrollTo({
      top: scrollOffset,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    function corgiFly(element) {
      const amplitude = isMobile() ? 15 : 50; // odległość, o którą porusza się element
      const duration = isMobile() ? 1 : 2; // częstotliwość drgań

      gsap.to(element, {
        y: amplitude,
        duration: duration,
        yoyo: true,
        repeat: -1,
        ease: 'sine.inOut',
      });
    }

    const svgImage = document.getElementById('corgiFly');
    corgiFly(svgImage);
  }, []);
  const report = () => {
    if (percentage <= 29 && percentage < 30) {
      return {
        color: '#e20d0d',
        trailColor: '#ffd4d4',
        status: 'error',
        heading: formatMessage({ id: 'global.checker.dog_ankle' }),
        security_level: `${
          data?.wpAdminPathSearchStatus && data?.usersList && data?.usersList.length > 0
            ? formatMessage({ id: 'global.checker.low_level_security_1' })
            : ''
        } 
        ${data?.wpAdminPathSearchStatus && !data?.usersList ? formatMessage({ id: 'global.checker.low_level_security_2' }) : ''}
        ${
          !data?.wpAdminPathSearchStatus && data?.usersList && data?.usersList.length > 0
            ? formatMessage({ id: 'global.checker.low_level_security_3' })
            : ''
        }
          `,
      };
    } else if (percentage > 29 && percentage <= 89) {
      return {
        color: '#F29225',
        trailColor: '#FFC380',
        status: 'warning',
        heading: formatMessage({ id: 'global.checker.dog_ankle' }),
        security_level: formatMessage({ id: 'global.checker.moderate_level_security' }),
      };
    } else if (percentage >= 90) {
      return {
        color: '#38D58B',
        trailColor: '#9DF2CB',
        status: 'success',
        heading: formatMessage({ id: 'global.checker.gratulations' }),
        security_level: formatMessage({ id: 'global.checker.high_level_security' }),
      };
    } else if (checkWebsite?.error === 'Unable to find website' || data?.error === 'Unable to find website') {
      return { color: '#e20d0d', trailColor: '#ffd4d4', status: 'error', heading: formatMessage({ id: 'global.checker.dog_ankle' }) };
    } else if (!isWordpress) {
      return { color: '#e20d0d', trailColor: '#ffd4d4', status: 'error', heading: formatMessage({ id: 'global.checker.dog_ankle' }) };
    }
  };

  // #ffd4d4
  const isError = report()?.status === 'error';
  const isWarning = report()?.status === 'warning';
  const isSuccess = report()?.status === 'success';
  const thereIsNoSuchPage = checkWebsite?.error === 'Unable to find website' || data?.error === 'Unable to find website';
  const corgiMascot = isWarning || isError || thereIsNoSuchPage || !isWordpress;
  const isErrorOrWarning = (isError && !thereIsNoSuchPage && isWordpress) || (isWarning && !thereIsNoSuchPage && isWordpress);

  const validation = () => {
    return Yup.object().shape({
      url: Yup.string().required(' '),
    });
  };

  // const openRegisterModal = () => {
  //   setIsOpenModal(true);
  //   openModal();
  // };
  const closeRegisterModal = () => {
    setIsOpenModal(false);
    closeModal();
  };

  const redirectToExternalPage = () => {
    window.open(`${data?.websiteUrl}/wp-admin`, '_blank');
  };

  const runLoader = () => {
    setProgress(3);
    setIsLoad(true);
    setTransitionDuration('8s');
    setTimeout(() => {
      setProgress(80);
    }, 10);
    setTimeout(() => {
      setCount(8);
    }, 100);
  };

  const afterReturnChecker = () => {
    setIsEditUrlActive(false);
    setTransitionDuration('1s');
    setProgress(100);
    setTimeout(() => {
      setIsLoad(false);
      setProgress(3);
      setTransitionDuration('8s');
    }, 500);
  };

  function enterUrl() {
    setTimeout(() => {
      const wrap = document.getElementById('enterUrl');
      const input = wrap.getElementsByTagName('input');
      input[0].focus();
    }, 100);
  }

  useEffect(() => {
    const storage = JSON.parse(sessionStorage.getItem('checkWebsite'));
    !storage?.websiteUrl?.includes(urlParam) && runLoader();
    async function fetchData() {
      const data = await getCheckWebsite(urlParam, loggedUser?.uuid);
      if (data) {
        setIsLoad(false);
      }
    }
    !storage?.websiteUrl?.includes(urlParam) && fetchData();
  }, [urlParam]);

  function getHttpsUrl(url) {
    if (url?.includes('https://')) {
      return url;
    }
    if (url?.includes('http://')) {
      return url.replace('http://', 'https://');
    } else {
      return `https://${url}`;
    }
  }

  function calcScaleForIframe() {
    const livePreview = document.querySelector('.livePreview') as HTMLElement;
    if (livePreview && isMobile()) {
      const livePreviewIframe = document.querySelector('.livePreview iframe') as HTMLElement;
      const livePreviewWidth = livePreview.offsetWidth;
      const scale = livePreviewWidth / 1440;
      livePreviewIframe.style.transform = `scale(${scale})`;
      livePreviewIframe.style.height = `${280 * (scale * 10)}px`;
    }
  }



  calcScaleForIframe();
  return (
    <>
      {!isUserRegister ? (
        <>
          <Modal className="checker-register" opened={isOpenModal} onClose={closeRegisterModal}>
            <RegisterModal
              translation={translation}
              setEmailRegister={setEmailRegister}
              closeRegisterModal={closeRegisterModal}
              setIsUserRegister={setIsUserRegister}
              url={data?.websiteUrl}
              checkerRegistration={checkerRegistration}
              formatMessage={formatMessage}
            />
          </Modal>
          <div className="websiteChecker">
            <div className="container">
              <div className={`websiteChecker__tile websiteChecker__tile--name ${loadingUrl ? 'websiteChecker__loadingUrl' : ''}`}>
                <div className="checkSite--form" onClick={() => enterUrl()}>
                  {!isLoad ? (
                    <Formik
                      initialValues={{
                        url: '',
                      }}
                      validationSchema={validation()}
                      onSubmit={async ({ url }) => {
                        setIsEditUrlActive(false);
                        history.push(`${ROUTES.WEBSITE_CHECKER}?url=${url}${getPartnerRefcode(loggedUser)}`);
                        setFromUrl(false);
                        const data = await getCheckWebsite(url, loggedUser?.uuid);
                        data && afterReturnChecker();
                      }}>
                      {({ errors, handleSubmit }) => (
                        <>
                          <SVG className="websiteName__icon" type={SVG_TYPE.GLOBE} />
                          <div
                            className={`websiteName ${!isEditUrlActive ? 'websiteName--click' : ''}`}
                            onClick={() => !isEditUrlActive && setIsEditUrlActive(true)}>
                            {isEditUrlActive ? (
                              <form onSubmit={handleSubmit} onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}>
                                <div id="enterUrl" className="checkSite__form">
                                  <Field
                                    placeholder={formatMessage({ id: 'global.checker.write_address_site' })}
                                    type="text"
                                    name="url"
                                    component={RegularField}
                                    errors={errors}
                                  />
                                </div>
                              </form>
                            ) : (
                              <>
                                <h1 className="websiteName__url websiteName__url--click">{data?.websiteUrl || urlParam}</h1>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </Formik>
                  ) : (
                    <>
                      <p className="progressBar--defaultState" />
                      <ProgressBar
                        completed={progress}
                        bgColor="#3832EC"
                        borderRadius="30"
                        transitionDuration={transitionDuration}
                        transitionTimingFunction="ease"
                        labelClassName="progressBar__label"
                        barContainerClassName="progressBar"
                        animateOnRender
                        initCompletedOnAnimation={0}
                      />
                    </>
                  )}
                </div>
                <div className="websiteChecker__title--wrapper">
                  {!loadingUrl || !fromUrl ? (
                    <>
                      <div className="reportLabel__wrapper">
                        <div className={`reportLabel reportLabel--${report()?.status}`}> {report()?.heading} </div>
                        {isErrorOrWarning && <p className="paragraph"> {formatMessage({ id: 'global.checker.not_enaugh_protected' })} </p>}
                        {thereIsNoSuchPage && <p className="paragraph"> {formatMessage({ id: 'global.checker.corgi_not_found' })} </p>}
                        {!isWordpress && !thereIsNoSuchPage && (
                          <p className="paragraph">
                            {formatMessage({ id: 'global.checker.not_wordpress' })} <span>{data?.websiteUrl} </span>{' '}
                            {formatMessage({ id: 'global.checker.not_wordpress_cd' })}
                          </p>
                        )}
                        {isSuccess && <p className="paragraph"> {formatMessage({ id: 'global.checker.corgi_is_here' })} </p>}

                        {corgiMascot ? (
                          <SVG className="iconRight" type={SVG_TYPE.CORGI_FULL_BABY} />
                        ) : (
                          <SVG className="iconRight iconRight--woof" type={SVG_TYPE.CORGI_WOOF_TAK} />
                        )}
                      </div>
                      <div className="livePreview">
                        <iframe
                          id="inlineFrameExample"
                          scrolling="no"
                          title="Inline Frame Example"
                          height="295"
                          src={getHttpsUrl(data?.websiteUrl)}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              {(isErrorOrWarning || isSuccess) && (
                <div className={`websiteChecker__tile websiteChecker__tile--report ${isSuccess ? 'websiteChecker__tile--success' : ''}`}>
                  <div className="report">
                    <div className="report--left">
                      <AnimatedProgressProvider valueStart={0} valueEnd={percentage} duration={4} easingFunction={easeQuadInOut}>
                        {(value) => {
                          const roundedValue = Math.round(value);
                          return (
                            <CircularProgressbar
                              value={value}
                              text={`${roundedValue}%`}
                              styles={buildStyles({
                                pathTransition: 'none',
                                pathColor: report()?.color,
                                textColor: report()?.color,
                                trailColor: report()?.trailColor,
                              })}
                            />
                          );
                        }}
                      </AnimatedProgressProvider>
                    </div>
                    <div className="report--right">
                      <p className="report__levelLabel">{formatMessage({ id: 'global.checker.website_security_level' })}</p>
                      <div className="report__level">
                        <div className="report__dot report__dot--error" />
                        <p className="report__level--paragraph">0-29% {formatMessage({ id: 'global.checker.low' })}</p>
                      </div>
                      <div className="report__level">
                        <div className="report__dot report__dot--warning" />
                        <p className="report__level--paragraph">30-89% {formatMessage({ id: 'global.checker.medium' })}</p>
                      </div>
                      <div className="report__level">
                        <div className="report__dot report__dot--success" />
                        <p className="report__level--paragraph">90-100% {formatMessage({ id: 'global.checker.high' })}</p>
                      </div>
                    </div>
                  </div>
                  {data?.wpAdminPathSearchStatus && (
                    <div className="report__error">
                      <p className="report__error--paragraph">
                        <SVG className="svg--exclamation-circle--fullred" type={SVG_TYPE.EXCLAMATION_CIRCLE} />{' '}
                        {formatMessage({ id: 'global.checker.public_adres' })}{' '}
                      </p>
                      <div className="report__link" onClick={() => redirectToExternalPage()}>
                        {' '}
                        {data?.websiteUrl}
                        {data?.websiteUrl.slice(-1) === '/' ? 'wp-admin' : '/wp-admin'}
                      </div>
                    </div>
                  )}
                  {data?.usersList && data?.usersList.length > 0 && (
                    <div className="report__error">
                      <p className="report__error--paragraph">
                        <SVG className="svg--exclamation-circle--fullred" type={SVG_TYPE.EXCLAMATION_CIRCLE} />{' '}
                        {formatMessage({ id: 'global.checker.public_user_info' })}{' '}
                      </p>
                      <ul className="report__usersList">
                        {data?.usersList.map((data) => {
                          return <li key={data.id}>{data.name}</li>;
                        })}
                      </ul>
                    </div>
                  )}
                  {/* {!isSSL && (
                    <div className="report__error">
                      <p className="report__error--paragraph">
                        <SVG className="svg--exclamation-circle--fullred" type={SVG_TYPE.EXCLAMATION_CIRCLE} />{' '}
                        {formatMessage({ id: 'global.no_certificate_ssl' })}
                      </p>
                      <ul className="report__usersList">
                        <li className="report__usersList--blue">{formatMessage({ id: 'global.connection_is_not_private' })} </li>
                      </ul>
                    </div>
                  )} */}
                  <div className="report__summary">
                    <p className="heading">{formatMessage({ id: 'global.checker.summary' })}</p>
                    <p className="paragraph">
                      {formatMessage({ id: 'global.checker.your_website' })}{' '}
                      <span className="bold">
                        {data?.websiteUrl.slice(-1) === '/' ? data?.websiteUrl.substr(0, data?.websiteUrl.length - 1) : data?.websiteUrl}{' '}
                      </span>
                      {report()?.security_level}
                    </p>
                    {!isSuccess && (
                      <p className="paragraph paragraph--row">
                        <span>{formatMessage({ id: 'global.checker.what_can_you_do' })}</span>
                        <Button label={formatMessage({ id: 'global.checker.check' })} classNames="btn--blue-new" onClick={() => scrollToTiles()} />
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
            {isErrorOrWarning && (
              <>
                <div id="tiles" className="tiles__wrapper">
                  <svg
                    className="tiles--background"
                    preserveAspectRatio="xMinYMin slice"
                    viewBox="0 0 1440 965"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M1440 177L0 0V830L1440 964.5V177Z" fill="white" />
                  </svg>
                  <div className="tiles">
                    <div className="tiles__heading">
                      <div className="left">
                        <p className="tiles--heading">{formatMessage({ id: 'global.checker.dont_worry' })}</p>
                        <p className="tiles--paragraph">{formatMessage({ id: 'global.checker.corgi_best_friend' })}</p>
                      </div>
                      <div className="right">
                        <SVG type={SVG_TYPE.CORGI_PREMIUM_FLY} />
                      </div>
                    </div>
                    <div className="tiles__content">
                      <div className="tiles__item">
                        <SVG type={SVG_TYPE.MONITOR} />
                        <div className="tiles__item--heading">{formatMessage({ id: 'global.checker.monitor' })}</div>
                        <div className="tiles__item--paragraph">{formatMessage({ id: 'global.checker.monitor_descript' })}</div>
                      </div>
                      <div className="tiles__item">
                        <SVG type={SVG_TYPE.BACKUP_ICON} />
                        <div className="tiles__item--heading">{formatMessage({ id: 'global.checker.backup' })}</div>
                        <div className="tiles__item--paragraph">{formatMessage({ id: 'global.checker.backup_descript' })}</div>
                      </div>
                      <div className="tiles__item">
                        <SVG type={SVG_TYPE.UPDATE} />
                        <div className="tiles__item--heading">{formatMessage({ id: 'global.checker.updates' })}</div>
                        <div className="tiles__item--paragraph">{formatMessage({ id: 'global.checker.updates_descript' })}</div>
                      </div>
                      <div className="tiles__item">
                        <SVG type={SVG_TYPE.SECURITY} />
                        <div className="tiles__item--heading">{formatMessage({ id: 'global.checker.security' })}</div>
                        <div className="tiles__item--paragraph">{formatMessage({ id: 'global.checker.security_descript' })}</div>
                        <SVG className="tiles--paws" type={SVG_TYPE.PAWS_2} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom__wrapper">
                  <svg
                    className="bottom--background"
                    preserveAspectRatio="xMinYMin slice"
                    viewBox="0 0 1440 616"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M1440 132.885L0 0V616H1440V132.885Z" fill="#352FEE" />
                  </svg>
                  <div className="bottom__content">
                    <div className="bottom">
                      <div className="bottom__l">
                        <SVG type={SVG_TYPE.CORGI_PREMIUM_CIRCLE} />
                      </div>
                      <div className="bottom__r">
                        <p className="bottom--heading">{formatMessage({ id: 'global.checker.dont_worry_2' })}</p>
                        <p className="bottom--paragraph">{formatMessage({ id: 'global.checker.corgi_best_friend_2' })}</p>
                        <Button
                          label={formatMessage({ id: 'global.checker.check_for_free' })}
                          classNames="btn--white-border"
                          onClick={() => history.push({ pathname: ADD_WEB_SITE_ROUTES.ACCOUNT, withoutWebsiteStep: true, url: data?.websiteUrl })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="websiteChecker websiteChecker--success">
          <SVG className="websiteChecker__bg" type={SVG_TYPE.HERO_SECTION_BG} />
          <div className="container container--center">
            <div className="websiteChecker__success">
              <SVG type={SVG_TYPE.PREMIUM_WOOF} />
              <p className="websiteChecker__success--heading"> {formatMessage({ id: 'global.success_verified_account' })} </p>
              <p className="websiteChecker__success--email"> {registerEmail} </p>
              <p className="websiteChecker__success--padding">{formatMessage({ id: 'global.checker.click_below_to_login' })} </p>
              <Button
                onClick={() => history.push(ROUTES.LOGIN)}
                label={formatMessage({ id: 'global.menu_login' })}
                classNames="btn--blue"
                iconImgSvg={SVG_TYPE.RIGHT_ARROW_BOLD}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WebsiteCheckerCopy;
