import { connect } from 'react-redux';
import faq from './Main';
import { bindActionCreators } from 'redux';
import { Actions as VActions } from 'Modules/ViewManagementModule';

type ConnectedP = {
  loggedUser: any;
};

type DispatchedP = {
  actions: {
    openModal: () => void;
    closeModal: () => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      openModal: () => VActions.openModal(),
      closeModal: () => VActions.closeModal(),
    },
    dispatch,
  ),
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(faq);
