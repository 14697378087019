import React from 'react';
import Menu from '../Menu/Container';
import HistoryEvents from '../HistoryEvents';
import PaymentsUser from '../PaymentsUser';
import ChangePassword from '../ChangePassword/Container';
import Partners from '../Partners';
import MyData from '../MyData';

type P = {
  openMenuFn: boolean;
  logout: () => void;
  close?: any;
  selectMenuFn?: any;
  selectActive?: any;
  comeBack: () => void;
  editUser: (data) => void;
  addPaymentMethod: (data) => void;
  deletePaymentMethod: (id) => void;
  loggedUser: any;
  paymentMethods: any;
};

const ClientPanel = ({
  loggedUser,
  editUser,
  comeBack,
  openMenuFn,
  selectMenuFn,
  selectActive,
  logout,
  paymentMethods,
  addPaymentMethod,
  deletePaymentMethod,
}: P) => {
  return (
    <>
      <Menu
        paymentMethods={paymentMethods}
        loggedUser={loggedUser}
        openMenuFn={openMenuFn}
        selectMenuFn={selectMenuFn}
        selectActive={selectActive}
        logout={logout}
      />
      <div className="userMenu__chose-wrapper">
        <div className="userMenu__chose">
          {selectActive === 'yourSite' && <HistoryEvents comeBack={comeBack} />}
          {selectActive === 'payments' && (
            <PaymentsUser
              comeBack={comeBack}
              paymentMethods={paymentMethods}
              addPaymentMethod={addPaymentMethod}
              deletePaymentMethod={deletePaymentMethod}
            />
          )}
          {selectActive === 'partners' && <Partners />}
          {selectActive === 'myData' && <MyData editUser={editUser} loggedUser={loggedUser} comeBack={comeBack} />}
          {selectActive === 'changePassword' && <ChangePassword comeBack={comeBack} />}
        </div>
      </div>
    </>
  );
};

export default ClientPanel;
