import React, { useRef, useState } from 'react';

interface FadeInOnScrollProps {
  children: React.ReactNode;
  className?: string;
  delay?: number;
  id?: string;
}

const FadeInOnScroll = ({ children, className = '', delay = 300, id }: FadeInOnScrollProps) => {
  const [isVisible, setVisible] = useState(false);

  const domRef = useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (delay) {
            setTimeout(() => {
              setVisible(entry.isIntersecting);
            }, delay);
          } else {
            setVisible(entry.isIntersecting);
          }
        }
      });
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <div id={id ? id : null} className={`${className} ${isVisible ? 'fade-in' : 'hidden'}`} ref={domRef}>
      {children}
    </div>
  );
};

export default FadeInOnScroll;
