import { Locale } from 'Shared/components';

const getPhonePrefixFromTranslation = () => {
  switch (Locale()) {
    case 'de':
      return '+49';
    case 'en':
      return  '+1';
    case 'pl':
      return  '+48';
    default:
      return '+48';
  }
};

export default getPhonePrefixFromTranslation;
