import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';

const success = (data) => ({
  type: Website.GET_WORDPRESS_AVAILABLE_UPDATES_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_WORDPRESS_AVAILABLE_UPDATES_FAILURE,
});

const getWordpressUpdates = (uuid) => async (dispatch) => {
  try {
    const { data } = await Connector.getWpUpdates(uuid);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
  }
};

export default getWordpressUpdates;
