import React from 'react';
import { history } from 'Store/Reducers/RootReducer';
import { Badge, Loader } from 'Shared/components';

type P = {
  number: number;
  label: any;
  labelType: string;
  push?: any;
  loaderVisible?: any;
};
const InfoCounter = ({ number, label, labelType, push, loaderVisible }: P) => {
  return (
    <div className={`infoCounter infoCounter--${labelType}`} onClick={() => history.push(push)}>
      {loaderVisible ? (
        <Loader />
      ) : (
        <>
          <p className={`infoCounter__number infoCounter__number--${labelType}`}>{number}</p>
          <Badge label={label} type={labelType} />
        </>
      )}
    </div>
  );
};

export default InfoCounter;
{
  /* <div className="websitesInfo__counter websitesInfo__counter--green" onClick={() => history.push(ROUTES.MY_WEBSITES + '?filter=itworks')}> */
}
