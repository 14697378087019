import React, { useEffect, useState } from 'react';
import Button from 'Shared/components/button/Button';
import { FormattedMessage } from 'react-intl';
import { SVG } from 'Shared/components/';
import { PACKET_TYPE, SVG_TYPE } from 'Shared/enums';
import { stripePricing } from 'Shared/utils';
import ReactTooltip from 'react-tooltip';
import { MainType } from './Container';
import { useHistory } from 'react-router-dom';
import ADD_WEB_SITE_ROUTES from 'Screens/AddWebsite/AddWebsiteRoutes';

type P = {
  location: any;
};

const Packages: React.FC<P & MainType> = ({ location, intl }) => {
  const [scalable] = useState({
    description: true,
    security: true,
    additional: true,
    backup: true,
    updates: true,
  });
  const history = useHistory();
  const renderTooltip = (idType = '') => (
    <span data-tip={idType && intl.formatMessage({ id: idType })}>
      <SVG type={SVG_TYPE.INFORMATION} />
    </span>
  );

  const handlePacketClick = (packet) => {
    history.push({
      pathname: `${ADD_WEB_SITE_ROUTES.WEBSITE}`,
      previousPath: history.location.pathname,
      packages: packet.toLowerCase(),
    });
  };

  const handleScalableChange = (type) => {
    // setScalable({ ...scalable, [type]: !scalable[type] });
    console.log(type);
  };

  //when scroll is more than 200px add  class packagesTop--fixed. Use useEffect
  const [scroll, setScroll] = useState(0);
  useEffect(() => {
    const query = document.querySelector('.fullscreen-modal__warpper--overflow');
    if (query) {
      query.addEventListener('scroll', () => {
        setScroll(query.scrollTop);
      });
    }
  }, []);

  return (
    <>
      <ReactTooltip place="bottom" type="light" effect="solid" className="tooltip" />
      <div className="packages">
        <div id="container" className={`container package-${location.packages}`}>
          <div id="packages" className={`packagesTop ${scroll > 200 ? 'packagesTop--fixed' : ''}`}>
            <h2 className="packagesTop--head">
              <FormattedMessage id="global.compare_packages" />
            </h2>
            <div className="packagesTop__wrap ">
              <div className={`packagesTop__content packagesTop__content--basic`}>
                <p className="packagesTop__name packagesTop__name--black">BASIC</p>
                <p className="packagesTop__price">
                  {stripePricing().basic} {stripePricing().currencies}
                  <span>
                    <FormattedMessage id="global.vat" />/
                    <FormattedMessage id="global.monthly_short" />
                  </span>
                </p>
                <div className="packagesTop__button">
                  <Button onClick={() => handlePacketClick(PACKET_TYPE.BASIC)} label={<FormattedMessage id="global.try_for_free" />} />
                </div>
                <SVG className="packagesTop--image" type={SVG_TYPE.PACKETS_BASIC} />
              </div>
            </div>
            <div className="packagesTop__wrap">
              <div className={`packagesTop__content packagesTop__content--premium`}>
                <p className="packagesTop__name packagesTop__name--white">PREMIUM</p>
                <p className="packagesTop__price">
                  {stripePricing().premium} {stripePricing().currencies}
                  <span>
                    <FormattedMessage id="global.vat" />/
                    <FormattedMessage id="global.monthly_short" />
                  </span>
                </p>
                <div className="packagesTop__button">
                  <Button onClick={() => handlePacketClick(PACKET_TYPE.PREMIUM)} label={<FormattedMessage id="global.try_for_free" />} />
                </div>
                <SVG className="packagesTop--image" type={SVG_TYPE.PACKETS_PREMIUM} />
              </div>
            </div>
            <div className="packagesTop__wrap">
              <div className={`packagesTop__content packagesTop__content--pro`}>
                <p className="packagesTop__name packagesTop__name--white">PRO</p>
                <p className="packagesTop__price">
                  {stripePricing().pro} {stripePricing().currencies}
                  <span>
                    <FormattedMessage id="global.vat" />/
                    <FormattedMessage id="global.monthly_short" />
                  </span>
                </p>
                <div className="packagesTop__button">
                  <Button onClick={() => handlePacketClick(PACKET_TYPE.PRO)} label={<FormattedMessage id="global.try_for_free" />} />
                </div>
                <SVG className="packagesTop--image" type={SVG_TYPE.PACKETS_PRO} />
              </div>
            </div>
          </div>
          <div className="packagesTable">
            <p className="packagesTable--head-mobile packagesTable--head-mobile-padding">
              <FormattedMessage id="global.compare_packages" />
            </p>
            <div className="packagesTable__line packagesTable__scalable-click" onClick={() => handleScalableChange('description')}>
              <div className={`packagesTable__scalable-chevron ${scalable.description ? 'packagesTable__scalable-chevron--up' : ''}`}>
                <p className="packagesTable--description packagesTable--description-bold">
                  <FormattedMessage id="global.pricing.diagnostics" />
                </p>
              </div>
              <div className="packagesTop__image">
                <SVG className="packagesTop--image-mobile" type={SVG_TYPE.PACKETS_BASIC} />
              </div>
              <div className="packagesTop__image">
                <SVG className="packagesTop--image-mobile" type={SVG_TYPE.PACKETS_PREMIUM} />
              </div>
              <div className="packagesTop__image">
                <SVG className="packagesTop--image-mobile" type={SVG_TYPE.PACKETS_PRO} />
              </div>
            </div>
            <div className={`packagesTable__scalable packagesTable__scalable--${scalable.description ? 'show' : 'hide'}`}>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description">
                  {renderTooltip('global.pricing.detailed_2')}
                  <FormattedMessage id="global.home_page.packet_basic_2" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description">
                  {renderTooltip('global.pricing.detailed_3')}
                  <FormattedMessage id="global.home_page.packet_basic_3" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description">
                  {renderTooltip('global.pricing.detailed_5')}
                  <FormattedMessage id="global.home_page.packet_basic_5" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description packagesTable--description--left">
                  <FormattedMessage id="global.home_page.packet_basic_7" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description packagesTable--description--left">
                  {/* {renderTooltip('global.pricing.detailed_9')} */}
                  <FormattedMessage id="global.details_modal.option_title_6" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description">
                  {renderTooltip('global.pricing.detailed_9')}
                  <FormattedMessage id="global.home_page.packet_premium_3" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description">
                  {renderTooltip('global.pricing.detailed_7')}
                  <FormattedMessage id="global.home_page.packet_premium_1" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
            </div>
            <div className="packagesTable__line packagesTable__scalable-click" onClick={() => handleScalableChange('backup')}>
              <div className={`packagesTable__scalable-chevron ${scalable.description ? 'packagesTable__scalable-chevron--up' : ''}`}>
                <p className="packagesTable--description packagesTable--description-bold">
                  <FormattedMessage id="global.details_modal.cyclic_backup" />
                </p>
              </div>
              <div className="packagesTop__image">
                <SVG className="packagesTop--image-mobile" type={SVG_TYPE.PACKETS_BASIC} />
              </div>
              <div className="packagesTop__image">
               <SVG className="packagesTop--image-mobile" type={SVG_TYPE.PACKETS_PREMIUM} />
              </div>
              <div className="packagesTop__image">
                <SVG className="packagesTop--image-mobile" type={SVG_TYPE.PACKETS_PRO} />
              </div>
            </div>
            <div className={`packagesTable__scalable packagesTable__scalable--${scalable.backup ? 'show' : 'hide'}`}>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description">
                  {renderTooltip('global.pricing.detailed_4')}
                  <FormattedMessage id="global.pricing.cyclic" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-vtext packagesTable--icon-vtext-desktop">
                    <FormattedMessage id="global.pricing.every_month" />
                  </span>
                  <span className="packagesTable--icon-vtext packagesTable--icon-vtext-mobile">
                    <FormattedMessage id="global.pricing.every_month_short" />
                  </span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-vtext packagesTable--icon-vtext-desktop">
                    {' '}
                    <FormattedMessage id="global.pricing.every_week" />
                  </span>
                  <span className="packagesTable--icon-vtext packagesTable--icon-vtext-mobile">
                    <FormattedMessage id="global.pricing.every_week_short" />
                  </span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-vtext packagesTable--icon-vtext-desktop">
                    <FormattedMessage id="global.pricing.every_day" />
                  </span>
                  <span className="packagesTable--icon-vtext packagesTable--icon-vtext-mobile">
                    <FormattedMessage id="global.pricing.every_day_short" />
                  </span>
                </p>
              </div>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description packagesTable--description--left">
                  {/* {renderTooltip('global.pricing.detailed_3')} */}
                  <FormattedMessage id="global.details_modal.option_title_1" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
            </div>
            <div className="packagesTable__line packagesTable__scalable-click" onClick={() => handleScalableChange('updates')}>
              <div className={`packagesTable__scalable-chevron ${scalable.updates ? 'packagesTable__scalable-chevron--up' : ''}`}>
                <p className="packagesTable--description packagesTable--description-bold">
                  <FormattedMessage id="global.what_tricks_corgi_can_do_options.automatic_updates.title" />
                </p>
              </div>
              <div className="packagesTop__image">
                <SVG className="packagesTop--image-mobile" type={SVG_TYPE.PACKETS_BASIC} />
              </div>
              <div className="packagesTop__image">
               <SVG className="packagesTop--image-mobile" type={SVG_TYPE.PACKETS_PREMIUM} />
              </div>
              <div className="packagesTop__image">
                <SVG className="packagesTop--image-mobile" type={SVG_TYPE.PACKETS_PRO} />
              </div>
            </div>
            <div className={`packagesTable__scalable packagesTable__scalable--${scalable.updates ? 'show' : 'hide'}`}>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description packagesTable--description--left">
                  {/* {renderTooltip('global.pricing.detailed_3')} */}
                  <FormattedMessage id="global.details_modal.option_title_2" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description">
                  {renderTooltip('global.pricing.detailed_12')}
                  <FormattedMessage id="global.home_page.packet_premium_6" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description packagesTable--description--left">
                  {/* {renderTooltip('global.pricing.detailed_3')} */}
                  <FormattedMessage id="global.details_modal.option_title_3" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
            </div>
            <div className="packagesTable__line packagesTable__scalable-click" onClick={() => handleScalableChange('security')}>
              <div className={`packagesTable__scalable-chevron ${scalable.security ? 'packagesTable__scalable-chevron--up' : ''}`}>
                <p className="packagesTable--description packagesTable--description-bold">
                  <FormattedMessage id="global.details_modal.copyright_protection" />
                </p>
              </div>
              <div className="packagesTop__image">
                <SVG className="packagesTop--image-mobile packagesTop__image-mobile-gray" type={SVG_TYPE.PACKETS_BASIC} />
              </div>
              <div className="packagesTop__image">
               <SVG className="packagesTop--image-mobile" type={SVG_TYPE.PACKETS_PREMIUM} />
              </div>
              <div className="packagesTop__image">
                <SVG className="packagesTop--image-mobile" type={SVG_TYPE.PACKETS_PRO} />
              </div>
            </div>
            <div className={`packagesTable__scalable packagesTable__scalable--${scalable.security ? 'show' : 'hide'}`}>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description">
                  {/* {renderTooltip('global.pricing.detailed_8')} */}
                  <FormattedMessage id="global.home_page.packet_basic_6" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description packagesTable--description--left">
                  {/* {renderTooltip('global.pricing.detailed_8')} */}
                  <FormattedMessage id="global.details_modal.option_title_4" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description">
                  {renderTooltip('global.pricing.detailed_8')}
                  <FormattedMessage id="global.details_modal.option_title_5" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description">
                  {renderTooltip('global.pricing.detailed_10')}
                  <FormattedMessage id="global.home_page.packet_premium_4" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description">
                  {renderTooltip('global.pricing.detailed_11')}
                  <FormattedMessage id="global.home_page.packet_premium_5" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
            </div>
            <div className="packagesTable__line packagesTable__scalable-click" onClick={() => handleScalableChange('additional')}>
              <div className={`packagesTable__scalable-chevron ${scalable.additional ? 'packagesTable__scalable-chevron--up' : ''}`}>
                <p className="packagesTable--description packagesTable--description-bold">
                  <FormattedMessage id="global.pricing.additional" />
                </p>
              </div>
              <div className="packagesTop__image">
                <SVG className="packagesTop--image-mobile packagesTop__image-mobile-gray" type={SVG_TYPE.PACKETS_BASIC} />
              </div>
              <div className="packagesTop__image">
                <SVG className="packagesTop--image-mobile packagesTop__image-mobile-gray" type={SVG_TYPE.PACKETS_PREMIUM} />
              </div>
              <div className="packagesTop__image">
                <SVG className="packagesTop--image-mobile" type={SVG_TYPE.PACKETS_PRO} />
              </div>
            </div>
            <div className={`packagesTable__scalable packagesTable__scalable--${scalable.additional ? 'show' : 'hide'}`}>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description">
                  {renderTooltip('global.pricing.detailed_13')}
                  <FormattedMessage id="global.home_page.packet_pro_1" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
              <div className="packagesTable__line packagesTable__line--bottom">
                <p className="packagesTable--description">
                  {renderTooltip('global.pricing.detailed_14')}
                  <FormattedMessage id="global.home_page.packet_pro_2" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
              <div className="packagesTable__line ">
                <p className="packagesTable--description">
                  {renderTooltip('global.pricing.detailed_15')}
                  <FormattedMessage id="global.home_page.packet_pro_3" />
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-x"></span>
                </p>
                <p className="packagesTable--icon">
                  <span className="packagesTable--icon-v"></span>
                </p>
              </div>
            </div>
          </div>
          <div className="packagesTakePreorder">
            <p className="packagesTakePreorder--text"></p>
            <Button onClick={() => handlePacketClick(PACKET_TYPE.BASIC)} label={<FormattedMessage id="global.try_for_free" />} />
            <Button onClick={() => handlePacketClick(PACKET_TYPE.PREMIUM)} label={<FormattedMessage id="global.try_for_free" />} />
            <Button onClick={() => handlePacketClick(PACKET_TYPE.PRO)} label={<FormattedMessage id="global.try_for_free" />} />
          </div>
          <div className="packagesPricingMobile">
            <div className="packagesTop__wrap ">
              <div className={`packagesTop__content packagesTop__content--basic`}>
                <p className="packagesTop__name packagesTop__name--black">BASIC</p>
                <p className="packagesTop__price">
                  {stripePricing().basic} {stripePricing().currencies}
                  <span>
                    <FormattedMessage id="global.vat" />/
                    <FormattedMessage id="global.monthly_short" />
                  </span>
                </p>
                <div className="packagesTop__button">
                  <Button onClick={() => handlePacketClick(PACKET_TYPE.BASIC)} label={<FormattedMessage id="global.try_for_free" />} />
                </div>
                <SVG className="packagesTop--image" type={SVG_TYPE.PACKETS_BASIC} />
              </div>
            </div>
            <div className="packagesTop__wrap">
              <div className={`packagesTop__content packagesTop__content--premium`}>
                <p className="packagesTop__name packagesTop__name--white">PREMIUM</p>
                <p className="packagesTop__price">
                  {stripePricing().premium} {stripePricing().currencies}
                  <span>
                    <FormattedMessage id="global.vat" />/
                    <FormattedMessage id="global.monthly_short" />
                  </span>
                </p>
                <div className="packagesTop__button">
                  <Button onClick={() => handlePacketClick(PACKET_TYPE.PREMIUM)} label={<FormattedMessage id="global.try_for_free" />} />
                </div>
                <SVG className="packagesTop--image" type={SVG_TYPE.PACKETS_PREMIUM} />
              </div>
            </div>
            <div className="packagesTop__wrap">
              <div className={`packagesTop__content packagesTop__content--pro`}>
                <p className="packagesTop__name packagesTop__name--white">PRO</p>
                <p className="packagesTop__price">
                  {stripePricing().pro} {stripePricing().currencies}
                  <span>
                    <FormattedMessage id="global.vat" />/
                    <FormattedMessage id="global.monthly_short" />
                  </span>
                </p>
                <div className="packagesTop__button">
                  <Button onClick={() => handlePacketClick(PACKET_TYPE.PRO)} label={<FormattedMessage id="global.try_for_free" />} />
                </div>
                <SVG className="packagesTop--image" type={SVG_TYPE.PACKETS_PRO} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Packages;
