import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import { Selectors as AuthSelectors } from 'Modules/AuthModule';
import { Toast } from 'Shared/components';

const init = () => ({
  type: User.EDIT_USER_INIT,
});

const success = (data) => ({
  type: User.EDIT_USER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.EDIT_USER_FAILURE,
});

const editUser = (data) => async (dispatch, getState) => {
  dispatch(init());
  Toast({ id: 'editUserSettings', type: 'loading', message: 'global.apex_no_data' });
  try {
    const modifiedObject = {
      email: data.email,
      lang: data.lang,
      company: {
        state: data.state,
        name: data.name,
        vat_id: data.vat_id,
        vat_type: data.vat_type,
        vat_country: data.vat_country,
        address: data.address,
        zip_code: data.zip_code,
        city: data.city,
        // country: 'co jest?',
        country: data.country,
        phone_number: data.phone_number,
        phone_prefix: data.phone_prefix,
        auto_invoice_dispatch: data.auto_invoice_dispatch,
        invoice_recipient_email: data.custom_email,
      },
    };
    const { uuid } = await AuthSelectors.authorizedUser(getState());
    const { data: responseData } = await Connector.editUser(uuid, modifiedObject);
    dispatch(success(responseData));
    Toast({ id: 'editUserSettings', type: 'success', message: 'global.toast.edit_user_success' });
    return responseData;
  } catch (err: any) {
    console.log(err.response);
    dispatch(failure());
    Toast({ id: 'editUserSettings', type: 'error', message: 'global.toast.edit_user_failure' });
    return false;
  }
};

export default editUser;
