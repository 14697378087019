import { Connector } from 'Modules/WebsiteModule';
import { Website } from 'Store/Actions/EnumTypes/ActionEnumTypes';

const success = (data) => ({
  type: Website.GET_WEBSITES_ALL_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_WEBSITES_ALL_FAILURE,
});

const getWebsitesAll =
  ({ filter }) =>
  async (dispatch) => {
    try {
      // dispatch(Actions.showLoader());
      const { data } = await Connector.getWebsitesAll({ filter });
      await dispatch(success(data));
      //hide toast
      return data;
    } catch (err) {
      dispatch(failure());
    }
  };

export default getWebsitesAll;
