import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';
import { bindActionCreators } from 'redux';
import Website from './Main';
import { RootState } from 'Store/Reducers/RootReducer';
import { Actions, Selectors } from 'Modules/UserModule';
import { Selectors as WebsitesSelectors } from 'Modules/WebsiteModule';
import { Actions as AddWebsiteActions, Selectors as AddWebsiteSelectors } from 'Modules/AddWebsiteModule';
import * as AuthModule from 'Modules/AuthModule';
import { WebsiteType } from 'Shared/models/AddWebsiteModel';
import { getMyWebsites } from 'Store/Actions/User';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';

type ConnectedP = {
  loggedUser: any;
  // loaderVisible: any;
  history?: any;
  websitesPartners: any;
  clientsLists: any;
  balance: any;
  userWebsites: any;
  withoutWebsiteForm?: any;
  maxStep: number;
  authorizedUser: any;
  myWebsites: any;
  translation: string;
};

type DispatchedP = {
  actions: {
    checkIsWordpress: (url) => any;
    addWebsiteFormChange: (data) => any;
    addWebsite: (data: WebsiteType) => any;
    getMyWebsites: (onlyRefresh) => any;
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      addWebsite: (data: WebsiteType) => AddWebsiteActions.addWebsite(data),
      checkIsWordpress: (url) => Actions.checkIsWordpress(url),
      addWebsiteFormChange: (data) => AddWebsiteActions.addWebsiteFormChange(data),
      getMyWebsites: (onlyRefresh) => getMyWebsites(onlyRefresh),
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  loggedUser: Selectors.getLoggedUser(state),
  clientsLists: Selectors.getClientsLists(state),
  websitesPartners: WebsitesSelectors.getWebsitesPartners(state),
  // loaderVisible: ViewManagementSelectors.loaderVisible(state),
  balance: Selectors.getPartnerBalance(state),
  userWebsites: Selectors.getUserWebsites(state),
  maxStep: AddWebsiteSelectors.getMaxStep(state),
  authorizedUser: AuthModule.Selectors.authorizedUser(state),
  myWebsites: Selectors.getMyWebsites(state),
  translation: ViewManagementSelectors.getTranslation(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Website));
