import { Connector } from '../../../Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import zxcvbn from 'zxcvbn';
import { Toast } from 'Shared/components';

const init = () => ({
  type: User.ADD_CONNECTIONS_INIT,
});

const success = (data) => ({
  type: User.ADD_CONNECTIONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.ADD_CONNECTIONS_FAILURE,
});

const addConnection = (dataForm) => async (dispatch) => {
  const getPasswordStrength = (password) => {
    const passwordStrength = zxcvbn(password).score;
    if (passwordStrength <= 1) {
      return 'weak';
    }
    if (passwordStrength > 1 && passwordStrength <= 3) {
      return 'medium';
    }
    if (passwordStrength > 3) {
      return 'strong';
    }
  };

  dispatch(init());
  Toast({ message: 'global.toast.add_ftp_loading', type: 'loading', id: 'updateConnection' });
  try {
    // dispatch(ViewManagementModule.Actions.showLoader());
    let modifiedObject = {};
    modifiedObject = {
      website_uuid: dataForm.website_uuid,
      protocol: dataForm.protocol,
      host: dataForm.host,
      port: dataForm.port,
      // remote_path: dataForm.remote_path,
      username: dataForm.username,
      password: dataForm.password,
      password_strength: getPasswordStrength(dataForm.password),
    };
    sessionStorage.setItem('ftp_connection_uuid', dataForm.website_uuid);
    sessionStorage.setItem('ftp_expire_date', new Date(new Date().getTime() + 2 * 60000).toISOString());
    const { data } = await Connector.addConnection(modifiedObject);
    dispatch(success(data));
    sessionStorage.removeItem('ftp_connection_uuid');
    sessionStorage.removeItem('ftp_expire_date');

    Toast({ message: 'global.toast.update_ftp_success', type: 'success', id: 'updateConnection' });
    return true;
  } catch (err: any) {
    sessionStorage.removeItem('ftp_expire_date');
    sessionStorage.removeItem('ftp_connection_uuid');

    dispatch(ViewManagementModule.Actions.hideLoader());

    Toast({ message: 'global.toast.update_ftp_failure', type: 'error', id: 'updateConnection' });
    dispatch(failure());
    return false;
  }
};

export default addConnection;
