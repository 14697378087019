import { AddWebsite } from '../EnumTypes/ActionEnumTypes';

const success = (data) => ({
  type: AddWebsite.CHANGE_CURRENT_STEP,
  payload: data,
});

const changeCurrentStep = (number: number) => async (dispatch) => {
  try {
    dispatch(success(number));
    return true;
  } catch (err) {
    return false;
  }
};
export default changeCurrentStep;
