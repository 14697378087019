import React from 'react';
import { CheckSite } from 'Shared/components';

const WebsiteChecker = () => {
  return (
    <div className="checkYourWebsite">
      <CheckSite url />
    </div>
  );
};

export default WebsiteChecker;
