import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'Modules/UserModule';
import Contact from './Main';
import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';
import { isMobile } from 'Shared/utils';

type ConnectedP = {
  history?: any;
  isMobile: boolean;
};

type DispatchedP = {
  actions: {
    sendContactForm: (dataForm) => void;
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      sendContactForm: Actions.sendContactForm,
    },
    dispatch,
  ),
});

const mapStateToProps = (): ConnectedP => ({
  isMobile: isMobile(),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Contact));
