import React, { useRef, useState } from 'react';

interface SlideOnScrollProps {
  children: React.ReactNode;
  direction: 'left' | 'right' | 'top' | 'bottom';
  className?: string;
  delay?: number;
  id?: string;
}

const SlideOnScroll = ({ children, className = '', delay = 300, direction, id }: SlideOnScrollProps) => {
  const [isVisible, setVisible] = useState(false);

  const domRef = useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (delay) {
            setTimeout(() => {
              setVisible(entry.isIntersecting);
            }, delay);
          } else {
            setVisible(entry.isIntersecting);
          }
        }
      });
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <div className={`slide-in ${className} ${isVisible ? `slide-in--${direction}` : 'hidden'} `} id={id ? id : ''} ref={domRef}>
      {children}
    </div>
  );
};

export default SlideOnScroll;
