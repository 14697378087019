import React from 'react';
import SVG from 'Shared/components/SVG';
import { SVG_TYPE } from 'Shared/enums';
import { FormattedMessage } from 'react-intl';
import Premium from '../../Assets/Image/premium.png';
import CorgiBottom from '../../Assets/Image/corgiline.svg';
import Button from 'Shared/components/button/Button';
import { Modal } from 'Shared/components/';
import ContactForm from '../../Components/ContactForm/Container';
import { MainType } from './Container';

const Data = [
  {
    question: <FormattedMessage id="global.faq.question_1" />,
    answer: <FormattedMessage id="global.faq.answer_1" />,
  },
  {
    question: <FormattedMessage id="global.faq.question_2" />,
    answer: <FormattedMessage id="global.faq.answer_2" />,
  },

  {
    question: <FormattedMessage id="global.faq.question_3" />,
    answer: <FormattedMessage id="global.faq.answer_3" />,
  },
  {
    question: <FormattedMessage id="global.faq.question_4" />,
    answer: <FormattedMessage id="global.faq.answer_4" />,
  },
  {
    question: <FormattedMessage id="global.faq.question_5" />,
    answer: <FormattedMessage id="global.faq.answer_5" />,
  },
];

class Faq extends React.Component<MainType, any> {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
      clicked: '',
    };
  }

  openModal = () => {
    this.setState({ isOpened: true });
    this.props.actions.openModal();
  };
  onClose = () => {
    this.setState({ isOpened: false });
    this.props.actions.closeModal();
  };

  render() {
    const { clicked, isOpened } = this.state;
    const toggle = (index) => {
      if (clicked === index) {
        return this.setState({ clicked: null });
      }
      this.setState({ clicked: index });
      // setClicked(index);
    };
    return (
      <>
        <Modal opened={isOpened} onClose={this.onClose}>
          <ContactForm />
        </Modal>
        <div className="faq">
          <div className="faq__container">
            <div className="faq--header">
              <h1>FAQ</h1>
              <SVG className="svg svg-" type={SVG_TYPE.WOOF_PREMIUM} />
            </div>
            {Data.map((item, index) => {
              return (
                <>
                  <div className="faq-wraps">
                    <div className="faq--head-wrapper" onClick={() => toggle(index)} key={index}>
                      <div className="faq--head">
                        {item.question}
                        {clicked === index ? (
                          <SVG className="svg svg--chevron-down up" type={SVG_TYPE.CHEVRON_DOWN} />
                        ) : (
                          <SVG className="svg svg--chevron-down" type={SVG_TYPE.CHEVRON_DOWN} />
                        )}
                      </div>
                    </div>
                    <div></div>
                    <div className={`faq--descript faq--descript${clicked === index ? '--active' : ''}`}>
                      <p>{item.answer}</p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="faq__contact">
            <div className="others__contact section__wrapper">
              <div className="others__contact--content">
                <h4 className="others__contact--header">
                  <FormattedMessage id="global.about_us.contact" />
                </h4>
                <p className="others__contact--descript">
                  <FormattedMessage id="global.about_us.contact_descript" />
                </p>
                <div className="others__contact--descript-btn">
                  <img src={Premium} alt="ikona premium" />
                  <Button onClick={this.openModal} label={<FormattedMessage id="global.write_us" />} classNames="others__contact--button" />
                </div>
              </div>
            </div>
            <div className="others--corgiLine">
              <img className="others__contact--corgiLine" src={CorgiBottom} alt="grafika corgi linie" />{' '}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Faq;
