import { addFirstPageImg, createAccountImg, dontHaveRememberImg, joinPartnerProgramImg, passiveMoneyImg } from 'Assets/Image/how-it-works';
import FadeInOnScroll from 'Components/FadeInOnScroll/FadeInOnScroll';
import React, { useState } from 'react';
import { SVG } from 'Shared/components';
import { ROUTES, SVG_TYPE } from 'Shared/enums';

interface HowItWorksProps {
  formatMessage: any;
  history: any;
}

const HowItWorks: React.FC<HowItWorksProps> = ({ formatMessage }) => {
  const [active, setActive] = useState(1);

  const options = [
    {
      title: formatMessage({ id: 'global.how_it_works_options.create_account.title' }),
      description: (
        <p>
          {formatMessage({ id: 'global.how_it_works_options.create_account.description1' })}{' '}
          <a href={ROUTES.REGISTER_PAGE} target="_blank" rel="noopener noreferrer" className="homepage__how-it-works-click">
            {formatMessage({ id: 'global.here' })}
          </a>{' '}
          {formatMessage({ id: 'global.how_it_works_options.create_account.description2' })}
        </p>
      ),
      assign: 'all',
      img: createAccountImg,
    },
    {
      title: formatMessage({ id: 'global.how_it_works_options.join_partner_program.title' }),
      description: <p>{formatMessage({ id: 'global.how_it_works_options.join_partner_program.description' })}</p>,
      assign: 'partner',
      img: joinPartnerProgramImg,
    },
    {
      title: formatMessage({ id: 'global.how_it_works_options.add_first_website.title' }),
      description: <p>{formatMessage({ id: 'global.how_it_works_options.add_first_website.description' })}</p>,
      assign: 'all',
      img: addFirstPageImg,
    },
    {
      title: formatMessage({ id: 'global.how_it_works_options.create_invoice.title' }),
      description: <p>{formatMessage({ id: 'global.how_it_works_options.create_invoice.description' })}</p>,
      assign: 'partner',
      img: dontHaveRememberImg,
    },
    {
      title: formatMessage({ id: 'global.how_it_works_options.passive_money.title' }),
      description: <p>{formatMessage({ id: 'global.how_it_works_options.passive_money.description' })}</p>,
      assign: 'partner',
      img: passiveMoneyImg,
    },
  ];

  return (
    <section className="section__wrapper homepage__how-it-works">
      <div className="homepage__gray"> </div>

      <FadeInOnScroll className="homepage__section-content section section--column ">
        <h2 className="homepage__subtitle homepage__subtitle--black homepage__subtitle--how-it-works homepage__subtitle--big">
          {formatMessage({ id: 'global.how_to_earn_on_corgi' })}
        </h2>

        <FadeInOnScroll delay={600} className="homepage__how-it-works-container">
          <ul className="homepage__how-it-works-list">
            {options.map((option, index) => {
              const key = index + 1;
              return (
                <>
                  <li
                    key={key}
                    onClick={() => setActive(key)}
                    className={`homepage__how-it-works-list-item ${active === key ? 'homepage__how-it-works-list-item--active' : ''}`}>
                    <span className="homepage__how-it-works-indicator">{key}</span>
                    <div className="homepage__how-it-works-text">
                      <h2>{option.title}</h2>
                      {option.description}
                    </div>
                    <span className="homepage__how-it-works-chevron">
                      <SVG type={SVG_TYPE.DROP_DOWN} />
                    </span>
                  </li>
                  <img
                    className={`homepage__how-it-works-list-item-image  ${active === key ? 'homepage__how-it-works-list-item-image--active' : ''}`}
                    src={option.img}
                    alt={option.title}
                  />
                </>
              );
            })}
          </ul>

          <div className="homepage__how-it-works-image">
            <img src={options[active - 1].img} alt="how it works" />
          </div>
        </FadeInOnScroll>
      </FadeInOnScroll>
    </section>
  );
};

export default HowItWorks;
