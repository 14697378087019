import React, { useRef } from 'react';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

const Pagination = ({ data, onPageChange }) => {
  const { page, lastPage } = data || {};
  const isFirstPage = page === 1;
  const isLastPage = page === lastPage;
  const paginationRef = useRef(null);

  const handleClick = (newPage) => {
    onPageChange(newPage);
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    paginationRef.current.classList.add('no-select');
  };

  const handleMouseUp = () => {
    paginationRef.current.classList.remove('no-select');
  };

  const generatePageNumbers = ({ page, lastPage }) => {
    let pages = [];

    if (lastPage <= 5) {
      for (let i = 1; i <= lastPage; i++) {
        pages.push(i);
      }
    } else if (page < 4) {
      pages = [1, 2, 3, '...', lastPage];
    } else if (page >= lastPage - 2) {
      pages = [1, '...'];
      for (let i = lastPage - 2; i <= lastPage; i++) {
        pages.push(i);
      }
    } else {
      pages = [1, '...', page, '...', lastPage];
    }

    return pages;
  };

  return (
    <div className="pagination" ref={paginationRef}>
      <div
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onClick={() => handleClick(page - 1)}
        className={`item ${isFirstPage ? 'item--disabled' : ''}`}>
        <SVG type={SVG_TYPE.CHEVRON_LEFT} />
      </div>
      {generatePageNumbers({ page, lastPage }).map((item) => (
        <div
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onClick={() => handleClick(item)}
          className={`item ${item === page ? 'item--active' : ''} ${item === '...' ? 'item--disabled' : ''}`}
          key={`item`}>
          {item}
        </div>
      ))}
      <div
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onClick={() => handleClick(page + 1)}
        className={`item ${isLastPage ? 'item--disabled' : ''}`}>
        <SVG type={SVG_TYPE.CHEVRON_RIGHT} />
      </div>
    </div>
  );
};

export default Pagination;
