import React from 'react';
import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';

const init = () => ({
  type: User.DELETE_PAYMENT_METHOD_INIT,
});

const success = (id) => ({
  type: User.DELETE_PAYMENT_METHOD_SUCCESS,
  payload: {
    id,
  },
});

const failure = () => ({
  type: User.DELETE_PAYMENT_METHOD_FAILURE,
});

const deleteIndustryOrPosition = (id) => async (dispatch) => {
  dispatch(init());
  const newToast = toast.loading(<FormattedMessage id="global.apex_no_data" />, { toastId: 'updateConnection' });
  try {
    await Connector.deletePaymentMethod(id);
    dispatch(success(id));
    toast.update(newToast, {
      render: (
        <div>
          <h2 className="toast-title toast-title--success">
            <FormattedMessage id="global.toast_succes" />
          </h2>
          <p>
            <FormattedMessage id="global.toast.remove_payment_success" />
          </p>
        </div>
      ),
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      // icon: <SVG type={SVG_TYPE.PASSED} />,
    });
    return true;
  } catch (err: any) {
    console.log(err?.response);
    dispatch(failure());
    toast.update(newToast, {
      render: (
        <div>
          <h2 className="toast-title toast-title--failure">
            <FormattedMessage id="global.toast_failure" />
          </h2>
          <p>
            <FormattedMessage id="global.toast.remove_payment_failure" />
          </p>
        </div>
      ),
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG type={SVG_TYPE.CLOSE_CIRCLE_FILL} variant={SVG_TYPE.CLOSE_CIRCLE_FILL} />,
    });

    return false;
  }
};

export default deleteIndustryOrPosition;
