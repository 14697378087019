import { AddWebsite } from '../EnumTypes/ActionEnumTypes';
import { Connector } from '../../../Modules/AddWebsiteModule';
import { FillCompanyDataType } from 'Shared/models/AddWebsiteModel';

const success = (data) => ({
  type: AddWebsite.FILL_COMPANY_DATA_SUCCESS,
  payload: {
    data,
  },
});
const failure = (data) => ({
  type: AddWebsite.FILL_COMPANY_DATA_FAILURE,
  payload: {
    data,
  },
});

const fillCompanyData = (data: FillCompanyDataType, id: string) => async (dispatch) => {
  try {
    await Connector.fillCompanyData(data, id);
    dispatch(success(data));

    return true;
  } catch (err) {
    dispatch(failure(data));
    console.log(err);
    return false;
  }
};

export default fillCompanyData;
