import { ROUTES } from 'Shared/enums';

const matchUrlToRoute = (url) => {
  if (url.includes('users')) {
    return ROUTES.DASHBOARD;
  }
  if (url.includes('workers')) {
    return ROUTES.WORKERS;
  }
};

export default matchUrlToRoute;
