import { MainType } from 'Components/ContactForm/Container';
import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';
import { phpVersion } from 'Shared/utils';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type P = {
  reportData: any;
};

class Report extends React.Component<MainType & P, any> {
  constructor(props) {
    super(props);
  }
  render() {
    const NOTIFICATION_SVG = {
      sms: 'MATERIAL_SMS',
      email: 'FEATHER_MAIL',
    };
    const NOTIFICATION_TYPE = {
      sms: 'SMS',
      email: 'Mail',
    };
    const { reportData } = this.props;

    return (
      <div className="report">
        <div className="page">
          <h1 className="report__header">Raport bezpieczeństwa strony</h1>
          <div className="report__content">
            {reportData?.plan === 'basic' ? (
              <div className="report__content--group">
                <div className="tile">
                  <div className="tile__up">
                    <SVG type={SVG_TYPE.OKAY} />
                  </div>
                  <div className="tile__down">{reportData?.name}</div>
                </div>
                <div className="tile">
                  <div className="tile__up">
                    <SVG type={SVG_TYPE.CORGI_BABY} />
                  </div>
                  <div className="tile__down lineBreak">
                    <p className="tile__down--paragraph">Twój pakiet</p>
                    <p>Corgi Basic</p>
                  </div>
                </div>
                <div className="tile">
                  <div className="tile__up">
                    <p className="tile__up--paragraph">Data obowiązywania</p>
                  </div>
                  <div className="tile__down lineBreak">
                    <p className="tile__down--date">
                      <span className="resetFontColor">od</span>
                      {reportData?.dateFrom}
                    </p>
                    <p className="tile__down--date">
                      <span className="resetFontColor">do</span>
                      {reportData?.dateTo}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="report__content--group">
                <div className="tile">
                  <div className="tile__up">
                    <SVG type={SVG_TYPE.CORGI_PREMIUM_REPORT} />
                  </div>
                  <div className="tile__down lineBreak">
                    <p className="tile__down--paragraph">Twój pakiet</p>
                    <p>Corgi Premium</p>
                    <p className="tile__down--websiteName">{reportData?.name}</p>
                  </div>
                </div>
                <div className="tile">
                  <div className="tile__up">
                    <p className="tile__up--paragraph">Data obowiązywania</p>
                  </div>
                  <div className="tile__down lineBreak">
                    <p className="tile__down--date">
                      <span className="resetFontColor">od </span>
                      {reportData?.dateFrom}
                    </p>
                    <p className="tile__down--date">
                      <span className="resetFontColor">do </span>
                      {reportData?.dateTo}
                    </p>
                  </div>
                </div>
                <div className="tile">
                  <div className="tile__up-custom">
                    <p className="tile__up--paragraph">Kliknij, aby przejść do</p>
                    <p className="tile__down--date">
                      pełnego <br /> widoku strony
                    </p>
                  </div>
                  <div className="tile__down">
                    <a href={reportData?.url} className="btn-forward">
                      <div className="text">widok strony</div>
                      <SVG type={SVG_TYPE.ARROW_RIGHT_LIGHT} />
                    </a>
                  </div>
                </div>
              </div>
            )}
            <h1 className="report__subheader">Miesięczny wykres uptime</h1>
            <div className="report__content--chart">tu będzie wykres</div>
            <div className="report__content--group">
              <div className="tile">
                <div className="tile__up">
                  <p className="tile__up--uptime">{Math.abs(parseFloat(reportData?.percentages)).toFixed(0)}%</p>
                </div>
                <div className="tile__down lineBreak">
                  <p>Uptime</p>
                  <p>Ostatnich 31 dni</p>
                </div>
              </div>
              <div className="wideTile">
                <h1 className="wideTile__header">Ostatnia kopia zapasowa</h1>
                <table className="table">
                  <tr>
                    <th>Backup</th> <th>Data utworzenia</th> <th>Rozmiar</th> <th>Po kompresji</th>
                  </tr>
                  <tr>
                    <td className="customFont">Pliki</td>
                    <td>{moment(reportData?.backups[1]?.created_at).format('YYYY-MM-DD')}</td>
                    <td>{(parseInt(reportData?.backups[1]?.size) / 1024 / 1024 / 1024).toFixed(0)}</td>
                    <td>{(parseInt(reportData?.backups[1]?.archive_size) / 1024 / 1024 / 1024).toFixed(0)}</td>
                  </tr>
                  <tr>
                    <td className="customFont">Baza danych</td>
                    <td>{moment(reportData?.backups[0]?.created_at).format('YYYY-MM-DD')}</td>
                    <td>{(parseInt(reportData?.backups[0]?.size) / 1024 / 1024).toFixed(0)}</td>
                    <td>{(parseInt(reportData?.backups[0]?.archive_size) / 1024 / 1024).toFixed(0)}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="report__content--group">
              <div className="tile">
                <div className="tile__up">
                  <SVG type={SVG_TYPE.PHP_VER} />
                </div>
                <div className="tile__down lineBreak">
                  <p className="tile__down">Wersja PHP</p>
                  <p className="customFont">{phpVersion(reportData?.php_version)}</p>
                </div>
              </div>
              <div className="product__tile sslTile">
                <div className="product__tile-up">
                  {reportData?.ssl_fail === 1 ? (
                    <SVG type={SVG_TYPE.FEATHER_LOCK} className={` svg--featherLock--true `} />
                  ) : (
                    <SVG type={SVG_TYPE.FEATHER_LOCK} className={` svg--featherLock--${reportData?.ssl_status === 'no_ssl' ? 'orange' : 'false'} `} />
                  )}
                </div>
                <div className="product__tile-down">
                  <p className="product__tile--bold">
                    <FormattedMessage id="global.home_page.packet_pro_3" />
                  </p>
                  {reportData?.ssl_fail === 1 ? (
                    <>
                      <p className={`product__tile--red`}>reportData ważności</p>
                      <p className="product__tile--red product__tile--validTo product__tile--validTo--red"></p>
                    </>
                  ) : (
                    <>
                      <p className={`product__tile--${reportData?.ssl_status === 'no_ssl' ? 'orange' : 'green'}`}>
                        {reportData?.ssl_status === 'no_ssl' ? <FormattedMessage id="global.inactivee" /> : 'reportData ważności'}
                      </p>
                      <p className="product__tile--green product__tile--validTo product__tile--validTo--green"></p>
                    </>
                  )}
                </div>
              </div>
              {reportData?.plan === 'basic' ? (
                <div className="tile">
                  <div className="tile__up-custom">
                    <p className="tile__up--paragraph">Kliknij, aby przejść do</p>
                    <p className="tile__down--date">
                      pełnego <br /> widoku strony
                    </p>
                  </div>
                  <div className="tile__down">
                    <a href="https://google.com" className="btn-forward">
                      <div className="text">widok strony</div>
                      <SVG type={SVG_TYPE.ARROW_RIGHT_LIGHT} />
                    </a>
                  </div>
                </div>
              ) : (
                <div className="tile">
                  <h1 className="wideTile__header">Aktualizacje</h1>
                  <div className="tile__up-check">
                    <div className="tile__up-check--item">
                      <SVG type={SVG_TYPE.OKAY} />
                      <p className="coreItem">
                        core <br /> wordpress
                      </p>
                    </div>
                    <div className="tile__up-check--item">
                      <SVG type={SVG_TYPE.OKAY} />
                      <p className="pluginItem">pluginy</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="page">
            <div className="report__content--list">
              <div className="updatesHeader">Lista aktualizacji</div>
            </div>
            {reportData?.updates?.length > 0 ? (
              <table className="list">
                <tr className="head">
                  <th>Rodzaj</th>
                  <th>reportData aktualizacji</th>
                  <th>Wersja</th>
                  <th>Status</th>
                  <th>Zakres aktualizacji</th>
                </tr>
                {reportData?.updates?.length > 0 &&
                  reportData?.updates?.map((update, index) => (
                    <tr className="row" key={index}>
                      <td>{update.type}</td>
                      <td>{update.created_at}</td>
                      <td>
                        {update.current_version} - {update.version}
                      </td>
                      <td>
                        <div className="success">{update.status}</div>
                      </td>
                      <td>{update.name}</td>
                    </tr>
                  ))}
              </table>
            ) : (
              <div className="emptyList">Brak utworzonych aktualizacji</div>
            )}
            <div className="separator" />
            <div className="report__content--list">
              <div className="notificationsHeader">Wysłane komunikaty</div>
            </div>
            {reportData?.notification?.length > 0 ? (
              <table className="list">
                <tr className="head">
                  <th>Rodzaj</th>
                  <th>Wysłano na</th>
                  <th>Treść wiadomości</th>
                  <th>reportData utworzenia</th>
                </tr>
                {reportData?.notification?.length > 0 &&
                  reportData?.notification?.map((notification, index) => (
                    <tr className="row" key={index}>
                      <td className="notificationType">
                        <SVG type={NOTIFICATION_SVG[notification.method]} />
                        <p>{NOTIFICATION_TYPE[notification.method]}</p>
                      </td>
                      <td>{notification.method === 'email' ? notification.email : notification.phone_number}</td>
                      <td>{notification.message}</td>
                      <td>{moment(notification.sent_at).format('hh:mm, DD.MM.YYYY')}</td>
                    </tr>
                  ))}
              </table>
            ) : (
              <div className="emptyList">Brak wysłanych komunikatów</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
// }

export default Report;
