import { Website } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import { Toast } from 'Shared/components';

const init = () => ({
  type: Website.UPDATE_WEBSITE_CONTACT_NUMBER_INIT,
});

const update = (data) => ({
  type: Website.UPDATE_WEBSITE_CONTACT_NUMBER_SUCCESS,
  payload: {
    data
  },
});

const failure = () => ({
  type: Website.UPDATE_WEBSITE_CONTACT_NUMBER_FAILURE,
});

const updateWebsitePhoneNumber = (data, website_uuid) => async (dispatch) => {
  dispatch(init());
  Toast({ id: 'editUserSettings', type: 'loading', message: 'global.apex_no_data' });
  try {
    await Connector.updateWebsite(data, website_uuid);
    dispatch(update(data));
    Toast({ id: 'editUserSettings', type: 'success', message: 'global.toast.edit_user_success' });
    return true;
  } catch (err: any) {
    console.log(err.response);
    dispatch(failure());
    Toast({ id: 'editUserSettings', type: 'error', message: 'global.toast.edit_user_failure' });
    return false;
  }
};

export default updateWebsitePhoneNumber;
