import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';

// const init = () => ({
//   type: User.DELETE_USER_INIT,
// });

const success = (uuid) => ({
  type: User.DELETE_USER_SUCCESS,
  payload: {
    uuid,
  },
});

const failure = () => ({
  type: User.DELETE_USER_FAILURE,
});

const deleteUser = (uuid) => async (dispatch) => {
  try {
    await Connector.deleteUser(uuid);
    dispatch(success(uuid));
    console.log('jestem sobie');
    return true;
  } catch (err) {
    dispatch(failure());
    return false;
  }
};

export default deleteUser;
