import { Connector, Actions as UserActions } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { Toast } from 'Shared/components';

const success = (paymentData) => ({
  type: User.UPDATE_DEFAULT_PAYMENT_METHOD_SUCCESS,
  payload: {
    paymentData,
  },
});

const failure = () => ({
  type: User.UPDATE_DEFAULT_PAYMENT_METHOD_FAILURE,
});

const updateDefalutPaymentMethod = (paymentData) => async (dispatch) => {
  //create random id for toast
  const randomId = Math.random().toString(36).substring(7);
  try {
    Toast({ message: 'global.apex_no_data', type: 'loading', id: randomId });
    const { data } = await Connector.updateDefalutPaymentMethod(paymentData);
    dispatch(success(data));
    await dispatch(UserActions.getPaymentMethods());
    Toast({ message: 'global.toast.update_payment_metod_success', type: 'success', id: randomId });
    return data;
  } catch (err) {
    dispatch(failure());
    Toast({ message: 'global.toast.update_payment_metod_failure', type: 'error', id: randomId });
    return false;
  }
};

export default updateDefalutPaymentMethod;
