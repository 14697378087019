import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MainType } from './Container';
import moment from 'moment';
import Krzysieniek from 'Assets/Image/person/krzysiek.gif';
import CorgiFlies from 'Assets/Image/corgi_flies.svg';
import Button from 'Shared/components/button/Button';
import Background from '../../Assets/Image/background_hp.svg';
import CorgiPawsLeft from '../../Assets/Image/corgi_paws-left.svg';
import CorgiPawsRight from '../../Assets/Image/corgi_paws-right.svg';
import CorgiBaby from '../../Assets/Image/corgi_baby.svg';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { SVG, Modal } from 'Shared/components';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const CorgiWebinar = ({ isMobile, history, webinar, actions: {  closeModal, getWebinarDetails } }: MainType) => {
  const myRef = useRef(null);
  const woof = useRef(null);
  const { pathname } = useLocation();
  const executeScrollWoof = () => scrollToRef(woof);
  const [modalOpened, setModalOpened] = useState(null);


  const {
    location: { origin, href },
  } = window;

  useEffect(() => {
    href === `${origin}/#pakiety` && scrollToRef(myRef);
  }, []);

  useEffect(() => {
    if (!modalOpened) {
      return;
    }
    const script = document.createElement('script');

    script.src = 'https://app.getresponse.com/view_webform_v2.js?u=QTJZm&webforms_id=hKDTm';
    script.async = true;

    let parent;
    setTimeout(() => {
      parent = document.getElementsByClassName('signUpToWebinar');
      parent[0].appendChild(script);
    }, 100);
  }, [modalOpened]);

  useEffect(() => {
    getWebinarDetails(pathname.slice(-1));
  }, []);



  // const open = () => {
  //   setModalOpened(true);
  //   openModal();
  // }

  const redirect = () => {
    history.push(ROUTES.HOME_PAGE);
  };

  const onClose = () => {
    setModalOpened(null);
    closeModal();
  };


  return (
    <>
      <Modal opened={modalOpened} onClose={onClose} className="popUp signUpToWebinar">
        <script
          type="text/javascript"
          src="https://app.getresponse.com/view_webform_v2.js?u=QTJZm&webforms_id=hKDTm"
          data-webform-id="hKDTm"></script>
      </Modal>
      <div className="home-page webinar">
        <div className="navi__header--webinarLogo">
          <SVG className="svg--logo" type={SVG_TYPE.LOGO} />
        </div>
        <div className="section__wrapper webinarPageHeader">
          <div className="section--background">
            <img src={Background} alt="background image" />
          </div>
          <div className="section banner">
            <div className="section__l">
              <SVG className="svg--main-logo" type={SVG_TYPE.MAIN_LOGO} />
            </div>
            <div className="section__r--webinar">
              <h1 className="section--header firstHeading">
                Zagrożenia witryny opartej na WordPress <br /> i metody jej ochrony
              </h1>
              <p className="section--under-header-webinar">Bezpłatne szkolenie online</p>
              {webinar && (
                <>
                  {webinar?.webinar_date !== '' ? (
                    <h1 className="section--header-webinar secondHeading">
                      {moment(webinar.webinar_date).format('DD.MM.YYYY')} r.{' '}
                      <span className="secondHeading_time">godz. {webinar.webinar_time.slice(0, 5)}</span>
                      <br />
                    </h1>
                  ) : (
                    redirect()
                  )}
                </>
              )}
              <Button classNames="btn--blue" label="Chcę wziąć udział" isLink link={webinar?.signup_url} />
            </div>
          </div>
        </div>
        <div className="section__wrapper section__wrapper-margin">
          r<div className="section__wrapper--gray"> </div>
          <div className="section__wrapper--webinarBlue"> </div>
          <div className="section">
            {!isMobile && (
              <div className="scrollSection">
                <p onClick={executeScrollWoof} className="scrollSection__webinar">
                  <SVG className="svg--chevron-down" type={SVG_TYPE.CHEVRON_DOWN} />
                </p>
              </div>
            )}
            <div className="section__c securityInfo">
              <SVG type={SVG_TYPE.CORGI_WITHOUT_WOOF} className="img corgiWoof" />
              <h2 className="section--header-white mainHeading mainHeading--white mainHeadingWebinar">
                Chroń się przed atakiem na <br /> stronę internetową. <br /> Twoją stronę.
              </h2>
              <div className="section__i securityStats">
                <div className="row">
                  <div className="tilesGroup">
                    <div className="subRow">
                      <div className="tile">9</div>
                      <div className="tile">0</div>
                    </div>
                    <div className="subRow">
                      <div className="tile">0</div>
                      <div className="tile">0</div>
                      <div className="tile">0</div>
                    </div>
                  </div>
                  <div className="text">Tyle witryn internetowych jest atakowanych w ciągu 1 minuty.</div>
                </div>
                <div className="row">
                  <div className="tilesGroup">
                    <div className="subRow single">
                      <div className="tile">3</div>
                      <div className="tile">6</div>
                      <div className="tile">5</div>
                    </div>
                  </div>
                  <div className="text">Tyle dni w roku jesteśmy narażeni na hakerów, infekcje oraz ataki na luki bezpieczeństwa.</div>
                </div>
                <div className="row">
                  <div className="tilesGroup">
                    <div className="subRow single">
                      <div className="tile">1</div>
                    </div>
                  </div>
                  <div className="text">Webinar. Tyle wystarczy, by poznać proste i co więcej - darmowe metody ochrony Twojej domeny.</div>
                </div>
                <div className="row">
                  <div className="tilesGroup" />
                  {isMobile && (
                    <div className="plainText">
                      <p className="webinarInfo">
                        Skąd się biorą podejrzanie <span className="bold"> błędy autoryzacji i ataki na witrynę </span>?{' '}
                        {isMobile && (
                          <>
                            {' '}
                            <br />
                            <br />{' '}
                          </>
                        )}{' '}
                        Jak hakerzy znajdują <span className="bold"> luki bezpieczeństwa w kodzie strony </span>?{' '}
                        {isMobile && (
                          <>
                            {' '}
                            <br />
                            <br />{' '}
                          </>
                        )}{' '}
                        Które narzędzia zwiększą <span className="bold"> ochronę WordPress </span>, szczególnie - teraz?
                      </p>
                      <p className="webinarInfo">Wreszcie...</p>
                      <p className="webinarInfo">Jak ustrzec stronę www, jeśli jej właściciel nie jest specjalistą IT?</p>
                      <p className="webinarInfo">
                        Odpowiedzi można szukać na własną rękę (ryzykowne), albo prościej i pewniej - zapytać ekspertów z branży. Nadchodzący webinar
                        to szansa, by rozwiać wątpliwości w sferze zagrożeń dla witryny.
                      </p>
                      <Button classNames="btn--white-border webinarAssigne" label="Chcę wziąć udział" isLink link={webinar?.signup_url} />
                      <img className="img-ceo" src={Krzysieniek} alt="Krzychu prezes" />
                    </div>
                  )}
                  {!isMobile && (
                    <div className="plainText">
                      <p className="webinarInfo">
                        Skąd się biorą podejrzanie<span className="bold"> błędy autoryzacji i ataki na witrynę</span>? <br /> Jak hakerzy znajdują{' '}
                        <span className="bold">luki bezpieczeństwa w kodzie strony</span>? <br /> Które narzędzia zwiększą{' '}
                        <span className="bold">ochronę WordPress</span>, szczególnie - teraz?
                        <br />
                      </p>
                      <p className="webinarInfo">Wreszcie...</p>
                      <p className="webinarInfo">Jak ustrzec stronę www, jeśli jej właściciel nie jest specjalistą IT?</p>
                      <p className="webinarInfo">
                        Odpowiedzi można szukać na własną rękę (ryzykowne), albo prościej <br /> i pewniej - zapytać ekspertów z branży. Nadchodzący
                        webinar {!isMobile && <br />} to szansa, by rozwiać wątpliwości w sferze zagrożeń dla witryny.
                      </p>
                      <Button classNames="btn--white-border webinarAssigne" label="Chcę wziąć udział" isLink link={webinar?.signup_url} />
                      <img className="img-ceo" src={Krzysieniek} alt="Krzychu prezes" />
                    </div>
                  )}
                </div>
              </div>
              <p ref={woof}></p>
            </div>
          </div>
        </div>
        <div className="section__wrapper sectionWebinar">
          {!isMobile && <img className="corgi_paws-left" src={CorgiPawsLeft} alt="Łapki" />}
          <section className="ceoInfoWrapper">
            <div className="ceo-wrapper">
              <div className="ceo-wrapper--box">
                <div className="ceo-info">
                  Webinar poprowadzi <span className="ceoName"> Krzysztof Organek</span>. Korzystając z lat doświadczenia jako programista i kierownik
                  projektów informatycznych, Krzysztof pełni funkcję prezesa zarządu firmy programistycznej korg.software oraz jest pomysłodawcą{' '}
                  <br /> i właścicielem aplikacji do cyberbezpieczeństwa corgi.pro. {!isMobile && <br />} Prywatnie lubi spokój, hiszpańską piłkę{' '}
                  {isMobile && <br />} i wycieczki rowerowe.
                </div>
              </div>
            </div>
            {isMobile && <img className="baby-corgi" src={CorgiBaby} alt="Mały corgi" />}
            <div className="webinar-info">
              <div className="webinar-info--box">
                <p>
                  Ochrona w sieci to temat niezwykle istotny i aktualny. W kontekscie ostatnich wydarzeń i nowych zagrożeń cyber-bezpieczeństwa,{' '}
                  {!isMobile && <br />} w szczególności.
                </p>
                <p>Istnieją sprawdzone metody na bezpieczne surfowanie i publikownie treści.</p>
                <p>Omówimy je podczas darmowego spotkania online, na które już dziś można się zapisać. Jutro - może zabraknąć miejsc.</p>
                <div className="button-wrapper">
                  <Button classNames="btn--blue" label="Chcę wziąć udział" isLink link={webinar?.signup_url} />
                </div>
              </div>
            </div>
          </section>
          {!isMobile && <img className="corgi_paws-right" src={CorgiPawsRight} alt="Łapki" />}
        </div>
        <div className="section__wrapper">
          <div className="summary">
            {!isMobile && (
              <div className="image">
                <img src={CorgiFlies} alt="Corgi is flying" />
              </div>
            )}
            <div className="signUp">
              <h1 className="section--header signUpHeading">Widzimy się?</h1>
              {isMobile && (
                <div className="image">
                  <img src={CorgiFlies} alt="Corgi is flying" />
                </div>
              )}
              {isMobile && (
                <>
                  <p>
                    <span className="bold">Szkolenie online </span> to dostęp do wiedzy eksperckiej. Podczas spotkania nasi specjaliści poruszą
                    aspekty technologii witryn opartych {!isMobile && <br />} na <span className="bold">WordPress</span>, m.in.{' '}
                    <span className="bold">krytyczne luki i zagrożenia, nowe metody hakerów czy ataki na IP.</span>
                  </p>
                  <div className="common-errors">
                    <p>
                      <span className="bold">Błąd 404 zamiast</span>… dostępu do e-commerce?
                    </p>
                    <p>
                      <span className="bold">Treści dla dorosłych </span> zamiast… wizytówki?
                    </p>
                    <p>
                      <span className="bold">Wyskakujące okienka</span>, których nie da się zamknąć?
                    </p>
                  </div>
                  <p>Odetchnij z ulgą i posłuchaj o prostych metodach, które skutecznie chronią witryny.</p>
                  <div className="button">
                    <Button classNames="btn--blue" label="Tak, biorę udział" isLink link={webinar?.signup_url} />
                  </div>
                </>
              )}
              {!isMobile && (
                <>
                  <p>
                    <span className="bold">Szkolenie online </span> to dostęp do wiedzy eksperckiej. Podczas spotkania <br /> nasi specjaliści poruszą
                    aspekty technologii witryn opartych {!isMobile && <br />} na <span className="bold">WordPress </span>, m.in.{' '}
                    <span className="bold"> krytyczne luki i zagrożenia, nowe metody {!isMobile && <br />} hakerów czy ataki na IP</span>.
                  </p>
                  <div className="common-errors">
                    <p>
                      <span className="bold">Błąd 404 zamiast</span>… dostępu do e-commerce?
                    </p>
                    <p>
                      <span className="bold">Treści dla dorosłych </span> zamiast… wizytówki?
                    </p>
                    <p>
                      <span className="bold">Wyskakujące okienka</span>, których nie da się zamknąć?
                    </p>
                  </div>
                  <p>
                    Odetchnij z ulgą i posłuchaj o prostych metodach, które skutecznie <br /> chronią witryny.
                  </p>
                  <div className="button">
                    <Button classNames="btn--blue" label="Tak, biorę udział" isLink link={webinar?.signup_url} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CorgiWebinar;
