import { Connector } from 'Modules/WebsiteModule';
import { Website } from '../EnumTypes/ActionEnumTypes';

const success = (data) => ({
  type: Website.GET_ADDITIONAL_URLS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Website.GET_ADDITIONAL_URLS_FAILURE,
});

const getAdditionalUrl = (uuid) => async (dispatch) => {
  try {
    const data = await Connector.getAdditionalUrl(uuid);
    dispatch(success(data.data));
  } catch (err: any) {
    console.log(err.response);
    dispatch(failure());

    return false;
  }
};

export default getAdditionalUrl;
