import React from 'react';
import { AddWebsite } from '../EnumTypes/ActionEnumTypes';
import { Connector } from '../../../Modules/AddWebsiteModule';
import { FtpType } from 'Shared/models/AddWebsiteModel';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';

const success = (data) => ({
  type: AddWebsite.ADD_FTP_SUCCESS,
  payload: {
    data,
  },
});
const failure = (data) => ({
  type: AddWebsite.ADD_FTP_FAILURE,
  payload: {
    data,
  },
});

const addFtp = (data: FtpType) => async (dispatch) => {
  const id = toast.loading(<FormattedMessage id="global.toast.add_ftp_loading" />, { toastId: 'addFtp' });
  try {
    sessionStorage.setItem('ftp_connection_uuid', data.website_uuid);
    sessionStorage.setItem('ftp_expire_date', new Date(new Date().getTime() + 2 * 60000).toISOString());
    await Connector.addFtp(data);
    dispatch(success(data));
    toast.update(id, {
      render: (
        <div>
          <h2 className="toast-title toast-title--success">
            <FormattedMessage id="global.toast_succes" />
          </h2>
          <p>
            <FormattedMessage id="global.toast.add_ftp_success" />
          </p>
        </div>
      ),
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      // icon: <SVG type={SVG_TYPE.PASSED} />,
    });
    sessionStorage.removeItem('ftp_connection_uuid');
    sessionStorage.removeItem('ftp_expire_date');
    return true;
  } catch (err: any) {
    sessionStorage.removeItem('ftp_connection_uuid');
    sessionStorage.removeItem('ftp_expire_date');
    dispatch(failure(data));
    console.log(err);
    toast.update(id, {
      render: (
        <div>
          <h2 className="toast-title toast-title--failure">
            <FormattedMessage id="global.toast_failure" />
          </h2>
          <p>
            <FormattedMessage id="global.toast.add_ftp_failure" />
          </p>
        </div>
      ),
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG type={SVG_TYPE.CLOSE_CIRCLE_FILL} variant={SVG_TYPE.CLOSE_CIRCLE_FILL} />,
    });
    return false;
  }
};

export default addFtp;
