import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';

// const init = () => ({
//   type: User.GET_PARTNER_BALANCE_INIT,
// });

const success = data => ({
  type: User.GET_PARTNER_BALANCE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.GET_PARTNER_BALANCE_FAILURE,
});

const getPartnerBalance = (currency) => async dispatch => {
  // dispatch(init());
  try {
    const { data } = await Connector.getMyBalance(currency);
    dispatch(success(data));
  } catch (err) {
    console.log(err);
    dispatch(failure());
  }
};

export default getPartnerBalance;
