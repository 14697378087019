import { connect } from 'react-redux';
import { RootState } from 'Store/Reducers/RootReducer';
import NewsletterSignUp from './Main';
import { Actions as VActions, Selectors as VSelectors } from 'Modules/ViewManagementModule';
import { bindActionCreators } from 'redux';

type ConnectedP = {
  translation: string;
};

type DispatchedP = {
  actions?: {
    setAppTranslation: (lang: string) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      setAppTranslation: VActions.setAppTranslation,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  translation: VSelectors.getTranslation(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterSignUp);
