import React from 'react';
import Select, { components } from 'react-select';
import { FieldProps } from 'formik';
import { Loader, SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { ChromePicker } from 'react-color';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

type Props = {
  label?: string;
  options?: any;
  type: string;
  icon?: string;
  disabled: boolean;
  placeholder?: string;
  wrapperClassName?: string;
  rows?: number;
  field: any;
  errors: any;
  form: any;
  isMulti?: boolean;
  isPreacher?: boolean;
  country?: string;
  isClearable?: boolean;
  onBlur?: any;
  isLoaderVisible?: boolean;
  ftp?: any;
  intl?: any;
  color?: any;
  onInput?: any;
  blocked?: any;
  handleOnChange?: any;
  errorDown?: any;
  isSearchable?: true;
  svgType?: SVG_TYPE;
  onKeyPress?: any;
  errorBelow?: boolean;
  customOnChange?: any;
  inputOnFocus?: any;
  inputmode?: any;
  isTax?: boolean;
  autoComplete?: any;
};

type S = {
  selectValue: any;
  isSelected: boolean;
  showPlaceholderDate: boolean;
  isEyeVisible: boolean;
  password: string;
  theme_color: any;
  pickerOpen: boolean;
  // options: any;
};

type OptionType = {
  label: string;
  value: string;
};
let multiArray = [];

class RegularField extends React.PureComponent<Props & FieldProps, S> {
  constructor(props: Props & FieldProps) {
    super(props);
    this.state = {
      selectValue: null,
      isSelected: false,
      showPlaceholderDate: true,
      isEyeVisible: false,
      password: '',
      theme_color: this.props.color,
      pickerOpen: false,
      // options: countryList().getData(),
    };
  }

  componentDidMount(): void {
    if (this.props.color) {
      document.getElementById('picker_thumbnail').style.background = this.props.color;
    }
  }

  handleColorPick = () => {
    document.getElementById('picker_thumbnail').style.background = this.state.theme_color;
    this.props.form.setFieldValue(this.props.field.value, this.state.theme_color);
    this.props.field.onChange(this.state.theme_color);
  };

  handleChangeComplete = (color) => {
    this.setState({ theme_color: color.hex });
    this.handleColorPick();
    this.props.customOnChange && this.props.customOnChange(this.state.theme_color);
  };

  handleChange = (color) => {
    this.setState({ theme_color: color.hex });
    this.handleColorPick();
  };

  public render() {
    const {
      field,
      type,
      country,
      placeholder,
      wrapperClassName,
      disabled = false,
      errors,
      form,
      label,
      icon,
      options,
      isClearable = true,
      isLoaderVisible = false,
      onInput,
      handleOnChange,
      customOnChange,
      inputOnFocus,
      inputmode,
      errorDown,
      isTax,
      autoComplete,
    } = this.props;
    let formClasses: string = 'form__field';
    formClasses += type ? ` form__field--${type}` : '';
    formClasses += wrapperClassName ? ` form__field--${wrapperClassName} ` : '';
    formClasses += options ? ` form__field--${field.value} ` : '';
    formClasses += disabled ? ' form__field--disabled' : '';
    formClasses += form.touched[field.name] && errors[field.name] ? ' form__field--error' : '';

    // if (field.value && this.props.options && this.props.options.find(option => option.value === field.value)) {
    //   console.log(field.value);
    //   this.setState({ isSelected: true, selectValue: this.props.options.find(option => option.value === field.value) });
    // }
    const onFocus = () => {
      this.setState({ isSelected: true });
    };
    const onBlur = (e) => {
      this.setState({ isSelected: false });
      if (this.props.onBlur) {
        this.props.onBlur(e);
      }
    };

    const onChangeHanlder = (value) => {
      handleOnChange && handleOnChange(value);
    };

    const handleChange = (value) => {
      onChangeHanlder(value);
      if (value && this.props.isMulti) {
        multiArray = [];
        value.map((val: any) => multiArray.push(val.value));
        this.props.form.setFieldValue(field.name, multiArray);
        this.setState({ selectValue: multiArray.length ? multiArray : '' });
      } else if (value && this.props.isPreacher) {
        this.props.form.setFieldValue(field.name, [value.value]);
        this.setState({ selectValue: value });
      } else {
        this.props.form.setFieldValue(field.name, value ? value.value : '');
        this.setState({ selectValue: value });
      }
    };
    const onChange = (e) => {
      this.props.form.setFieldValue(field.name, e.currentTarget.files[0]);
    };

    const handleCustomChange = (e) => {
      customOnChange && customOnChange(e);
      field.onChange(e);
    };

    const DropdownIndicator = (props, event) => {
      return (
        <components.DropdownIndicator
          {...props}
          onClick={() => event?.currentTarget?.parentNode?.nextSibling.firstChild.click()}></components.DropdownIndicator>
      );
    };
    const togglePasswordVisibility = () => {
      this.setState((prevState) => ({ isEyeVisible: !prevState.isEyeVisible }));
    };

    const customFilter = (option, searchText) => {
      if (
        option.data.searchableLabel
          ? option.data.searchableLabel.toLowerCase().includes(searchText.toLowerCase())
          : option.data.label.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return true;
      }
      return false;
    };

    const customTaxFilter = (option, searchText) => {
      if (option.value.vat_country.toLowerCase().includes(searchText.toLowerCase())) {
        return true;
      }
      return false;
    };

    return (
      <div className={formClasses}>
        {type && type === 'select' ? (
          <>
            {/* <label className="select-label" htmlFor={field.name}> */}
            {label && (
              <label className="field-label" htmlFor={field.name}>
                {label}{' '}
                {form.touched[field.name] && !this.props.errorBelow && (
                  <span className={`${errorDown ? 'form__field--label-error-down' : 'form__field--label-error'}`}>
                    {errors[field.name] && `* ${errors[field.name]}`}
                  </span>
                )}
              </label>
            )}
            {this.props.isPreacher ? (
              <Select
                value={
                  field.value.length > 0
                    ? this.props.options &&
                      this.props.options.find((option) => field.value.find((preacher) => option.value === (preacher && preacher.uuid)))
                    : field.value.length === 0 && Array.isArray(field.value)
                    ? this.props.options && this.props.options.find((option) => option.value === null)
                    : ''
                }
                onChange={handleChange}
                onFocus={onFocus}
                isMulti={this.props.isMulti ? true : false}
                noOptionsMessage={() => 'Brak wyników'}
                onBlur={onBlur}
                options={options}
                
                isClearable={true}
                className="react-select"
                isSearchable={this.props.isSearchable}
                getOptionLabel={(props) => {
                  const { label, svgType }: any = props;
                  return (
                    <div className="select__option-container">
                      {svgType && <SVG type={svgType} />}
                      <span>{label}</span>
                    </div>
                  ) as unknown as string;
                }}
                classNamePrefix={`react-select react-select${this.props.isMulti ? '--is-multi' : ''}`}
                placeholder={placeholder || <FormattedMessage id="global.just_choose" />}
                filterOption={this.props.isSearchable && customFilter}
              />
            ) : (
              <>
                {/* {console.log(this.props.options?.find((option) => option.value === field.value))} */}
                {/* {console.log(this.props.options)} */}
                <Select
                  components={(event) => DropdownIndicator(this.props, event)}
                  value={
                    field.value || field.value == null || field.value === 0
                      ? this.props?.options && this.props.options?.find((option) => JSON.stringify(option.value) == JSON.stringify(field.value))
                      : ''
                  }
                  onChange={handleChange}
                  onFocus={onFocus}
                  isMulti={this.props.isMulti ? true : false}
                  noOptionsMessage={() => 'Brak wyników'}
                    onBlur={onBlur}
                  options={options}
                  isClearable={isClearable}
                  className={`react-select ${this.props.blocked ? 'react-select--blocked' : ''}`}
                  classNamePrefix="react-select"
                  isDisabled={this.props.blocked}
                  placeholder={placeholder}
                  isSearchable={this.props.isSearchable}
                  filterOption={isTax ? customTaxFilter : customFilter}
                  getOptionLabel={(props) => {
                    const { value, label, svgType }: any = props;
                    return (
                      <div className="select__option-container">
                        {svgType && <SVG type={svgType} />}
                        <span>{isTax ? value.vat_country : label}</span>
                      </div>
                    ) as unknown as string;
                  }}
                />
                {((!label && errors && form.touched) || this.props.errorBelow) && (
                  <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>
                )}
              </>
            )}
            {/* {icon && <i className={`${icon} ${field.value === 1 ? 'verify-success' : field.value === 0 ? 'verify-pending' : ''}`} />} */}
            {/* {errors && form.touched && <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>} */}
          </>
        ) : type && type === 'file' ? (
          <>
            <label className="field-label" htmlFor={field.name}>
              {label}
            </label>
            <input onInput={onInput} type={type} id={field.name} name={field.name} onChange={onChange} placeholder={placeholder} />
            {/* <i className={icon} /> */}
            {errors && form.touched && (
              <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>
            )}
          </>
        ) : type && type === 'date' ? (
          <>
            <input
              {...field}
              type={(this.state.showPlaceholderDate || form.values.isUnknown) && !field.value ? 'text' : 'date'}
              disabled={form.values.isUnknown}
              onFocus={() => this.setState({ showPlaceholderDate: false })}
              onBlur={() => !form.values.isUnknown && this.setState({ showPlaceholderDate: true })}
              name={field.name}
              placeholder={placeholder}
              onChange={field.onChange}
              checked={field.value}
              onInput={onInput}
            />
            {/* {icon && <i className={icon} />} */}
            {errors && form.touched && (
              <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>
            )}
          </>
        ) : type && type === 'textarea' ? (
          <>
            <label className="field-label" htmlFor={field.name}>
              {label}{' '}
              {form.touched[field.name] && <span className="form__field--label-error">{errors[field.name] && `* ${errors[field.name]}`}</span>}
            </label>
            <textarea
              {...field}
              type={type}
              disabled={form.values.isUnknown}
              onFocus={() => this.setState({ showPlaceholderDate: false })}
              onBlur={() => !form.values.isUnknown && this.setState({ showPlaceholderDate: true })}
              name={field.name}
              placeholder={placeholder}
              onChange={field.onChange}
              // checked={field.value}
            />
            {/* {icon && <i className={icon} />} */}
            {/* {errors && form.touched && <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>} */}
          </>
        ) : type && type === 'countryList' ? (
          <>
            <label className="field-label" htmlFor={field.name}>
              {label}
              {form.touched[field.name] && <span className="form__field--label-error">{errors[field.name] && `* ${errors[field.name]}`}</span>}
            </label>

            {country ? (
              <Select
                options={options}
                onChange={(value) => form.setFieldValue(field.name, value.value)}
                value={options.find(({ value: optionLabel }) => optionLabel === country)}
                placeholder={''}
                isSearchable={this.props.isSearchable}
              />
            ) : (
              <Select
                options={options}
                onChange={(value) => form.setFieldValue(field.name, value)}
                value={options?.find(({ value: optionLabel }) => optionLabel === field?.value?.value)}
                // value={options.find(({ label: optionLabel }) => optionLabel === field.value.label)}
                // onChange={(value) => form.setFieldValue(field.name, value.value)}
                placeholder={''}
                isSearchable={this.props.isSearchable}
              />
            )}
          </>
        ) : type && type === 'password' ? (
          <>
            {label && (
              <label className="field-label" htmlFor={field.name}>
                {label}
                {form.touched[field.name] && !this.props.errorBelow && (
                  <span className="form__field--label-error">{errors[field.name] && `* ${errors[field.name]}`}</span>
                )}
              </label>
            )}
            <input
              autoComplete={field.name === 'password' ? 'on' : autoComplete ? autoComplete : 'new-password'}
              type={this.state.isEyeVisible ? 'text' : type}
              name={field.name}
              onFocus={() => inputOnFocus && inputOnFocus()}
              onChange={(e) => {
                this.setState({ password: e.target.value });
                customOnChange && customOnChange(e);
                field.onChange(e);
              }}
              value={field.value}
              placeholder={placeholder}
              disabled={disabled}
              onBlur={onBlur}
              onInput={onInput}
              className="form__field--password"
            />
            <div className="eye" onClick={togglePasswordVisibility}>
              <SVG type={this.state.isEyeVisible ? SVG_TYPE.CROSSED_EYE : SVG_TYPE.EYE} />
            </div>
            {this.props.svgType && <SVG className="form__field-svg" type={this.props.svgType} />}
  
            {((!label && errors && form.touched) || this.props.errorBelow) && (
              <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>
            )}
          </>
        ) : type && type === 'picker' ? (
          <>
            <label className="picker" onClick={() => this.setState({ pickerOpen: !this.state.pickerOpen })}>
              <div className="picker__thumbnail" id="picker_thumbnail" />
              <div className="picker__hex" id="picker_hex">
                <div>{this.state.theme_color}</div>
              </div>
              <div className="picker__dropDown">
                <SVG type={SVG_TYPE.DROP_DOWN} />
              </div>
            </label>
            {this.state.pickerOpen && (
              <div className="picker__menu">
                <ChromePicker color={this.state.theme_color} onChangeComplete={this.handleChangeComplete} onChange={this.handleChange} disableAlpha />
              </div>
            )}
          </>
        ) : (
          <>       
            {label && (
              <label className="field-label" htmlFor={field.name}>
                {label}
                {errors[field.name] && !this.props?.errorBelow && (
                  <span className={`${errorDown ? 'form__field--label-error-down' : 'form__field--label-error'}`}>
                    {errors[field.name] && `* ${errors[field.name]}`}
                  </span>
                )}
              </label>
            )}
            <input
              autoComplete={field.name === 'password' ? 'on' : autoComplete ? autoComplete : 'new-password'}
              type={type}
              name={field.name}
              onChange={handleCustomChange}
              onKeyPress={this.props.onKeyPress}
              value={field.value}
              placeholder={placeholder}
              disabled={disabled}
              onBlur={onBlur}
              onInput={onInput}
              inputMode={inputmode}
            />
            {isLoaderVisible && <Loader width={20} height={20} />}
            {((!label && errors && form.touched) || this.props.errorBelow) && (
              <div className="form__field--label-error">
                {_.get(form.touched, field.name) && <span>{_.get(errors, field.name)}</span>}
              </div>
            )}
            {icon && <img className="icon" src={icon} alt="" />}
            {this.props.svgType && <SVG className="form__field--svg" type={this.props.svgType} />}
          </>
        )}
      </div>
    );
  }
}

export default RegularField;
