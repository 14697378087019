import { connect } from 'react-redux';
import { RootState } from 'Store/Reducers/RootReducer';
import { Selectors as VSelectors } from 'Modules/ViewManagementModule';
import { Actions as AuthActions } from 'Modules/AuthModule';
import { bindActionCreators } from 'redux';
import RemindPassword from './Main';
import { injectIntl } from 'react-intl';
import { INTL_TYPE } from 'Shared/enums';

type ConnectedP = {
  translation: string;
};

type DispatchedP = {
  actions?: {
    recoverPassword: (email: string) => any;
  };
};

export type MainType = ConnectedP & DispatchedP & INTL_TYPE;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      recoverPassword: AuthActions.recoverPassword,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  translation: VSelectors.getTranslation(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RemindPassword));
